<template>
    <div class="main_Container_Flex">
        <div class="auth_Container">
            <!-- <h2>Войти</h2> -->
            <div class="h2">Калькулятор РТМ</div>
            <input class="input" type="text" v-model="username" placeholder="Логин">
            <input class="input" type="password" v-model="password" placeholder="Пароль">
            <button @click="login">Войти</button>
        </div>
    </div>

</template>

<script>
// import argon2 from 'argon2';
// import bcrypt from 'bcryptjs'
export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        async login() { 
            // const hashedPassword = await bcrypt.hashSync(this.password,10);
            // console.log(hashedPassword)
            // // const hashedPassword = 546545
            // console.log(hashedPassword)
            const response = await fetch(process.env.VUE_APP_BASE_URL+ '/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: this.username, password: this.password }),
            });

            if (response.ok) {
                // console.log(this.username)
                // localStorage.setItem('userName', this.username);
                sessionStorage.setItem("userName", this.username);
                console.log('Успешно');
                window.location.href = '/';
            } else {
                console.error('Не получилось авторизоваться');
                alert("Имя пользователя или пароль введены неверно")
            }
        },
    },
};
</script>

<style>
.auth_Container {
    max-width: 800px;
}

@media(max-width: 450px) {
    .auth_Container {
        max-width: 90%;
    }
}

.main_Container_Flex {
    background-color: #cce6ff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.auth_Container .input {
    width: 100%;
}

@media(max-width: 400px) {
    .auth_Container .input {
        max-width: 350px;
        width: auto;
    }
}

h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
}
</style>