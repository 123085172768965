<template lang="">
    <!-- название проекта -->
    <div class="changeProjectNameBigContainer">
            <div class="changeProjectNameContainer">
                <div class="h2">Наименование проекта</div>
                <input class="input" v-bind:placeholder = ProjectName v-model="ProjectName"/>
                <div class="appButton" @click="handleClickName(projectId,ProjectName)">Изменить название проекта</div>
                <DeleteShieldButton :project-id=projectId />
            </div>
    </div>
            
    <div class="main_Container_Parameters">
        <div @click="showParameters=!showParameters" class="row_Container">
            <div class="h2">Параметры проекта</div> 
            <div :class="{ 'active': showParameters, 'arrow_Container':showParameters} "> <img class="arrow_Png" src="../../../public/arrow.png" alt=""></div>
        </div> 
        <div v-if="showParameters" class="InputProjectParamsContainer2">
            <div class="InputProjectParamsContainer3">
                <!-- здесь разгруппировываем инпуты -->
                <div class="groupInputsProjectParams">
                    <div class="h3">Выбрать параметры понижающего трансформатора</div>
                    <InputProjectParams 
                    @updateProjectParams ="(label, inputText)=>{ProjectData1[label] = inputText, console.log(inputText), ProjectData[label] = inputText}"
                    v-for="(item,index) in ProjectData1 " 
                    :key="index"
                    :value-input = "item"
                    :label-text="index" 
                    class="InputProjectParamsInput"
                    />
                    <div class="appButton" @click="() => chooseTransPopupBool = true">Выбрать трансформатор</div>
                </div>
                <div class="groupInputsProjectParams">
                    <div class="h3">Данные вводного авт.</div>
                    <InputProjectParams 
                    @updateProjectParams ="(label, inputText)=>ProjectData2[label] = inputText"
                    v-for="(item,index) in ProjectData2 " 
                    :key="index"
                    :value-input = "item"
                    :label-text="index" 
                    class="InputProjectParamsInput"
                    />
                </div>
                <div class="groupInputsProjectParams">
                    <div class="h3">Поправоч.коэф.*</div>
                    <InputProjectParams 
                    @updateProjectParams ="(label, inputText)=>{ProjectData3[label] = inputText, console.log(inputText)}"
                    v-for="(item,index) in ProjectData3 " 
                    :key="index"
                    :value-input = "item"
                    :label-text="index" 
                    class="InputProjectParamsInput"
                    />
                    <div style="text-align:left">* - принимается по ГОСТ Р 50571.5.52-2011, Таблица В.52.1</div>
                </div>
            </div>

            <div class="ProjectData4_Container">
                <InputProjectParams 
                @updateProjectParams ="(label, inputText)=>ProjectData4[label] = inputText"
                v-for="(item,index) in ProjectData4 " 
                :key="index"
                :value-input = "item"
                :label-text="index" 
                class="InputProjectParamsInput"
                />
             </div>
            <!-- <div class="InputProjectParams InputProjectParamsContainer">
                <InputProjectParams 
                @updateProjectParams ="(label, inputText)=>ProjectData[label] = inputText"
                v-for="(item,index) in ProjectData " 
                :key="index"
                :value-input = "item"
                :label-text="index" 
                class="InputProjectParamsInput"
                />
            </div> -->
    
            <div class="appButton updateProjectParameters" @click="handleClick(projectId,ProjectDataRes)" > Обновить параметры проекта</div>
        </div>
    </div>

    <transPopupContainer 
    @togglechooseTransPopupBool="{ chooseTransPopupBool = false;  }" 
    v-if="chooseTransPopupBool" 
    @newValueTrans="(data) => setNewValueTrans(data)"
    />
</template>
<script>

// /update-project-data/

import { ref, watchEffect, onMounted } from 'vue'

import InputProjectParams from './InputProjectParams.vue';
import DeleteShieldButton from '../../components/ChangeShieldData/DeleteShieldButton.vue'
import transPopupContainer from '../ShieldsData/TransPopup/TransPopupContainer.vue'

export default {
    props: {
        projectId: {
            type: String,
            required: true
        }
    },
    // emits:["giveParametersTop","giveNameTop","giveProjectChanged"],
    emits: ["giveParametersTop", "giveNameTop"],
    methods: {
        async handleClick(id, data) {
            console.log(id)
            console.log(data)

            // проверка инпутов на пустоту


            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-data/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                        data: data
                    })
                })
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    try {
                        const response_2 = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
                            method: 'POST',
                            body: JSON.stringify({
                                id: id,
                                changeExcel: true,
                                changeNCad: true
                            })
                        })
                        if (response_2.ok) {
                            alert("Данные изменены")
                            this.$emit('giveParametersTop', data)
                            console.log("data: " + data)
                            location.reload()
                        }
                        else {
                            throw new Error('Неправильный ответ сети');
                        }
                    }
                    catch (error) {
                        console.error('Ошибка изменения данных:', error);
                    }


                }
            }
            catch (error) {
                alert("Ошибка изменения данных: " + error)
            }
        },



        async handleClickName(id, name) {
            console.log(id)
            console.log(name)
            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-name/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                        name: name
                    })
                })
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    // location.reload()
                    alert("Данные изменены")
                    location.reload()
                    console.log("name: " + name)
                }
            }
            catch (error) {
                alert("Ошибка изменения имени: " + error)
            }
        }

    },
    components: {
        InputProjectParams,
        DeleteShieldButton,
        transPopupContainer
    },
    setup(props, { emit }) {

        const chooseTransPopupBool = ref(false)

        const id = ref(props.projectId);


        watchEffect(() => {
            id.value = props.projectId;
        });

        onMounted(async () => {
            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/shield/?id=' + props.projectId);
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                }
                const data = await response.json();
                ProjectData.value = data.data; // сохраняем полученные данные в переменной shield
                ProjectName.value = data.name
                // ProjectChanged.value = data.changed
                // console.log(ProjectChanged.value )
                emit('giveParametersTop', ProjectData.value)
                emit('giveNameTop', ProjectName.value)
                // emit('giveProjectChanged', ProjectChanged.value)

                // распределяем элементы ProjectData по группам
                const copyProjectData = ProjectData.value
                for (let key in copyProjectData) {
                    if (key === "R1" || key === "Ro" || key === "X1" || key === "Xo") {
                        ProjectData1.value[key] = copyProjectData[key]
                    }
                }
                for (let key in copyProjectData) {
                    if (key === "Длина, км" || key === "Кратноть Iп, КП") {
                        ProjectData2.value[key] = copyProjectData[key]
                    }
                }
                for (let key in copyProjectData) {
                    if (key === "Ikz3max" || key === "Ikz3min" ||
                        key === "Определение Ikз(3) max в месте подключения КТП" ||
                        key === "Определение Ikз(3) min в месте подключения КТП") {
                        ProjectData3.value[key] = copyProjectData[key]
                    }
                }
                for (let key in copyProjectData) {
                    if (key != "R1" &&
                        key != "Ro" &&
                        key != "X1" &&
                        key != "Xo" &&
                        key != "Длина, км" &&
                        key != "Кратноть Iп, КП" &&
                        key != "Определение Ikз(3) max в месте подключения КТП" &&
                        key != "Определение Ikз(3) min в месте подключения КТП" &&
                        key != "Ikz3max" &&
                        key != "Ikz3min") {
                        ProjectData4.value[key] = copyProjectData[key]
                    }
                }


            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        });

        const ProjectData = ref({})

        const ProjectData1 = ref({})
        const ProjectData2 = ref({})
        const ProjectData3 = ref({})
        const ProjectData4 = ref({})

        const ProjectDataRes = ref({})


        const ProjectName = ref('')
        // const ProjectChanged = ref(false)
        const showParameters = ref(false)

        // Отслеживаем изменения в ProjectData1, ProjectData2, ProjectData3 и ProjectData4
        // и обновляем ProjectDataRes
        watchEffect(() => {

            // распределяем элементы ProjectData по группам
            let copyProjectData = ProjectData.value
            
            console.log(copyProjectData)
            for (let key in copyProjectData) {
                if (key === "R1" || key === "Ro" || key === "X1" || key === "Xo") {
                    console.log("sdxgjiosdrjgpisdigjd")
                    ProjectData1.value[key] = copyProjectData[key]
                }
            }
            console.log(ProjectData1.value)

            ProjectDataRes.value = {
                ...ProjectData1.value,
                ...ProjectData2.value,
                ...ProjectData3.value,
                ...ProjectData4.value,
            };
        });

        function setNewValueTrans(data) {
            console.log(data)
            console.log(ProjectData.value)
            ProjectData.value["R1"] = data[0]
            ProjectData.value["Ro"] = data[1]
            ProjectData.value["X1"] = data[2]
            ProjectData.value["Xo"] = data[3]
            chooseTransPopupBool.value = false;
        }

        return {
            id,
            ProjectData,
            showParameters,
            ProjectName,
            ProjectData1,
            ProjectData2,
            ProjectData3,
            ProjectData4,
            ProjectDataRes,
            chooseTransPopupBool,
            setNewValueTrans
        }
    }
}
</script>
<style>
.changeProjectNameBigContainer {
    width: -webkit-fill-available;
}

.changeProjectNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    /* width: 100%; */
    flex-wrap: wrap;
}

.changeProjectNameContainer .input,
.changeProjectNameContainer .appButton {
    max-height: 20px;
    font-size: 14px;
    margin-right: 10px;
    padding: 0.5em 0.8em;
}

.changeProjectNameContainer .appButton {
    display: flex;
    align-items: center;
    padding: 0.5em 0.8em;
}

.h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.changeProjectNameContainer .h2 {
    margin-right: 10px;
}

@media(max-width: 800px) {

    .changeProjectNameContainer .input,
    .changeProjectNameContainer .appButton {
        max-height: 15px;
        font-size: 14px;
        margin-right: 5px;
    }

    .changeProjectNameContainer h2 {
        font-size: 16px;
        margin-right: 5px;
    }
}

.InputProjectParamsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    justify-content: center;
}

.InputProjectParamsInput {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    flex-direction: column;
    display: flex;
    justify-content: start;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 15px;
    font-size: 14px;
}

@media(max-width: 400px) {
    .InputProjectParamsInput {
        width: 100%;
        margin-bottom: 7px;
    }
}

.updateProjectParameters {
    width: 300px;
    margin-bottom: 1rem;
}

.row_Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 15px;
    cursor: pointer
}

.arrow_Png {
    object-fit: contain;
    max-width: 15px;
    max-height: 20px;
    transition: 0.3s ease;
    cursor: pointer;
}

.row_Container:hover .arrow_Png {
    transform: scale(1.1);
}

.main_Container_Parameters {
    border-radius: 30px;
    background-color: #fff;
    margin-bottom: 20px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
}

.InputProjectParams {
    margin-bottom: 20px;
}

.appButton.updateProjectParameters {
    /* font-size: 18px; */
}

.InputProjectParamsContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.arrow_Container.active .arrow_Png {
    transform: rotate(90deg);
}

.h3 {
    margin: 15px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}

.groupInputsProjectParams {
    padding: 15px;
    border: 1px solid #004c97;
    border-radius: 20px;
    margin: 20px;
}

.InputProjectParamsContainer3 {
    display: flex;
    flex-wrap: wrap;
}

.groupInputsProjectParams {
    /* flex-grow: 1;
    flex-shrink: 1; */
    max-width: 25%;
    width: 25%;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
    .groupInputsProjectParams {
        /* flex-grow: 0.25;
        flex-shrink: 0.25; */
        max-width: 25%;
        width: 25%;
    }
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .groupInputsProjectParams {
        max-width: 25%;
        width: 25%;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .groupInputsProjectParams {
        max-width: 100%;
        width: 100%;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .groupInputsProjectParams {
        max-width: 100%;
        width: 100%;
    }
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    .groupInputsProjectParams {
        max-width: 100%;
        width: 100%;
    }
}

.InputProjectParamsContainer3>div {
    height: fit-content;
}

.ProjectData4_Container {
    display: flex;
    flex-wrap: wrap;
}
</style>