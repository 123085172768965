<template>
  <div style="margin-bottom: 50px;">
    <div id="nav" class="headerContainer">
      <router-link to="/shields/" class="logoContain"><img src="..\public\logo.svg" /> </router-link>
      <!-- <div class="buttonAccountControl">Выйти</div> -->
      <LogOut />
    </div>
    <router-view></router-view>
    <a href="https://docs.google.com/forms/d/1GIswMYEgbUI_VaQGtizwxaN08eMXu3ZBXVDUDDYmn3Y/edit"
      class="link_work">Трудоустройство в КРУС-Запад</a>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import FuncPage from './pages/FuncPage.vue'
import LogOut from './pages/LogoutPage.vue'

// let arr = []
// guide_items.forEach(element => {
//   let name = element.name
//   let ki = element.ki
//   let cos = element.cos
//   let id = element.id
//   let parents = []
//   if (id >= 2 & id <= 58) {
//     parents.push(1)
//     parents.push(1.1)
//   }
//   if (id >= 59 & id <= 109) {
//     parents.push(1)
//     parents.push(1.2)
//   }
//   if (id >= 110 & id <= 193) {
//     parents.push(1)
//     parents.push(1.3)
//   }

//   if (id >= 194 & id <= 216) {
//     parents.push(1)
//     parents.push(1.4)
//   }

//   if (id >= 217 & id <= 286) {
//     parents.push(1)
//     parents.push(1.5)
//   }
//   if (id >= 287 & id <= 479) {
//     parents.push(1)
//     parents.push(1.6)
//   }

//   if (id >= 480 & id <= 645) {
//     parents.push(1)
//     parents.push(1.7)
//   }

//   if (id >= 646 & id <= 793) {
//     parents.push(1)
//     parents.push(1.8)
//   }

//   if (id >= 794 & id <= 796) {
//     parents.push(1)
//     parents.push(1.9)
//   }

//   if (id >= 797 & id <= 821) {
//     parents.push(1)
//     parents.push(1.10)
//   }

//   if (id >= 822 & id <= 865) {
//     parents.push(1)
//     parents.push(1.11)
//   }

//   if (id >= 866 & id <= 938) {
//     parents.push(1)
//     parents.push(1.12)
//   }


//   if (id >= 939 & id <= 961) {
//     parents.push(1)
//     parents.push(1.13)
//   }

//   if (id >= 962 & id <= 987) {
//     parents.push(1)
//     parents.push(1.14)
//   }

//   if (id >= 988 & id <= 1001) {
//     parents.push(1)
//     parents.push(1.15)
//   }

//   if (id >= 1002 & id <= 1017) {
//     parents.push(1)
//     parents.push(1.16)
//   }

//   if (id >= 1018 & id <= 1029) {
//     parents.push(1)
//     parents.push(1.17)
//   }

//   if (id >= 1030 & id <= 1068) {
//     parents.push(1)
//     parents.push(1.18)
//   }

//   if (id >= 1069 & id <= 1091) {
//     parents.push(1)
//     parents.push(1.19)
//   }

//   if (id >= 1092 & id <= 1098) {
//     parents.push(1)
//     parents.push(1.20)
//   }
//   if (id >= 1099 & id <= 1114) {
//     parents.push(1)
//     parents.push(1.21)
//   }
//   if (id >= 1115 & id <= 1155) {
//     parents.push(1)
//     parents.push(1.22)
//   }
//   if (id >= 1156 & id <= 1219) {
//     parents.push(1)
//     parents.push(1.23)
//   }
//   if (id >= 1220 & id <= 1288) {
//     parents.push(1)
//     parents.push(1.24)
//   }
//   if (id >= 1289 & id <= 1388) {
//     parents.push(1)
//     parents.push(1.25)
//   }
//   if (id >= 1389 & id <= 1412) {
//     parents.push(1)
//     parents.push(1.26)
//   }
//   if (id >= 1413 & id <= 1441) {
//     parents.push(1)
//     parents.push(1.27)
//   }
//   if (id >= 1442 & id <= 1476) {
//     parents.push(1)
//     parents.push(1.25)
//   }
//   if (id >= 1477 & id <= 1534) {
//     parents.push(2)
//     parents.push(2.1)
//   }
//   if (id >= 1535 & id <= 1538) {
//     parents.push(2)
//     parents.push(2.2)
//   }
//   if (id >= 1539 & id <= 1543) {
//     parents.push(2)
//     parents.push(2.3)
//   }
//   if (id >= 1544 & id <= 1561) {
//     parents.push(2)
//     parents.push(2.4)
//   }
//   if (id >= 1562 & id <= 1580) {
//     parents.push(2)
//     parents.push(2.5)
//   }
//   if (id >= 1581 & id <= 1686) {
//     parents.push(2)
//     parents.push(2.6)
//   }
//   if (id >= 1687 & id <= 1701) {
//     parents.push(2)
//     parents.push(2.7)
//   }
//   if (id >= 1703 & id <= 1746) {
//     parents.push(2)
//     parents.push(2.8)
//   }


//   if (id >= 1747 & id <= 1831) {
//     parents.push(3)
//   }

//   // let name = element.name
//   // let ki = element.ki
//   // let cos = element.cos
//   // let id = element.id
//   // let parents = []
//   arr.push({name,ki,cos,id,parents})
// });

export default {
  name: 'App',
  components: {
    //FuncPage,
    LogOut
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0px;
}

.headerContainer {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #004c97;
  align-items: center;
  /* margin-bottom: 1.4rem; */
}

.logoContain {
  max-width: 200px;
}

.logoContain img {
  width: 100%;
  object-fit: contain;
}

@media(max-width: 700px) {
  .logoContain {
    max-width: 200px;
  }

}

@media(max-width: 700px) {
  .logoContain {
    max-width: 150px;
  }
}

.buttonAccountControl {
  background-color: #ECF5FF;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 700;
  font-size: 1.2em;
  cursor: pointer;
  transition: 0.3s ease;
}

.buttonAccountControl:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
}

.link_work {
  margin-bottom: 10px;
  margin-right: 10px;
  right: 0;
  bottom: 0;
  position: fixed;
  color: rgb(112, 112, 112);
}
</style>
