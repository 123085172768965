export default {
    categories: [
        {
            id: 1,
            name: "1. Расчётные коэффициенты электрических нагрузок отдельных электроприемников",
            level: 1,
            parent: []
        },
        {
            id: 2,
            name: "2. РАСЧЕТНЫЕ КОЭФФИЦИЕНТЫ ЭЛЕКТРИЧЕСКИХ НАГРУЗОК ПО УСТАНОВКЕ, ПРОЦЕССУ, ЦЕХУ, ПРЕДПРИЯТИЮ В ЦЕЛОМ",
            level: 1,
            parent: []
        },
        {
            id: 3,
            name: "3. РАСЧЕТНЫЕ КОЭФФИЦИЕНТЫ ЭЛЕКТРИЧЕСКИХ НАГРУЗОК НЕФТЯНЫХ СКВАЖИН",
            level: 1,
            parent: []
        },
        {
            id: 1.1,
            name: "1.1. МАШИНОСТРОИТЕЛЬНАЯ И МЕТАЛЛООБРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.2,
            name: "1.2. АВТОМОБИЛЬНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },

        {
            id: 1.3,
            name: "1.3. ПРИБОРОСТРОИТЕЛЬНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.4,
            name: "1.4. НЕФТЯНАЯ И НЕФТЕПЕРЕРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.5,
            name: "1.5. ЭЛЕКТРОТЕХНИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.6,
            name: "1.6. ЧЕРНАЯ МЕТАЛЛУРГИЯ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.7,
            name: "1.7. ГОРНОДОБЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.8,
            name: "1.8. ЛЕСНАЯ И ДЕРЕВООБРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.9,
            name: "1.9. ЦЕЛЛЮЛОЗНО-БУМАЖНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.10,
            name: "1.10. ГИДРОЛИЗНОЕ ПРОИЗВОДСТВО",
            level: 2,
            parent: [1]
        },
        {
            id: 1.11,
            name: "1.11. ПРОМЫШЛЕННОСТЬ СТРОИТЕЛЬНЫХ МАТЕРИАЛОВ И СТРОИТЕЛЬСТВО",
            level: 2,
            parent: [1]
        },
        {
            id: 1.12,
            name: "1.12. ХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.13,
            name: "1.13. ЛЕГКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.14,
            name: "1.14. ХЛОПЧАТОБУМАЖНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.15,
            name: "1.15. ТЕКСТИЛЬНО-ГАЛАНТЕРЕЙНАЯ И ТРИКОТАЖНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.16,
            name: "1.16. ЛЬНЯНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.17,
            name: "1.17. ПЕНЬКО-ДЖУТОВАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.18,
            name: "1.18. ШЕРСТЯНАЯ ПРОМЫШЛЕННОСТЬ И ПРОИЗВОДСТВО КОВРОВ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.19,
            name: "1.19. ШЕЛКОВАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.20,
            name: "1.20. ШВЕЙНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.21,
            name: "1.21. КОЖГАЛАНТЕРЕЙНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.22,
            name: "1.22. КОЖЕВЕННАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.23,
            name: "1.23. ОБУВНАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },

        {
            id: 1.24,
            name: "1.24. МЕХОВАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.25,
            name: "1.25.  ПРОМЫШЛЕННОСТЬ ИСКУССТВЕННЫХ КОЖ И ПЛЕНОЧНЫХ МАТЕРИАЛОВ ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.26,
            name: "1.26. ФАРФОРО-ФАЯНСОВАЯ  ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.27,
            name: "1.27. ВАЛЯЛЬНО-ВОЙЛОЧНАЯ  ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [1]
        },
        {
            id: 1.28,
            name: "1.28. ПРЕДПРИЯТИЯ ПО ИЗГОТОВЛЕНИЮ ТЕХОСНАСТКИ И ПЛАСТМАССОВОЙ ФУРНИТУРЫ",
            level: 2,
            parent: [1]
        },
        {
            id: 2.1,
            name: "2.1. ЧЕРНАЯ МЕТАЛЛУРГИЯ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.2,
            name: "2.2. ЛЕСНАЯ И ДЕРЕВООБРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.3,
            name: "2.3. ЛЕГКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },

        {
            id: 2.4,
            name: "2.4. ГОРНОДОБЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.5,
            name: "2.5. НЕФТЯНАЯ И НЕФТЕПЕРЕРАБАТЫВАЮЩАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.6,
            name: "2.6. ХИМИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.7,
            name: "2.7.ПОЛИГРАФИЧЕСКАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        },
        {
            id: 2.8,
            name: "2.8. ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ",
            level: 2,
            parent: [2]
        }
    ]
}