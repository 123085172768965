<template lang="">
    <div>
        <div> {{label}}</div>
        <input 
            v-if="input" 
            v-bind:class="{ 'invalid': invalid, 'input': true}" 
            type="text" 
            v-model="inputValue"
            @input="handleInput"
        />
        <!-- @input="$emit('updateFiderData', label, $event.target.value); 
            validateInput(label, $event.target.value)"
             v-bind:placeholder=value  -->
        <select class="input" v-model="selected" v-if="select" @change="handleChange">
             <option value="" disabled selected>Выбрать</option> 
            <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
        </select>
    </div>
</template>
<script>
import { ref, watchEffect, watch } from 'vue';

export default {
    props: {
        labelText: String,
        valueInput: String,
        voltage: String,
        valueEP: Object,
        fiderId: String
    },
    // methods: {
    //     validateInput(label, value) {
    //         // Добавьте здесь проверки для валидации данных
    //         // Например, можно проверить наличие значений, тип данных и т.д.
    //         const regex = /[^.,0-9]/;
    //         const numericInputs = ["Кол-во ЭП, n", "Номинальная (установленная) мощность, кВт", "Коэфф. исп.Кu", "Коэфф. cosφ", "Длина кабеля, км", "Кол-во жил", "Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)"];
    //         if (!numericInputs.includes(label)) {
    //             console.log(value)
    //         } else {
    //             if (regex.test(value)) {
    //                 console.log("Строка содержит буквы или другие символы, кроме точки и запятой.");
    //                 alert("Введено не число")
    //             }
    //         }
    //     },
    //     handleChange() {
    //         this.$emit('updateFiderData', this.label, this.selected);
    //     }
    // },
    setup(props, { emit }) {
        let input = ref(false)
        let select = ref(false)
        let options = ref([])
        let selected = ref('')
        const varPropsValueInput = ref(props.valueInput)
        let invalid = ref(false)
        const label = ref(props.labelText);
        const value = ref(props.valueInput);
        const inputValue = ref(props.valueInput)

        watchEffect(() => {
            if (props.labelText == "Напряжение, В") {
                select.value = true,
                    options.value = [380, 220]
            }
            else if (props.labelText == "Тип автомата") {
                select.value = true,
                    options.value = ["АВ", "АВ+УЗО", "ДИФ(тип A)", "ДИФ(тип AC)", "ДИФ(тип Asi)", "ДИФ(тип S)"]
            }
            else if (props.labelText == "Кол-во полюсов автомата" && props.voltage == "380") {
                select.value = true,
                    // options = ["3P", "P", "P+N", "3P+N"]
                    options.value = ["3P", "3P+N"]
            }
            else if (props.labelText == "Кол-во полюсов автомата" && props.voltage == "220") {
                select.value = true,
                    // options = ["3P", "P", "P+N", "3P+N"]
                    options.value = ["P", "P+N"]
            }
            else if (props.labelText == "Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)") {
                select.value = true,
                    options.value = ["C", "D", "B"]
            }
            else if (props.labelText == "Тип кабеля") {
                select.value = true,
                    options.value = ["ВВГнг", "ВВГнг(А) ", "ВВГнг(А)-ХЛ ", "ВВГнг(А)-LS", "ВВГнг(А)-FRLS", "КВВГнг(А)", "КВВГнг(А)-ХЛ", "КПБП", "ВБШвнг(А)", "ВБШвнг(А)-ХЛ"]
            }
            else if (props.labelText == "Способ прокладки кабеля") {
                select.value = true,
                    options.value = ["На воздухе", "В земле"]
            }
            else if (props.labelText == "Тип загрузки") {
                select.value = true,
                    options.value = ["Двигатель", "Освещение", "Электрообогрев", "Другое"]
            }
            else if (props.labelText == "Наименование ЭП (справочный)" & props.valueEP.fiderId == props.fiderId) {
                inputValue.value = props.valueEP.name
                input.value = true
            }
            else if (props.labelText == "Коэфф. исп.Кu" & props.valueEP.fiderId == props.fiderId) {
                inputValue.value = props.valueEP.ki
                input.value = true
            }
            else if (props.labelText == "Коэфф. cosφ" & props.valueEP.fiderId == props.fiderId) {
                inputValue.value = props.valueEP.cos
                input.value = true
            }
            else {
                input.value = true
            }
        });


        // const value = ref(props.valueInput);

        watch(inputValue, () => {
            handleInput()
        })
        watchEffect(() => {
            label.value = props.labelText;
            value.value = props.valueInput;
            inputValue.value = props.valueInput
            if (props.labelText == "Кол-во полюсов автомата") {

                if (options.value.indexOf(varPropsValueInput.value) == -1) {
                    // value.value = options.value[0]
                    console.log("Меняем значение selected")
                    selected.value = options.value[0]
                    varPropsValueInput.value = selected.value
                    // emitChangeEvent()
                    emit('updateFiderData', label.value, selected.value);
                    emit('change', label.value, selected.value);
                }
            }

        });

        // 
        watchEffect(() => {
            if (props.valueInput !== varPropsValueInput.value) {
                selected.value = ''
            }
        })
        // 

        const handleInput = () => {
            emit('updateFiderData', label.value, inputValue.value)
            validateInput(label.value, inputValue.value)
        }


        const handleChange = () => {
            emit('updateFiderData', label.value, selected.value);
            console.log(label.value)
        };

        const validateInput = (label, value) => {
            const regex = /[^.,0-9]/;
            const numericInputs = ["Кол-во ЭП, n", "Номинальная (установленная) мощность, кВт", "Коэфф. исп.Кu", "Коэфф. cosφ", "Длина кабеля, км", "Кол-во жил", "Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)"];

            // const inputElement = document.getElementById(label);

            if (!numericInputs.includes(label)) {
                console.log(value);
            } else {
                if (regex.test(value) || value == "") {
                    console.log("Строка содержит буквы или другие символы, кроме точки и запятой.");
                    // alert("Введено не число");
                    // inputElement.classList.add("invalid-input"); // Добавляем CSS-класс
                    invalid.value = true
                } else {
                    // inputElement.classList.remove("invalid-input"); // Удаляем CSS-класс
                    invalid.value = false
                }
            }
        };


        return {
            label,
            value,
            input,
            select,
            selected,
            options,
            handleChange,
            invalid,
            validateInput,
            inputValue,
            handleInput
        }
    }
}
</script>
<style>
.input_Label {
    font-size: 14px;
}

.input {
    font-size: 12px;

}

.invalid {
    border: 1px solid red;
    outline-color: red;
}

.input.input-fider {
    max-width: 300px
}
</style>