<template lang="">
    <div v-for="(shield,index) in shields" :key="index">
        <!-- тут через v-for выводим все шкафы -->
        <ShieldComponent :shield-data="shield" />
    </div>
</template>
<script>
import { ref, watchEffect } from 'vue';

import ShieldComponent from './ShieldComponent.vue'
export default {
    components: {
        ShieldComponent
    },
    props: {
        shieldsProp: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const shields = ref([...props.shieldsProp])


        watchEffect(() => {
            shields.value = [...props.shieldsProp].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)); // Сортируем копию массива по убыванию даты updatedAt
        });
        return {
            shields
        }
    }
}
</script>
<style></style>