export default {

    guide_items: [
        {
            name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные",
            ki: "0,13",
            cos: "0,45",
            id: 2,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, при крупносерийном производстве",
            ki: "0,16",
            cos: "0,5",
            id: 3,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, при тяжелом режиме работы: штамповочные прессы, автоматы, револьверные, обдирочные, зубофрезерные, а также крупные токарные, строгальные, фрезерные, карусельные и расточные станки",
            ki: "0,17",
            cos: "0,65",
            id: 4,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, с особо тяжелым режимом работы: приводы молотов, ковочных машин, волочильных станков, очистных барабанов, бегунов и др.",
            ki: "0,22",
            cos: "0,65",
            id: 5,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Шлифовальные станки шарикоподшипниковых заводов",
            ki: "0,275",
            cos: "0,65",
            id: 6,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Автоматические поточные линии обработки металлов",
            ki: "0,55",
            cos: "0,7",
            id: 7,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Механические цехи, многошпиндельные автоматы для изготовления деталей из прутков",
            ki: "0,2",
            cos: "0,55",
            id: 8,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Переносной электроинструмент",
            ki: "0,06",
            cos: "0,5",
            id: 9,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Вентиляторы, эксгаустеры, санитарно-гигиеническая вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 10,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы, компрессоры, двигатель-генераторы",
            ki: "0,7",
            cos: "0,85",
            id: 11,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Элеваторы, транспортеры, шнеки, конвейеры несблокированные",
            ki: "0,4",
            cos: "0,75",
            id: 12,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Элеваторы, транспортеры, шнеки, конвейеры сблокированные",
            ki: "0,55",
            cos: "0,75",
            id: 13,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Краны, тельферы при ПВ - 25%",
            ki: "0,05",
            cos: "0,5",
            id: 14,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Краны, тельферы при ПВ - 40%",
            ki: "0,1",
            cos: "0,5",
            id: 15,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сварочные трансформаторы для ручной дуговой сварки и резки металлов",
            ki: "0,3",
            cos: "0,425",
            id: 16,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сварочные машины шовные",
            ki: "0,3",
            cos: "0,7",
            id: 17,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сварочные машины стыковые и точечные",
            ki: "0,3",
            cos: "0,6",
            id: 18,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сварочные трансформаторы для автоматической и полуавтоматической дуговой сварки",
            ki: "0,35",
            cos: "0,5",
            id: 19,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Однопостовые сварочные двигатель-генераторы",
            ki: "0,3",
            cos: "0,6",
            id: 20,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Многопостовые сварочные двигатель-генераторы",
            ki: "0,5",
            cos: "0,7",
            id: 21,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Машины для сварки трением",
            ki: "0,6",
            cos: "0,8",
            id: 22,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с непрерывной (автоматической) загрузкой, сушильные шкафы",
            ki: "0,6",
            cos: "0,95",
            id: 23,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с периодической загрузкой, сушильные шкафы",
            ki: "0,5",
            cos: "0,85",
            id: 24,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с неавтоматической загрузкой изделий",
            ki: "0,5",
            cos: "0,95",
            id: 25,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Мелкие нагревательные приборы",
            ki: "0,6",
            cos: "1",
            id: 26,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Индукционные печи низкой частоты",
            ki: "0,7",
            cos: "0,95",
            id: 27,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Двигатель-генераторы индукционных печей высокой частоты",
            ki: "0,7",
            cos: "0,8",
            id: 28,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Ламповые генераторы индукционных печей высокой частоты",
            ki: "0,7",
            cos: "0,65",
            id: 29,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Тиристорные преобразователи установок ТВЧ",
            ki: "0,7",
            cos: "0,65",
            id: 30,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для качественных сталей с механизированной загрузкой",
            ki: "0,75",
            cos: "0,9",
            id: 31,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для качественных сталей без механизированной загрузки",
            ki: "0,6",
            cos: "0,87",
            id: 32,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для фасонного литья с механизированной загрузкой",
            ki: "0,75",
            cos: "0,9",
            id: 33,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для фасонного литья без механизированной загрузки",
            ki: "0,65",
            cos: "0,87",
            id: 34,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 0,5 до 1,5 т для фасонного литья (в подсобных цехах с автоматическим регулированием электродов)",
            ki: "0,5",
            cos: "0,8",
            id: 35,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дуговые печи цветного металла (медные сплавы) емкостью от 0,25 до 0,5 т с ручным регулированием электродов",
            ki: "0,7",
            cos: "0,75",
            id: 36,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Вспомогательные механизмы дуговых печей и печей сопротивления",
            ki: "0,12",
            cos: "0,65",
            id: 37,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы, компрессоры, двигатель-генераторы",
            ki: "0,7",
            cos: "0,825",
            id: 38,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Вентиляторы, эксгаустеры; вентиляционное оборудование",
            ki: "0,65",
            cos: "0,8",
            id: 39,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы, компрессоры с синхронными электродвигателями",
            ki: "0,7",
            cos: "0,9",
            id: 40,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Станки автоматических линий механических цехов",
            ki: "0,3",
            cos: "0,65",
            id: 41,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Станки автоматических линий, рассчитанные по циклограмме",
            ki: "0,4",
            cos: "0,65",
            id: 42,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Станки автоматических линий тяжелого режима работы, рассчитанные по циклограмме",
            ki: "0,5",
            cos: "0,65",
            id: 43,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Литейное оборудование: очистные барабаны, бегуны, выбивные решетки, зачистные машины, пескометы, дробеметные камеры и др.",
            ki: "0,5",
            cos: "0,65",
            id: 44,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с автоматической загрузкой напряжением 380 В",
            ki: "0,7",
            cos: "1",
            id: 45,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с автоматической загрузкой с трансформаторами 380/220 В",
            ki: "0,7",
            cos: "0,85",
            id: 46,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с неавтоматической загрузкой напряжением 380 В",
            ki: "0,5",
            cos: "1",
            id: 47,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Печи сопротивления с неавтоматической загрузкой с трансформаторами 380/220 В",
            ki: "0,5",
            cos: "0,95",
            id: 48,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Нагреватели гальванических ванн",
            ki: "0,6",
            cos: "1",
            id: 49,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Выпрямители гальванических ванн",
            ki: "0,7",
            cos: "0,8",
            id: 50,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Установки автоматической дуговой сварки, комплектуемые выпрямителями",
            ki: "0,35",
            cos: "0,8",
            id: 51,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Установки ручной дуговой сварки и резки металлов, комплектуемые выпрямителями",
            ki: "0,3",
            cos: "0,8",
            id: 52,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Установки автоматической дуговой сварки, комплектуемые преобразователями",
            ki: "0,35",
            cos: "0,7",
            id: 53,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Установки ручной дуговой сварки и резки металлов, комплектуемые преобразователями",
            ki: "0,3",
            cos: "0,7",
            id: 54,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Стенды для испытания узлов и агрегатов без рекуперации электроэнергии в сеть",
            ki: "0,4",
            cos: "0,65",
            id: 55,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Зарядные агрегаты аккумуляторов",
            ki: "0,7",
            cos: "0,65",
            id: 56,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Лабораторное оборудование, дефектоскопы, испытательные машины и др.",
            ki: "0,1",
            cos: "0,5",
            id: 57,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Оборудование вычислительных центров ЭВМ",
            ki: "0,4",
            cos: "0,6",
            id: 58,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Электродвигатели хорошо загруженных, непрерывно работающих механизмов: вентиляторов, насосов, центрифуг и т.п.",
            ki: "0,625",
            cos: "0,8",
            id: 59,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели индивидуального привода станков нормального режима работы: токарных, фрезерных, сверлильных, карусельных, строгальных, долбежных и т.п.",
            ki: "0,13",
            cos: "0,575",
            id: 60,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели тяжелого режима работы: зубофрезерных, обдирочных, револьверных автоматов, штамповочных эксцентриковых прессов, станков-автоматов, агрегатных станков и т.п.",
            ki: "0,185",
            cos: "0,65",
            id: 61,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели особо тяжелого режима работы: очистные и галтовочные барабаны, бегуны, шаровые мельницы, кривошипные прессы, ковочные машины, выбивные решетки и т.п.",
            ki: "0,22",
            cos: "0,65",
            id: 62,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "а) механо-обработки",
            ki: "0,35",
            cos: "0,625",
            id: 63,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "б) кузнечного производства",
            ki: "0,525",
            cos: "0,7",
            id: 64,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "в) прессово-штамповочного производства",
            ki: "0,6",
            cos: "0,7",
            id: 65,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "г) сварочного производства",
            ki: "0,4",
            cos: "0,6",
            id: 66,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели повторно-кратковременного режима работы: кранов, тельферов, кран-балок, подъемников, вспомогательных двигателей, рольгангов, подъемных столов, редукторов для открывания фромуг и т.п. в механических, сборочных и им подобных цехах для ПВ = 25%",
            ki: "0,06",
            cos: "0,45",
            id: 67,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели повторно-кратковременного режима работы: кранов, тельферов, кран-балок, подъемников, вспомогательных двигателей, рольгангов, подъемных столов, редукторов для открывания фромуг и т.п. в литейных, кузнечных и им подобных цехах для ПВ = 40%",
            ki: "0,1",
            cos: "0,5",
            id: 68,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., несблокированные",
            ki: "0,4",
            cos: "0,7",
            id: 69,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., сблокированные",
            ki: "0,5",
            cos: "0,75",
            id: 70,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Лабораторные нагревательные приборы: автоклавы, сушильные шкафы и т.п.",
            ki: "0,3",
            cos: "0,95",
            id: 71,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электрические печи сопротивления, нагревательные аппараты, сушильные шкафы, камеры сушки, ванны и т.п. периодического действия",
            ki: "0,5",
            cos: "0,95",
            id: 72,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., непрерывного действия",
            ki: "0,7",
            cos: "0,95",
            id: 73,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Печи индукционные низкой частоты",
            ki: "0,75",
            cos: "0,85",
            id: 74,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Печи индукционные высокой частоты с собственными двигатель-генераторами",
            ki: "0,6",
            cos: "0,7",
            id: 75,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Печи индукционные высокой частоты с ламповыми генераторами",
            ki: "0,75",
            cos: "0,8",
            id: 76,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Мотор-генераторы высокой частоты",
            ki: "0,6",
            cos: "0,75",
            id: 77,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Генераторы высокой частоты одномашинные",
            ki: "0,65",
            cos: "0,8",
            id: 78,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10 тс автоматическим регулированием электродов, для качественных сталей и для фасонного литья, с механизированной загрузкой",
            ki: "0,7",
            cos: "0,9",
            id: 79,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью от 3 до 10 тс автоматическим регулированием электродов, для качественных сталей и для фасонного литья, но без механизированной загрузки",
            ki: "0,55",
            cos: "0,85",
            id: 80,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью 0,5-1,5 т для фасонного литья с автоматическим регулированием электродов без механизированной загрузки",
            ki: "0,5",
            cos: "0,8",
            id: 81,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дуговые печи цветного металла (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов без механизированной загрузки",
            ki: "0,5",
            cos: "0,85",
            id: 82,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дуговые печи цветного металла (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов с механизированной загрузкой непрерывного действия",
            ki: "0,6",
            cos: "0,85",
            id: 83,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Рудотермические печи с трехфазными трансформаторами 6; 7,5 и 9 мВА",
            ki: "0,7",
            cos: "0,9",
            id: 84,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Нагревательные приборы и аппараты (печи сопротивления) в административно-бытовых и медико-санитарных службах",
            ki: "0,5",
            cos: "0,95",
            id: 85,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Мелкие нагревательные приборы",
            ki: "0,3",
            cos: "0,95",
            id: 86,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "однопостовой",
            ki: "0,2",
            cos: "0,35",
            id: 87,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "многопостовой",
            ki: "0,3",
            cos: "0,4",
            id: 88,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Сварочные трансформаторы автоматической и полуавтоматической сварки",
            ki: "0,35",
            cos: "0,5",
            id: 89,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Автоматы и машины дуговой сварки",
            ki: "0,4",
            cos: "0,65",
            id: 90,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Сварочные машины шовные",
            ki: "0,25",
            cos: "0,65",
            id: 91,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Сварочные машины стыковые и точечные",
            ki: "0,35",
            cos: "0,6",
            id: 92,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "однопостовые",
            ki: "0,3",
            cos: "0,65",
            id: 93,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "многопостовые",
            ki: "0,5",
            cos: "0,75",
            id: 94,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "однопостовая",
            ki: "0,35",
            cos: "0,5",
            id: 95,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "многопостовая",
            ki: "0,45",
            cos: "0,5",
            id: 96,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Нагреватели заклепок",
            ki: "0,35",
            cos: "0,55",
            id: 97,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Контактный нагрев под штамповку",
            ki: "0,5",
            cos: "0,65",
            id: 98,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Контактные машины",
            ki: "0,6",
            cos: "0,7",
            id: 99,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Многошпиндельные автоматы для изготовления деталей из прутков",
            ki: "0,2",
            cos: "0,5",
            id: 100,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Шлифовальные станки шарикоподшипниковых заводов",
            ki: "0,3",
            cos: "0,65",
            id: 101,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Испытательные стенды мелкомоторные",
            ki: "0,2",
            cos: "0,65",
            id: 102,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Испытательные стенды крупномоторные",
            ki: "0,25",
            cos: "0,6",
            id: 103,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Мелкомоторные испытательные лабораторные установки и приборы",
            ki: "0,1",
            cos: "0,5",
            id: 104,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электронно-вычислительные машины и счетно-решающие устройства",
            ki: "0,15",
            cos: "0,6",
            id: 105,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Устройства электропитания радиотрансляционных узлов, узлов связи и т.п.",
            ki: "0,6",
            cos: "0,75",
            id: 106,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Преобразовательные агрегатных станций,автокар, аккумуляторов автомашин и т.п.",
            ki: "0,5",
            cos: "0,7",
            id: 107,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Выпрямительные устройства гальваноцехов на полупроводниковых выпрямителях",
            ki: "0,6",
            cos: "0,8",
            id: 108,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Электроосвещение",
            ki: "0,85",
            cos: "0,95",
            id: 109,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Линия изготовления тары из полистирола",
            ki: "0,6",
            cos: "0,85",
            id: 110,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Машины для сварки полимеров",
            ki: "0,5",
            cos: "1",
            id: 111,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Краны-штабелеры",
            ki: "0,1",
            cos: "0,5",
            id: 112,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Стеллажи механизированные",
            ki: "0,4",
            cos: "0,75",
            id: 113,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Лифты грузовые",
            ki: "0,2",
            cos: "0,6",
            id: 114,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Лифты пассажирские",
            ki: "0,15",
            cos: "0,55",
            id: 115,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Приводы открывания ворот",
            ki: "0,05",
            cos: "0,75",
            id: 116,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "нагреватели",
            ki: "0,5",
            cos: "0,85",
            id: 117,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "вентиляторы",
            ki: "0,7",
            cos: "0,8",
            id: 118,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установки ультразвуковой очистки",
            ki: "0,7",
            cos: "0,7",
            id: 119,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установки моечные с подогревом",
            ki: "0,6",
            cos: "0,7",
            id: 120,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установки моечные полуавтомат",
            ki: "0,7",
            cos: "0,65",
            id: 121,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установки сушильные",
            ki: "0,5",
            cos: "0,85",
            id: 122,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Камеры окрасочные механизир.",
            ki: "0,7",
            cos: "0,85",
            id: 123,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Ванна",
            ki: "0,6",
            cos: "0,85",
            id: 124,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Конвейеры окрасочные",
            ki: "0,7",
            cos: "0,83",
            id: 125,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Выпрямитель",
            ki: "0,6",
            cos: "0,8",
            id: 126,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линии гальванопокрытий",
            ki: "0,7",
            cos: "0,8",
            id: 127,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модульные линии",
            ki: "0,65",
            cos: "0,8",
            id: 128,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линии меднения",
            ki: "0,6",
            cos: "0,9",
            id: 129,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линия подготовки поверхности",
            ki: "0,65",
            cos: "0,8",
            id: 130,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линия осветления сплава",
            ki: "0,6",
            cos: "0,85",
            id: 131,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линия отмывки плат",
            ki: "0,7",
            cos: "0,85",
            id: 132,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Регенератор",
            ki: "0,7",
            cos: "0,8",
            id: 133,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Устан. химического травления",
            ki: "0,65",
            cos: "0,85",
            id: 134,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка лужения плат",
            ki: "0,6",
            cos: "0,85",
            id: 135,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модуль проявления",
            ki: "0,65",
            cos: "0,8",
            id: 136,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модуль окрашивания",
            ki: "0,5",
            cos: "0,65",
            id: 137,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модуль промывки",
            ki: "0,7",
            cos: "0,85",
            id: 138,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модуль химического дубления",
            ki: "0,65",
            cos: "0,8",
            id: 139,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Полуавтомат трафарет печати",
            ki: "0,5",
            cos: "0,65",
            id: 140,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Камера тепла и влаги. Насос",
            ki: "0,7",
            cos: "0,8",
            id: 141,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Нагрев",
            ki: "0,5",
            cos: "0,85",
            id: 142,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Выпрямитель",
            ki: "0,7",
            cos: "0,8",
            id: 143,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Устройство автомат. загрузки",
            ki: "0,55",
            cos: "0,75",
            id: 144,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка механич.зачистки",
            ki: "0,12",
            cos: "0,4",
            id: 145,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка пригот. эмульсии",
            ki: "0,7",
            cos: "0,8",
            id: 146,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Термобарокамера. Насос",
            ki: "0,7",
            cos: "0,8",
            id: 147,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Нагрев",
            ki: "0,5",
            cos: "0,85",
            id: 148,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка моеч. вибрац.",
            ki: "0,7",
            cos: "0,8",
            id: 149,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Барабан галтовочный",
            ki: "0,5",
            cos: "0,65",
            id: 150,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Ванны",
            ki: "0,6",
            cos: "0,8",
            id: 151,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Смеситель эмульсии",
            ki: "0,3",
            cos: "0,85",
            id: 152,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Фильтр",
            ki: "0,35",
            cos: "0,85",
            id: 153,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Дистиллятор",
            ki: "0,7",
            cos: "0,9",
            id: 154,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линия транспорт. подвесок",
            ki: "0,55",
            cos: "0,75",
            id: 155,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Ванны",
            ki: "0,5",
            cos: "0,8",
            id: 156,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Модульные линии",
            ki: "0,5",
            cos: "0,8",
            id: 157,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линии гальванопокрытий",
            ki: "0,5",
            cos: "0,8",
            id: 158,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка нанесения фоторезист",
            ki: "0,6",
            cos: "0,85",
            id: 159,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка экспонирования",
            ki: "0,6",
            cos: "0,85",
            id: 160,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка нанесения сплава",
            ki: "0,6",
            cos: "0,85",
            id: 161,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка снятия фоторезиста",
            ki: "0,65",
            cos: "0,8",
            id: 162,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка проявления",
            ki: "0,65",
            cos: "0,8",
            id: 163,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка травления печ. плат",
            ki: "0,65",
            cos: "0,8",
            id: 164,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка оплавления",
            ki: "0,6",
            cos: "0,85",
            id: 165,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка струйная",
            ki: "0,6",
            cos: "0,85",
            id: 166,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка снятия краски",
            ki: "0,65",
            cos: "0,85",
            id: 167,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка узг. промывки плат",
            ki: "0,7",
            cos: "0,85",
            id: 168,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Линия химической металлизации",
            ki: "0,6",
            cos: "0,8",
            id: 169,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Установка печати",
            ki: "0,5",
            cos: "0,7",
            id: 170,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Выпрямители для зарядной",
            ki: "0,5",
            cos: "0,8",
            id: 171,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Аппарат A M",
            ki: "0,7",
            cos: "0,85",
            id: 172,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Приводы",
            ki: "0,7",
            cos: "0,85",
            id: 173,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование по подготовке проводов, кабелей",
            ki: "0,7",
            cos: "0,85",
            id: 174,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование по подготовке микросхем",
            ki: "0,7",
            cos: "0,85",
            id: 175,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Стенды",
            ki: "0,7",
            cos: "0,85",
            id: 176,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Термобарокамера",
            ki: "0,7",
            cos: "0,85",
            id: 177,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Контрольно-испытательное оборудование",
            ki: "0,7",
            cos: "0,85",
            id: 178,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование автоматизированной сборки плат",
            ki: "0,7",
            cos: "0,85",
            id: 179,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование автоматизированной пайки плат",
            ki: "0,7",
            cos: "0,85",
            id: 180,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование намоточное",
            ki: "0,7",
            cos: "0,85",
            id: 181,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Оборудование пропитки и заливки",
            ki: "0,7",
            cos: "0,85",
            id: 182,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Прессы",
            ki: "0,17",
            cos: "0,65",
            id: 183,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Станки",
            ki: "0,12",
            cos: "0,4",
            id: 184,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Приборы",
            ki: "0,3",
            cos: "0,8",
            id: 185,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Стенды",
            ki: "0,675",
            cos: "0,85",
            id: 186,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Машина для статических испытаний",
            ki: "0,7",
            cos: "0,85",
            id: 187,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Электроинструмент",
            ki: "0,06",
            cos: "0,5",
            id: 188,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Лабораторные приборы",
            ki: "0,5",
            cos: "0,9",
            id: 189,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Стол с подсветом",
            ki: "0,6",
            cos: "1",
            id: 190,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Щиты автоматики",
            ki: "1",
            cos: "1",
            id: 191,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Электроосвещение",
            ki: "0,95",
            cos: "0,95",
            id: 192,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Вычислительные комплексы при числе 3 и более",
            ki: "0,85",
            cos: "0,8",
            id: 193,
            parents: [
                1,
                1.3
            ]
        },
        {
            name: "Электропогружные насосы мощностью до 35 кВт",
            ki: "0,95",
            cos: "0,7",
            id: 194,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Электропогружные насосы мощностью свыше 35 кВт",
            ki: "0,8",
            cos: "0,75",
            id: 195,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Станки-качалки высоко- и среднебитные* скважин (свыше 10 т/сутки) нефти",
            ki: "0,35",
            cos: "0,4",
            id: 196,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Станки-качалки низкодебитных скважин (менее 10 т/сутки нефти)",
            ki: "0,25",
            cos: "0,35",
            id: 197,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы перекачки нефти со сборных пунктов на товарные парки",
            ki: "0,4",
            cos: "0,85",
            id: 198,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы перекачки нефти с товарных парков потребителю",
            ki: "0,7",
            cos: "0,8",
            id: 199,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы подачи воды для нужд законтурного обводнения",
            ki: "0,7",
            cos: "0,85",
            id: 200,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы подачи воды в пласт",
            ki: "0,75",
            cos: "0,9",
            id: 201,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Прочее электрооборудование 0,4 кВ",
            ki: "0,7",
            cos: "0,7",
            id: 202,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы",
            ki: "0,88",
            cos: "0,9",
            id: 203,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,78",
            cos: "0,71",
            id: 204,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Газовые компрессоры",
            ki: "0,61",
            cos: "0,94",
            id: 205,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Воздуходувки, газодувки",
            ki: "0,7",
            cos: "0,81",
            id: 206,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы",
            ki: "0,78",
            cos: "0,89",
            id: 207,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,5",
            cos: "0,72",
            id: 208,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Газовые компрессоры",
            ki: "0,47",
            cos: "0,8",
            id: 209,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы",
            ki: "0,85",
            cos: "0,9",
            id: 210,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,58",
            cos: "0,67",
            id: 211,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Газовые компрессоры",
            ki: "0,73",
            cos: "0,93",
            id: 212,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы",
            ki: "0,81",
            cos: "0,85",
            id: 213,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,66",
            cos: "0,69",
            id: 214,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Насосы",
            ki: "0,62",
            cos: "0,87",
            id: 215,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,63",
            cos: "0,7",
            id: 216,
            parents: [
                1,
                1.4
            ]
        },
        {
            name: "Барабаны смесительные и сушильные скрубберы",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 217,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Электромагнитные сепараторы индивидуальные",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 218,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генераторы",
            ki: "0,7",
            cos: "по сх.замещения",
            id: 219,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Электровибрационные машины",
            ki: "0,8",
            cos: "по сх.замещения",
            id: 220,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Кузнечные машины (механизмы кузнечных цехов)",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 221,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Литейные машины, очистные и кантовочные барабаны, бегуны, шаровые мельницы и т.п.",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 222,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Заварочные откачные автомашины",
            ki: "0,5",
            cos: "по сх.замещения",
            id: 223,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Откачные посты (стенды)",
            ki: "0,45",
            cos: "по сх.замещения",
            id: 224,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Автоматы для спекания штабиков (вольфрама, молибдена, тантала и т.п.)",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 225,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сварочные автоматы",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 226,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Высокочастотный генератор",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 227,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Вакуум-пропиточные установки",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 228,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор сетевой мощностью до 25 кВт",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 229,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор сетевой мощностью от 25 до 100 кВт",
            ki: "0,575",
            cos: "по сх.замещения",
            id: 230,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор сетевой мощностью свыше 100 кВт",
            ki: "0,275",
            cos: "по сх.замещения",
            id: 231,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор для индивидуального питания стендов до 25 кВт",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 232,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор для индивидуального питания стендов от 25 до 100 кВт",
            ki: "0,225",
            cos: "по сх.замещения",
            id: 233,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Двигатель-генератор для индивидуального питания стендов свыше 100 кВт",
            ki: "0,225",
            cos: "по сх.замещения",
            id: 234,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Индукционные регуляторы сетевые",
            ki: "0,55",
            cos: "по сх.замещения",
            id: 235,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Индукционные регуляторы индивидуальные",
            ki: "0,2",
            cos: "по сх.замещения",
            id: 236,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Выпрямители полупроводниковые",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 237,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Автотрансформаторы индивидуальные",
            ki: "0,2",
            cos: "по сх.замещения",
            id: 238,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Автотрансформаторы индивидуальные сетевые",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 239,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Трансформаторы индивидуальные",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 240,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Трансформаторы индивидуальные сетевые",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 241,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Тренировочные стенды",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 242,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Установка для испытания на пробой",
            ki: "0,1",
            cos: "по сх.замещения",
            id: 243,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Лабораторное оборудование",
            ki: "0,25",
            cos: "по сх.замещения",
            id: 244,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные карусельные, точильные и т.п.",
            ki: "0,14",
            cos: "по сх.замещения",
            id: 245,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Металлорежущие станки при крупносерийном производстве с нормальным режимом работы",
            ki: "0,17",
            cos: "по сх.замещения",
            id: 246,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Металлорежущие станки мелкосерийного производства с особо тяжелым режимом работы, а также привода молотков, ковочных машин, волочильных станков и др.",
            ki: "0,22",
            cos: "по сх.замещения",
            id: 247,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сварочные трансформаторы для ручной дуговой электросварки",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 248,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сварочные трансформаторы для автоматической дуговой сварки",
            ki: "0,5",
            cos: "по сх.замещения",
            id: 249,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Однопостовые сварочные двигатель-генераторы",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 250,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сварочные машины (нагреватели заклепок)",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 251,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сушильные камеры периодического действия",
            ki: "0,55",
            cos: "по сх.замещения",
            id: 252,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Стекольные печи для плавки кварцевого стекла",
            ki: "0,75",
            cos: "по сх.замещения",
            id: 253,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Высокочастотные печи для вытяжки кварцевых трубок",
            ki: "0,8",
            cos: "по сх.замещения",
            id: 254,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Печи сопротивления с периодической загрузкой",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 255,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Нагревательные аппараты",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 256,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Сушильные шкафы",
            ki: "0,75",
            cos: "по сх.замещения",
            id: 257,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Элеваторы ковшовые, вертикальные и наклонные, шнеки, конвейеры и т.п. несблокированные",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 258,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Элеваторы ковшовые, вертикальные и наклонные, шнеки, конвейеры и т.п. сблокированные",
            ki: "0,55",
            cos: "по сх.замещения",
            id: 259,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Питатели пластинчатые, барабанные, дисковые и т.п.",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 260,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Конвейеры легкие мощностью до 10 кВт",
            ki: "0,45",
            cos: "по сх.замещения",
            id: 261,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Конвейеры легкие мощностью свыше 10 кВт",
            ki: "",
            cos: "по сх.замещения",
            id: 262,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Транспортеры ленточные",
            ki: "0,575",
            cos: "по сх.замещения",
            id: 263,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Транспортеры винтовые",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 264,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Дробилки шнековые и конусные для крупного дробления",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 265,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Дробилки шнековые и конусные среднего дробления",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 266,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Дробилки конусные для мелкого дробления, валковые и молотковые мощностью до 100 кВт",
            ki: "0,775",
            cos: "по сх.замещения",
            id: 267,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Дробилки конусные для мелкого дробления, валковые и молотковые мощностью свыше 100 кВт",
            ki: "0,8",
            cos: "по сх.замещения",
            id: 268,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Грохоты",
            ki: "0,55",
            cos: "по сх.замещения",
            id: 269,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Мельницы шаровые",
            ki: "0,825",
            cos: "по сх.замещения",
            id: 270,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Грейферные краны",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 271,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Магнитные краны",
            ki: "0,5",
            cos: "по сх.замещения",
            id: 272,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Штабеллеры",
            ki: "0,16",
            cos: "по сх.замещения",
            id: 273,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Скиповый подъемник",
            ki: "0,05",
            cos: "по сх.замещения",
            id: 274,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Электротележки",
            ki: "0,1",
            cos: "по сх.замещения",
            id: 275,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Вентиляторы санитарно-гигиенической вентиляции",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 276,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Вентиляторы производственные",
            ki: "0,775",
            cos: "по сх.замещения",
            id: 277,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Вентиляторы к дробилкам",
            ki: "0,425",
            cos: "по сх.замещения",
            id: 278,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Насосы водяные",
            ki: "0,75",
            cos: "по сх.замещения",
            id: 279,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Насосы песковые",
            ki: "0,7",
            cos: "по сх.замещения",
            id: 280,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Вакуум насосы",
            ki: "0,9",
            cos: "по сх.замещения",
            id: 281,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Насосы автоматизированных артскважин",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 282,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Дымососы",
            ki: "0,9",
            cos: "по сх.замещения",
            id: 283,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Газодувки",
            ki: "0,8",
            cos: "по сх.замещения",
            id: 284,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Воздуходувки",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 285,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 286,
            parents: [
                1,
                1.5
            ]
        },
        {
            name: "Транспортеры",
            ki: "0,5",
            cos: "0,525",
            id: 287,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Транспортеры катучие",
            ki: "0,3",
            cos: "0,75",
            id: 288,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Питатели пластинчатые и ленточные",
            ki: "0,45",
            cos: "0,75",
            id: 289,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дробилки молотковые",
            ki: "0,8",
            cos: "0,8",
            id: 290,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дозировочные столы",
            ki: "0,25",
            cos: "0,5",
            id: 291,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Штабелеры",
            ki: "0,16",
            cos: "0,675",
            id: 292,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Углеперегружатели",
            ki: "0,14",
            cos: "0,5",
            id: 293,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Коксовыталкиватели",
            ki: "0,1",
            cos: "0,75",
            id: 294,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Загрузочные вагоны",
            ki: "0,3",
            cos: "0,6",
            id: 295,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Двересъемные машины",
            ki: "0,25",
            cos: "0,7",
            id: 296,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электровозы сушильных машин вагонов",
            ki: "0,16",
            cos: "0,75",
            id: 297,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Скиповые подъемники",
            ki: "0,05",
            cos: "0,5",
            id: 298,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Кабестаны",
            ki: "0,5",
            cos: "0,7",
            id: 299,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вагоноопрокидыватели",
            ki: "0,4",
            cos: "0,55",
            id: 300,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,825",
            cos: "0,785",
            id: 301,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы газовых горелок",
            ki: "0,65",
            cos: "0,85",
            id: 302,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вращающиеся распределители",
            ki: "0,03",
            cos: "0,7",
            id: 303,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Грохоты кокса и затворы",
            ki: "0,12",
            cos: "0,5",
            id: 304,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Разливочные машины",
            ki: "0,3",
            cos: "0,6",
            id: 305,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Бегуны",
            ki: "0,7",
            cos: "0,65",
            id: 306,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Воздухонагреватели",
            ki: "0,5",
            cos: "0,82",
            id: 307,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Газоочистка",
            ki: "0,7",
            cos: "0,7",
            id: 308,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны рудного двора",
            ki: "0,35",
            cos: "0,7",
            id: 309,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Грейферные краны",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 310,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы питательные мартеновского цеха",
            ki: "0,9",
            cos: "0,9",
            id: 311,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дымососы мартеновского цеха",
            ki: "0,9",
            cos: "0,9",
            id: 312,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Печные заслонки мартеновских печей",
            ki: "0,25",
            cos: "0,6",
            id: 313,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы принудительного дутья",
            ki: "0,6",
            cos: "0,75",
            id: 314,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Магнитные краны",
            ki: "0,5",
            cos: "по сх.замещения",
            id: 315,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны разливочные мартеновского цеха",
            ki: "0,22",
            cos: "по сх.замещения",
            id: 316,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны заливочного мартеновского цеха",
            ki: "0,2",
            cos: "0,6",
            id: 317,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Завалочные машины",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 318,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны двора изложниц",
            ki: "0,4",
            cos: "0,6",
            id: 319,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы высокого давления охлаждения форм",
            ki: "0,9",
            cos: "0,9",
            id: 320,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Преобразователи привода наклона конвертора",
            ki: "0,25",
            cos: "по сх.замещения",
            id: 321,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сталевозные и шлаковозные тележки",
            ki: "0,1",
            cos: "по сх.замещения",
            id: 322,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы",
            ki: "0,65",
            cos: "0,75",
            id: 323,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Разливочные краны",
            ki: "0,22",
            cos: "0,6",
            id: 324,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Заливочные краны",
            ki: "0,2",
            cos: "0,6",
            id: 325,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы систем подачи сыпучих",
            ki: "0,375",
            cos: "0,675",
            id: 326,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "а) односкоростные с синхронными двигателями",
            ki: "0,9",
            cos: "0,9",
            id: 327,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "б) двухскоростные с асинхронными двигателями",
            ki: "0,6",
            cos: "0,7",
            id: 328,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги раскатные",
            ki: "0,2",
            cos: "0,6",
            id: 329,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги индивидуальные",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 330,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги у ножниц",
            ki: "0,22",
            cos: "по сх.замещения",
            id: 331,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги приемные",
            ki: "0,34",
            cos: "по сх.замещения",
            id: 332,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги у весов",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 333,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги центральные",
            ki: "0,1",
            cos: "0,88",
            id: 334,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги станинные",
            ki: "0,2",
            cos: "по сх.замещения",
            id: 335,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги отводящие",
            ki: "0,25",
            cos: "по сх.замещения",
            id: 336,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги стана 250-1",
            ki: "0,1",
            cos: "по сх.замещения",
            id: 337,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги стана 140",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 338,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Рольганги в среднем",
            ki: "0,17",
            cos: "по сх.замещения",
            id: 339,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Роликоправильные машины",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 340,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Преобразователи частоты рольгангов",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 341,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Кантователи",
            ki: "0,2",
            cos: "0,7",
            id: 342,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Кантователи основной и дополнительной клети стана 300",
            ki: "0,35",
            cos: "по сх.замещения",
            id: 343,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Манипуляторы, нажимные устройства",
            ki: "0,2",
            cos: "по сх.замещения",
            id: 344,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сталкиватели",
            ki: "0,12",
            cos: "по сх.замещения",
            id: 345,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Толкатели слябов",
            ki: "0,32",
            cos: "по сх.замещения",
            id: 346,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Штабелирующие столы",
            ki: "0,1",
            cos: "0,8",
            id: 347,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы машинных залов",
            ki: "0,65",
            cos: "0,8",
            id: 348,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы прокатных станов",
            ki: "0,725",
            cos: "0,825",
            id: 349,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны отделения разделения слитков",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 350,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Клещевые (колодцевые) краны прокатного цеха",
            ki: "0,55",
            cos: "по сх.замещения",
            id: 351,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Транспортеры обрезков слябов",
            ki: "0,16",
            cos: "по сх.замещения",
            id: 352,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Транспортные краны готовой продукции",
            ki: "0,45",
            cos: "0,6",
            id: 353,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Конвейеры",
            ki: "0,35",
            cos: "0,7",
            id: 354,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны отделения заготовок",
            ki: "0,45",
            cos: "0,6",
            id: 355,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Шарнир-краны",
            ki: "0,3",
            cos: "0,6",
            id: 356,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Слитковозы",
            ki: "0,2",
            cos: "по сх.замещения",
            id: 357,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Трансферкары, привод",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 358,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Подъемные столы, толкатели",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 359,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Двигатели крышек",
            ki: "0,1",
            cos: "0,65",
            id: 360,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Ножницы холодной резки",
            ki: "0,45",
            cos: "0,65",
            id: 361,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Пилы и ножницы горячей резки",
            ki: "0,15",
            cos: "0,9",
            id: 362,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Ножницы блюминга",
            ki: "0,25",
            cos: "0,5",
            id: 363,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Транспортеры ножниц",
            ki: "0,25",
            cos: "0,9",
            id: 364,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Ножницы района холодильника",
            ki: "0,3",
            cos: "0,5",
            id: 365,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вращение и перемещение пил горячей резки",
            ki: "0,5",
            cos: "по сх.замещения",
            id: 366,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Роликоправильные машины",
            ki: "0,15",
            cos: "по сх.замещения",
            id: 367,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Направляющие линейки и нажимные механизмы чистовых клетей",
            ki: "0,01",
            cos: "0,75",
            id: 368,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Тянущие ролики моталок",
            ki: "0,3",
            cos: "по сх.замещения",
            id: 369,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Гильотинные ножницы",
            ki: "0,13",
            cos: "0,5",
            id: 370,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Шлеппера",
            ki: "0,25",
            cos: "0,7",
            id: 371,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Крышки нагревательных колодцев, шиберы, перекидка клапанов, заслонки нагревательных печей, тарельчатые клапаны",
            ki: "0,1",
            cos: "0,6",
            id: 372,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы качания кристаллизатора",
            ki: "0,6",
            cos: "0,8",
            id: 373,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Тянущая клеть",
            ki: "0,7",
            cos: "0,8",
            id: 374,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы газовой резки",
            ki: "0,5",
            cos: "0,7",
            id: 375,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы уборки отрезанных слитков",
            ki: "0,5",
            cos: "0,6",
            id: 376,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Технологические вентиляторы",
            ki: "0,8",
            cos: "0,8",
            id: 377,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы насосно-аккумуляторной станции",
            ki: "0,6",
            cos: "0,8",
            id: 378,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Транспортеры, элеваторы, питатели",
            ki: "0,475",
            cos: "0,7",
            id: 379,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Задувки",
            ki: "0,8",
            cos: "0,9",
            id: 380,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы, дымососы",
            ki: "0,675",
            cos: "0,85",
            id: 381,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны",
            ki: "0,175",
            cos: "0,6",
            id: 382,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы открывания фрамуг",
            ki: "0,02",
            cos: "0,5",
            id: 383,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вспомогательные механизмы печей",
            ki: "0,12",
            cos: "0,6",
            id: 384,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Очистные барабаны",
            ki: "0,25",
            cos: "0,65",
            id: 385,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дробилки,грохоты",
            ki: "0,7",
            cos: "0,8",
            id: 386,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дробилки молотковые",
            ki: "0,8",
            cos: "0,85",
            id: 387,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дробилки молотковые",
            ki: "0,625",
            cos: "0,775",
            id: 388,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Мельницы шаровые",
            ki: "0,8",
            cos: "0,8",
            id: 389,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Мельницы стержневые",
            ki: "0,7",
            cos: "0,75",
            id: 390,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Грохоты",
            ki: "0,55",
            cos: "0,65",
            id: 391,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Конвейеры до 10 кВт",
            ki: "0,45",
            cos: "0,75",
            id: 392,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Конвейеры выше 10 кВт",
            ki: "0,65",
            cos: "0,725",
            id: 393,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Питатели пластинчатые, тарельчатые, барабанные, дисковые",
            ki: "0,35",
            cos: "0,55",
            id: 394,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Элеваторы, шнеки",
            ki: "0,6",
            cos: "0,7",
            id: 395,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы вращающихся печей",
            ki: "0,6",
            cos: "0,7",
            id: 396,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дымососы печей",
            ki: "0,7",
            cos: "0,8",
            id: 397,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны, электролафеты",
            ki: "0,15",
            cos: "0,65",
            id: 398,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Толкатели,туннельных печей",
            ki: "0,2",
            cos: "0,5",
            id: 399,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Прессы гидравлические и функциональные",
            ki: "0,625",
            cos: "0,775",
            id: 400,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосно-аккумуляторные станции",
            ki: "0,7",
            cos: "0,8",
            id: 401,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электрофильтры",
            ki: "0,5",
            cos: "0,7",
            id: 402,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механизмы пылеуборки",
            ki: "0,46",
            cos: "0,85",
            id: 403,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сушильные барабаны",
            ki: "0,6",
            cos: "0,7",
            id: 404,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Магнитные сепараторы",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 405,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Печи сопротивления с непрерывной загрузкой",
            ki: "0,8",
            cos: "0,965",
            id: 406,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Печи сопротивления с периодической загрузкой",
            ki: "0,6",
            cos: "0,965",
            id: 407,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Печи сопротивления с автоматической загрузкой изделий, сушильные шкафы, нагревательные приборы",
            ki: "0,775",
            cos: "0,965",
            id: 408,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "а) для качественных сталей с механизированной загрузкой",
            ki: "0,75",
            cos: "0,9",
            id: 409,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "б) для качественных сталей без механизированной загрузки",
            ki: "0,6",
            cos: "0,87",
            id: 410,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "в) для фасонного литья с механизированной загрузкой",
            ki: "0,75",
            cos: "0,9",
            id: 411,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "г) для фасонного литья без механизированной загрузки",
            ki: "0,65",
            cos: "0,87",
            id: 412,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дуговые сталеплавильные печи емкостью 0,5-1,5 т для фасонного литья (во вспомогательных цехах с автоматическим регулированием электродов)",
            ki: "0,5",
            cos: "0,8",
            id: 413,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дуговые печи цветного литья (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов",
            ki: "0,7",
            cos: "0,75",
            id: 414,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Руднотермические печи с трехфазными трансформаторами 6, 7,5 и 9 мВА",
            ki: "0,9",
            cos: "0,9",
            id: 415,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сушильные шкафы",
            ki: "0,775",
            cos: "1",
            id: 416,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 160 мм, рабочий ток 3-3,6 кА",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 417,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм, рабочий ток 3-3,6 кА",
            ki: "0,08",
            cos: "по сх.замещения",
            id: 418,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм, рабочий ток 5-7кА",
            ki: "0,115",
            cos: "",
            id: 419,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм, рабочий ток 8-9кА",
            ki: "0,145",
            cos: "",
            id: 420,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм, рабочий ток 5-7кА",
            ki: "0,115",
            cos: "",
            id: 421,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм, рабочий ток 8-9 кА",
            ki: "0,15",
            cos: "",
            id: 422,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм, рабочий ток 5-7 кА",
            ki: "0,06",
            cos: "по сх.замещения",
            id: 423,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм, 8-9 кА",
            ki: "0,105",
            cos: "по сх.замещения",
            id: 424,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 630 мм, рабочий ток 8-9 кА",
            ki: "0,12",
            cos: "по сх.замещения",
            id: 425,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 160 мм рабочий ток 3-3,6 кА",
            ki: "0,05",
            cos: "по сх.замещения",
            id: 426,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм рабочий ток 3-3,6 кА",
            ki: "0,125",
            cos: "по сх.замещения",
            id: 427,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм рабочий ток 5-7 кА",
            ki: "0,18",
            cos: "по сх.замещения",
            id: 428,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 320 мм рабочий ток 8-9 кА",
            ki: "0,225",
            cos: "по сх.замещения",
            id: 429,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм рабочий ток 5-7 кА",
            ki: "0,18",
            cos: "по сх.замещения",
            id: 430,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм рабочий 8-9 кА",
            ki: "0,235",
            cos: "по сх.замещения",
            id: 431,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм рабочий ток 5-7 кА",
            ki: "0,095",
            cos: "по сх.замещения",
            id: 432,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 400 мм рабочий ток 8-9 кА",
            ki: "0,135",
            cos: "по сх.замещения",
            id: 433,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Диаметр кристаллизатора 630 мм рабочий ток 8-9 кА",
            ki: "0,19",
            cos: "по сх.замещения",
            id: 434,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Мелкие нагревательные приборы",
            ki: "0,6",
            cos: "1",
            id: 435,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "а) однопостовой",
            ki: "0,2",
            cos: "0,35",
            id: 436,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "б) многопостовой",
            ki: "0,25",
            cos: "0,4",
            id: 437,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные трансформаторы автоматической сварки",
            ki: "0,35",
            cos: "0,45",
            id: 438,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Однопостовые сварочные двигатель-генераторы",
            ki: "0,3",
            cos: "0,625",
            id: 439,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Многопостовые сварочные двигатель-генераторы",
            ki: "0,5",
            cos: "0,725",
            id: 440,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные машины шовные",
            ki: "0,35",
            cos: "0,7",
            id: 441,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные машины стыковые и точечные",
            ki: "0,225",
            cos: "0,6",
            id: 442,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные дуговые автоматы типа АДС",
            ki: "0,35",
            cos: "0,5",
            id: 443,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные трансформаторы дуговой электросварки",
            ki: "0,2",
            cos: "0,4",
            id: 444,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Ламповые генераторы индукционных печей высокой частоты",
            ki: "запросить",
            cos: "0,65",
            id: 445,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Двигатель-генераторы индукционных печей высокой частоты",
            ki: "запросить",
            cos: "0,8",
            id: 446,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы",
            ki: "0,65",
            cos: "0,8",
            id: 447,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,65",
            cos: "0,8",
            id: 448,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Механическое станочное оборудование",
            ki: "0,16",
            cos: "0,55",
            id: 449,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Деревообрабатывающие станки",
            ki: "0,55",
            cos: "0,75",
            id: 450,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Котельная",
            ki: "0,6",
            cos: "0,75",
            id: 451,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сантехвентиляция",
            ki: "0,65",
            cos: "0,75",
            id: 452,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Внутреннее освещение производственных цехов",
            ki: "0,9",
            cos: "0,975",
            id: 453,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Внутреннее освещение вспомогательных цехов",
            ki: "0,9",
            cos: "1",
            id: 454,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Волочильные станы",
            ki: "0,375",
            cos: "0,835",
            id: 455,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электропечи сопротивления для термической обработки",
            ki: "0,625",
            cos: "1",
            id: 456,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Производственные вентиляторы и воздуходувки",
            ki: "0,65",
            cos: "0,75",
            id: 457,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Центробежные насосы",
            ki: "0,6",
            cos: "0,7",
            id: 458,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Краны и тельферы",
            ki: "0,1",
            cos: "0,65",
            id: 459,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные аппараты",
            ki: "0,05",
            cos: "0,6",
            id: 460,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Калибровочное производство",
            ki: "0,425",
            cos: "0,78",
            id: 461,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электропечи сопротивления для термической обработки",
            ki: "0,625",
            cos: "1",
            id: 462,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Автоматические линии изготовления гаек, болтов, шурупов",
            ki: "0,375",
            cos: "0,675",
            id: 463,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электропечи сопротивления для термической обработки",
            ki: "0,6",
            cos: "1",
            id: 464,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Прокатное и отделочное отделения (станы)",
            ki: "0,35",
            cos: "0,825",
            id: 465,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Электропечи сопротивления для термической обработки",
            ki: "0,6",
            cos: "1",
            id: 466,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Агрегаты непрерывного травления",
            ki: "0,425",
            cos: "0,775",
            id: 467,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Канатные машины",
            ki: "0,375",
            cos: "0,71",
            id: 468,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Прессовка и сушка электродов",
            ki: "0,405",
            cos: "0,75",
            id: 469,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Дробильно-сушильное отделение и смесители",
            ki: "0,425",
            cos: "0,675",
            id: 470,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Волочильные станы порошковой проволоки",
            ki: "0,35",
            cos: "0,75",
            id: 471,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Вентиляторы пневмотранспорта",
            ki: "0,625",
            cos: "0,845",
            id: 472,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Плетельные станки",
            ki: "0,375",
            cos: "0,675",
            id: 473,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Сварочные автоматы",
            ki: "0,225",
            cos: "0,625",
            id: 474,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Автоматы",
            ki: "0,675",
            cos: "0,65",
            id: 475,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Насосы эмульсионного хозяйства",
            ki: "0,45",
            cos: "0,65",
            id: 476,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Агрегаты гальванических покрытий",
            ki: "0,6",
            cos: "0,78",
            id: 477,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Горячее покрытие (агрегат с электрованной оцинкования или лужения)",
            ki: "0,6",
            cos: "1",
            id: 478,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Воздуходувки для растворов",
            ki: "0,6",
            cos: "0,7",
            id: 479,
            parents: [
                1,
                1.6
            ]
        },
        {
            name: "Компенсаторы стационарные мощностью до 2000* кВт",
            ki: "0,75",
            cos: "0,75",
            id: 480,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компенсаторы стационарные мощностью от 200 до 400 кВт",
            ki: "0,825",
            cos: "0,8",
            id: 481,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компенсаторы стационарные мощностью свыше 400 кВт",
            ki: "0,9",
            cos: "0,8",
            id: 482,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью до 50 кВт",
            ki: "0,7",
            cos: "0,75",
            id: 483,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью от 50 до 200 кВт",
            ki: "0,75",
            cos: "0,8",
            id: 484,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью от 200 до 400 кВт",
            ki: "0,8",
            cos: "0,8",
            id: 485,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью свыше 400 кВт",
            ki: "0,9",
            cos: "0,85",
            id: 486,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы частичного проветривания",
            ki: "0,65",
            cos: "0,75",
            id: 487,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы главного проветривания, мощностью до 200 кВт",
            ki: "0,7",
            cos: "0,8",
            id: 488,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы главного проветривания, мощностью от 200 до 800 кВт",
            ki: "0,75",
            cos: "0,8",
            id: 489,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы главного проветривания, мощностью свыше 800 кВт",
            ki: "0,8",
            cos: "0,9",
            id: 490,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,65",
            cos: "0,75",
            id: 491,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Ртутные выпрямители металлические",
            ki: "0,9",
            cos: "0,9",
            id: 492,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Ртутные выпрямители стеклянные",
            ki: "0,75",
            cos: "0,75",
            id: 493,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Селеновые выпрямители",
            ki: "0,8",
            cos: "0,8",
            id: 494,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Толкатели, опрокидыватели, качающиеся площадки",
            ki: "0,6",
            cos: "0,65",
            id: 495,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Шахтные двери, бункерные затворы",
            ki: "0,5",
            cos: "0,65",
            id: 496,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дозаторы",
            ki: "0,5",
            cos: "0,65",
            id: 497,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Погрузочно-доставочные машины",
            ki: "0,65",
            cos: "0,65",
            id: 498,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скребковые конвейеры",
            ki: "0,6",
            cos: "0,65",
            id: 499,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скреперные лебедки мощностью до 15 кВт",
            ki: "0,5",
            cos: "0,65",
            id: 500,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скреперные лебедки мощностью свыше 15 кВт",
            ki: "0,65",
            cos: "0,65",
            id: 501,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Лебедки на материальных уклонах",
            ki: "0,6",
            cos: "0,65",
            id: 502,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Подъемники лифтовые",
            ki: "0,2",
            cos: "0,65",
            id: 503,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Клетевые подъемники",
            ki: "0,6",
            cos: "0,65",
            id: 504,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скиповые подъемники",
            ki: "0,7",
            cos: "0,8",
            id: 505,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электросверла ручные и колонковые",
            ki: "0,4",
            cos: "0,675",
            id: 506,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки вращательного бурения",
            ki: "0,45",
            cos: "0,675",
            id: 507,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки ударно-вращательного бурения",
            ki: "0,45",
            cos: "0,675",
            id: 508,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки холодной обработки металлов",
            ki: "0,3",
            cos: "0,5",
            id: 509,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Механизмы обработки земли в литейных цехах",
            ki: "0,6",
            cos: "0,65",
            id: 510,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Горны",
            ki: "0,5",
            cos: "0,75",
            id: 511,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные двигатель-генераторы",
            ki: "0,6",
            cos: "0,75",
            id: 512,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные агрегаты",
            ki: "0,5",
            cos: "0,75",
            id: 513,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные трансформаторы",
            ki: "0,2",
            cos: "0,4",
            id: 514,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электровибрационные механизмы",
            ki: "0,6",
            cos: "0,65",
            id: 515,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вагоноопрокидыватели",
            ki: "0,4",
            cos: "0,55",
            id: 516,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вакуум-насосы",
            ki: "0,8",
            cos: "0,85",
            id: 517,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "электродвигатели",
            ki: "0,5",
            cos: "0,65",
            id: 518,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "нагревательные приборы",
            ki: "0,7",
            cos: "1",
            id: 519,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Краны мостовые, грейферные, кран-балки, тельферы, лифты",
            ki: "0,2",
            cos: "0,5",
            id: 520,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи сопротивления, нагревательные приборы, сушильные, шкафы",
            ki: "0,8",
            cos: "1",
            id: 521,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Освещение стационарных подземных выработок",
            ki: "1",
            cos: "1",
            id: 522,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Освещение участков горных работ",
            ki: "0,9",
            cos: "1",
            id: 523,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Экскаваторы одноковшовые с приводом на постоянном токе по системе ДГД на добыче при грунтах:",
            ki: "0,825",
            cos: "0,65",
            id: 524,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скреперные лебедки мощностью до 10 кВт",
            ki: "0,3",
            cos: "0,7",
            id: 525,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скреперные лебедки мощностью от 10 до 15 кВт",
            ki: "0,6",
            cos: "0,7",
            id: 526,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Скреперные лебедки мощностью свыше 15 кВт",
            ki: "0,6",
            cos: "0,7",
            id: 527,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Лебедки маневровые и откаточные",
            ki: "0,55",
            cos: "0,75",
            id: 528,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Подъемы мощностью свыше 2000 кВт",
            ki: "0,25",
            cos: "0,35",
            id: 529,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Краны мостовые, грейферные, кран-балки, тельферы, лифты",
            ki: "0,25",
            cos: "0,35",
            id: 530,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи сопротивления, нагревательные приборы, сушильные шкафы",
            ki: "0,775",
            cos: "0,965",
            id: 531,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Выпрямители полупроводниковые",
            ki: "0,75",
            cos: "0,96",
            id: 532,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Выпрямители селеновые",
            ki: "0,8",
            cos: "0,8",
            id: 533,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные машины шовные",
            ki: "0,35",
            cos: "0,7",
            id: 534,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные машины стыковые и точечные",
            ki: "0,35",
            cos: "0,6",
            id: 535,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные двигатель-генераторы",
            ki: "0,3",
            cos: "0,625",
            id: 536,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные двигатель-генераторы для однопостовой сварки",
            ki: "0,3",
            cos: "0,625",
            id: 537,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные двигатель-генераторы для многопостовой сварки",
            ki: "0,5",
            cos: "0,725",
            id: 538,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные трансформаторы для однопостовой ручной сварки",
            ki: "0,2",
            cos: "0,35",
            id: 539,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные трансформаторы для многопостовой ручной сварки",
            ki: "0,25",
            cos: "0,5",
            id: 540,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные трансформаторы для автоматической сварки",
            ki: "0,35",
            cos: "0,45",
            id: 541,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные дуговые автоматы",
            ki: "0,35",
            cos: "0,5",
            id: 542,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки холодной обработки металла",
            ki: "0,35",
            cos: "0,65",
            id: 543,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "электродвигатели",
            ki: "0,5",
            cos: "0,65",
            id: 544,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "нагревательные приборы",
            ki: "0,7",
            cos: "1",
            id: 545,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Освещение зданий на прикарьерной промплощадке",
            ki: "0,9",
            cos: "1",
            id: 546,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Освещение в карьере",
            ki: "1",
            cos: "1",
            id: 547,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Освещение территории прикарьерной промплощадки",
            ki: "1",
            cos: "1",
            id: 548,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "на вскрыше при грунтах",
            ki: "0,825",
            cos: "0,55",
            id: 549,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Экскаваторы одноковшовые с приводом на переменном токе при грунтах ",
            ki: "0,825",
            cos: "0,65",
            id: 550,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Экскаваторы многоковшовые",
            ki: "0,75",
            cos: "0,7",
            id: 551,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Экскаваторы роторные",
            ki: "запросить",
            cos: "0,7",
            id: 552,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Отвалообразователи ленточные",
            ki: "запросить",
            cos: "0,65",
            id: 553,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки ударно-канатного бурения",
            ki: "0,5",
            cos: "0,65",
            id: 554,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Станки вращательного бурения",
            ki: "0,5",
            cos: "0,7",
            id: 555,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры передвижные",
            ki: "0,7",
            cos: "0,8",
            id: 556,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры стационарные мощностью до 200 кВт",
            ki: "0,7",
            cos: "0,8",
            id: 557,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры стационарные мощностью от 200 до 400 кВт",
            ki: "0,8",
            cos: "0,8",
            id: 558,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры стационарные мощностью свыше 400 кВт",
            ki: "0,9",
            cos: "0,8",
            id: 559,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью до 50 кВт",
            ki: "0,7",
            cos: "0,75",
            id: 560,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью от 50 до 200 кВт",
            ki: "0,75",
            cos: "0,8",
            id: 561,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью от 200 до 500 кВт",
            ki: "0,8",
            cos: "0,8",
            id: 562,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы мощностью свыше 500 кВт ",
            ki: "0,85",
            cos: "0,85",
            id: 563,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Землесосы и песковые насосы мощностью до 50 кВт",
            ki: "0,8",
            cos: "0,8",
            id: 564,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Землесосы и песковые насосы мощностью свыше 50 кВт",
            ki: "0,85",
            cos: "0,8",
            id: 565,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электровибрационные механизмы",
            ki: "0,7",
            cos: "0,65",
            id: 566,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вакуум-насосы",
            ki: "0,8",
            cos: "0,75",
            id: 567,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы производственные",
            ki: "0,7",
            cos: "0,78",
            id: 568,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы главного проветривания мощностью от 200 до 800 кВт",
            ki: "0,775",
            cos: "0,8",
            id: 569,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы главного проветривания мощностью свыше 800 кВт",
            ki: "0,875",
            cos: "0,85",
            id: 570,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,65",
            cos: "0,75",
            id: 571,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы производственного водоснабжения",
            ki: "0,8",
            cos: "0,85",
            id: 572,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы песковые производственные",
            ki: "0,75",
            cos: "0,8",
            id: 573,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы песковые дренажные",
            ki: "0,8",
            cos: "0,8",
            id: 574,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы песковые для перекачки хвостов и оборотного водоснабжения",
            ki: "0,8",
            cos: "0,8",
            id: 575,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вакуум-насосы",
            ki: "0,95",
            cos: "0,85",
            id: 576,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы масляные",
            ki: "0,65",
            cos: "0,75",
            id: 577,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "2 Аглоэксгаустеры для лент 75 м",
            ki: "0,85",
            cos: "0,9",
            id: 578,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "2 Аглоэксгаустеры для лент 50 мм",
            ki: "0,75",
            cos: "0,9",
            id: 579,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы производственные, воздуходувки, дымососы",
            ki: "0,75",
            cos: "0,8",
            id: 580,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,8",
            cos: "0,8",
            id: 581,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вентиляторы к дробилкам",
            ki: "0,5",
            cos: "0,675",
            id: 582,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры стационарные мощностью до 200 кВт",
            ki: "0,75",
            cos: "0,75",
            id: 583,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Компрессоры стационарные мощностью от 200 до 400 кВт",
            ki: "0,9",
            cos: "0,8",
            id: 584,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки крупного дробленая, щековые и конусные с двухдвигательным приводом",
            ki: "0,7",
            cos: "0,6",
            id: 585,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки конусные крупного дробления с однодвигательным приводом, дробилки конусные среднего дробления",
            ki: "0,7",
            cos: "0,725",
            id: 586,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки короткоконусные мелкого дробления",
            ki: "0,7",
            cos: "0,75",
            id: 587,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки одновалковые и чатырехвалковые мелкого дробления",
            ki: "0,7",
            cos: "0,75",
            id: 588,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки молотковые",
            ki: "0,78",
            cos: "0,875",
            id: 589,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дробилки четырехвалковые",
            ki: "0,85",
            cos: "0,85",
            id: 590,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Мельницы шаровые",
            ki: "0,9",
            cos: "0,9",
            id: 591,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Мельницы шаровые и стержневые II и III стадий дробления",
            ki: "0,8",
            cos: "0,9",
            id: 592,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Мельницы мощностью до 100 кВт",
            ki: "0,8",
            cos: "0,75",
            id: 593,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Мельницы самоизмельчения 7,5х2,5 м",
            ki: "0,75",
            cos: "0,9",
            id: 594,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Грохоты разные",
            ki: "0,5",
            cos: "0,65",
            id: 595,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Грохоты барабанные",
            ki: "0,65",
            cos: "0,75",
            id: 596,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Механизмы вращающихся печей",
            ki: "0,6",
            cos: "0,7",
            id: 597,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Дымососы печей",
            ki: "0,7",
            cos: "0,8",
            id: 598,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вспомогательные механизмы конвейеров",
            ki: "0,15",
            cos: "0,5",
            id: 599,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вагоноопрокидыватели",
            ki: "0,4",
            cos: "0,55",
            id: 600,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Конвейеры ленточные легкие с двигателями до 10 кВт",
            ki: "0,6",
            cos: "0,65",
            id: 601,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Конвейеры ленточные легкие с двигателями свыше 10 кВт",
            ki: "0,75",
            cos: "0,75",
            id: 602,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Питатели ленточные, улитковые, тарельчатые, лотковые с двигателями до 10 кВт, шаропитатели, электромагнитные и магнитные сепараторы",
            ki: "0,4",
            cos: "0,65",
            id: 603,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Питатели, конвейеры электровибрационные, грохоты",
            ki: "0,79",
            cos: "0,625",
            id: 604,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Конвейеры корпуса крупного дробления",
            ki: "0,5",
            cos: "0,7",
            id: 605,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вспомогательные механизмы конвейеров",
            ki: "0,15",
            cos: "0,5",
            id: 606,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Конвейеры тяжелые с шириной ленты до 1400 мм",
            ki: "0,7",
            cos: "0,75",
            id: 607,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Конвейеры сверхтяжелые с шириной ленты 1600-2000 мм",
            ki: "0,8",
            cos: "0,825",
            id: 608,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Питатели пластинчатые тарельчатые, лотковые мощностью свыше 10 кВт шнеки, элеваторы, механические топки",
            ki: "0,7",
            cos: "0,75",
            id: 609,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сгустители",
            ki: "0,7",
            cos: "0,8",
            id: 610,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Барабаны смесительные",
            ki: "0,7",
            cos: "0,8",
            id: 611,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Чашевые охладители",
            ki: "0,7",
            cos: "0,85",
            id: 612,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Столы концентрационные, чаны контактные и реагентные",
            ki: "0,6",
            cos: "0,7",
            id: 613,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сушильные барабаны и сепараторы",
            ki: "0,6",
            cos: "0,8",
            id: 614,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Классификаторы спиральные",
            ki: "0,7",
            cos: "0,8",
            id: 615,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Отсадочные машины, шлюзы",
            ki: "0,6",
            cos: "0,7",
            id: 616,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Классификаторы реечные",
            ki: "0,6",
            cos: "0,7",
            id: 617,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Мешкозашивочные машины, фильтры весовые дозаторы, погрузчики концентрата",
            ki: "0,3",
            cos: "0,6",
            id: 618,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Флотационные машины механические и пневмомеханические",
            ki: "0,9",
            cos: "0,8",
            id: 619,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вакуум-фильтры",
            ki: "0,4",
            cos: "0,6",
            id: 620,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электрофильтры",
            ki: "0,4",
            cos: "0,87",
            id: 621,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сепараторы электромагнитные и магнитные барабанные (привод барабана)",
            ki: "0,75",
            cos: "0,75",
            id: 622,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Магнитные сепараторы индивидуальные",
            ki: "0,4",
            cos: "по сх.замещения",
            id: 623,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сепараторы электромагнитные разные. Нагрузки на обработку возбуждения со стороны переменного тока по отношению к номинальной мощности электромагнитов сепаратора",
            ki: "1,1",
            cos: "0,875",
            id: 624,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Реагентные питатели, опробователи",
            ki: "0,3",
            cos: "0,5",
            id: 625,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи сушильные барабанные, смесители, промывочные барабаны, окомкователи, корытные мойки (логоушеры)",
            ki: "0,75",
            cos: "0,8",
            id: 626,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Преобразовательные агрегаты электрофильтров",
            ki: "0,6",
            cos: "0,85",
            id: 627,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи обжиговые для дв. пост. тока",
            ki: "0,8",
            cos: "1",
            id: 628,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи обжиговые для дв. перем. тока",
            ki: "0,8",
            cos: "0,85",
            id: 629,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Тиристорные преобразователи для возбудителей синхронных двигателей",
            ki: "0,8",
            cos: "0,5",
            id: 630,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Тиристорные преобразователи частоты",
            ki: "0,7",
            cos: "0,7",
            id: 631,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Двигатель-генераторы",
            ki: "0,7",
            cos: "0,8",
            id: 632,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сварочные трансформаторы",
            ki: "0,2",
            cos: "0,4",
            id: 633,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Лебедки разные",
            ki: "0,3",
            cos: "0,5",
            id: 634,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Вулканизационные аппараты",
            ki: "0,885",
            cos: "1",
            id: 635,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электроковши, электрогрелки",
            ki: "0,675",
            cos: "0,75",
            id: 636,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Металлообрабатывающие станки",
            ki: "0,2",
            cos: "0,5",
            id: 637,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Сушильные шкафы",
            ki: "0,75",
            cos: "1",
            id: 638,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Лабораторное оборудование",
            ki: "0,4",
            cos: "0,725",
            id: 639,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Печи сушильные (омические)",
            ki: "1",
            cos: "1",
            id: 640,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Нагревательные приборы",
            ki: "0,7",
            cos: "1",
            id: 641,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Краны грейферные",
            ki: "0,25",
            cos: "0,55",
            id: 642,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Краны разные",
            ki: "0,2",
            cos: "0,55",
            id: 643,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Электроковши, грелки для масла",
            ki: "0,97",
            cos: "1",
            id: 644,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Насосы масляные",
            ki: "0,65",
            cos: "0,75",
            id: 645,
            parents: [
                1,
                1.7
            ]
        },
        {
            name: "Крановые установки лесозаготовительных предприятий: К-305Н",
            ki: "0,28",
            cos: "0,42",
            id: 646,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Крановые установки лесозаготовительных предприятий: ККС-10",
            ki: "0,25",
            cos: "0,42",
            id: 647,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Крановые установки лесозаготовительных предприятий: ККЦ-7,5",
            ki: "0,3",
            cos: "0,45",
            id: 648,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Козловые к консольно-козловые краны с грейфером (при непрерывной работе)",
            ki: "0,4",
            cos: "0,6",
            id: 649,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "БКСМ-14, ПM-2",
            ki: "0,2",
            cos: "0,4",
            id: 650,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Башенный кран КБ-572",
            ki: "0,15",
            cos: "0,35",
            id: 651,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Мостовой кран",
            ki: "0,3",
            cos: "0,5",
            id: 652,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Кабель-кран КК-20",
            ki: "0,4",
            cos: "0,65",
            id: 653,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "разгрузочно-растаскивающее устройство",
            ki: "0,1",
            cos: "0,554",
            id: 654,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "сортировочные транспортеры",
            ki: "0,415",
            cos: "0,7",
            id: 655,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "раскряжевочно-сортировочные линии ПЛХ-2, ПДХ-3 с неавтоматическим сортировочным транспортером",
            ki: "0,22",
            cos: "0,6",
            id: 656,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Поперечный растаскиватель хлыстов ПРХ-2С",
            ki: "0,1",
            cos: "0,554",
            id: 657,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Многожильный раскряжевочный агрегат с поперечной подачей хлыста (МР-8)",
            ki: "0,3",
            cos: "0,8",
            id: 658,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сучкорезная линия ПСЛ-1",
            ki: "0,2",
            cos: "0,645",
            id: 659,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Слешерная линия разделки долготья на коротье",
            ki: "0,22",
            cos: "0,554",
            id: 660,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Установка по производству технологической щепы УПЩ-3",
            ki: "0,3",
            cos: "0,554",
            id: 661,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рубильная машина на балансах МРГ-35",
            ki: "0,43",
            cos: "0,57",
            id: 662,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Трелевочные лебедки с циклическим движением троса",
            ki: "0,225",
            cos: "0,55",
            id: 663,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Трелевочные лебедки с бесконечным движением троса",
            ki: "0,265",
            cos: "0,575",
            id: 664,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Лебедка на штабелевке и погрузке на складах",
            ki: "0,175",
            cos: "0,54",
            id: 665,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Бревнотаски на бирже",
            ki: "0,45",
            cos: "0,7",
            id: 666,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Бревнотаски амбарные, лебедки на складах",
            ki: "0,25",
            cos: "0,5",
            id: 667,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Бревнотаски береговые и цепные транспортеры, шнеки и конвейеры: cблокированные",
            ki: "0,475",
            cos: "0,75",
            id: 668,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Бревнотаски береговые и цепные транспортеры, шнеки и конвейеры: несблокированные",
            ki: "0,35",
            cos: "0,75",
            id: 669,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Бревносвалы",
            ki: "0,1",
            cos: "0,525",
            id: 670,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Дровокольные станки",
            ki: "0,2",
            cos: "0,4",
            id: 671,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Электропилы (валка деревьев, раскряжевка)",
            ki: "0,35",
            cos: "0,645",
            id: 672,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Окорочные станки",
            ki: "0,3",
            cos: "0,554",
            id: 673,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Лесопильные рамы",
            ki: "0,6",
            cos: "0,75",
            id: 674,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Многопильный станок",
            ki: "0,43",
            cos: "0,75",
            id: 675,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Обрезные станки",
            ki: "0,2",
            cos: "0,554",
            id: 676,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Торцовочные станки",
            ki: "0,4",
            cos: "0,554",
            id: 677,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Торцовки",
            ki: "0,4",
            cos: "0,554",
            id: 678,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рубильные машины",
            ki: "0,3",
            cos: "0,554",
            id: 679,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Шпалорезный станок",
            ki: "0,4",
            cos: "0,645",
            id: 680,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Универсальный станок",
            ki: "0,4",
            cos: "0,645",
            id: 681,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Ленточно-делительный станок",
            ki: "0,4",
            cos: "0,645",
            id: 682,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рейсмусовые и фуговальные станки",
            ki: "0,45",
            cos: "0,55",
            id: 683,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Тарный станок",
            ki: "0,5",
            cos: "0,645",
            id: 684,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Тарно-делительный станок",
            ki: "0,5",
            cos: "0,645",
            id: 685,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Балансирная пила",
            ki: "0,2",
            cos: "0,6",
            id: 686,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Древесно-стружечный станок",
            ki: "0,4",
            cos: "0,6",
            id: 687,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Деревошерстный станок",
            ki: "0,4",
            cos: "0,6",
            id: 688,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Ребровый станок",
            ki: "0,3",
            cos: "0,554",
            id: 689,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Круглопильный станок для поперечного распиливания",
            ki: "0,25",
            cos: "0,55",
            id: 690,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Круглопильный станок для продольного распиливания",
            ki: "0,25",
            cos: "0,55",
            id: 691,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Концеравнильный двухпильный станок",
            ki: "0,2",
            cos: "0,645",
            id: 692,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Агрегатный станок",
            ki: "0,2",
            cos: "0,645",
            id: 693,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Строгальный станок",
            ki: "0,6",
            cos: "0,645",
            id: 694,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сортировка щепы",
            ki: "0,5",
            cos: "0,69",
            id: 695,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рольганги",
            ki: "0,3",
            cos: "0,554",
            id: 696,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Скребковые транспортеры",
            ki: "0,4",
            cos: "0,69",
            id: 697,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Ленточные транспортеры",
            ki: "0,2",
            cos: "0,554",
            id: 698,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Брусоперекладчики",
            ki: "0,3",
            cos: "0,554",
            id: 699,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сбрасыватель",
            ki: "0,2",
            cos: "0,49",
            id: 700,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Дробилки",
            ki: "0,325",
            cos: "0,58",
            id: 701,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Грохоты",
            ki: "0,6",
            cos: "0,7",
            id: 702,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Продольно-торцевые станки и станки для изготовления дранки",
            ki: "0,125",
            cos: "0,52",
            id: 703,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Ленточные ребровые станки",
            ki: "0,475",
            cos: "0,675",
            id: 704,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Круглопильные станки с механической подачей (обрезные, длинореечные, делительные) ребровые,",
            ki: "0,45",
            cos: "0,675",
            id: 705,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Круглопильные станки с ручной подачей (балансирные, маятниковые) торцевые,",
            ki: "0,35",
            cos: "0,55",
            id: 706,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Фрезерные, шипорезные, сверлильные, долбежные, токарные по дереву",
            ki: "0,2",
            cos: "0,7",
            id: 707,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Фрезерные, шипорезные, сверлильные, долбежные, токарные поточные линии",
            ki: "0,25",
            cos: "0,7",
            id: 708,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Шлифовальные станки",
            ki: "0,45",
            cos: "0,6",
            id: 709,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Автоматические линии деревообработки",
            ki: "0,5",
            cos: "0,65",
            id: 710,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Станки ремонтно-механических мастерских",
            ki: "0,175",
            cos: "0,625",
            id: 711,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Прессы",
            ki: "0,55",
            cos: "0,8",
            id: 712,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Транспортеры скребниковые и ленточные",
            ki: "0,5",
            cos: "0,7",
            id: 713,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Питатели пластинчатые, тарельчатые, барабанные",
            ki: "0,4",
            cos: "0,6",
            id: 714,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рольганги, элеваторы",
            ki: "0,3",
            cos: "0,675",
            id: 715,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Краны, тельферы, тележки цеховые",
            ki: "0,1",
            cos: "0,5",
            id: 716,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сепараторы",
            ki: "0,4",
            cos: "0,65",
            id: 717,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сушильные барабаны",
            ki: "0,6",
            cos: "0,7",
            id: 718,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Электрические печи сопротивления",
            ki: "0,8",
            cos: "0,95",
            id: 719,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Переносный электроинструмент",
            ki: "0,08",
            cos: "0,5",
            id: 720,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Машина УПФМ",
            ki: "0,45",
            cos: "0,7",
            id: 721,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Насосы, компрессоры",
            ki: "0,7",
            cos: "0,85",
            id: 722,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляторы эксгаустеров и сушильных камер",
            ki: "0,7",
            cos: "0,8",
            id: 723,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,65",
            cos: "0,8",
            id: 724,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Четырехсторонние строительные станки",
            ki: "0,16",
            cos: "0,53",
            id: 725,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Двухсторонние шипорезы",
            ki: "0,12",
            cos: "0,36",
            id: 726,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Шлифовальные машины",
            ki: "0,22",
            cos: "0,37",
            id: 727,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Линии продольной и поперечной обработки створок",
            ki: "0,31",
            cos: "0,44",
            id: 728,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Станочное оборудование цеха погонажа",
            ki: "0,61",
            cos: "0,59",
            id: 729,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сушильное отделение",
            ki: "0,37",
            cos: "0,7",
            id: 730,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Малярное отделение",
            ki: "0,5",
            cos: "0,55",
            id: 731,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Лакокрасочное отделение цеха",
            ki: "0,73",
            cos: "0,8",
            id: 732,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Технологическая вентиляция",
            ki: "0,545",
            cos: "0,735",
            id: 733,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сантехническая вентиляция",
            ki: "0,75",
            cos: "0,8",
            id: 734,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рубительная машина",
            ki: "0,245",
            cos: "0,535",
            id: 735,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Дезинтегратор",
            ki: "0,12",
            cos: "0,3",
            id: 736,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Транспортеры щепы",
            ki: "0,405",
            cos: "0,55",
            id: 737,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Пневмотранспорт щепы",
            ki: "0,84",
            cos: "0,85",
            id: 738,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Механизмы сортировки щепы",
            ki: "0,245",
            cos: "0,5",
            id: 739,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Рафинатор",
            ki: "0,645",
            cos: "0,87",
            id: 740,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Дефибратор со вспомогательным оборудованием",
            ki: "0,63",
            cos: "0,75",
            id: 741,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Мешалка бассейна и насос массы",
            ki: "0,43",
            cos: "0,78",
            id: 742,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Механизмы проклейки",
            ki: "0,47",
            cos: "0,65",
            id: 743,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Отливочная машина",
            ki: "0,795",
            cos: "0,85",
            id: 744,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Насосы брака и обратной воды",
            ki: "0,36",
            cos: "0,82",
            id: 745,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вспомогательные механизмы",
            ki: "0,38",
            cos: "0,65",
            id: 746,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,38",
            cos: "0,7",
            id: 747,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Форматные станки твердого потока",
            ki: "0,41",
            cos: "0,5",
            id: 748,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Отливочная машина пористого потока",
            ki: "0,22",
            cos: "0,48",
            id: 749,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сушилка пористого потока",
            ki: "0,56",
            cos: "0,76",
            id: 750,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вакуум-насосы",
            ki: "0,77",
            cos: "0,85",
            id: 751,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Насосы высокого и низкого давления",
            ki: "0,23",
            cos: "0,76",
            id: 752,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Насосы теплового аккумулятора",
            ki: "0,89",
            cos: "0,86",
            id: 753,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Транспортеры",
            ki: "0,26",
            cos: "0,58",
            id: 754,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,14",
            cos: "0,58",
            id: 755,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вспомогательное оборудование пресса",
            ki: "0,47",
            cos: "0,8",
            id: 756,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляторы закалочных камер",
            ki: "0,3",
            cos: "0,83",
            id: 757,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Транспортные механизмы (тележки, рольганги и др.)",
            ki: "0,1",
            cos: "0,3",
            id: 758,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,5",
            cos: "0,5",
            id: 759,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Технические механизмы",
            ki: "0,54",
            cos: "0,65",
            id: 760,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,36",
            cos: "0,6",
            id: 761,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Моечная установка",
            ki: "0,7",
            cos: "0,8",
            id: 762,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Установка заправки маслом",
            ki: "0,5",
            cos: "0,645",
            id: 763,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Аппарат промывочный",
            ki: "0,7",
            cos: "0,8",
            id: 764,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Гидроподъемник",
            ki: "0,7",
            cos: "0,8",
            id: 765,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Гайковерт",
            ki: "0,14",
            cos: "0,5",
            id: 766,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Шероховальный станок",
            ki: "0,14",
            cos: "0,5",
            id: 767,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Электровулканизаторы",
            ki: "0,4",
            cos: "0,6",
            id: 768,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Компрессор",
            ki: "0,7",
            cos: "0,8",
            id: 769,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Испытательный стенд",
            ki: "0,4",
            cos: "0,64",
            id: 770,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Токарный станок",
            ki: "0,14",
            cos: "0,5",
            id: 771,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Обрезной станок",
            ki: "0,14",
            cos: "0,5",
            id: 772,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Фрезерный станок",
            ki: "0,14",
            cos: "0,5",
            id: 773,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Строгальный станок",
            ki: "0,14",
            cos: "0,5",
            id: 774,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Расточный станок",
            ki: "0,14",
            cos: "0,5",
            id: 775,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сверлильный станок",
            ki: "0,14",
            cos: "0,5",
            id: 776,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Заточный станок",
            ki: "0,14",
            cos: "0,5",
            id: 777,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Пилоножеточный станок",
            ki: "0,14",
            cos: "0,5",
            id: 778,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Шлифовальный станок",
            ki: "0,14",
            cos: "0,5",
            id: 779,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сварочные трансформаторы",
            ki: "0,2",
            cos: "0,399",
            id: 780,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Однопостовые сварочные двигатель-генераторы",
            ki: "0,2",
            cos: "0,399",
            id: 781,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,65",
            cos: "0,8",
            id: 782,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,5",
            cos: "0,69",
            id: 783,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,65",
            cos: "0,8",
            id: 784,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Дымососы",
            ki: "0,9",
            cos: "0,901",
            id: 785,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Насосы водяные",
            ki: "0,7",
            cos: "0,8",
            id: 786,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Механизмы на сплаве: сплоточные машины",
            ki: "0,6",
            cos: "0,69",
            id: 787,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сортировочные машины",
            ki: "0,6",
            cos: "0,69",
            id: 788,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Сортировочно-формовочные машины",
            ki: "0,65",
            cos: "0,69",
            id: 789,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Гидроускорители",
            ki: "0,65",
            cos: "0,69",
            id: 790,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Потокообразователи",
            ki: "0,7",
            cos: "0,8",
            id: 791,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Барабанные ускорители",
            ki: "0,3",
            cos: "0,554",
            id: 792,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Троповые ускорители",
            ki: "0,5",
            cos: "0,6",
            id: 793,
            parents: [
                1,
                1.8
            ]
        },
        {
            name: "Мельница",
            ki: "0,97",
            cos: "0,88",
            id: 794,
            parents: [
                1,
                1.9
            ]
        },
        {
            name: "Дробилка",
            ki: "0,33",
            cos: "0,77",
            id: 795,
            parents: [
                1,
                1.9
            ]
        },
        {
            name: "Оборудование размольного отделения без мельниц",
            ki: "0,43",
            cos: "0,81",
            id: 796,
            parents: [
                1,
                1.9
            ]
        },
        {
            name: "Насосы непрерывного режима работы (перекачка воды, технологического полупродукта)",
            ki: "0,7",
            cos: "по сх.замещения",
            id: 797,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы периодического режима работы (вывод шлама, перекачка химикатов)",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 798,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сцежи",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 799,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Мешалки",
            ki: "0,325",
            cos: "по сх.замещения",
            id: 800,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сепараторы",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 801,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Компрессоры сжатого воздуха",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 802,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы непрерывного режима",
            ki: "0,7",
            cos: "по сх.замещения",
            id: 803,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы периодического режима работы",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 804,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Мешалки",
            ki: "0,325",
            cos: "по сх.замещения",
            id: 805,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сепараторы",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 806,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Турбовоздуходувки, нагнетатели воздуха",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 807,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Дымососы",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 808,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Вентиляторы производственные",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 809,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Пневмотранспорт",
            ki: "0,82",
            cos: "по сх.замещения",
            id: 810,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Транспортеры, конвейеры, питатели",
            ki: "0,525",
            cos: "по сх.замещения",
            id: 811,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Флотаторы",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 812,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "ЦРМ",
            ki: "0,65",
            cos: "по сх.замещения",
            id: 813,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Упаковочная установка",
            ki: "0,6",
            cos: "по сх.замещения",
            id: 814,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Насосы водозаборных и очистных сооружений",
            ki: "0,7",
            cos: "по сх.замещения",
            id: 815,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,625",
            cos: "по сх.замещения",
            id: 816,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Станочное оборудование ремонтных мастерских",
            ki: "0,135",
            cos: "по сх.замещения",
            id: 817,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Электротали",
            ki: "0,075",
            cos: "по сх.замещения",
            id: 818,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Электросварочные аппараты для ручной сварки",
            ki: "0,325",
            cos: "по сх.замещения",
            id: 819,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Оборудование КИПиА",
            ki: "0,225",
            cos: "по сх.замещения",
            id: 820,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Электропривод задвижек",
            ki: "0,65",
            cos: "",
            id: 821,
            parents: [
                1,
                1.1
            ]
        },
        {
            name: "Сырьевые мельницы (главный привод)",
            ki: "0,72",
            cos: "0,85",
            id: 822,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Низковольтное оборудование сырьевого цеха",
            ki: "0,56",
            cos: "0,75",
            id: 823,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Шлам-насосы",
            ki: "0,56",
            cos: "0,75",
            id: 824,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Болтушки",
            ki: "0,62",
            cos: "0,8",
            id: 825,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Дробилки",
            ki: "0,54",
            cos: "0,8",
            id: 826,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Крановые мешалки шлама",
            ki: "0,38",
            cos: "0,5",
            id: 827,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Экскаваторы",
            ki: "0,4",
            cos: "0,7",
            id: 828,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Транспортеры сырья",
            ki: "0,5",
            cos: "0,75",
            id: 829,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Карьер в целом",
            ki: "0,65",
            cos: "0,75",
            id: 830,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Вращающиеся печи без холодильников",
            ki: "0,7",
            cos: "0,8",
            id: 831,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Вращающиеся печи с холодильниками",
            ki: "0,6",
            cos: "0,7",
            id: 832,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Главные приводы печей",
            ki: "0,7",
            cos: "0,8",
            id: 833,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Дымососы печей",
            ki: "0,7",
            cos: "0,8",
            id: 834,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Механизмы переуборки",
            ki: "0,46",
            cos: "0,65",
            id: 835,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,57",
            cos: "0,75",
            id: 836,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Транспортеры,клинкера",
            ki: "0,45",
            cos: "0,7",
            id: 837,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Холодильники",
            ki: "0,6",
            cos: "0,85",
            id: 838,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Электрофильтры",
            ki: "0,8",
            cos: "0,85",
            id: 839,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Цементные мельницы в целом",
            ki: "0,8",
            cos: "0,85",
            id: 840,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Главный привод цементных мельниц",
            ki: "0,85",
            cos: "0,875",
            id: 841,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Низковольтное оборудование цементных мельниц",
            ki: "0,48",
            cos: "0,75",
            id: 842,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Упаковочная",
            ki: "0,4",
            cos: "0,7",
            id: 843,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Грейферные краны",
            ki: "0,5",
            cos: "0,6",
            id: 844,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Пневмовинтовые насосы (фулер-насосы)",
            ki: "0,48",
            cos: "0,75",
            id: 845,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Сушильное отделение",
            ki: "0,6",
            cos: "0,75",
            id: 846,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Питатели и дозаторы",
            ki: "0,6",
            cos: "0,78",
            id: 847,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Угольные мельницы",
            ki: "0,7",
            cos: "0,83",
            id: 848,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Электрокалориферы",
            ki: "0,6",
            cos: "0,88",
            id: 849,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,75",
            cos: "0,85",
            id: 850,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Водонасосы",
            ki: "0,8",
            cos: "0,8",
            id: 851,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,64",
            cos: "0,75",
            id: 852,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Бетоноукладчики",
            ki: "0,15",
            cos: "0,6",
            id: 853,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Автоматические станки для правки и резки проволоки",
            ki: "0,15",
            cos: "0,6",
            id: 854,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Формовочные машины",
            ki: "0,15",
            cos: "0,6",
            id: 855,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Конвейеры",
            ki: "0,15",
            cos: "0,5",
            id: 856,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Рольганги",
            ki: "0,1",
            cos: "0,5",
            id: 857,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Земснаряды",
            ki: "0,59",
            cos: "0,735",
            id: 858,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Экскаваторы с электроприводом",
            ki: "запросить",
            cos: "0,55",
            id: 859,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Растворные узлы",
            ki: "запросить",
            cos: "0,55",
            id: 860,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Краны башенные и портальные",
            ki: "запросить",
            cos: "0,5",
            id: 861,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Трансформаторный электроподогрев бетона, отогрев грунта и трубопроводов",
            ki: "запросить",
            cos: "0,75",
            id: 862,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Однопостовые двигатель-генераторы сварки",
            ki: "запросить",
            cos: "0,6",
            id: 863,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Сварочные трансформаторы",
            ki: "0,2",
            cos: "0,4",
            id: 864,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Переносные механизмы",
            ki: "запросить",
            cos: "0,45",
            id: 865,
            parents: [
                1,
                1.11
            ]
        },
        {
            name: "Прядильные машины штапеля",
            ki: "0,38",
            cos: "0,7",
            id: 866,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прядильные машины капрона",
            ki: "0,65",
            cos: "0,7",
            id: 867,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прядильные машины вискозного корда",
            ki: "0,5",
            cos: "0,7",
            id: 868,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прядильные машины центрифугального шелка (главный привод)",
            ki: "0,52",
            cos: "0,7",
            id: 869,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прядильные машины центрифугального шелка (электроверетена)",
            ki: "0,62",
            cos: "0,7",
            id: 870,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прядильные машины ацетатного шелка",
            ki: "0,7",
            cos: "0,7",
            id: 871,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Мешалки растворителей",
            ki: "0,55",
            cos: "0,8",
            id: 872,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Фильтр-прессы",
            ki: "0,35",
            cos: "0,55",
            id: 873,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Крутильные машины ацетатного шелка",
            ki: "0,6",
            cos: "0,7",
            id: 874,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Перемоточные машины",
            ki: "0,78",
            cos: "0,7",
            id: 875,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Тростильно-крутильные машины",
            ki: "0,89",
            cos: "0,8",
            id: 876,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Крутильные машины",
            ki: "0,64",
            cos: "0,8",
            id: 877,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вытяжные машины",
            ki: "0,7",
            cos: "0,85",
            id: 878,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Ткацкие станки",
            ki: "0,74",
            cos: "0,7",
            id: 879,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Динильные котлы",
            ki: "0,58",
            cos: "1",
            id: 880,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Периодопреобразователи",
            ki: "0,75",
            cos: "0,9",
            id: 881,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,78",
            cos: "0,8",
            id: 882,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Водонасосные",
            ki: "0,83",
            cos: "0,8",
            id: 883,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,64",
            cos: "0,76",
            id: 884,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры этилена",
            ki: "0,85",
            cos: "0,9",
            id: 885,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Технологические линии",
            ki: "0,6",
            cos: "0,75",
            id: 886,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Эльма-насосы",
            ki: "0,5",
            cos: "0,8",
            id: 887,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Центрифуги",
            ki: "0,4",
            cos: "0,6",
            id: 888,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Сушилка",
            ki: "0,5",
            cos: "0,5",
            id: 889,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Грануляторы",
            ki: "0,8",
            cos: "0,8",
            id: 890,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Газодувки",
            ki: "0,45",
            cos: "0,7",
            id: 891,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры цеха дистилляции и очистки азота",
            ki: "0,43",
            cos: "0,7",
            id: 892,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры воздушные",
            ki: "0,67",
            cos: "0,98",
            id: 893,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Газодувка газогенераторного цеха",
            ki: "0,57",
            cos: "0,85",
            id: 894,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры газовые",
            ki: "0,85",
            cos: "0,99",
            id: 895,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Триплекс-насосы",
            ki: "0,4",
            cos: "0,75",
            id: 896,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Двигатель турбины (мототурбонасосы)",
            ki: "0,81",
            cos: "0,85",
            id: 897,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Экспансионные машины",
            ki: "0,8",
            cos: "0,85",
            id: 898,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Циркуляционные компрессоры синтеза",
            ki: "0,74",
            cos: "0,78",
            id: 899,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Нагнетатели кислородно-воздушные смеси (КВС)",
            ki: "0,82",
            cos: "0,86",
            id: 900,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры аммиачные",
            ki: "0,7",
            cos: "0,98",
            id: 901,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Кислорододувки",
            ki: "0,6",
            cos: "0,78",
            id: 902,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Трубогазодувки",
            ki: "0,73",
            cos: "0,9",
            id: 903,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Турбокомпрессоры газовые",
            ki: "0,8",
            cos: "0,95",
            id: 904,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Насосы грунтового водоснабжения",
            ki: "запросить",
            cos: "0,7",
            id: 905,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры, насосы, вентиляторы, мешалки центрифуги (приводы с непрерывным технологическим потоком)",
            ki: "0,5",
            cos: "0,86",
            id: 906,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Резиносмесители, работающие на приготовлении резиновой смеси",
            ki: "0,5",
            cos: "0,875",
            id: 907,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Резиносмесители, работающие на вулканизацию каучука",
            ki: "0,54",
            cos: "0,875",
            id: 908,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вальцы под резиносмесители",
            ki: "0,65",
            cos: "0,875",
            id: 909,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Подогреватели на вальцы",
            ki: "0,56",
            cos: "0,875",
            id: 910,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Пелетайзеры",
            ki: "0,36",
            cos: "0,875",
            id: 911,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Шприц-машины проекторных агрегатов",
            ki: "0,7",
            cos: "0,8",
            id: 912,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Шприц-машины автокамерных агрегатов",
            ki: "0,53",
            cos: "0,7",
            id: 913,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Каландры обкладочные (обрезинивание корда)",
            ki: "0,68",
            cos: "0,8",
            id: 914,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Сборочные станки",
            ki: "0,44",
            cos: "0,6",
            id: 915,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вулканизаторы шин",
            ki: "0,05",
            cos: "0,4",
            id: 916,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вулканизаторы автокамер и ободных лент",
            ki: "0,16",
            cos: "0,5",
            id: 917,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Компрессоры (синхронные двигатели)",
            ki: "0,93",
            cos: "0,875",
            id: 918,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Водонасосные (водоснабжение)",
            ki: "0,89",
            cos: "0,8",
            id: 919,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Насосы циркуляционной и перегретой воды",
            ki: "0,43",
            cos: "0,7",
            id: 920,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Насосы воды низкого давления",
            ki: "0,87",
            cos: "0,8",
            id: 921,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Насосы воды высокого давления",
            ki: "0,55",
            cos: "0,8",
            id: 922,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,69",
            cos: "0,75",
            id: 923,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Транспортные системы",
            ki: "0,25",
            cos: "0,45",
            id: 924,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Агрегаты для изготовления особо прочных транспортерных лент",
            ki: "0,11",
            cos: "по сх.замещения",
            id: 925,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Каландры для изготовления сердечников транспортерных лент",
            ki: "0,48",
            cos: "0,69",
            id: 926,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Каландры для обкладки транспортерных лент",
            ki: "0,28",
            cos: "0,51",
            id: 927,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Подогревательные вальцы производства транспортерных лент",
            ki: "0,47",
            cos: "0,8",
            id: 928,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Шприц-машины производства формовой техники",
            ki: "0,37",
            cos: "0,47",
            id: 929,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прессы с электрообогревом производства формовой техники при работе",
            ki: "0,78",
            cos: "по сх.замещения",
            id: 930,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Прессы с электрообогревом производства формовой техники при разогреве",
            ki: "запросить",
            cos: "1",
            id: 931,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Подогревательные вальцы производства формовой техники",
            ki: "0,43",
            cos: "0,58",
            id: 932,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Резиносмесители подготовительного цеха",
            ki: "0,5",
            cos: "0,8",
            id: 933,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Смесительные вальцы '84' подготовительного цеха",
            ki: "0,54",
            cos: "0,8",
            id: 934,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Шприц-машины камер 12-38 мм",
            ki: "0,4",
            cos: "0,67",
            id: 935,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Агрегаты для наложения наружного слоя на рукава диаметром 19-38 мм",
            ki: "0,28",
            cos: "0,74",
            id: 936,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Каландры подготовительного цеха обкладки",
            ki: "0,54",
            cos: "0,69",
            id: 937,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Каландры подготовительного цеха листования",
            ki: "0,36",
            cos: "0,52",
            id: 938,
            parents: [
                1,
                1.12
            ]
        },
        {
            name: "Джины с питателем, волокноочистители",
            ki: "0,8",
            cos: "0,82",
            id: 939,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Линтера, семеоголители",
            ki: "0,7",
            cos: "0,8",
            id: 940,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Очистители хлопка-сырца, семеочистители",
            ki: "0,5",
            cos: "0,7",
            id: 941,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Шнеки хлопка-сырца, отходов, сепараторы, конденсаторы,приемно-подающие устройства хлопка- сырца",
            ki: "0,4",
            cos: "0,65",
            id: 942,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Шнеки семян, элеваторы, транспортеры хлопка",
            ki: "0,3",
            cos: "0,6",
            id: 943,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Вентиляторы цехового транспорта хлопка-сырца,отсоса от конденсаторов волокна и линта, обеспенивания технологических машин",
            ki: "0,7",
            cos: "0,7",
            id: 944,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Вентиляторы дворового пневмотранспорта",
            ki: "0,5",
            cos: "0,7",
            id: 945,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Вентиляторы отсоса влаги от бунтов",
            ki: "0,6",
            cos: "0,7",
            id: 946,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Гидронасос (с электродвигателем на каждой ступени) и остальные электродвигатели гидропрессовой установки",
            ki: "0,4",
            cos: "0,7",
            id: 947,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Гидронасос с одним двигателем на всех ступенях",
            ki: "0,8",
            cos: "0,75",
            id: 948,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Основные технологические машины (линии по выработке длинного и короткого волокна)",
            ki: "0,7",
            cos: "0,8",
            id: 949,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Транспортные устройства (шнеки, транспортеры, элеваторы)",
            ki: "0,4",
            cos: "0,65",
            id: 950,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Вентиляторы и насосы технологического оборудования",
            ki: "0,7",
            cos: "0,7",
            id: 951,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Механизмы погрузочно-разгрузочных работ в сырьевой зоне",
            ki: "0,2",
            cos: "0,7",
            id: 952,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Прессы-группировки в зоне сортировки сырья",
            ki: "0,6",
            cos: "0,75",
            id: 953,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Линии выработки длинного волокна и луба",
            ki: "0,5",
            cos: "0,78",
            id: 954,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Линии выработки короткого волокна и луба",
            ki: "0,5",
            cos: "0,78",
            id: 955,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Подъемно-транспортное оборудование производственного корпуса",
            ki: "0,2",
            cos: "0,7",
            id: 956,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Наружный и внутренний пневмотранспорт и вакуум-насосы пылеуборки",
            ki: "0,6",
            cos: "0,85",
            id: 957,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Волокноотделительные машины",
            ki: "0,84",
            cos: "0,85",
            id: 958,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Обеспыливающая вентиляция",
            ki: "0,84",
            cos: "0,85",
            id: 959,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Насосы технологической насосной станции",
            ki: "0,7",
            cos: "0,8",
            id: 960,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Прессовое оборудование участков брикетирования",
            ki: "0,6",
            cos: "0,78",
            id: 961,
            parents: [
                1,
                1.13
            ]
        },
        {
            name: "Разрыхлительно-очистительное оборудование",
            ki: "0,65",
            cos: "0,78",
            id: 962,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Трепальное оборудование",
            ki: "0,68",
            cos: "0,8",
            id: 963,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Шабазы",
            ki: "0,3",
            cos: "0,8",
            id: 964,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование по переработке отходов производства",
            ki: "0,65",
            cos: "0,78",
            id: 965,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,88",
            cos: "0,8",
            id: 966,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Ленточные, ровничные и гребне-чесальные машины",
            ki: "0,68",
            cos: "0,8",
            id: 967,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Мотально-тростильные машины",
            ki: "0,82",
            cos: "0,8",
            id: 968,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Крутильные машины кольцевого кручения",
            ki: "0,88",
            cos: "0,85",
            id: 969,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Крутильные машины двухстадийного кручения",
            ki: "0,82",
            cos: "0,75",
            id: 970,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Прядильно-крутильные машины",
            ki: "0,88",
            cos: "0,85",
            id: 971,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Кольцепрядильные машины ",
            ki: "0,88",
            cos: "0,85",
            id: 972,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Прядильные машины пневмомеханического прядения без сероудаления",
            ki: "0,69",
            cos: "0,8",
            id: 973,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Прядильные машины пневмомеханического прядения с сероудалением",
            ki: "0,72",
            cos: "0,8",
            id: 974,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Прядильные машины роторного прядения",
            ki: "0,74",
            cos: "0,8",
            id: 975,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Мотальные и уточно-перемоточные машины",
            ki: "0,82",
            cos: "0,8",
            id: 976,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,67",
            cos: "0,8",
            id: 977,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Шлихтовальные машины",
            ki: "0,73",
            cos: "0,82",
            id: 978,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Ткацкие станки",
            ki: "0,82",
            cos: "0,75",
            id: 979,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Браковочные, приемно-контрольные бердоремезные, узловязательные машины",
            ki: "0,69",
            cos: "0,75",
            id: 980,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование отдела перемотки",
            ki: "0,66",
            cos: "0,8",
            id: 981,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование опально-мерсеризационного отдела",
            ki: "0,68",
            cos: "0,75",
            id: 982,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование намоточного отдела",
            ki: "0,7",
            cos: "0,75",
            id: 983,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование отдела выработки фильтров",
            ki: "0,75",
            cos: "0,75",
            id: 984,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Машина для упаковки ваты",
            ki: "0,77",
            cos: "0,7",
            id: 985,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Ватоформочная машина",
            ki: "0,77",
            cos: "0,7",
            id: 986,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Оборудование отделастерилизации и упаковки",
            ki: "0,79",
            cos: "0,85",
            id: 987,
            parents: [
                1,
                1.14
            ]
        },
        {
            name: "Лентоткацкие станки",
            ki: "0,64",
            cos: "0,7",
            id: 988,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,4",
            cos: "0,7",
            id: 989,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Уточно-мотальные автоматы",
            ki: "0,63",
            cos: "0,7",
            id: 990,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Шнуроплетельные машины",
            ki: "0,65",
            cos: "0,7",
            id: 991,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Лентоплетельные машины и уточно-перемоточные автоматы",
            ki: "0,7",
            cos: "0,72",
            id: 992,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Гардинные челночные машины уточно-вязательные основязательные машины",
            ki: "0,7",
            cos: "0,7",
            id: 993,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Гардинные машины",
            ki: "0,72",
            cos: "0,7",
            id: 994,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,3",
            cos: "0,7",
            id: 995,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Круглотрикотажные и кругловязательные жаккардовые машины, однофонтурные машины",
            ki: "0,63",
            cos: "0,7",
            id: 996,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Автомат плосковязательный контурный круглоластичная машина",
            ki: "0,55",
            cos: "0,7",
            id: 997,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Бобинажно-перемоточная машина",
            ki: "0,72",
            cos: "0,7",
            id: 998,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Рисунчатая многосистемная однофонтурная машина и автомат чулочно-насосный Решель-машина для изготовления гардинного полотна и кругловязальная *котонная однофонтурная машина, двухгребенчатая основовязательная машина",
            ki: "0,65",
            cos: "0,75",
            id: 999,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Плосковязательная машина",
            ki: "0,72",
            cos: "0,7",
            id: 1000,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Выпускной конвейер",
            ki: "0,8",
            cos: "0,8",
            id: 1001,
            parents: [
                1,
                1.15
            ]
        },
        {
            name: "Льночесальное производство",
            ki: "0,8",
            cos: "0,8",
            id: 1002,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Поточные линии",
            ki: "0,65",
            cos: "0,8",
            id: 1003,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,85",
            cos: "0,8",
            id: 1004,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Гребенечесальные, ленточные и ровничные машины",
            ki: "0,7",
            cos: "0,8",
            id: 1005,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Прядильные машины сухого прядения",
            ki: "0,82",
            cos: "0,82",
            id: 1006,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Прядильные машины мокрого прядения",
            ki: "0,8",
            cos: "0,8",
            id: 1007,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Сушильные машины",
            ki: "0,79",
            cos: "0,85",
            id: 1008,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Ткацкое производство",
            ki: "0,73",
            cos: "0,84",
            id: 1009,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Мотальные машины",
            ki: "0,73",
            cos: "0,84",
            id: 1010,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Крутильные машины",
            ki: "0,82",
            cos: "0,81",
            id: 1011,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,67",
            cos: "0,8",
            id: 1012,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Шлихтовальные машины",
            ki: "0,75",
            cos: "0,82",
            id: 1013,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Ткацкие станки",
            ki: "0,82",
            cos: "0,75",
            id: 1014,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Браковочно-мерильные машины",
            ki: "0,69",
            cos: "0,75",
            id: 1015,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Картоносекальная мастерская",
            ki: "0,6",
            cos: "0,7",
            id: 1016,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Цеха химической обработки ровницы и крашения пряжи",
            ki: "0,8",
            cos: "0,85",
            id: 1017,
            parents: [
                1,
                1.16
            ]
        },
        {
            name: "Поточные линии подготовки волокна",
            ki: "0,65",
            cos: "0,75",
            id: 1018,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,81",
            cos: "0,79",
            id: 1019,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Прядильные машины",
            ki: "0,81",
            cos: "0,8",
            id: 1020,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Крутильные машины",
            ki: "0,77",
            cos: "0,8",
            id: 1021,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Веревочные прядевьющие и свивальные машины",
            ki: "0,6",
            cos: "0,7",
            id: 1022,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Канатные прядевьющие и свивальные машины",
            ki: "0,76",
            cos: "0,75",
            id: 1023,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Канатоплетенные машины",
            ki: "0,64",
            cos: "0,72",
            id: 1024,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Мотальные машины",
            ki: "0,71",
            cos: "0,8",
            id: 1025,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,6",
            cos: "0,7",
            id: 1026,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Шлихтовальные машины",
            ki: "0,72",
            cos: "0,8",
            id: 1027,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Ткацкие станки",
            ki: "0,75",
            cos: "0,72",
            id: 1028,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Браковочно-учетные машины",
            ki: "0,66",
            cos: "0,7",
            id: 1029,
            parents: [
                1,
                1.17
            ]
        },
        {
            name: "Оборудование цеха переработки кизячей шерсти",
            ki: "0,7",
            cos: "0,7",
            id: 1030,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование сортировочного цеха",
            ki: "0,77",
            cos: "0,8",
            id: 1031,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование моечно-сушильного цеха",
            ki: "0,73",
            cos: "0,7",
            id: 1032,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Лабазы",
            ki: "0,3",
            cos: "0,74",
            id: 1033,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование помещения рыхления свалков",
            ki: "0,7",
            cos: "0,75",
            id: 1034,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование цеха постоянной классировки",
            ki: "0,44",
            cos: "0,85",
            id: 1035,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование сырьевой лаборатории",
            ki: "0,7",
            cos: "0,6",
            id: 1036,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование дезкамеры",
            ki: "0,7",
            cos: "0,7",
            id: 1037,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Карбонизационно-красильные линии непрерывного действия для обработки волокна",
            ki: "0,79",
            cos: "0,8",
            id: 1038,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Трепальные машины",
            ki: "0,79",
            cos: "0,8",
            id: 1039,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование для обработки отходов и угарно-ватирное оборудование",
            ki: "0,67",
            cos: "0,8",
            id: 1040,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Смесовые машины",
            ki: "0,59",
            cos: "0,8",
            id: 1041,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Кольцепрядильные суконные машины",
            ki: "0,8",
            cos: "0,83",
            id: 1042,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,61",
            cos: "0,82",
            id: 1043,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Пневмомеханические прядильные машины",
            ki: "0,74",
            cos: "0,83",
            id: 1044,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование для обработки угаров",
            ki: "0,59",
            cos: "0,8",
            id: 1045,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Смесовые машины",
            ki: "0,54",
            cos: "0,82",
            id: 1046,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,72",
            cos: "0,8",
            id: 1047,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Ленточные машины",
            ki: "0,74",
            cos: "0,8",
            id: 1048,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Гребнечесальные машины",
            ki: "0,86",
            cos: "0,77",
            id: 1049,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Штапельные машины",
            ki: "0,65",
            cos: "0,8",
            id: 1050,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Красильно-гладильное оборудование",
            ki: "0,57",
            cos: "0,8",
            id: 1051,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Розничные машины",
            ki: "0,74",
            cos: "0,8",
            id: 1052,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Кольцепрядильные машины камвольные",
            ki: "0,82",
            cos: "0,83",
            id: 1053,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Тростильнокрутильное оборудование",
            ki: "0,82",
            cos: "0,83",
            id: 1054,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Мотально-сновальные машины",
            ki: "0,67",
            cos: "0,8",
            id: 1055,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Шлихтовальные машины",
            ki: "0,67",
            cos: "0,8",
            id: 1056,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Ткацкие станки (без двигателей подъема навоев)",
            ki: "0,8",
            cos: "0,75",
            id: 1057,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Контрольно-чистильное, приборное оборудование",
            ki: "0,6",
            cos: "0,8",
            id: 1058,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Ковровые ткацкие машины двухполотенные",
            ki: "0,6",
            cos: "0,8",
            id: 1059,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Однополотенные ковровые ткацкие станки",
            ki: "0,7",
            cos: "0,8",
            id: 1060,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Ткацко-приготовительный отдел",
            ki: "0,75",
            cos: "0,7",
            id: 1061,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Термофиксация",
            ki: "0,88",
            cos: "0,75",
            id: 1062,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Тафтинг-машины",
            ki: "0,7",
            cos: "0,77",
            id: 1063,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Латекс-машины",
            ki: "0,85",
            cos: "0,75",
            id: 1064,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Оборудование сухой отделки",
            ki: "0,75",
            cos: "0,8",
            id: 1065,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Печатный агрегат",
            ki: "0,78",
            cos: "0,8",
            id: 1066,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Краскосварочное отделение",
            ki: "0,78",
            cos: "0,8",
            id: 1067,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Установка конфекции, резки, упаковки, перемотки",
            ki: "0,85",
            cos: "0,8",
            id: 1068,
            parents: [
                1,
                1.18
            ]
        },
        {
            name: "Основное технологическое оборудование шелкомотального производства",
            ki: "0,7",
            cos: "0,8",
            id: 1069,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование цеха переработки отходов кокономотания",
            ki: "0,4",
            cos: "0,65",
            id: 1070,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Крутильное оборудование",
            ki: "0,79",
            cos: "0,8",
            id: 1071,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Запарное оборудование",
            ki: "0,65",
            cos: "0,8",
            id: 1072,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Уточно-перемоточное оборудование",
            ki: "0,7",
            cos: "0,8",
            id: 1073,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Сновальные машины",
            ki: "0,59",
            cos: "0,78",
            id: 1074,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Шлихтовальные машины",
            ki: "0,65",
            cos: "0,75",
            id: 1075,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Ткацкие станки",
            ki: "0,84",
            cos: "0,75",
            id: 1076,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Товарно-чистильное и браковочное оборудование",
            ki: "0,69",
            cos: "0,8",
            id: 1077,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование участков сухой отделки (каландры, стригальные машины, ворсовальные, опальные)",
            ki: "0,75",
            cos: "0,82",
            id: 1078,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Термические токоприемники",
            ki: "0,75",
            cos: "1",
            id: 1079,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Инфракрасные нагреватели",
            ki: "1",
            cos: "1",
            id: 1080,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "отделочных линий",
            ki: "0,62",
            cos: "0,8",
            id: 1081,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Складальное, мерильное и браковочное оборудование",
            ki: "0,55",
            cos: "0,7",
            id: 1082,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование крашения волокна периодического действия",
            ki: "0,56",
            cos: "0,82",
            id: 1083,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование крашения пряжи периодического действия",
            ki: "0,56",
            cos: "0,84",
            id: 1084,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование подготовки волокна",
            ki: "0,65",
            cos: "0,75",
            id: 1085,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Участки лабазов",
            ki: "0,3",
            cos: "0,74",
            id: 1086,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Иглопробивные цехи, угарные участки (переработка отходов) и браковочные участки",
            ki: "0,7",
            cos: "0,75",
            id: 1087,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Участки лабазов",
            ki: "0,3",
            cos: "0,74",
            id: 1088,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Сновально-мотальное оборудование",
            ki: "0,5",
            cos: "0,7",
            id: 1089,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование переработки отходов",
            ki: "0,7",
            cos: "0,75",
            id: 1090,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Оборудование обработки отходов",
            ki: "0,7",
            cos: "0,75",
            id: 1091,
            parents: [
                1,
                1.19
            ]
        },
        {
            name: "Швейные машины",
            ki: "0,6",
            cos: "0,62",
            id: 1092,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Транспортеры швейных конвейеров",
            ki: "0,68",
            cos: "0,8",
            id: 1093,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Браковочно-мерильные машины",
            ki: "0,4",
            cos: "0,62",
            id: 1094,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Раскройное оборудование",
            ki: "0,4",
            cos: "0,65",
            id: 1095,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Утюги, прессы",
            ki: "0,75",
            cos: "0,98",
            id: 1096,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Машины герметизации швов",
            ki: "0,8",
            cos: "0,7",
            id: 1097,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Смесители",
            ki: "0,5",
            cos: "0,8",
            id: 1098,
            parents: [
                1,
                1.2
            ]
        },
        {
            name: "Дисторезальная ротационная машина и картонорезальная машина",
            ki: "0,35",
            cos: "0,76",
            id: 1099,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Электрогидравлический пресс для вырубки деталей",
            ki: "0,303",
            cos: "0,8",
            id: 1100,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Универсальный просекальный станок, пресс гидравлический для деталей и пресс карусельный механический выработки",
            ki: "0,303",
            cos: "0,73",
            id: 1101,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для спускания краев деталей низа, браковочно-мерильная полуавтомат для постановки хольнитенов машина и",
            ki: "0,4",
            cos: "0,59",
            id: 1102,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина проходная для перетяжки перчаточных кож",
            ki: "0,36",
            cos: "0,76",
            id: 1103,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина раскройная четырехшкивная ленточная",
            ki: "0,26",
            cos: "0,76",
            id: 1104,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для спускания краев деталей верха",
            ki: "0,35",
            cos: "0,59",
            id: 1105,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для клеймления деталей",
            ki: "0,28",
            cos: "0,58",
            id: 1106,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Пресс для тюковки отходов",
            ki: "0,23",
            cos: "0,76",
            id: 1107,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Высокочастотная установка",
            ki: "0,73",
            cos: "0,86",
            id: 1108,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Пресс для постановки мелкой фурнитуры и обжига рамочных замков",
            ki: "0,37",
            cos: "0,58",
            id: 1109,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для багировки и нарезки клинчиков портфелей, сумок",
            ki: "0,27",
            cos: "0,59",
            id: 1110,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для гибки и формирования ботанов чемоданов и машина раскройно-ленточная",
            ki: "0,37",
            cos: "0,73",
            id: 1111,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для выворотки сумок хозяйственных и дорожных",
            ki: "0,27",
            cos: "0,73",
            id: 1112,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина для окраски в пачках краев деталей кожгалантерейных изделий",
            ki: "0,265",
            cos: "0,58",
            id: 1113,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Машина швейная",
            ki: "0,35",
            cos: "0,58",
            id: 1114,
            parents: [
                1,
                1.21
            ]
        },
        {
            name: "Барабан подвесной для отмочно-зольных процессов, красильно-жировальных процессов и преддубильно-дубильных процессов",
            ki: "0,23",
            cos: "0,7",
            id: 1115,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина мездрильная",
            ki: "0,19",
            cos: "0,8",
            id: 1116,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина двоильно-ленточная и машина валичная отжимная",
            ki: "0,23",
            cos: "0,8",
            id: 1117,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина волососгонно-чистильная",
            ki: "0,27",
            cos: "0,88",
            id: 1118,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Пресс для тюковки хромовой стружки",
            ki: "0,23",
            cos: "0,78",
            id: 1119,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Шерстомойная машина",
            ki: "0,54",
            cos: "0,76",
            id: 1120,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Агрегат для обезволошивания шкур",
            ki: "0,63",
            cos: "0,79",
            id: 1121,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина строгальная",
            ki: "0,19",
            cos: "0,81",
            id: 1122,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина разводная гидравлическая",
            ki: "0,27",
            cos: "0,83",
            id: 1123,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина проходная щетино-дергательная",
            ki: "0,27",
            cos: "0,79",
            id: 1124,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Сушилка внаклейку на стекло 200-рамная, проходная, сушилка и вакуумная сушилка",
            ki: "0,45",
            cos: "0,87",
            id: 1125,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Сушилка рамная подвесная и машина намазная",
            ki: "0,45",
            cos: "0,78",
            id: 1126,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина обрезная",
            ki: "0,23",
            cos: "0,59",
            id: 1127,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Вибрационно-мягчильная машина, проходной шлифовально-обеспыливающий агрегат и машина шлифовальная",
            ki: "0,27",
            cos: "0,85",
            id: 1128,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для очистки от пыли с 2-х сторон шлифовальных кож",
            ki: "0,27",
            cos: "0,76",
            id: 1129,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина поливочная, агрегат покрывного крашения щетками, а также агрегат покрывного крашения двухкратный",
            ki: "0,45",
            cos: "0,76",
            id: 1130,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Аппретурная машина 'Ратона'",
            ki: "0,45",
            cos: "0,79",
            id: 1131,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Гидравлический пресс",
            ki: "0,3",
            cos: "0,87",
            id: 1132,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина проходная гладильная 'Рамора'",
            ki: "0,36",
            cos: "0,83",
            id: 1133,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина тянульно-мягчильная",
            ki: "0,27",
            cos: "0,79",
            id: 1134,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для покрывного крашения и сушки кож",
            ki: "0,45",
            cos: "0,87",
            id: 1135,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Измерительная машина и машина для клеймения кож",
            ki: "0,3",
            cos: "0,58",
            id: 1136,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Автоматическое распылительное устройство во взрывоопасном исполнении, распылительная кабина",
            ki: "0,54",
            cos: "0,79",
            id: 1137,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина проходная отжимная",
            ki: "0,29",
            cos: "0,81",
            id: 1138,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Выносной транспортер для стружки",
            ki: "0,25",
            cos: "0,54",
            id: 1139,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для обрубки краев кож",
            ki: "0,23",
            cos: "0,7",
            id: 1140,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Непрерывная увлажнительная машина",
            ki: "0,36",
            cos: "0,76",
            id: 1141,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Пылеотстойник",
            ki: "0,54",
            cos: "0,8",
            id: 1142,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Проходная обеспыливающая машина с нагнетателем",
            ki: "0,36",
            cos: "0,87",
            id: 1143,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Сушилка к агрегатам крашения щетками",
            ki: "0,45",
            cos: "0,88",
            id: 1144,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для отжига и разварки кож",
            ki: "0,27",
            cos: "0,85",
            id: 1145,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Двоильно-ленточная машина волососгонно-чистильная машина, и машина валичная отжимная",
            ki: "0,27",
            cos: "0,81",
            id: 1146,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Мездрильная машина и машина строгальная",
            ki: "0,19",
            cos: "0,82",
            id: 1147,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Сушильно-увлажнительный агрегат",
            ki: "0,45",
            cos: "0,79",
            id: 1148,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Пресс",
            ki: "0,23",
            cos: "0,77",
            id: 1149,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для подвилки кож низа обуви, машина наманная и валичная щеточная машина для жирования кож",
            ki: "0,27",
            cos: "0,73",
            id: 1150,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Сушильно-увлажнительный агрегат для сушки и увлажнения юфтевых кож внаклейку на дюралюминиевых листах",
            ki: "0,45",
            cos: "0,87",
            id: 1151,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Барабанная разводная машина",
            ki: "0,36",
            cos: "0,8",
            id: 1152,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Каток проходной подошвенный",
            ki: "0,54",
            cos: "0,8",
            id: 1153,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Машина для измерения площади жестких кож",
            ki: "0,36",
            cos: "0,73",
            id: 1154,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Барабан подвесной преддубильно-дубильных процессов",
            ki: "0,23",
            cos: "0,7",
            id: 1155,
            parents: [
                1,
                1.22
            ]
        },
        {
            name: "Пресс для разруба материалов низа",
            ki: "0,33",
            cos: "0,65",
            id: 1156,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для выравнивания низа обуви",
            ki: "0,36",
            cos: "0,7",
            id: 1157,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для тиснения и перфорации обуви",
            ki: "0,38",
            cos: "0,8",
            id: 1158,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для шкурения и шлифовки деталей низа обуви",
            ki: "9,43*",
            cos: "0,78",
            id: 1159,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для фрезерования площадки на ход по верху",
            ki: "0,43",
            cos: "0,78",
            id: 1160,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для взъерошивания (фрезерование) подошв по периметру",
            ki: "0,37",
            cos: "0,75",
            id: 1161,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машины для фрезерования подошв в печках",
            ki: "0,24",
            cos: "0,85",
            id: 1162,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для холодной полировки подошв",
            ki: "0,42",
            cos: "0,7",
            id: 1163,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для обеспыливания контура подошв",
            ki: "0,44",
            cos: "0,7",
            id: 1164,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для спускания краев деталей низа",
            ki: "0,47",
            cos: "0,6",
            id: 1165,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для профилирования подошв площади",
            ki: "0,36",
            cos: "0,68",
            id: 1166,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для клеймения размеров",
            ki: "0,31",
            cos: "0,95",
            id: 1167,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для вырезания желоба в полустельках",
            ki: "0,39",
            cos: "0,72",
            id: 1168,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для приклеивания супинаторов",
            ki: "0,59",
            cos: "0,51",
            id: 1169,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для намазки клеем пяточной части",
            ki: "0,5",
            cos: "0,68",
            id: 1170,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для склеивания деталей",
            ki: "0,39",
            cos: "0,52",
            id: 1171,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для активизации клеевых пленок на подошвах",
            ki: "0,6",
            cos: "0,95",
            id: 1172,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для склеивания подошв с каблуками",
            ki: "0,34",
            cos: "0,71",
            id: 1173,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для вырезания подошв по контуру",
            ki: "0,43",
            cos: "0,6",
            id: 1174,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для снятия фаски с пяточной части стелек",
            ki: "0,5",
            cos: "0,8",
            id: 1175,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для формирования подошв стелек",
            ki: "0,34",
            cos: "0,68",
            id: 1176,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Сушилка для клеевой пленки на стельках",
            ki: "0,44",
            cos: "0,46",
            id: 1177,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Конвейер вырубочной ленточный",
            ki: "0,55",
            cos: "0,65",
            id: 1178,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Конвейер для обработки деталей низа",
            ki: "0,51",
            cos: "0,52",
            id: 1179,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для верхнего кроя",
            ki: "0,35",
            cos: "0,65",
            id: 1180,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для раскроя текстильных материалов",
            ki: "0,31",
            cos: "0,77",
            id: 1181,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для выравнивания кроя",
            ki: "0,46",
            cos: "0,75",
            id: 1182,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Конвейер закройный ленточный для текстиля",
            ki: "0,4",
            cos: "0,55",
            id: 1183,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для клеймения деталей верха обуви",
            ki: "0,77",
            cos: "0,95",
            id: 1184,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Конвейер заготовочный ленточный",
            ki: "0,54",
            cos: "0,81",
            id: 1185,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Конвейер закройный ленточный для верхних кож",
            ki: "0,45",
            cos: "0,53",
            id: 1186,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для опускания краев деталей верха заготовок",
            ki: "0,44",
            cos: "0,7",
            id: 1187,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Швейная машина для сострачивания деталей верха и машина для разглаживания швов задников",
            ki: "0,47",
            cos: "0,7",
            id: 1188,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для загибки краев деталей верха, машина для вставки блочек и швейная машина",
            ki: "0,44",
            cos: "0,54",
            id: 1189,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Горячее формирование деталей верха",
            ki: "0,61",
            cos: "0,75",
            id: 1190,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для тиснения и перфорации",
            ki: "0,38",
            cos: "0,8",
            id: 1191,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для дублирования (вклеивания деталей верха обуви)",
            ki: "0,68",
            cos: "0,95",
            id: 1192,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Увлажнительная камера заготовок",
            ki: "0,54",
            cos: "0,93",
            id: 1193,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для чистки колодок, полировка подошв",
            ki: "0,3",
            cos: "0,72",
            id: 1194,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для прикрепления подошв",
            ki: "0,36",
            cos: "0,54",
            id: 1195,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для вытяжки и формирования задников",
            ki: "0,69",
            cos: "0,95",
            id: 1196,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для крепления гвоздями деталей низа",
            ki: "0,54",
            cos: "0,54",
            id: 1197,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для термоувлажнения",
            ki: "0,73",
            cos: "0,95",
            id: 1198,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для обтяжки и клеевой затяжки насосной пусковой части заготовок",
            ki: "0,47",
            cos: "0,82",
            id: 1199,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для затяжки гененочной части заготовок",
            ki: "0,43",
            cos: "0,95",
            id: 1200,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для затяжки насосной пучковой части обуви",
            ki: "0,5",
            cos: "0,82",
            id: 1201,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для затяжки пяточной части заготовок",
            ki: "0,З",
            cos: "0,65",
            id: 1202,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для клеевой затяжки пяточной части заготовок",
            ki: "0,52",
            cos: "0,82",
            id: 1203,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для затяжки пяточной части заготовок",
            ki: "0,46",
            cos: "0,81",
            id: 1204,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для затяжки бочков и пяточной части гвоздями",
            ki: "0,55",
            cos: "0,59",
            id: 1205,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для формирования следа обуви",
            ki: "0,49",
            cos: "0,81",
            id: 1206,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Сушилка для обуви",
            ki: "0,45",
            cos: "0,65",
            id: 1207,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Элеватор (хранение обуви)",
            ki: "0,1",
            cos: "0,46",
            id: 1208,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для прикрепления каблуков, камера для аппаратирования верха обуви и машина для шкурения пластмассовых каблуков",
            ki: "0,32",
            cos: "0,68",
            id: 1209,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для обрезки излишней кромки",
            ki: "0,5",
            cos: "0,6",
            id: 1210,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Сушилка, первая и вторая намазка клеем",
            ki: "0,44",
            cos: "0,68",
            id: 1211,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Термоактиватор (активизация клеевых пленок)",
            ki: "0,6",
            cos: "0,95",
            id: 1212,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Пресс для склеивания подошв с каблуками",
            ki: "0,34",
            cos: "0,71",
            id: 1213,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для фрезерования подошв",
            ki: "0,37",
            cos: "0,75",
            id: 1214,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для шлифования каблуков",
            ki: "0,53",
            cos: "0,82",
            id: 1215,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для снятия обуви с колодок",
            ki: "0,5",
            cos: "0,73",
            id: 1216,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для утюжки верха обуви",
            ki: "0,31",
            cos: "1",
            id: 1217,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для разглаживания верха",
            ki: "0,4",
            cos: "1",
            id: 1218,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Машина для разглаживания голени",
            ki: "0,5",
            cos: "1",
            id: 1219,
            parents: [
                1,
                1.23
            ]
        },
        {
            name: "Баркас с подогревателем и мешалка переносная Н/О",
            ki: "0,21",
            cos: "0,76",
            id: 1220,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Разводная валичная машина",
            ki: "0,39",
            cos: "0,79",
            id: 1221,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Рубильная машина",
            ki: "0,39",
            cos: "0,79",
            id: 1222,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Проходная шерстерезная машина",
            ki: "0,15",
            cos: "0,79",
            id: 1223,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Центрифуга",
            ki: "0,39",
            cos: "0,87",
            id: 1224,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Линия непрерывной обработки",
            ki: "0,445",
            cos: "0,8",
            id: 1225,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Загрузочный транспортер",
            ki: "0,45",
            cos: "0,58",
            id: 1226,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для мойки и обезвреживания",
            ki: "0,23",
            cos: "0,88",
            id: 1227,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Мездрильная машина",
            ki: "0,27",
            cos: "0,8",
            id: 1228,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для уменьшения толщины кожевой ткани",
            ki: "0,39",
            cos: "0,76",
            id: 1229,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина отжимная",
            ki: "0,45",
            cos: "0,83",
            id: 1230,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Отжимной пресс",
            ki: "0,2",
            cos: "0,83",
            id: 1231,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Жировальная машина",
            ki: "0,2",
            cos: "0,76",
            id: 1232,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Мялка молотковая",
            ki: "0,45",
            cos: "0,79",
            id: 1233,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Агрегат анилинового крашения",
            ki: "0,5",
            cos: "0,86",
            id: 1234,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Сушилка концевая рамная",
            ki: "0,59",
            cos: "0,76",
            id: 1235,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Проходная разбивочная машина",
            ki: "0,45",
            cos: "0,77",
            id: 1236,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Стригальная машина",
            ki: "0,3",
            cos: "0,8",
            id: 1237,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Барабан комбинированный для откатки",
            ki: "0,28",
            cos: "0,8",
            id: 1238,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Сетчатый барабан",
            ki: "0,28",
            cos: "0,77",
            id: 1239,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Чесальная машина",
            ki: "0,54",
            cos: "0,77",
            id: 1240,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Намазная машина",
            ki: "0,28",
            cos: "0,76",
            id: 1241,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина гладильная",
            ki: "0,38",
            cos: "0,8",
            id: 1242,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для тяжки, разбивки шкурок норки",
            ki: "0,37",
            cos: "0,64",
            id: 1243,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Подвесная петельная сушилка",
            ki: "0,45",
            cos: "0,82",
            id: 1244,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина проходная для склеивания меха на тканевой основе",
            ki: "0,27",
            cos: "0,59",
            id: 1245,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Станок для заточки дисковых ножей",
            ki: "0,2",
            cos: "0,76",
            id: 1246,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Колотильная машина и 2-х барабанная чесальная машина",
            ki: "0,45",
            cos: "0,76",
            id: 1247,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Вибрационно-мягчильная машина 'Поллиса' и шлифовальная мягчительная машина",
            ki: "0,23",
            cos: "0,83",
            id: 1248,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина растяжная для шкурок норки",
            ki: "0,23",
            cos: "0,73",
            id: 1249,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Измерительная машина",
            ki: "0,3",
            cos: "0,64",
            id: 1250,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Гидропресс",
            ki: "0,18",
            cos: "0,84",
            id: 1251,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Сушилка для подсушки",
            ki: "0,36",
            cos: "0,73",
            id: 1252,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Обрезная машина",
            ki: "0,23",
            cos: "0,59",
            id: 1253,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Аппарат для обезжиривания",
            ki: "0,27",
            cos: "0,83",
            id: 1254,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для химической чистки",
            ki: "0,3",
            cos: "0,87",
            id: 1255,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Адсорбер",
            ki: "0,45",
            cos: "0,72",
            id: 1256,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Нитроагрегат",
            ki: "0,45",
            cos: "0,84",
            id: 1257,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Щеточная машина",
            ki: "0,36",
            cos: "0,76",
            id: 1258,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Скорняжная машина",
            ki: "0,45",
            cos: "0,54",
            id: 1259,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Агрегат для опарки шапок и агрегат для правки шапок",
            ki: "0,225",
            cos: "0,78",
            id: 1260,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Сушилка",
            ki: "0,58",
            cos: "0,73",
            id: 1261,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина маркировочная, машина для измерения размеров головных уборов и машина для заполнения реквизитов на ленточке",
            ki: "0,23",
            cos: "0,56",
            id: 1262,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для чистки и обжига",
            ki: "0,27",
            cos: "0,54",
            id: 1263,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Maшина электрозакройная",
            ki: "0,33",
            cos: "0,64",
            id: 1264,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Пресс",
            ki: "0,23",
            cos: "0,72",
            id: 1265,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Автомат для печатания ярлыков",
            ki: "0,28",
            cos: "0,53",
            id: 1266,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Механическая линейка для обрезания полотен при настилании",
            ki: "0,23",
            cos: "0,53",
            id: 1267,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для вырезания наружных контуров",
            ki: "0,3",
            cos: "0,54",
            id: 1268,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина для высекания внутренних контуров, лекал, машина для высекания фигурных отверстий в лекалах, утюг, станок для клеймения срезов лекал, машина для резки карточных заготовок лекал из рулона",
            ki: "0,23",
            cos: "0,58",
            id: 1269,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Пресс гидравлический и пачковязательная машина",
            ki: "0,23",
            cos: "0,59",
            id: 1270,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина раскройная четырехшкивная ленточная",
            ki: "0,27",
            cos: "0,64",
            id: 1271,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина фотоэлектронная для измерения площади лекал",
            ki: "0,27",
            cos: "0,54",
            id: 1272,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Установка для скоростного копирования лекал",
            ki: "0,27",
            cos: "0,72",
            id: 1273,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Машина швейная 12-ти игольная для стяжки ватина",
            ki: "0,36",
            cos: "0,73",
            id: 1274,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Щетка механическая для чистки изделий",
            ki: "0,23",
            cos: "0,76",
            id: 1275,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Швейные машины различных классов",
            ki: "0,36",
            cos: "0,58",
            id: 1276,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Механическая линейная для обрезки и клеймения полотен при настилании",
            ki: "0,3",
            cos: "0,54",
            id: 1277,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Автомат для печатания контрольно-маркировочных талонов",
            ki: "0,27",
            cos: "0,59",
            id: 1278,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Установка для перекачки мездры",
            ki: "0,54",
            cos: "0,86",
            id: 1279,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Установка с насосами для повторного использования стоков",
            ki: "0,36",
            cos: "0,79",
            id: 1280,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Транспортер для опилок",
            ki: "0,27",
            cos: "0,76",
            id: 1281,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Конвейеры отделочные",
            ki: "0,36",
            cos: "0,76",
            id: 1282,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Перекидчик",
            ki: "0,23",
            cos: "0,76",
            id: 1283,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Бункер для опилок и лоскута",
            ki: "0,38",
            cos: "0,64",
            id: 1284,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Конвейер и транспортер механический для хранения и транспортирования лекал",
            ki: "0,45",
            cos: "0,76",
            id: 1285,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Механизированный настилочный стол с решающейся этажеркой",
            ki: "0,36",
            cos: "0,58",
            id: 1286,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Элеватор для хранения кроя",
            ki: "0,27",
            cos: "0,59",
            id: 1287,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Механизированная вешалка",
            ki: "0,27",
            cos: "0,64",
            id: 1288,
            parents: [
                1,
                1.24
            ]
        },
        {
            name: "Линия для подготовки и смешивания синтетических волокон и линия для формирования лицевого покрытия синтетической кожи с экспериментальной камерой предкоагуляции",
            ki: "0,675",
            cos: "0,76",
            id: 1289,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Агрегат для формирования волокнистого слоя и предварительного иглопрокалывания",
            ki: "0,63",
            cos: "0,87",
            id: 1290,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Агрегат для термоусадки и агрегат для дублирования",
            ki: "0,63",
            cos: "0,77",
            id: 1291,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Линия для пропитки волокнистой основы и агрегат для промывки",
            ki: "0,68",
            cos: "0,79",
            id: 1292,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина сушильно-ширильная",
            ki: "0,63",
            cos: "0,8",
            id: 1293,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Агрегат красильно-сушильный для отделки",
            ki: "0,5",
            cos: "0,76",
            id: 1294,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Щипальная машина с конденсатором, машина для набивки шерсти в мешки",
            ki: "0,45",
            cos: "0,77",
            id: 1295,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Шлифовальная машина и двоильно-ленточная машина",
            ki: "0,54",
            cos: "0,87",
            id: 1296,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Двухстадийный смеситель и двухроторный смеситель",
            ki: "0,54",
            cos: "0,89",
            id: 1297,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Червячный асциллирующий смеситель",
            ki: "0,63",
            cos: "0,68",
            id: 1298,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Червячный пресс-кранолятор",
            ki: "0,63",
            cos: "0,87",
            id: 1299,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Установка пневмотранспорта и диспергатор для перетирования пигментов",
            ki: "0,63",
            cos: "0,8",
            id: 1300,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вакуум-насос",
            ki: "0,63",
            cos: "0,76",
            id: 1301,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Тепловая станция и диспергатор для перетирования наполнителей",
            ki: "0,63",
            cos: "0,86",
            id: 1302,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Весы автоматические",
            ki: "0,5",
            cos: "0,59",
            id: 1303,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Копировальная машина",
            ki: "0,67",
            cos: "0,77",
            id: 1304,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Печатная 4-х красочная машина",
            ki: "0,5",
            cos: "0,77",
            id: 1305,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Реактор емкостью до 1250 л",
            ki: "0,63",
            cos: "0,77",
            id: 1306,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Планетарный смеситель",
            ki: "0,55",
            cos: "9,76*",
            id: 1307,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Установка для тиснения",
            ki: "0,68",
            cos: "0,86",
            id: 1308,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Каландр отделочный",
            ki: "0,49",
            cos: "0,87",
            id: 1309,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для paзброкировки клеенки",
            ki: "0,57",
            cos: "0,76",
            id: 1310,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина ширильно-цепная",
            ki: "0,58",
            cos: "0,77",
            id: 1311,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вентиляторы технологических линий",
            ki: "0,7",
            cos: "0,89",
            id: 1312,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Насосы технологических линий",
            ki: "0,8",
            cos: "0,76",
            id: 1313,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Контакт-дисельвер",
            ki: "0,63",
            cos: "0,8",
            id: 1314,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Краскотерна и мельница тонкого помола",
            ki: "0,54",
            cos: "0,86",
            id: 1315,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вакуум-синусдисольвер",
            ki: "0,63",
            cos: "0,83",
            id: 1316,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вакуум-мешалка",
            ki: "0,55",
            cos: "0,76",
            id: 1317,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вакуум-насос",
            ki: "0,63",
            cos: "0,76",
            id: 1318,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Ленточно-шлифовальный станок",
            ki: "0,45",
            cos: "0,87",
            id: 1319,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Мяльный агрегат с распределительным шкафом",
            ki: "0,54",
            cos: "0,77",
            id: 1320,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Комбинированная браковочно-учетная машина",
            ki: "0,45",
            cos: "0,83",
            id: 1321,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Тепловая установка",
            ki: "0,63",
            cos: "0,89",
            id: 1322,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Браковочно-перемотальная машина для подложки",
            ki: "0,47",
            cos: "0,83",
            id: 1323,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Автоматическая тандем-установка для нанесения покрытия",
            ki: "0,68",
            cos: "0,87",
            id: 1324,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Агрегат 4-х дисковый для резки синтетического каучука и нож гидравлический",
            ki: "0,23",
            cos: "0,85",
            id: 1325,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вальцы 800 мм рафинирующие и резиносмеситель",
            ki: "0,55",
            cos: "0,8",
            id: 1326,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Камера механизированная для разогрева каучука",
            ki: "0,54",
            cos: "0,77",
            id: 1327,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вальцы смесительные и вальцы подогревательные",
            ki: "0,55",
            cos: "0,85",
            id: 1328,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Смеситель и краскотерка 3-х валковая",
            ki: "0,54",
            cos: "0,79",
            id: 1329,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Одночервячная машина",
            ki: "0,4",
            cos: "0,8",
            id: 1330,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Штамп-автомат",
            ki: "0,35",
            cos: "0,72",
            id: 1331,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Пресс карусельный вулканизационный",
            ki: "0,23",
            cos: "0,75",
            id: 1332,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вулканизационный пресс гидравлический",
            ki: "0,23",
            cos: "0,72",
            id: 1333,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Двоильно-ленточная машина",
            ki: "0,45",
            cos: "0,82",
            id: 1334,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Девулканизатор непрерывного действия",
            ki: "0,54",
            cos: "0,85",
            id: 1335,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Бункер с питателем",
            ki: "0,54",
            cos: "0,75",
            id: 1336,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Реактор-смеситель",
            ki: "0,54",
            cos: "0,88",
            id: 1337,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Бункер с ворошителем",
            ki: "0,54",
            cos: "0,59",
            id: 1338,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Дозатор с весовым контролем",
            ki: "0,23",
            cos: "0,73",
            id: 1339,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Буферная емкость для регенератной крошки с питателем",
            ki: "0,63",
            cos: "0,73",
            id: 1340,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вальцы 800 мм дробильные",
            ki: "0,55",
            cos: "0,85",
            id: 1341,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Вальцы размалывающие и рафинирующие",
            ki: "0,55",
            cos: "0,8",
            id: 1342,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Мельница тонкого измельчения",
            ki: "0,53",
            cos: "0,88",
            id: 1343,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Сито вибрационное одноярусное",
            ki: "0,63",
            cos: "0,59",
            id: 1344,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Дробилка молотковая",
            ki: "0,63",
            cos: "0,74",
            id: 1345,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Гидроразбиватель и коническая мельница",
            ki: "0,72",
            cos: "0,89",
            id: 1346,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для предварительного измельчения отходов и длинносеточная машина",
            ki: "0,68",
            cos: "0,88",
            id: 1347,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Аппарат для работы под налив и реактор",
            ki: "0,63",
            cos: "0,83",
            id: 1348,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Кондукс тонкого размола",
            ki: "0,52",
            cos: "0,88",
            id: 1349,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Емкость для массы с мешалкой и реактор с рамой мешалкой",
            ki: "0,63",
            cos: "0,8",
            id: 1350,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Пресс для вырубки стелек и машина для раскроя картона на коробки",
            ki: "0,23",
            cos: "0,75",
            id: 1351,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для сшивки коробок",
            ki: "0,23",
            cos: "0,54",
            id: 1352,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Горизонтальный транспортер",
            ki: "0,54",
            cos: "0,73",
            id: 1353,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Измельчительная мельница",
            ki: "0,52",
            cos: "0,9",
            id: 1354,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Мельница с металлическими дисками",
            ki: "0,52",
            cos: "0,6",
            id: 1355,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Циклические чаны с мешалкой",
            ki: "0,54",
            cos: "0,79",
            id: 1356,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Мельница с базальтовыми дисками",
            ki: "0,52",
            cos: "0,8",
            id: 1357,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Конический рафинер и чаны с мешалкой",
            ki: "0,54",
            cos: "0,9",
            id: 1358,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Двухсеточная машина",
            ki: "0,68",
            cos: "0,86",
            id: 1359,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для резания рулонного картона на листы",
            ki: "0,58",
            cos: "0,87",
            id: 1360,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для тиснения и машина для шкурения",
            ki: "0,45",
            cos: "0,88",
            id: 1361,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для резания рулонного картона на ленты",
            ki: "0,375",
            cos: "0,8",
            id: 1362,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Линия для упаковки листов картона",
            ki: "0,575",
            cos: "0,75",
            id: 1363,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Малая краскотерочная машина",
            ki: "0,54",
            cos: "0,73",
            id: 1364,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Гид роразб и вател ь",
            ki: "0,63",
            cos: "0,88",
            id: 1365,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для предварительного измельчения кожотходов и кондуксы",
            ki: "0,65",
            cos: "0,88",
            id: 1366,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Коническая мельница, бассейн, дезенинтегратор и виброгрохот",
            ki: "0,63",
            cos: "0,8",
            id: 1367,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Гидропульпер",
            ki: "0,63",
            cos: "0,87",
            id: 1368,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Намоточная машина",
            ki: "0,75",
            cos: "0,83",
            id: 1369,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Кондуксы",
            ki: "0,53",
            cos: "0,88",
            id: 1370,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Центробежный очиститель",
            ki: "0,63",
            cos: "0,76",
            id: 1371,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для мойки фетров",
            ki: "0,54",
            cos: "0,75",
            id: 1372,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Длинносеточная машина",
            ki: "0,68",
            cos: "0,88",
            id: 1373,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Установка для центрования и обрезка кромок",
            ki: "0,68",
            cos: "0,85",
            id: 1374,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Каландр отделочный и намоточная машина",
            ki: "0,68",
            cos: "0,83",
            id: 1375,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Шлифовальная машина с системой отсоса пыли",
            ki: "0,45",
            cos: "0,85",
            id: 1376,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Оборудование для предварительной сортировки",
            ki: "0,45",
            cos: "0,75",
            id: 1377,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для резки картона",
            ki: "0,3",
            cos: "0,85",
            id: 1378,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Тиснильный каландр",
            ki: "0,68",
            cos: "0,88",
            id: 1379,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Бассейны",
            ki: "0,8",
            cos: "0,89",
            id: 1380,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Реакторы, аппараты цельносварные, емкости для массы",
            ki: "0,7",
            cos: "0,8",
            id: 1381,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Автомат для вырубки задников и автомат для спускания края задников",
            ki: "0,3",
            cos: "0,76",
            id: 1382,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Прессы для формирования и вырубки стелек и простилок и машина для шкурения грани стелек",
            ki: "0,23",
            cos: "0,76",
            id: 1383,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для спускания края деталей",
            ki: "0,32",
            cos: "0,59",
            id: 1384,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для формирования стелек",
            ki: "0,32",
            cos: "0,76",
            id: 1385,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для связки стелек",
            ki: "0,32",
            cos: "0,54",
            id: 1386,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для раскроя картона для коробок",
            ki: "0,225",
            cos: "0,73",
            id: 1387,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машина для сшивки коробок",
            ki: "0,225",
            cos: "0,54",
            id: 1388,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Дробилки и вальцы грубого помола",
            ki: "0,35",
            cos: "0,75",
            id: 1389,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Дезинтеграторы",
            ki: "0,65",
            cos: "0,75",
            id: 1390,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Бегуны, штатомойки, смесители сухих компонентов, мешалки",
            ki: "0,65",
            cos: "0,7",
            id: 1391,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Грохоты вибрационные",
            ki: "0,7",
            cos: "0,7",
            id: 1392,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Сита",
            ki: "0,5",
            cos: "0,7",
            id: 1393,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Сепараторы",
            ki: "0,4",
            cos: "0,65",
            id: 1394,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Питатели",
            ki: "0,3",
            cos: "0,65",
            id: 1395,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Шкивы электромагнитные",
            ki: "0,65",
            cos: "0,7",
            id: 1396,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Машины глинорезальные",
            ki: "0,7",
            cos: "0,7",
            id: 1397,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Прессы, вакуумпрессы",
            ki: "0,55",
            cos: "0,7",
            id: 1398,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Фильтр-прессы",
            ki: "0,58",
            cos: "0,7",
            id: 1399,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Барабаны сушильные автоматизированные, центрифуги, бетоносмесители",
            ki: "0,4",
            cos: "0,7",
            id: 1400,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Дозаторы весовые автоматизированные*",
            ki: "0,3",
            cos: "0,7",
            id: 1401,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Пoлуaвтoмaты для формовки капселей, насосы мембранные, установки для литья изделий*",
            ki: "0,66",
            cos: "0,7",
            id: 1402,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Дозаторы весовые автоматизированные*",
            ki: "0,3",
            cos: "0,7",
            id: 1403,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Полуавтоматы для формовки капселей, насосы мембранные, установки для литья изделий*",
            ki: "0,66",
            cos: "0,7",
            id: 1404,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Полуавтоматы для формовки плоских изделий и станки для калибровки гипсовых форм",
            ki: "0,4",
            cos: "0,7",
            id: 1405,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Полуавтоматы для формовки полых изделий, блюд, тарелок",
            ki: "0,58",
            cos: "0,7",
            id: 1406,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Мельницы",
            ki: "0,66",
            cos: "0,8",
            id: 1407,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Сушилка",
            ki: "0,65",
            cos: "0,8",
            id: 1408,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Автоматы для литья полых изделий, полуавтоматы оправочные, обдувки, глазурования, шлифовки, декоррования, поточные и автоматизированные изготовления чашек, автомат для печати",
            ki: "0,7",
            cos: "0,7",
            id: 1409,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Полуавтоматы для отводки плоских изделий, упаковки стенки для печати через сетку",
            ki: "0,55",
            cos: "0,7",
            id: 1410,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Дробилки щековые лабораторные растворомешалки",
            ki: "0,3",
            cos: "0,7",
            id: 1411,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Электрические печи лабораторные",
            ki: "0,7",
            cos: "1",
            id: 1412,
            parents: [
                1,
                1.26
            ]
        },
        {
            name: "Трепальные машины, щипально-замасливающие, обезрепеивающие и машины для разработки свалков нефти",
            ki: "0,68",
            cos: "0,8",
            id: 1413,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Питатель универсальный",
            ki: "0,65",
            cos: "0,78",
            id: 1414,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Смесовые машины",
            ki: "0,59",
            cos: "0,8",
            id: 1415,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Чесальные машины",
            ki: "0,73",
            cos: "0,82",
            id: 1416,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Свойлачивающие полуавтоматы",
            ki: "0,69",
            cos: "0,75",
            id: 1417,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Свойлачивающие машины",
            ki: "0,56",
            cos: "0,7",
            id: 1418,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Машины катальные",
            ki: "0,66",
            cos: "0,75",
            id: 1419,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Станки для расправки основы, точильно-пропиловочные",
            ki: "0,75",
            cos: "0,8",
            id: 1420,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Оборудование для обработки отходов",
            ki: "0,83",
            cos: "0,75",
            id: 1421,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Молотовые машины",
            ki: "0,69",
            cos: "0,8",
            id: 1422,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Красильные аппараты",
            ki: "0,81",
            cos: "0,8",
            id: 1423,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Станки ролико-растяжные, рычажно-растяжные, насадочно-расколодочные, расправочно-отжимные",
            ki: "0,7",
            cos: "0,8",
            id: 1424,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Сушильные камеры",
            ki: "0,7",
            cos: "0,8",
            id: 1425,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Универсально-свойлачивающие машины",
            ki: "0,47",
            cos: "0,8",
            id: 1426,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Кисловочные машины",
            ki: "0,75",
            cos: "0,8",
            id: 1427,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Молотовые машины",
            ki: "0,82",
            cos: "0,87",
            id: 1428,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Красильные аппараты",
            ki: "0,8",
            cos: "0,8",
            id: 1429,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Сушильно-ширильные машины",
            ki: "0,7",
            cos: "0,8",
            id: 1430,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Сушильные камеры и сушилки для кругов",
            ki: "0,75",
            cos: "0,8",
            id: 1431,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Станки для полировки, обрезки, шлифовки валяной обуви",
            ki: "0,5",
            cos: "0,75",
            id: 1432,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Машины для комплексной отделки поверхности валенок с электроопаливанием",
            ki: "0,9",
            cos: "0,97",
            id: 1433,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Машины для сухой отделки",
            ki: "0,62",
            cos: "0,8",
            id: 1434,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Машины для обкатки войлочных полировальных кругов",
            ki: "0,8",
            cos: "0,86",
            id: 1435,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Пресса гидравлические",
            ki: "0,5",
            cos: "0,86",
            id: 1436,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Вальцы подогревательные",
            ki: "0,8",
            cos: "0,8",
            id: 1437,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Пресса электрогидравлические",
            ki: "0,5",
            cos: "0,86",
            id: 1438,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Пресса-автоматы для горячей вулканизации",
            ki: "0,8",
            cos: "1",
            id: 1439,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Гидропривод",
            ki: "0,75",
            cos: "0,8",
            id: 1440,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Участок строительного войлока, машины для разработки войлочного лоскута",
            ki: "0,8",
            cos: "0,85",
            id: 1441,
            parents: [
                1,
                1.27
            ]
        },
        {
            name: "Сверлильные, точильно-шлифовальные резьбонарезные, зубофрезерные и зубодолбежные станки",
            ki: "0,16",
            cos: "0,6",
            id: 1442,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Кузнечно-прессовоe оборудование, гидравлические прессы, термопластавтоматы",
            ki: "0,65",
            cos: "0,8",
            id: 1443,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Ножницы, гибочное оборудование, мостовые краны литейных цехов",
            ki: "0,45",
            cos: "0,65",
            id: 1444,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Галтовочное и очистные барабаны, сварочные трансформаторы ручной сварки, волочильные и протяжные станки, решетка выбивная",
            ki: "0,25",
            cos: "0,65",
            id: 1445,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Трансформаторы для полуавтоматичаской и автоматической сварки, питатели дисковые",
            ki: "0,4",
            cos: "0,55",
            id: 1446,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Токарные станки, автоматы и полуавтоматы, металлообрабатывающие станки литейного производства",
            ki: "0,2",
            cos: "0,6",
            id: 1447,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Фрезерные, строительные расточные станки",
            ki: "0,17",
            cos: "0,65",
            id: 1448,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Кран-балки, тельферы, лифты",
            ki: "0,3",
            cos: "0,5",
            id: 1449,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Сушильные шкафы, камеры, печи сопротивления",
            ki: "0,8",
            cos: "1",
            id: 1450,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Кран-балки, тельферы лифты, станки деревообрабатывающие",
            ki: "0,3",
            cos: "0,6",
            id: 1451,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Дымососы печей, окрасочные камеры, индукционные печи высокой частоты",
            ki: "0,7",
            cos: "0,8",
            id: 1452,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Машины консольные для центробежного литья",
            ki: "0,7",
            cos: "0,8",
            id: 1453,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Агрегаты гальванического покрытия",
            ki: "0,6",
            cos: "0,9",
            id: 1454,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Пневотранспорт* деревообделочного оборудования, шаровые мельницы, дробилки комбинированные",
            ki: "0,85",
            cos: "0,85",
            id: 1455,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Питатели вибрационные и бункеры с вибрационными питателями",
            ki: "0,98",
            cos: "0,6",
            id: 1456,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Барабаны сушильные, элеваторы ковшевые",
            ki: "0,6",
            cos: "0,7",
            id: 1457,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Конвейеры ленточные",
            ki: "0,5",
            cos: "0,7",
            id: 1458,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Бегуны смешивающие",
            ki: "0,7",
            cos: "0,65",
            id: 1459,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Питатели качающиеся",
            ki: "0,6",
            cos: "0,8",
            id: 1460,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Установки магнитной сепарации",
            ki: "0,4",
            cos: "0,8",
            id: 1461,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Индукционные печи низкой частоты",
            ki: "0,7",
            cos: "0,35",
            id: 1462,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Подсобное оборудование прядильно-ткацких производств (валичные, точильные, клееварки)",
            ki: "0,4",
            cos: "0,7",
            id: 1463,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Внутрицеховой транспорт непрерывный",
            ki: "0,4",
            cos: "0,75",
            id: 1464,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Электрические краны, штабеллеры",
            ki: "0,3",
            cos: "0,75",
            id: 1465,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Оборудование химических станций и красковарок, емкостей с мешалками",
            ki: "0,5",
            cos: "0,76",
            id: 1466,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Холодильные станции (низковольтные токоприемники кроме насосов и холодильных машин)",
            ki: "0,75",
            cos: "0,84",
            id: 1467,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Градирни",
            ki: "0,7",
            cos: "0,8",
            id: 1468,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Оборудование складов сырья и химикатов",
            ki: "0,25",
            cos: "0,75",
            id: 1469,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Оборудование пожарных депо",
            ki: "0,3",
            cos: "0,78",
            id: 1470,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Лаборатории",
            ki: "0,3",
            cos: "0,75",
            id: 1471,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Центральные ремонтные и прочие мастерские",
            ki: "0,3",
            cos: "0,8",
            id: 1472,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "а) электроплиты и электрофритюрницы",
            ki: "0,65",
            cos: "1",
            id: 1473,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "б) электрические мармиты, тепловые шкафы, электросковороды и тепловые стойки",
            ki: "0,5",
            cos: "1",
            id: 1474,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "в) прочее электрооборудование",
            ki: "0,3",
            cos: "0,7",
            id: 1475,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "Зарядные станции тяговых аккумуляторов",
            ki: "0,4",
            cos: "0,75",
            id: 1476,
            parents: [
                1,
                1.25
            ]
        },
        {
            name: "По установке непрерывной разливки стали (УНРС) в целом",
            ki: "0,7",
            cos: "0,7",
            id: 1477,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Аглофабрики",
            ki: "0,6",
            cos: "запросить",
            id: 1478,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Коксохимический завод с углемойкой без сероочистки",
            ki: "0,3",
            cos: "запросить",
            id: 1479,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Доменный цех",
            ki: "0,6",
            cos: "0,855",
            id: 1480,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Мартеновский цех с котлами утилизаторами",
            ki: "0,3",
            cos: "0,74",
            id: 1481,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Мартеновский цех без котлов утилизаторов",
            ki: "0,2",
            cos: "запросить",
            id: 1482,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Главный привод",
            ki: "0,75",
            cos: "запросить",
            id: 1483,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Механизмы и краны",
            ki: "0,375",
            cos: "запросить",
            id: 1484,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Всего по стану",
            ki: "0,5",
            cos: "запросить",
            id: 1485,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Заготовочный стан 900",
            ki: "0,625",
            cos: "запросить",
            id: 1486,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Трубопрокатный стан (агрегат Штифеля 130-З60 мм)",
            ki: "0,6",
            cos: "0,525",
            id: 1487,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Прошивной стан 250-1",
            ki: "0,36",
            cos: "запросить",
            id: 1488,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Автомат-стан 250-1",
            ki: "0,35",
            cos: "запросить",
            id: 1489,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Редукционный и калибровочный станы 140",
            ki: "0,19",
            cos: "запросить",
            id: 1490,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Калибровочный стан и механизмы трубоотделки",
            ki: "0,59",
            cos: "запросить",
            id: 1491,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сварочные трансформаторы дуговой электросварки",
            ki: "0,4",
            cos: "0,2",
            id: 1492,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Ламповые генераторы индукционных печей высокой частоты",
            ki: "0,65",
            cos: "запросить",
            id: 1493,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Двигатель-генераторы индукционных высокой частоты",
            ki: "0,8",
            cos: "запросить",
            id: 1494,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Насосы",
            ki: "0,8",
            cos: "0,65",
            id: 1495,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Компенсаторы",
            ki: "0,8",
            cos: "0,65",
            id: 1496,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Механическое станочное оборудование",
            ki: "0,55",
            cos: "0,16",
            id: 1497,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Деревообрабатывающие станки",
            ki: "0,75",
            cos: "0,55",
            id: 1498,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Котельная",
            ki: "0,75",
            cos: "0,6",
            id: 1499,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сантехвентиляция",
            ki: "0,75",
            cos: "0,65",
            id: 1500,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Внутреннее освещение производственных цехов",
            ki: "0,975",
            cos: "0,9",
            id: 1501,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Внутреннее освещение вспомогательных цехов",
            ki: "1",
            cos: "0,9",
            id: 1502,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Волочильные станы",
            ki: "0,835",
            cos: "0,375",
            id: 1503,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Электропечи сопротивления для термической обработки",
            ki: "1",
            cos: "0,625",
            id: 1504,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Производственные вентиляторы и воздуходувки",
            ki: "0,75",
            cos: "0,65",
            id: 1505,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Центробежные насосы",
            ki: "0,7",
            cos: "0,6",
            id: 1506,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Краны и тельферы",
            ki: "0,65",
            cos: "0,1",
            id: 1507,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сварочные аппараты",
            ki: "0,6",
            cos: "0,05",
            id: 1508,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Расточный станок",
            ki: "0,5",
            cos: "0,14",
            id: 1509,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сверлильный станок",
            ki: "0,5",
            cos: "0,14",
            id: 1510,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Заточный станок",
            ki: "0,5",
            cos: "0,14",
            id: 1511,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Пилоножеточный станок",
            ki: "0,5",
            cos: "0,14",
            id: 1512,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Шлифовальный станок",
            ki: "0,5",
            cos: "0,14",
            id: 1513,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сварочные трансформаторы",
            ki: "0,399",
            cos: "0,2",
            id: 1514,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Однопостовые сварочные двигатель-генераторы",
            ki: "0,399",
            cos: "0,2",
            id: 1515,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,8",
            cos: "0,65",
            id: 1516,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,69",
            cos: "0,5",
            id: 1517,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,8",
            cos: "0,65",
            id: 1518,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Дымососы",
            ki: "0,901",
            cos: "0,9",
            id: 1519,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Насосы водяные",
            ki: "0,8",
            cos: "0,7",
            id: 1520,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Механизмы на сплаве: сплоточные машины",
            ki: "0,69",
            cos: "0,6",
            id: 1521,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сортировочные машины",
            ki: "0,69",
            cos: "0,6",
            id: 1522,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сортировочно-формовочные машины",
            ki: "0,69",
            cos: "0,65",
            id: 1523,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Гидроускорители",
            ki: "0,69",
            cos: "0,65",
            id: 1524,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Потокообразователи",
            ki: "0,8",
            cos: "0,7",
            id: 1525,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Барабанные ускорители",
            ki: "0,554",
            cos: "0,3",
            id: 1526,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Троповые ускорители",
            ki: "0,6",
            cos: "0,5",
            id: 1527,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Вспомогательные механизмы автомат-стана 250-1",
            ki: "0,45",
            cos: "запросить",
            id: 1528,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Рельсобалочный стан",
            ki: "0,55",
            cos: "запросить",
            id: 1529,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Сортовые станы 300",
            ki: "0,5",
            cos: "0,92",
            id: 1530,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Мелкосортные и проволочные станы",
            ki: "0,5",
            cos: "запросить",
            id: 1531,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Тонколистовой стан, включая отжиг",
            ki: "0,5",
            cos: "запросить",
            id: 1532,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Преобразовательный агрегат главного подъема доменного цеха",
            ki: "0,5",
            cos: "0,85",
            id: 1533,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "Ремонтные и вспомогательные цехи",
            ki: "0,45",
            cos: "0,815",
            id: 1534,
            parents: [
                2,
                2.1
            ]
        },
        {
            name: "лесопильные цеха и заводы (2-х, 4-х, 6-ти и 8-ми рамные)",
            ki: "0,4",
            cos: "0,6675",
            id: 1535,
            parents: [
                2,
                2.2
            ]
        },
        {
            name: "Тарные цеха",
            ki: "0,38",
            cos: "0,6",
            id: 1536,
            parents: [
                2,
                2.2
            ]
        },
        {
            name: "Деревообрабатывающие цеха (оконных и щитовых блоков)",
            ki: "0,4",
            cos: "0,645",
            id: 1537,
            parents: [
                2,
                2.2
            ]
        },
        {
            name: "Цех древесно-волокнистых плит",
            ki: "0,47",
            cos: "0,69",
            id: 1538,
            parents: [
                2,
                2.2
            ]
        },
        {
            name: "Цеха подготовки волокна",
            ki: "0,65",
            cos: "0,75",
            id: 1539,
            parents: [
                2,
                2.3
            ]
        },
        {
            name: "Браковочные цеха",
            ki: "0,7",
            cos: "0,73",
            id: 1540,
            parents: [
                2,
                2.3
            ]
        },
        {
            name: "Нитепрошивные и холстопрошивные цеха",
            ki: "0,75",
            cos: "0,77",
            id: 1541,
            parents: [
                2,
                2.3
            ]
        },
        {
            name: "Цеха подготовки волокна",
            ki: "0,65",
            cos: "0,75",
            id: 1542,
            parents: [
                2,
                2.3
            ]
        },
        {
            name: "Цеха клееных материалов",
            ki: "0,65",
            cos: "0,79",
            id: 1543,
            parents: [
                2,
                2.3
            ]
        },
        {
            name: "Клетьевой подъем дренажной шахты",
            ki: "0,7",
            cos: "0,7",
            id: 1544,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Корпус дробления",
            ki: "0,6",
            cos: "0,7",
            id: 1545,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Сушильный корпус",
            ki: "0,65",
            cos: "0,75",
            id: 1546,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Радиальные сгустители",
            ki: "0,65",
            cos: "0,75",
            id: 1547,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Погрузка угля",
            ki: "0,4",
            cos: "0,75",
            id: 1548,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Шламовое хозяйство",
            ki: "0,7",
            cos: "0,75",
            id: 1549,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Околоствольный двор",
            ki: "0,65",
            cos: "0,7",
            id: 1550,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Собственные нужды скиповых угольных подъемников",
            ki: "0,7",
            cos: "0,7",
            id: 1551,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Клетьевые подъемники",
            ki: "0,7",
            cos: "0,7",
            id: 1552,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Технологический комплекс",
            ki: "0,65",
            cos: "0,7",
            id: 1553,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Котельная",
            ki: "0,7",
            cos: "0,7",
            id: 1554,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Калориферная",
            ki: "0,7",
            cos: "0,75",
            id: 1555,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Насосная",
            ki: "0,75",
            cos: "0,75",
            id: 1556,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Административно-бытовой комбинат",
            ki: "0,6",
            cos: "0,7",
            id: 1557,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Прочие мелкие установки",
            ki: "0,7",
            cos: "0,7",
            id: 1558,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Очистные работы",
            ki: "0,4",
            cos: "0,6",
            id: 1559,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Подготовительные работы",
            ki: "0,55",
            cos: "0,9",
            id: 1560,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Прочие механизмы",
            ki: "0,7",
            cos: "0,7",
            id: 1561,
            parents: [
                2,
                2.4
            ]
        },
        {
            name: "Компрессорные станции",
            ki: "0,875",
            cos: "0,825",
            id: 1562,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Глубинонасосные установки",
            ki: "0,525",
            cos: "0,65",
            id: 1563,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "воды",
            ki: "0,7",
            cos: "0,8",
            id: 1564,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "нефти",
            ki: "0,65",
            cos: "0,75",
            id: 1565,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Бурящиеся буровые",
            ki: "0,475",
            cos: "0,675",
            id: 1566,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Нефтедобывающий район в целом",
            ki: "0,45",
            cos: "0,75",
            id: 1567,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Электрообессоливающая установка при установленной мощности 130 кВт",
            ki: "0,55",
            cos: "0,7",
            id: 1568,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Электрообессоливающая установка при установленной мощности 350 кВт",
            ki: "0,8",
            cos: "0,85",
            id: 1569,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Депарафинизация масел",
            ki: "0,95",
            cos: "0,85",
            id: 1570,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Атмосферно-вакуумная трубчатка с электроприводом",
            ki: "0,8",
            cos: "0,88",
            id: 1571,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Вторичная перегонка",
            ki: "0,95",
            cos: "0,85",
            id: 1572,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Фенольные установки",
            ki: "0,95",
            cos: "0,85",
            id: 1573,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Термический крекинг",
            ki: "0,85",
            cos: "запросить",
            id: 1574,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Азеотропная установка",
            ki: "0,4",
            cos: "запросить",
            id: 1575,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Алкилирование",
            ki: "0,65",
            cos: "0,86",
            id: 1576,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Газофракционирующая установка",
            ki: "0,7",
            cos: "0,84",
            id: 1577,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Каталитический крекинг",
            ki: "0,85",
            cos: "0,9",
            id: 1578,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Сернокислотная очистка",
            ki: "0,3",
            cos: "запросить",
            id: 1579,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Гидроформинг",
            ki: "0,5",
            cos: "запросить",
            id: 1580,
            parents: [
                2,
                2.5
            ]
        },
        {
            name: "Резиносмесители",
            ki: "0,68",
            cos: "0,8",
            id: 1581,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Пелетайзеры",
            ki: "0,75",
            cos: "0,85",
            id: 1582,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,7",
            cos: "0,85",
            id: 1583,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Транспортное оборудование",
            ki: "0,35",
            cos: "0,65",
            id: 1584,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,65",
            cos: "0,8",
            id: 1585,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,4",
            cos: "0,75",
            id: 1586,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Двигатель-генератор",
            ki: "0,7",
            cos: "0,8",
            id: 1587,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Подъемники",
            ki: "0,4",
            cos: "0,45",
            id: 1588,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,25",
            cos: "0,5",
            id: 1589,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Зарядные агрегаты",
            ki: "0,625",
            cos: "0,8",
            id: 1590,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,45",
            cos: "0,6",
            id: 1591,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Внутризаводской транспорт",
            ki: "0,65",
            cos: "0,75",
            id: 1592,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Пневмотранспорт",
            ki: "0,7",
            cos: "0,8",
            id: 1593,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1594,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое отделение",
            ki: "0,55",
            cos: "0,77",
            id: 1595,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1596,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Пневмотранспорт",
            ki: "0,7",
            cos: "0,8",
            id: 1597,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Станки",
            ki: "0,6",
            cos: "0,65",
            id: 1598,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1599,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,65",
            cos: "0,7",
            id: 1600,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1601,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,52",
            cos: "0,73",
            id: 1602,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1603,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,4",
            cos: "0,6",
            id: 1604,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,65",
            cos: "0,8",
            id: 1605,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,4",
            cos: "0,67",
            id: 1606,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,7",
            cos: "0,8",
            id: 1607,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,46",
            cos: "0,62",
            id: 1608,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Электрообогрев прессов",
            ki: "0,8",
            cos: "0,95",
            id: 1609,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Установка ТВЧ",
            ki: "0,8",
            cos: "0,6",
            id: 1610,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Внутризаводской транспорт",
            ki: "0,61",
            cos: "0,8",
            id: 1611,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляция",
            ki: "0,65",
            cos: "0,8",
            id: 1612,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Насосы",
            ki: "0,75",
            cos: "0,8",
            id: 1613,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Массомешалки",
            ki: "0,6",
            cos: "0,8",
            id: 1614,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вертикальные паронитовые вальцы и отбор. устройства",
            ki: "0,62",
            cos: "0,75",
            id: 1615,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вакуум-насосы РМК-3",
            ki: "0,45",
            cos: "0,8",
            id: 1616,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прочее технологическое оборудование",
            ki: "0,6",
            cos: "0,7",
            id: 1617,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Электрообогрев прессов",
            ki: "0,8",
            cos: "0,95",
            id: 1618,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Металлорежущие станки",
            ki: "0,18",
            cos: "0,8",
            id: 1619,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Сантехническое оборудование",
            ki: "0,65",
            cos: "0,8",
            id: 1620,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Пневмотранспорт",
            ki: "0,7",
            cos: "0,8",
            id: 1621,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Транспортеры, шнеки, весовая, дозировки, лебедки",
            ki: "0,6",
            cos: "0,7",
            id: 1622,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Лабораторное оборудование",
            ki: "0,6",
            cos: "0,65",
            id: 1623,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Термические приемники",
            ki: "0,6",
            cos: "0,95",
            id: 1624,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,7",
            cos: "0,8",
            id: 1625,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех производства транспортных лент и приводных ремней (высоковольтные двигатели)",
            ki: "0,67",
            cos: "0,8",
            id: 1626,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех производства транспортных лент и приводных ремней (низковольтные двигатели)",
            ki: "0,4",
            cos: "0,7",
            id: 1627,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех производства формовой техники без учета электропроцессов",
            ki: "0,39",
            cos: "0,63",
            id: 1628,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех производства формовой техники с учетом электропроцессов",
            ki: "0,67",
            cos: "0,8",
            id: 1629,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Подготовительный цех",
            ki: "0,58",
            cos: "запросить",
            id: 1630,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех спецшлангов (в том числе без дорновых рукавов)",
            ki: "0,41",
            cos: "0,57",
            id: 1631,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех спиральных и буровых рукавов",
            ki: "0,3",
            cos: "0,6",
            id: 1632,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех напорных рукавов",
            ki: "0,32",
            cos: "0,62",
            id: 1633,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех клиновидных ремней",
            ki: "0,39",
            cos: "0,63",
            id: 1634,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Подготовительный цех (высоковольтные двигатели)",
            ki: "0,7",
            cos: "0,875",
            id: 1635,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Подготовительный цех (низковольтные двигатели)",
            ki: "0,65",
            cos: "0,65",
            id: 1636,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Сборочный цех",
            ki: "0,5",
            cos: "0,65",
            id: 1637,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех каландров (высоковольтные двигатели)",
            ki: "0,65",
            cos: "0,875",
            id: 1638,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех каландров (низковольтные двигатели)",
            ki: "0,45",
            cos: "0,65",
            id: 1639,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Автокамерный цех (высоковольтные двигатели)",
            ki: "0,8",
            cos: "0,875",
            id: 1640,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Автокамерный цех (низковольтные двигатели)",
            ki: "0,4",
            cos: "0,75",
            id: 1641,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех вулканизации",
            ki: "0,35",
            cos: "0,55",
            id: 1642,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех очистки этилена",
            ki: "0,9",
            cos: "0,9",
            id: 1643,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех полимеризации",
            ki: "0,5",
            cos: "0,75",
            id: 1644,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех дистилляции и очистки азота",
            ki: "0,75",
            cos: "0,8",
            id: 1645,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех грануляции",
            ki: "0,75",
            cos: "0,8",
            id: 1646,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех катализации",
            ki: "0,85",
            cos: "0,65",
            id: 1647,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех легковоспламеняющихся жидкостей",
            ki: "0,9",
            cos: "0,75",
            id: 1648,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех разделения воздуха",
            ki: "0,87",
            cos: "0,95",
            id: 1649,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Газовый цех (на природном газе)",
            ki: "0,87",
            cos: "0,8",
            id: 1650,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Газовый цех (на газогенераторном газе)",
            ki: "0,65",
            cos: "0,8",
            id: 1651,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех холодильных установок",
            ki: "0,79",
            cos: "0,9",
            id: 1652,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех компрессии",
            ki: "0,87",
            cos: "0,9",
            id: 1653,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех моноэтаноламиновой очистки",
            ki: "0,74",
            cos: "0,8",
            id: 1654,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех синтеза аммиака",
            ki: "0,87",
            cos: "0,85",
            id: 1655,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех водной очистки",
            ki: "0,89",
            cos: "0,8",
            id: 1656,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех медно-аммиачной очистки",
            ki: "0,8",
            cos: "0,85",
            id: 1657,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех синтеза метанола",
            ki: "0,81",
            cos: "0,7",
            id: 1658,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех сероочистки",
            ki: "0,8",
            cos: "0,64",
            id: 1659,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех конверсии окиси углерода",
            ki: "0,77",
            cos: "0,8",
            id: 1660,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех ректификации метанола",
            ki: "0,5",
            cos: "0,72",
            id: 1661,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Химический цех",
            ki: "0,6",
            cos: "0,8",
            id: 1662,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прядильный цех (с учетом динильных котлов)",
            ki: "0,65",
            cos: "0,75",
            id: 1663,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Крутильный цех",
            ki: "0,7",
            cos: "0,75",
            id: 1664,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Отделочный цех",
            ki: "0,7",
            cos: "0,75",
            id: 1665,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Бобинажно-перемоточный цех",
            ki: "0,8",
            cos: "0,75",
            id: 1666,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех регенераций отходов",
            ki: "0,65",
            cos: "0,7",
            id: 1667,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Химический цех",
            ki: "0,55",
            cos: "0,7",
            id: 1668,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прядильный цех",
            ki: "0,7",
            cos: "0,8",
            id: 1669,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Крутильный цех",
            ki: "0,7",
            cos: "0,8",
            id: 1670,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Ткацкий цех",
            ki: "0,85",
            cos: "0,75",
            id: 1671,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Химический цех",
            ki: "0,55",
            cos: "0,7",
            id: 1672,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прядильный цех (без электроверетен)",
            ki: "0,65",
            cos: "0,75",
            id: 1673,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Отделочный цех",
            ki: "0,65",
            cos: "0,75",
            id: 1674,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Бобинажно-перемоточный цех",
            ki: "0,8",
            cos: "0,7",
            id: 1675,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Химический цех",
            ki: "0,6",
            cos: "0,7",
            id: 1676,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прядильно-отделочный цех",
            ki: "0,75",
            cos: "0,8",
            id: 1677,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Химический цех",
            ki: "0,9",
            cos: "0,65",
            id: 1678,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Прядильный цех",
            ki: "0,85",
            cos: "0,7",
            id: 1679,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Крутильный цех",
            ki: "0,7",
            cos: "0,65",
            id: 1680,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех регенерации ацетона",
            ki: "0,9",
            cos: "0,8",
            id: 1681,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Водонасосная",
            ki: "0,75",
            cos: "0,8",
            id: 1682,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех слабой азотной кислоты при повышенном давлении",
            ki: "0,86",
            cos: "0,95",
            id: 1683,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Цех слабой азотной кислоты при нормальном давлении",
            ki: "0,78",
            cos: "0,91",
            id: 1684,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "В целом по заводу, исключая электролиз хлора и каустической соды",
            ki: "0,525",
            cos: "0,95",
            id: 1685,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Электролиз (серии ванн) производства хлора и каустической соды",
            ki: "1",
            cos: "0,9",
            id: 1686,
            parents: [
                2,
                2.6
            ]
        },
        {
            name: "Ротационный цех в целом",
            ki: "0,63",
            cos: "0,8",
            id: 1687,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Цех изготовления офсетных форм в целом",
            ki: "0,54",
            cos: "0,7",
            id: 1688,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Цех глубокой печати в целом",
            ki: "0,54",
            cos: "0,85",
            id: 1689,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Брошюровочный цех в целом",
            ki: "0,58",
            cos: "0,75",
            id: 1690,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатный цех в целом",
            ki: "0,66",
            cos: "0,65",
            id: 1691,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Переплетный цех в целом",
            ki: "0,47",
            cos: "0,72",
            id: 1692,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатные машины 'Пламаг' и 'Вимаг' (газеты)",
            ki: "0,5",
            cos: "0,54",
            id: 1693,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатные машины ГА, ГА-2 и ГАУ (газеты)",
            ki: "0,425",
            cos: "0,24",
            id: 1694,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Многокрасочные печатные машины 'Пламаг' (журналы)",
            ki: "0,7",
            cos: "0,4",
            id: 1695,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатные машины 'Планета' (журналы, иллюстрации)",
            ki: "0,7",
            cos: "0,45",
            id: 1696,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатные машины ПД-2 и ПРП-3 (книги, журналы)",
            ki: "0,65",
            cos: "0,3",
            id: 1697,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Печатные машины ПРК-2, ПРК-3 и 'Пламаг' (книги, журналы)",
            ki: "0,66",
            cos: "0,45",
            id: 1698,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Строкоотливные машины Н-7 и Н-11",
            ki: "0,62",
            cos: "0,5",
            id: 1699,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Фальцевальные машины",
            ki: "0,95",
            cos: "0,75",
            id: 1700,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "Вкладочно-швейные резальные агрегаты ВШРА (журналы)",
            ki: "0,55",
            cos: "0,3",
            id: 1701,
            parents: [
                2,
                2.7
            ]
        },
        {
            name: "2.8. ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ",
            ki: "0,6",
            cos: "0,5",
            id: 1702,
            parents: []
        },
        {
            name: "Технологическое оборудование",
            ki: "0,4",
            cos: "",
            id: 1703,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Транспортное оборудование",
            ki: "0,5",
            cos: "",
            id: 1704,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Жидкостные насосы",
            ki: "0,6",
            cos: "0,8",
            id: 1705,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Воздушные и газовые компрессоры, насосы",
            ki: "0,525",
            cos: "0,8",
            id: 1706,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляторы и дымососы",
            ki: "0,525",
            cos: "",
            id: 1707,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Мельница сортового помола",
            ki: "0,725",
            cos: "0,8",
            id: 1708,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Мельница обойного помола",
            ki: "0,775",
            cos: "0,8",
            id: 1709,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Сушильно-очистительные башни с шахтами и газовыми сушилками",
            ki: "0,475",
            cos: "0,75",
            id: 1710,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Сушильно-очистительные башни и механизированные склады зерна",
            ki: "0,6",
            cos: "0,75",
            id: 1711,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,525",
            cos: "0,7",
            id: 1712,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Нагревательное оборудование",
            ki: "0,85",
            cos: "0,95",
            id: 1713,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Компрессоры, насосы",
            ki: "0,675",
            cos: "0,775",
            id: 1714,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,45",
            cos: "0,675",
            id: 1715,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Нагревательное оборудование",
            ki: "0,85",
            cos: "0,95",
            id: 1716,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Компрессоры, насосы",
            ki: "0,675",
            cos: "0,775",
            id: 1717,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование для производства масла, сыра, брынзы, цельномолочной продукции",
            ki: "0,525",
            cos: "0,75",
            id: 1718,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование для производства молочных консервов",
            ki: "0,7",
            cos: "0,75",
            id: 1719,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Транспортное оборудование",
            ki: "0,6",
            cos: "0,75",
            id: 1720,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляционное оборудование",
            ki: "0,725",
            cos: "0,75",
            id: 1721,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Холодильное оборудование",
            ki: "0,675",
            cos: "0,8",
            id: 1722,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Теплотехническое оборудование",
            ki: "0,55",
            cos: "0,8",
            id: 1723,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,4",
            cos: "0,7",
            id: 1724,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Нагревательное оборудование",
            ki: "0,7",
            cos: "0,95",
            id: 1725,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Дымогенераторы",
            ki: "0,6",
            cos: "0,95",
            id: 1726,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Печи производства рыбных консервов",
            ki: "0,3",
            cos: "1",
            id: 1727,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "привод",
            ki: "0,7",
            cos: "0,75",
            id: 1728,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "нагрев",
            ki: "0,8",
            cos: "0,95",
            id: 1729,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Транспортное и грузоподъемное оборудование",
            ki: "0,3",
            cos: "0,7",
            id: 1730,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Насосы",
            ki: "0,55",
            cos: "0,8",
            id: 1731,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Компрессоры и морозильные агрегаты",
            ki: "0,7",
            cos: "",
            id: 1732,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Выпрямители",
            ki: "0,8",
            cos: "0,6",
            id: 1733,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,6",
            cos: "0,8",
            id: 1734,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Технологическое оборудование",
            ki: "0,3",
            cos: "0,7",
            id: 1735,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Нагревательное оборудование",
            ki: "0,4",
            cos: "0,95",
            id: 1736,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Грузоподъемное оборудование",
            ki: "0,3",
            cos: "0,65",
            id: 1737,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Компрессоры, насосы",
            ki: "0,575",
            cos: "0,8",
            id: 1738,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,6",
            cos: "0,7",
            id: 1739,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Компрессоры",
            ki: "0,8",
            cos: "0,85",
            id: 1740,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Насосы",
            ki: "0,6",
            cos: "0,8",
            id: 1741,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Лифты",
            ki: "0,2",
            cos: "0,65",
            id: 1742,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляторы технологические",
            ki: "0,6",
            cos: "0,7",
            id: 1743,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Зарядные станции",
            ki: "0,825",
            cos: "0,82",
            id: 1744,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Вентиляторы сантехнические",
            ki: "0,7",
            cos: "0,8",
            id: 1745,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Станки РММ",
            ki: "0,4",
            cos: "0,6",
            id: 1746,
            parents: [
                2,
                2.8
            ]
        },
        {
            name: "Бактерицидная установка",
            ki: "0,9",
            cos: "0,98",
            id: 1747,
            parents: [
                3
            ]
        },
        {
            name: "Бетономешалка",
            ki: "0,45",
            cos: "0,87",
            id: 1748,
            parents: [
                3
            ]
        },
        {
            name: "Блок питания БПРУ",
            ki: "0,45",
            cos: "0,9",
            id: 1749,
            parents: [
                3
            ]
        },
        {
            name: "Вентилятор вытяжной",
            ki: "0,32",
            cos: "0,76",
            id: 1750,
            parents: [
                3
            ]
        },
        {
            name: "Вентилятор дутьевой",
            ki: "0,72",
            cos: "0,9",
            id: 1751,
            parents: [
                3
            ]
        },
        {
            name: "Вентилятор приточный",
            ki: "0,64",
            cos: "0,81",
            id: 1752,
            parents: [
                3
            ]
        },
        {
            name: "Воздушное охлаждение газа",
            ki: "0,72",
            cos: "0,93",
            id: 1753,
            parents: [
                3
            ]
        },
        {
            name: "Ворота",
            ki: "0,11",
            cos: "0,87",
            id: 1754,
            parents: [
                3
            ]
        },
        {
            name: "Вычислительная техника",
            ki: "0,5",
            cos: "0,9",
            id: 1755,
            parents: [
                3
            ]
        },
        {
            name: "Дистиллятор",
            ki: "0,72",
            cos: "0,87",
            id: 1756,
            parents: [
                3
            ]
        },
        {
            name: "Дымосос",
            ki: "0,72",
            cos: "0,85",
            id: 1757,
            parents: [
                3
            ]
        },
        {
            name: "Емкость аварийная",
            ki: "0,27",
            cos: "0,86",
            id: 1758,
            parents: [
                3
            ]
        },
        {
            name: "Емкость дренажная",
            ki: "0,56",
            cos: "0,86",
            id: 1759,
            parents: [
                3
            ]
        },
        {
            name: "Задвижка",
            ki: "0,11",
            cos: "0,73",
            id: 1760,
            parents: [
                3
            ]
        },
        {
            name: "Зарядное устройство для аккумуляторов",
            ki: "0,41",
            cos: "0,7",
            id: 1761,
            parents: [
                3
            ]
        },
        {
            name: "Калорифер",
            ki: "0,63",
            cos: "1",
            id: 1762,
            parents: [
                3
            ]
        },
        {
            name: "Кладовая",
            ki: "0,41",
            cos: "0,98",
            id: 1763,
            parents: [
                3
            ]
        },
        {
            name: "Компрессор воздушный",
            ki: "0,64",
            cos: "0,81",
            id: 1764,
            parents: [
                3
            ]
        },
        {
            name: "Конвейер",
            ki: "0,68",
            cos: "0,8",
            id: 1765,
            parents: [
                3
            ]
        },
        {
            name: "Конденсатосборник",
            ki: "0,54",
            cos: "0,8",
            id: 1766,
            parents: [
                3
            ]
        },
        {
            name: "Кран козловой",
            ki: "0,11",
            cos: "0,7",
            id: 1767,
            parents: [
                3
            ]
        },
        {
            name: "Кран мостовой",
            ki: "0,25",
            cos: "0,7",
            id: 1768,
            parents: [
                3
            ]
        },
        {
            name: "Кран подвесной",
            ki: "0,21",
            cos: "0,7",
            id: 1769,
            parents: [
                3
            ]
        },
        {
            name: "Механическое оборудование",
            ki: "0,3",
            cos: "0,75",
            id: 1770,
            parents: [
                3
            ]
        },
        {
            name: "Нагревательное оборудование",
            ki: "0,6",
            cos: "1",
            id: 1771,
            parents: [
                3
            ]
        },
        {
            name: "Нагрузка пожаротушения",
            ki: "0,08",
            cos: "0,8",
            id: 1772,
            parents: [
                3
            ]
        },
        {
            name: "Насос водозаборной скважины",
            ki: "0,66",
            cos: "0,72",
            id: 1773,
            parents: [
                3
            ]
        },
        {
            name: "Насос газовый",
            ki: "0,72",
            cos: "0,8",
            id: 1774,
            parents: [
                3
            ]
        },
        {
            name: "Насос горячей воды",
            ki: "0,56",
            cos: "0,8",
            id: 1775,
            parents: [
                3
            ]
        },
        {
            name: "Насос дозировочный",
            ki: "0,8",
            cos: "0,9",
            id: 1776,
            parents: [
                3
            ]
        },
        {
            name: "Насос масляный",
            ki: "0,31",
            cos: "0,81",
            id: 1777,
            parents: [
                3
            ]
        },
        {
            name: "Насос на ДНС",
            ki: "0,75",
            cos: "0,86",
            id: 1778,
            parents: [
                3
            ]
        },
        {
            name: "Насос на КСП",
            ki: "0,75",
            cos: "0,9",
            id: 1779,
            parents: [
                3
            ]
        },
        {
            name: "Насос на ЦПС",
            ki: "0,75",
            cos: "0,86",
            id: 1780,
            parents: [
                3
            ]
        },
        {
            name: "Насос над артскважиной",
            ki: "0,64",
            cos: "0,81",
            id: 1781,
            parents: [
                3
            ]
        },
        {
            name: "Насос оборотный",
            ki: "0,67",
            cos: "0,95",
            id: 1782,
            parents: [
                3
            ]
        },
        {
            name: "Насос очистных сооружений",
            ki: "0,57",
            cos: "0,76",
            id: 1783,
            parents: [
                3
            ]
        },
        {
            name: "Насос перекачки воды",
            ki: "0,56",
            cos: "0,88",
            id: 1784,
            parents: [
                3
            ]
        },
        {
            name: "Насос питательный",
            ki: "0,65",
            cos: "0,9",
            id: 1785,
            parents: [
                3
            ]
        },
        {
            name: "Насос подачи ингибитора",
            ki: "0,66",
            cos: "0,88",
            id: 1786,
            parents: [
                3
            ]
        },
        {
            name: "Насос подачи реагента",
            ki: "0,68",
            cos: "0,89",
            id: 1787,
            parents: [
                3
            ]
        },
        {
            name: "Насос подготовки воды",
            ki: "0,73",
            cos: "0,76",
            id: 1788,
            parents: [
                3
            ]
        },
        {
            name: "Насос подпитки",
            ki: "0,65",
            cos: "0,89",
            id: 1789,
            parents: [
                3
            ]
        },
        {
            name: "Насос подпиточный",
            ki: "0,63",
            cos: "0,89",
            id: 1790,
            parents: [
                3
            ]
        },
        {
            name: "Насос противопожарной насосной станции",
            ki: "0,08",
            cos: "0,9",
            id: 1791,
            parents: [
                3
            ]
        },
        {
            name: "Насос разгрузки",
            ki: "0,57",
            cos: "0,91",
            id: 1792,
            parents: [
                3
            ]
        },
        {
            name: "Насос сетевой",
            ki: "0,5",
            cos: "0,9",
            id: 1793,
            parents: [
                3
            ]
        },
        {
            name: "Насос ШГН",
            ki: "0,66",
            cos: "0,78",
            id: 1794,
            parents: [
                3
            ]
        },
        {
            name: "Насос ЭЦН",
            ki: "0,68",
            cos: "0,79",
            id: 1795,
            parents: [
                3
            ]
        },
        {
            name: "Насос ЭЦН (70 Гц)",
            ki: "0,68",
            cos: "0,67",
            id: 1796,
            parents: [
                3
            ]
        },
        {
            name: "Освещение аварийное",
            ki: "1",
            cos: "0,98",
            id: 1797,
            parents: [
                3
            ]
        },
        {
            name: "Освещение внутреннее б/п",
            ki: "0,5",
            cos: "0,98",
            id: 1798,
            parents: [
                3
            ]
        },
        {
            name: "Освещение внутреннее с/п",
            ki: "0,7",
            cos: "0,98",
            id: 1799,
            parents: [
                3
            ]
        },
        {
            name: "Освещение наружное",
            ki: "0,7",
            cos: "0,98",
            id: 1800,
            parents: [
                3
            ]
        },
        {
            name: "Освещение охранное",
            ki: "1",
            cos: "0,98",
            id: 1801,
            parents: [
                3
            ]
        },
        {
            name: "Освещение рабочее",
            ki: "0,7",
            cos: "0,98",
            id: 1802,
            parents: [
                3
            ]
        },
        {
            name: "Освещение ремонтное",
            ki: "0,05",
            cos: "0,98",
            id: 1803,
            parents: [
                3
            ]
        },
        {
            name: "Отопление вентилируемого помещения б/р",
            ki: "0,9",
            cos: "1",
            id: 1804,
            parents: [
                3
            ]
        },
        {
            name: "Отопление вентилируемого помещения с/р",
            ki: "0,45",
            cos: "1",
            id: 1805,
            parents: [
                3
            ]
        },
        {
            name: "Отопление воздушное",
            ki: "0,8",
            cos: "0,75",
            id: 1806,
            parents: [
                3
            ]
        },
        {
            name: "Отопление компрессорной б/р",
            ki: "0,9",
            cos: "1",
            id: 1807,
            parents: [
                3
            ]
        },
        {
            name: "Отопление компрессорной с/р",
            ki: "0,45",
            cos: "1",
            id: 1808,
            parents: [
                3
            ]
        },
        {
            name: "Отопление насосной б/р",
            ki: "0,9",
            cos: "1",
            id: 1809,
            parents: [
                3
            ]
        },
        {
            name: "Отопление насосной с/р",
            ki: "0,45",
            cos: "1",
            id: 1810,
            parents: [
                3
            ]
        },
        {
            name: "Отопление электропомещения б/р",
            ki: "0,9",
            cos: "1",
            id: 1811,
            parents: [
                3
            ]
        },
        {
            name: "Отопление электропомещения с/р",
            ki: "0,45",
            cos: "1",
            id: 1812,
            parents: [
                3
            ]
        },
        {
            name: "Печь ПТБ (Венгрия)",
            ki: "0,8",
            cos: "0,79",
            id: 1813,
            parents: [
                3
            ]
        },
        {
            name: "Пресс",
            ki: "0,42",
            cos: "0,87",
            id: 1814,
            parents: [
                3
            ]
        },
        {
            name: "Розетка",
            ki: "0,1",
            cos: "0,98",
            id: 1815,
            parents: [
                3
            ]
        },
        {
            name: "Сборник утечек",
            ki: "0,48",
            cos: "0,8",
            id: 1816,
            parents: [
                3
            ]
        },
        {
            name: "Станок сверлильный",
            ki: "0,31",
            cos: "0,8",
            id: 1817,
            parents: [
                3
            ]
        },
        {
            name: "Станок токарно-винтовой",
            ki: "0,31",
            cos: "0,85",
            id: 1818,
            parents: [
                3
            ]
        },
        {
            name: "Стол лабораторный",
            ki: "0,41",
            cos: "0,9",
            id: 1819,
            parents: [
                3
            ]
        },
        {
            name: "Тележка",
            ki: "0,51",
            cos: "0,7",
            id: 1820,
            parents: [
                3
            ]
        },
        {
            name: "Тиристорный возбудитель",
            ki: "0,9",
            cos: "0,39",
            id: 1821,
            parents: [
                3
            ]
        },
        {
            name: "Трансформатор понижающий",
            ki: "0,85",
            cos: "0,7",
            id: 1822,
            parents: [
                3
            ]
        },
        {
            name: "Холодильник",
            ki: "0,36",
            cos: "0,98",
            id: 1823,
            parents: [
                3
            ]
        },
        {
            name: "Шкаф сушильный",
            ki: "0,5",
            cos: "1",
            id: 1824,
            parents: [
                3
            ]
        },
        {
            name: "Щит КИП и А",
            ki: "0,9",
            cos: "0,98",
            id: 1825,
            parents: [
                3
            ]
        },
        {
            name: "Щит управления котлоагрегатом",
            ki: "0,65",
            cos: "0,98",
            id: 1826,
            parents: [
                3
            ]
        },
        {
            name: "Электродегидратор",
            ki: "0,54",
            cos: "0,85",
            id: 1827,
            parents: [
                3
            ]
        },
        {
            name: "Электрообогрев труб",
            ki: "0,6",
            cos: "1",
            id: 1828,
            parents: [
                3
            ]
        },
        {
            name: "Электропечь",
            ki: "0,58",
            cos: "1",
            id: 1829,
            parents: [
                3
            ]
        },
        {
            name: "Электрополотенце",
            ki: "0,07",
            cos: "1",
            id: 1830,
            parents: [
                3
            ]
        },
        {
            name: "Электроталь",
            ki: "0,11",
            cos: "0,75",
            id: 1831,
            parents: [
                3
            ]
        }
    ]
}


// let guide_items = [
//     {
//         name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные",
//         ki: "0,13",
//         cos: "0,45",
//         id: 2,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, при крупносерийном производстве",
//         ki: "0,16",
//         cos: "0,5",
//         id: 3,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, при тяжелом режиме работы: штамповочные прессы, автоматы, револьверные, обдирочные, зубофрезерные, а также крупные токарные, строгальные, фрезерные, карусельные и расточные станки",
//         ki: "0,17",
//         cos: "0,65",
//         id: 4,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные, карусельные, точильные, ножницы листовые, сортовые, фасонные, скрапные, арматурные, с особо тяжелым режимом работы: приводы молотов, ковочных машин, волочильных станков, очистных барабанов, бегунов и др.",
//         ki: "0,22",
//         cos: "0,65",
//         id: 5,
//         parents: []
//     },
//     {
//         name: "Шлифовальные станки шарикоподшипниковых заводов",
//         ki: "0,275",
//         cos: "0,65",
//         id: 6,
//         parents: []
//     },
//     {
//         name: "Автоматические поточные линии обработки металлов",
//         ki: "0,55",
//         cos: "0,7",
//         id: 7,
//         parents: []
//     },
//     {
//         name: "Механические цехи, многошпиндельные автоматы для изготовления деталей из прутков",
//         ki: "0,2",
//         cos: "0,55",
//         id: 8,
//         parents: []
//     },
//     {
//         name: "Переносной электроинструмент",
//         ki: "0,06",
//         cos: "0,5",
//         id: 9,
//         parents: []
//     },
//     {
//         name: "Вентиляторы, эксгаустеры, санитарно-гигиеническая вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 10,
//         parents: []
//     },
//     {
//         name: "Насосы, компрессоры, двигатель-генераторы",
//         ki: "0,7",
//         cos: "0,85",
//         id: 11,
//         parents: []
//     },
//     {
//         name: "Элеваторы, транспортеры, шнеки, конвейеры несблокированные",
//         ki: "0,4",
//         cos: "0,75",
//         id: 12,
//         parents: []
//     },
//     {
//         name: "Элеваторы, транспортеры, шнеки, конвейеры сблокированные",
//         ki: "0,55",
//         cos: "0,75",
//         id: 13,
//         parents: []
//     },
//     {
//         name: "Краны, тельферы при ПВ - 25%",
//         ki: "0,05",
//         cos: "0,5",
//         id: 14,
//         parents: []
//     },
//     {
//         name: "Краны, тельферы при ПВ - 40%",
//         ki: "0,1",
//         cos: "0,5",
//         id: 15,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для ручной дуговой сварки и резки металлов",
//         ki: "0,3",
//         cos: "0,425",
//         id: 16,
//         parents: []
//     },
//     {
//         name: "Сварочные машины шовные",
//         ki: "0,3",
//         cos: "0,7",
//         id: 17,
//         parents: []
//     },
//     {
//         name: "Сварочные машины стыковые и точечные",
//         ki: "0,3",
//         cos: "0,6",
//         id: 18,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для автоматической и полуавтоматической дуговой сварки",
//         ki: "0,35",
//         cos: "0,5",
//         id: 19,
//         parents: []
//     },
//     {
//         name: "Однопостовые сварочные двигатель-генераторы",
//         ki: "0,3",
//         cos: "0,6",
//         id: 20,
//         parents: []
//     },
//     {
//         name: "Многопостовые сварочные двигатель-генераторы",
//         ki: "0,5",
//         cos: "0,7",
//         id: 21,
//         parents: []
//     },
//     {
//         name: "Машины для сварки трением",
//         ki: "0,6",
//         cos: "0,8",
//         id: 22,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с непрерывной (автоматической) загрузкой, сушильные шкафы",
//         ki: "0,6",
//         cos: "0,95",
//         id: 23,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с периодической загрузкой, сушильные шкафы",
//         ki: "0,5",
//         cos: "0,85",
//         id: 24,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с неавтоматической загрузкой изделий",
//         ki: "0,5",
//         cos: "0,95",
//         id: 25,
//         parents: []
//     },
//     {
//         name: "Мелкие нагревательные приборы",
//         ki: "0,6",
//         cos: "1",
//         id: 26,
//         parents: []
//     },
//     {
//         name: "Индукционные печи низкой частоты",
//         ki: "0,7",
//         cos: "0,95",
//         id: 27,
//         parents: []
//     },
//     {
//         name: "Двигатель-генераторы индукционных печей высокой частоты",
//         ki: "0,7",
//         cos: "0,8",
//         id: 28,
//         parents: []
//     },
//     {
//         name: "Ламповые генераторы индукционных печей высокой частоты",
//         ki: "0,7",
//         cos: "0,65",
//         id: 29,
//         parents: []
//     },
//     {
//         name: "Тиристорные преобразователи установок ТВЧ",
//         ki: "0,7",
//         cos: "0,65",
//         id: 30,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для качественных сталей с механизированной загрузкой",
//         ki: "0,75",
//         cos: "0,9",
//         id: 31,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для качественных сталей без механизированной загрузки",
//         ki: "0,6",
//         cos: "0,87",
//         id: 32,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для фасонного литья с механизированной загрузкой",
//         ki: "0,75",
//         cos: "0,9",
//         id: 33,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10т с автоматическим регулированием электродов для фасонного литья без механизированной загрузки",
//         ki: "0,65",
//         cos: "0,87",
//         id: 34,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 0,5 до 1,5 т для фасонного литья (в подсобных цехах с автоматическим регулированием электродов)",
//         ki: "0,5",
//         cos: "0,8",
//         id: 35,
//         parents: []
//     },
//     {
//         name: "Дуговые печи цветного металла (медные сплавы) емкостью от 0,25 до 0,5 т с ручным регулированием электродов",
//         ki: "0,7",
//         cos: "0,75",
//         id: 36,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы дуговых печей и печей сопротивления",
//         ki: "0,12",
//         cos: "0,65",
//         id: 37,
//         parents: []
//     },
//     {
//         name: "Насосы, компрессоры, двигатель-генераторы",
//         ki: "0,7",
//         cos: "0,825",
//         id: 38,
//         parents: []
//     },
//     {
//         name: "Вентиляторы, эксгаустеры; вентиляционное оборудование",
//         ki: "0,65",
//         cos: "0,8",
//         id: 39,
//         parents: []
//     },
//     {
//         name: "Насосы, компрессоры с синхронными электродвигателями",
//         ki: "0,7",
//         cos: "0,9",
//         id: 40,
//         parents: []
//     },
//     {
//         name: "Станки автоматических линий механических цехов",
//         ki: "0,3",
//         cos: "0,65",
//         id: 41,
//         parents: []
//     },
//     {
//         name: "Станки автоматических линий, рассчитанные по циклограмме",
//         ki: "0,4",
//         cos: "0,65",
//         id: 42,
//         parents: []
//     },
//     {
//         name: "Станки автоматических линий тяжелого режима работы, рассчитанные по циклограмме",
//         ki: "0,5",
//         cos: "0,65",
//         id: 43,
//         parents: []
//     },
//     {
//         name: "Литейное оборудование: очистные барабаны, бегуны, выбивные решетки, зачистные машины, пескометы, дробеметные камеры и др.",
//         ki: "0,5",
//         cos: "0,65",
//         id: 44,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с автоматической загрузкой напряжением 380 В",
//         ki: "0,7",
//         cos: "1",
//         id: 45,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с автоматической загрузкой с трансформаторами 380/220 В",
//         ki: "0,7",
//         cos: "0,85",
//         id: 46,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с неавтоматической загрузкой напряжением 380 В",
//         ki: "0,5",
//         cos: "1",
//         id: 47,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с неавтоматической загрузкой с трансформаторами 380/220 В",
//         ki: "0,5",
//         cos: "0,95",
//         id: 48,
//         parents: []
//     },
//     {
//         name: "Нагреватели гальванических ванн",
//         ki: "0,6",
//         cos: "1",
//         id: 49,
//         parents: []
//     },
//     {
//         name: "Выпрямители гальванических ванн",
//         ki: "0,7",
//         cos: "0,8",
//         id: 50,
//         parents: []
//     },
//     {
//         name: "Установки автоматической дуговой сварки, комплектуемые выпрямителями",
//         ki: "0,35",
//         cos: "0,8",
//         id: 51,
//         parents: []
//     },
//     {
//         name: "Установки ручной дуговой сварки и резки металлов, комплектуемые выпрямителями",
//         ki: "0,3",
//         cos: "0,8",
//         id: 52,
//         parents: []
//     },
//     {
//         name: "Установки автоматической дуговой сварки, комплектуемые преобразователями",
//         ki: "0,35",
//         cos: "0,7",
//         id: 53,
//         parents: []
//     },
//     {
//         name: "Установки ручной дуговой сварки и резки металлов, комплектуемые преобразователями",
//         ki: "0,3",
//         cos: "0,7",
//         id: 54,
//         parents: []
//     },
//     {
//         name: "Стенды для испытания узлов и агрегатов без рекуперации электроэнергии в сеть",
//         ki: "0,4",
//         cos: "0,65",
//         id: 55,
//         parents: []
//     },
//     {
//         name: "Зарядные агрегаты аккумуляторов",
//         ki: "0,7",
//         cos: "0,65",
//         id: 56,
//         parents: []
//     },
//     {
//         name: "Лабораторное оборудование, дефектоскопы, испытательные машины и др.",
//         ki: "0,1",
//         cos: "0,5",
//         id: 57,
//         parents: []
//     },
//     {
//         name: "Оборудование вычислительных центров ЭВМ",
//         ki: "0,4",
//         cos: "0,6",
//         id: 58,
//         parents: []
//     },
//     {
//         name: "Электродвигатели хорошо загруженных, непрерывно работающих механизмов: вентиляторов, насосов, центрифуг и т.п.",
//         ki: "0,625",
//         cos: "0,8",
//         id: 59,
//         parents: []
//     },
//     {
//         name: "Электродвигатели индивидуального привода станков нормального режима работы: токарных, фрезерных, сверлильных, карусельных, строгальных, долбежных и т.п.",
//         ki: "0,13",
//         cos: "0,575",
//         id: 60,
//         parents: []
//     },
//     {
//         name: "Электродвигатели тяжелого режима работы: зубофрезерных, обдирочных, револьверных автоматов, штамповочных эксцентриковых прессов, станков-автоматов, агрегатных станков и т.п.",
//         ki: "0,185",
//         cos: "0,65",
//         id: 61,
//         parents: []
//     },
//     {
//         name: "Электродвигатели особо тяжелого режима работы: очистные и галтовочные барабаны, бегуны, шаровые мельницы, кривошипные прессы, ковочные машины, выбивные решетки и т.п.",
//         ki: "0,22",
//         cos: "0,65",
//         id: 62,
//         parents: []
//     },
//     {
//         name: "а) механо-обработки",
//         ki: "0,35",
//         cos: "0,625",
//         id: 63,
//         parents: []
//     },
//     {
//         name: "б) кузнечного производства",
//         ki: "0,525",
//         cos: "0,7",
//         id: 64,
//         parents: []
//     },
//     {
//         name: "в) прессово-штамповочного производства",
//         ki: "0,6",
//         cos: "0,7",
//         id: 65,
//         parents: []
//     },
//     {
//         name: "г) сварочного производства",
//         ki: "0,4",
//         cos: "0,6",
//         id: 66,
//         parents: []
//     },
//     {
//         name: "Электродвигатели повторно-кратковременного режима работы: кранов, тельферов, кран-балок, подъемников, вспомогательных двигателей, рольгангов, подъемных столов, редукторов для открывания фромуг и т.п. в механических, сборочных и им подобных цехах для ПВ = 25%",
//         ki: "0,06",
//         cos: "0,45",
//         id: 67,
//         parents: []
//     },
//     {
//         name: "Электродвигатели повторно-кратковременного режима работы: кранов, тельферов, кран-балок, подъемников, вспомогательных двигателей, рольгангов, подъемных столов, редукторов для открывания фромуг и т.п. в литейных, кузнечных и им подобных цехах для ПВ = 40%",
//         ki: "0,1",
//         cos: "0,5",
//         id: 68,
//         parents: []
//     },
//     {
//         name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., несблокированные",
//         ki: "0,4",
//         cos: "0,7",
//         id: 69,
//         parents: []
//     },
//     {
//         name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., сблокированные",
//         ki: "0,5",
//         cos: "0,75",
//         id: 70,
//         parents: []
//     },
//     {
//         name: "Лабораторные нагревательные приборы: автоклавы, сушильные шкафы и т.п.",
//         ki: "0,3",
//         cos: "0,95",
//         id: 71,
//         parents: []
//     },
//     {
//         name: "Электрические печи сопротивления, нагревательные аппараты, сушильные шкафы, камеры сушки, ванны и т.п. периодического действия",
//         ki: "0,5",
//         cos: "0,95",
//         id: 72,
//         parents: []
//     },
//     {
//         name: "Электродвигатели механизмов непрерывного транспорта: транспортеры, элеваторы, конвейеры и т.п., непрерывного действия",
//         ki: "0,7",
//         cos: "0,95",
//         id: 73,
//         parents: []
//     },
//     {
//         name: "Печи индукционные низкой частоты",
//         ki: "0,75",
//         cos: "0,85",
//         id: 74,
//         parents: []
//     },
//     {
//         name: "Печи индукционные высокой частоты с собственными двигатель-генераторами",
//         ki: "0,6",
//         cos: "0,7",
//         id: 75,
//         parents: []
//     },
//     {
//         name: "Печи индукционные высокой частоты с ламповыми генераторами",
//         ki: "0,75",
//         cos: "0,8",
//         id: 76,
//         parents: []
//     },
//     {
//         name: "Мотор-генераторы высокой частоты",
//         ki: "0,6",
//         cos: "0,75",
//         id: 77,
//         parents: []
//     },
//     {
//         name: "Генераторы высокой частоты одномашинные",
//         ki: "0,65",
//         cos: "0,8",
//         id: 78,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10 тс автоматическим регулированием электродов, для качественных сталей и для фасонного литья, с механизированной загрузкой",
//         ki: "0,7",
//         cos: "0,9",
//         id: 79,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью от 3 до 10 тс автоматическим регулированием электродов, для качественных сталей и для фасонного литья, но без механизированной загрузки",
//         ki: "0,55",
//         cos: "0,85",
//         id: 80,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью 0,5-1,5 т для фасонного литья с автоматическим регулированием электродов без механизированной загрузки",
//         ki: "0,5",
//         cos: "0,8",
//         id: 81,
//         parents: []
//     },
//     {
//         name: "Дуговые печи цветного металла (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов без механизированной загрузки",
//         ki: "0,5",
//         cos: "0,85",
//         id: 82,
//         parents: []
//     },
//     {
//         name: "Дуговые печи цветного металла (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов с механизированной загрузкой непрерывного действия",
//         ki: "0,6",
//         cos: "0,85",
//         id: 83,
//         parents: []
//     },
//     {
//         name: "Рудотермические печи с трехфазными трансформаторами 6; 7,5 и 9 мВА",
//         ki: "0,7",
//         cos: "0,9",
//         id: 84,
//         parents: []
//     },
//     {
//         name: "Нагревательные приборы и аппараты (печи сопротивления) в административно-бытовых и медико-санитарных службах",
//         ki: "0,5",
//         cos: "0,95",
//         id: 85,
//         parents: []
//     },
//     {
//         name: "Мелкие нагревательные приборы",
//         ki: "0,3",
//         cos: "0,95",
//         id: 86,
//         parents: []
//     },
//     {
//         name: "однопостовой",
//         ki: "0,2",
//         cos: "0,35",
//         id: 87,
//         parents: []
//     },
//     {
//         name: "многопостовой",
//         ki: "0,3",
//         cos: "0,4",
//         id: 88,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы автоматической и полуавтоматической сварки",
//         ki: "0,35",
//         cos: "0,5",
//         id: 89,
//         parents: []
//     },
//     {
//         name: "Автоматы и машины дуговой сварки",
//         ki: "0,4",
//         cos: "0,65",
//         id: 90,
//         parents: []
//     },
//     {
//         name: "Сварочные машины шовные",
//         ki: "0,25",
//         cos: "0,65",
//         id: 91,
//         parents: []
//     },
//     {
//         name: "Сварочные машины стыковые и точечные",
//         ki: "0,35",
//         cos: "0,6",
//         id: 92,
//         parents: []
//     },
//     {
//         name: "однопостовые",
//         ki: "0,3",
//         cos: "0,65",
//         id: 93,
//         parents: []
//     },
//     {
//         name: "многопостовые",
//         ki: "0,5",
//         cos: "0,75",
//         id: 94,
//         parents: []
//     },
//     {
//         name: "однопостовая",
//         ki: "0,35",
//         cos: "0,5",
//         id: 95,
//         parents: []
//     },
//     {
//         name: "многопостовая",
//         ki: "0,45",
//         cos: "0,5",
//         id: 96,
//         parents: []
//     },
//     {
//         name: "Нагреватели заклепок",
//         ki: "0,35",
//         cos: "0,55",
//         id: 97,
//         parents: []
//     },
//     {
//         name: "Контактный нагрев под штамповку",
//         ki: "0,5",
//         cos: "0,65",
//         id: 98,
//         parents: []
//     },
//     {
//         name: "Контактные машины",
//         ki: "0,6",
//         cos: "0,7",
//         id: 99,
//         parents: []
//     },
//     {
//         name: "Многошпиндельные автоматы для изготовления деталей из прутков",
//         ki: "0,2",
//         cos: "0,5",
//         id: 100,
//         parents: []
//     },
//     {
//         name: "Шлифовальные станки шарикоподшипниковых заводов",
//         ki: "0,3",
//         cos: "0,65",
//         id: 101,
//         parents: []
//     },
//     {
//         name: "Испытательные стенды мелкомоторные",
//         ki: "0,2",
//         cos: "0,65",
//         id: 102,
//         parents: []
//     },
//     {
//         name: "Испытательные стенды крупномоторные",
//         ki: "0,25",
//         cos: "0,6",
//         id: 103,
//         parents: []
//     },
//     {
//         name: "Мелкомоторные испытательные лабораторные установки и приборы",
//         ki: "0,1",
//         cos: "0,5",
//         id: 104,
//         parents: []
//     },
//     {
//         name: "Электронно-вычислительные машины и счетно-решающие устройства",
//         ki: "0,15",
//         cos: "0,6",
//         id: 105,
//         parents: []
//     },
//     {
//         name: "Устройства электропитания радиотрансляционных узлов, узлов связи и т.п.",
//         ki: "0,6",
//         cos: "0,75",
//         id: 106,
//         parents: []
//     },
//     {
//         name: "Преобразовательные агрегатных станций,автокар, аккумуляторов автомашин и т.п.",
//         ki: "0,5",
//         cos: "0,7",
//         id: 107,
//         parents: []
//     },
//     {
//         name: "Выпрямительные устройства гальваноцехов на полупроводниковых выпрямителях",
//         ki: "0,6",
//         cos: "0,8",
//         id: 108,
//         parents: []
//     },
//     {
//         name: "Электроосвещение",
//         ki: "0,85",
//         cos: "0,95",
//         id: 109,
//         parents: []
//     },
//     {
//         name: "Линия изготовления тары из полистирола",
//         ki: "0,6",
//         cos: "0,85",
//         id: 110,
//         parents: []
//     },
//     {
//         name: "Машины для сварки полимеров",
//         ki: "0,5",
//         cos: "1",
//         id: 111,
//         parents: []
//     },
//     {
//         name: "Краны-штабелеры",
//         ki: "0,1",
//         cos: "0,5",
//         id: 112,
//         parents: []
//     },
//     {
//         name: "Стеллажи механизированные",
//         ki: "0,4",
//         cos: "0,75",
//         id: 113,
//         parents: []
//     },
//     {
//         name: "Лифты грузовые",
//         ki: "0,2",
//         cos: "0,6",
//         id: 114,
//         parents: []
//     },
//     {
//         name: "Лифты пассажирские",
//         ki: "0,15",
//         cos: "0,55",
//         id: 115,
//         parents: []
//     },
//     {
//         name: "Приводы открывания ворот",
//         ki: "0,05",
//         cos: "0,75",
//         id: 116,
//         parents: []
//     },
//     {
//         name: "нагреватели",
//         ki: "0,5",
//         cos: "0,85",
//         id: 117,
//         parents: []
//     },
//     {
//         name: "вентиляторы",
//         ki: "0,7",
//         cos: "0,8",
//         id: 118,
//         parents: []
//     },
//     {
//         name: "Установки ультразвуковой очистки",
//         ki: "0,7",
//         cos: "0,7",
//         id: 119,
//         parents: []
//     },
//     {
//         name: "Установки моечные с подогревом",
//         ki: "0,6",
//         cos: "0,7",
//         id: 120,
//         parents: []
//     },
//     {
//         name: "Установки моечные полуавтомат",
//         ki: "0,7",
//         cos: "0,65",
//         id: 121,
//         parents: []
//     },
//     {
//         name: "Установки сушильные",
//         ki: "0,5",
//         cos: "0,85",
//         id: 122,
//         parents: []
//     },
//     {
//         name: "Камеры окрасочные механизир.",
//         ki: "0,7",
//         cos: "0,85",
//         id: 123,
//         parents: []
//     },
//     {
//         name: "Ванна",
//         ki: "0,6",
//         cos: "0,85",
//         id: 124,
//         parents: []
//     },
//     {
//         name: "Конвейеры окрасочные",
//         ki: "0,7",
//         cos: "0,83",
//         id: 125,
//         parents: []
//     },
//     {
//         name: "Выпрямитель",
//         ki: "0,6",
//         cos: "0,8",
//         id: 126,
//         parents: []
//     },
//     {
//         name: "Линии гальванопокрытий",
//         ki: "0,7",
//         cos: "0,8",
//         id: 127,
//         parents: []
//     },
//     {
//         name: "Модульные линии",
//         ki: "0,65",
//         cos: "0,8",
//         id: 128,
//         parents: []
//     },
//     {
//         name: "Линии меднения",
//         ki: "0,6",
//         cos: "0,9",
//         id: 129,
//         parents: []
//     },
//     {
//         name: "Линия подготовки поверхности",
//         ki: "0,65",
//         cos: "0,8",
//         id: 130,
//         parents: []
//     },
//     {
//         name: "Линия осветления сплава",
//         ki: "0,6",
//         cos: "0,85",
//         id: 131,
//         parents: []
//     },
//     {
//         name: "Линия отмывки плат",
//         ki: "0,7",
//         cos: "0,85",
//         id: 132,
//         parents: []
//     },
//     {
//         name: "Регенератор",
//         ki: "0,7",
//         cos: "0,8",
//         id: 133,
//         parents: []
//     },
//     {
//         name: "Устан. химического травления",
//         ki: "0,65",
//         cos: "0,85",
//         id: 134,
//         parents: []
//     },
//     {
//         name: "Установка лужения плат",
//         ki: "0,6",
//         cos: "0,85",
//         id: 135,
//         parents: []
//     },
//     {
//         name: "Модуль проявления",
//         ki: "0,65",
//         cos: "0,8",
//         id: 136,
//         parents: []
//     },
//     {
//         name: "Модуль окрашивания",
//         ki: "0,5",
//         cos: "0,65",
//         id: 137,
//         parents: []
//     },
//     {
//         name: "Модуль промывки",
//         ki: "0,7",
//         cos: "0,85",
//         id: 138,
//         parents: []
//     },
//     {
//         name: "Модуль химического дубления",
//         ki: "0,65",
//         cos: "0,8",
//         id: 139,
//         parents: []
//     },
//     {
//         name: "Полуавтомат трафарет печати",
//         ki: "0,5",
//         cos: "0,65",
//         id: 140,
//         parents: []
//     },
//     {
//         name: "Камера тепла и влаги. Насос",
//         ki: "0,7",
//         cos: "0,8",
//         id: 141,
//         parents: []
//     },
//     {
//         name: "Нагрев",
//         ki: "0,5",
//         cos: "0,85",
//         id: 142,
//         parents: []
//     },
//     {
//         name: "Выпрямитель",
//         ki: "0,7",
//         cos: "0,8",
//         id: 143,
//         parents: []
//     },
//     {
//         name: "Устройство автомат. загрузки",
//         ki: "0,55",
//         cos: "0,75",
//         id: 144,
//         parents: []
//     },
//     {
//         name: "Установка механич.зачистки",
//         ki: "0,12",
//         cos: "0,4",
//         id: 145,
//         parents: []
//     },
//     {
//         name: "Установка пригот. эмульсии",
//         ki: "0,7",
//         cos: "0,8",
//         id: 146,
//         parents: []
//     },
//     {
//         name: "Термобарокамера. Насос",
//         ki: "0,7",
//         cos: "0,8",
//         id: 147,
//         parents: []
//     },
//     {
//         name: "Нагрев",
//         ki: "0,5",
//         cos: "0,85",
//         id: 148,
//         parents: []
//     },
//     {
//         name: "Установка моеч. вибрац.",
//         ki: "0,7",
//         cos: "0,8",
//         id: 149,
//         parents: []
//     },
//     {
//         name: "Барабан галтовочный",
//         ki: "0,5",
//         cos: "0,65",
//         id: 150,
//         parents: []
//     },
//     {
//         name: "Ванны",
//         ki: "0,6",
//         cos: "0,8",
//         id: 151,
//         parents: []
//     },
//     {
//         name: "Смеситель эмульсии",
//         ki: "0,3",
//         cos: "0,85",
//         id: 152,
//         parents: []
//     },
//     {
//         name: "Фильтр",
//         ki: "0,35",
//         cos: "0,85",
//         id: 153,
//         parents: []
//     },
//     {
//         name: "Дистиллятор",
//         ki: "0,7",
//         cos: "0,9",
//         id: 154,
//         parents: []
//     },
//     {
//         name: "Линия транспорт. подвесок",
//         ki: "0,55",
//         cos: "0,75",
//         id: 155,
//         parents: []
//     },
//     {
//         name: "Ванны",
//         ki: "0,5",
//         cos: "0,8",
//         id: 156,
//         parents: []
//     },
//     {
//         name: "Модульные линии",
//         ki: "0,5",
//         cos: "0,8",
//         id: 157,
//         parents: []
//     },
//     {
//         name: "Линии гальванопокрытий",
//         ki: "0,5",
//         cos: "0,8",
//         id: 158,
//         parents: []
//     },
//     {
//         name: "Установка нанесения фоторезист",
//         ki: "0,6",
//         cos: "0,85",
//         id: 159,
//         parents: []
//     },
//     {
//         name: "Установка экспонирования",
//         ki: "0,6",
//         cos: "0,85",
//         id: 160,
//         parents: []
//     },
//     {
//         name: "Установка нанесения сплава",
//         ki: "0,6",
//         cos: "0,85",
//         id: 161,
//         parents: []
//     },
//     {
//         name: "Установка снятия фоторезиста",
//         ki: "0,65",
//         cos: "0,8",
//         id: 162,
//         parents: []
//     },
//     {
//         name: "Установка проявления",
//         ki: "0,65",
//         cos: "0,8",
//         id: 163,
//         parents: []
//     },
//     {
//         name: "Установка травления печ. плат",
//         ki: "0,65",
//         cos: "0,8",
//         id: 164,
//         parents: []
//     },
//     {
//         name: "Установка оплавления",
//         ki: "0,6",
//         cos: "0,85",
//         id: 165,
//         parents: []
//     },
//     {
//         name: "Установка струйная",
//         ki: "0,6",
//         cos: "0,85",
//         id: 166,
//         parents: []
//     },
//     {
//         name: "Установка снятия краски",
//         ki: "0,65",
//         cos: "0,85",
//         id: 167,
//         parents: []
//     },
//     {
//         name: "Установка узг. промывки плат",
//         ki: "0,7",
//         cos: "0,85",
//         id: 168,
//         parents: []
//     },
//     {
//         name: "Линия химической металлизации",
//         ki: "0,6",
//         cos: "0,8",
//         id: 169,
//         parents: []
//     },
//     {
//         name: "Установка печати",
//         ki: "0,5",
//         cos: "0,7",
//         id: 170,
//         parents: []
//     },
//     {
//         name: "Выпрямители для зарядной",
//         ki: "0,5",
//         cos: "0,8",
//         id: 171,
//         parents: []
//     },
//     {
//         name: "Аппарат A M",
//         ki: "0,7",
//         cos: "0,85",
//         id: 172,
//         parents: []
//     },
//     {
//         name: "Приводы",
//         ki: "0,7",
//         cos: "0,85",
//         id: 173,
//         parents: []
//     },
//     {
//         name: "Оборудование по подготовке проводов, кабелей",
//         ki: "0,7",
//         cos: "0,85",
//         id: 174,
//         parents: []
//     },
//     {
//         name: "Оборудование по подготовке микросхем",
//         ki: "0,7",
//         cos: "0,85",
//         id: 175,
//         parents: []
//     },
//     {
//         name: "Стенды",
//         ki: "0,7",
//         cos: "0,85",
//         id: 176,
//         parents: []
//     },
//     {
//         name: "Термобарокамера",
//         ki: "0,7",
//         cos: "0,85",
//         id: 177,
//         parents: []
//     },
//     {
//         name: "Контрольно-испытательное оборудование",
//         ki: "0,7",
//         cos: "0,85",
//         id: 178,
//         parents: []
//     },
//     {
//         name: "Оборудование автоматизированной сборки плат",
//         ki: "0,7",
//         cos: "0,85",
//         id: 179,
//         parents: []
//     },
//     {
//         name: "Оборудование автоматизированной пайки плат",
//         ki: "0,7",
//         cos: "0,85",
//         id: 180,
//         parents: []
//     },
//     {
//         name: "Оборудование намоточное",
//         ki: "0,7",
//         cos: "0,85",
//         id: 181,
//         parents: []
//     },
//     {
//         name: "Оборудование пропитки и заливки",
//         ki: "0,7",
//         cos: "0,85",
//         id: 182,
//         parents: []
//     },
//     {
//         name: "Прессы",
//         ki: "0,17",
//         cos: "0,65",
//         id: 183,
//         parents: []
//     },
//     {
//         name: "Станки",
//         ki: "0,12",
//         cos: "0,4",
//         id: 184,
//         parents: []
//     },
//     {
//         name: "Приборы",
//         ki: "0,3",
//         cos: "0,8",
//         id: 185,
//         parents: []
//     },
//     {
//         name: "Стенды",
//         ki: "0,675",
//         cos: "0,85",
//         id: 186,
//         parents: []
//     },
//     {
//         name: "Машина для статических испытаний",
//         ki: "0,7",
//         cos: "0,85",
//         id: 187,
//         parents: []
//     },
//     {
//         name: "Электроинструмент",
//         ki: "0,06",
//         cos: "0,5",
//         id: 188,
//         parents: []
//     },
//     {
//         name: "Лабораторные приборы",
//         ki: "0,5",
//         cos: "0,9",
//         id: 189,
//         parents: []
//     },
//     {
//         name: "Стол с подсветом",
//         ki: "0,6",
//         cos: "1",
//         id: 190,
//         parents: []
//     },
//     {
//         name: "Щиты автоматики",
//         ki: "1",
//         cos: "1",
//         id: 191,
//         parents: []
//     },
//     {
//         name: "Электроосвещение",
//         ki: "0,95",
//         cos: "0,95",
//         id: 192,
//         parents: []
//     },
//     {
//         name: "Вычислительные комплексы при числе 3 и более",
//         ki: "0,85",
//         cos: "0,8",
//         id: 193,
//         parents: []
//     },
//     {
//         name: "Электропогружные насосы мощностью до 35 кВт",
//         ki: "0,95",
//         cos: "0,7",
//         id: 194,
//         parents: []
//     },
//     {
//         name: "Электропогружные насосы мощностью свыше 35 кВт",
//         ki: "0,8",
//         cos: "0,75",
//         id: 195,
//         parents: []
//     },
//     {
//         name: "Станки-качалки высоко- и среднебитные* скважин (свыше 10 т/сутки) нефти",
//         ki: "0,35",
//         cos: "0,4",
//         id: 196,
//         parents: []
//     },
//     {
//         name: "Станки-качалки низкодебитных скважин (менее 10 т/сутки нефти)",
//         ki: "0,25",
//         cos: "0,35",
//         id: 197,
//         parents: []
//     },
//     {
//         name: "Насосы перекачки нефти со сборных пунктов на товарные парки",
//         ki: "0,4",
//         cos: "0,85",
//         id: 198,
//         parents: []
//     },
//     {
//         name: "Насосы перекачки нефти с товарных парков потребителю",
//         ki: "0,7",
//         cos: "0,8",
//         id: 199,
//         parents: []
//     },
//     {
//         name: "Насосы подачи воды для нужд законтурного обводнения",
//         ki: "0,7",
//         cos: "0,85",
//         id: 200,
//         parents: []
//     },
//     {
//         name: "Насосы подачи воды в пласт",
//         ki: "0,75",
//         cos: "0,9",
//         id: 201,
//         parents: []
//     },
//     {
//         name: "Прочее электрооборудование 0,4 кВ",
//         ki: "0,7",
//         cos: "0,7",
//         id: 202,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,88",
//         cos: "0,9",
//         id: 203,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,78",
//         cos: "0,71",
//         id: 204,
//         parents: []
//     },
//     {
//         name: "Газовые компрессоры",
//         ki: "0,61",
//         cos: "0,94",
//         id: 205,
//         parents: []
//     },
//     {
//         name: "Воздуходувки, газодувки",
//         ki: "0,7",
//         cos: "0,81",
//         id: 206,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,78",
//         cos: "0,89",
//         id: 207,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,5",
//         cos: "0,72",
//         id: 208,
//         parents: []
//     },
//     {
//         name: "Газовые компрессоры",
//         ki: "0,47",
//         cos: "0,8",
//         id: 209,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,85",
//         cos: "0,9",
//         id: 210,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,58",
//         cos: "0,67",
//         id: 211,
//         parents: []
//     },
//     {
//         name: "Газовые компрессоры",
//         ki: "0,73",
//         cos: "0,93",
//         id: 212,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,81",
//         cos: "0,85",
//         id: 213,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,66",
//         cos: "0,69",
//         id: 214,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,62",
//         cos: "0,87",
//         id: 215,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,63",
//         cos: "0,7",
//         id: 216,
//         parents: []
//     },
//     {
//         name: "Барабаны смесительные и сушильные скрубберы",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 217,
//         parents: []
//     },
//     {
//         name: "Электромагнитные сепараторы индивидуальные",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 218,
//         parents: []
//     },
//     {
//         name: "Двигатель-генераторы",
//         ki: "0,7",
//         cos: "по сх.замещения",
//         id: 219,
//         parents: []
//     },
//     {
//         name: "Электровибрационные машины",
//         ki: "0,8",
//         cos: "по сх.замещения",
//         id: 220,
//         parents: []
//     },
//     {
//         name: "Кузнечные машины (механизмы кузнечных цехов)",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 221,
//         parents: []
//     },
//     {
//         name: "Литейные машины, очистные и кантовочные барабаны, бегуны, шаровые мельницы и т.п.",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 222,
//         parents: []
//     },
//     {
//         name: "Заварочные откачные автомашины",
//         ki: "0,5",
//         cos: "по сх.замещения",
//         id: 223,
//         parents: []
//     },
//     {
//         name: "Откачные посты (стенды)",
//         ki: "0,45",
//         cos: "по сх.замещения",
//         id: 224,
//         parents: []
//     },
//     {
//         name: "Автоматы для спекания штабиков (вольфрама, молибдена, тантала и т.п.)",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 225,
//         parents: []
//     },
//     {
//         name: "Сварочные автоматы",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 226,
//         parents: []
//     },
//     {
//         name: "Высокочастотный генератор",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 227,
//         parents: []
//     },
//     {
//         name: "Вакуум-пропиточные установки",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 228,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор сетевой мощностью до 25 кВт",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 229,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор сетевой мощностью от 25 до 100 кВт",
//         ki: "0,575",
//         cos: "по сх.замещения",
//         id: 230,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор сетевой мощностью свыше 100 кВт",
//         ki: "0,275",
//         cos: "по сх.замещения",
//         id: 231,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор для индивидуального питания стендов до 25 кВт",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 232,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор для индивидуального питания стендов от 25 до 100 кВт",
//         ki: "0,225",
//         cos: "по сх.замещения",
//         id: 233,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор для индивидуального питания стендов свыше 100 кВт",
//         ki: "0,225",
//         cos: "по сх.замещения",
//         id: 234,
//         parents: []
//     },
//     {
//         name: "Индукционные регуляторы сетевые",
//         ki: "0,55",
//         cos: "по сх.замещения",
//         id: 235,
//         parents: []
//     },
//     {
//         name: "Индукционные регуляторы индивидуальные",
//         ki: "0,2",
//         cos: "по сх.замещения",
//         id: 236,
//         parents: []
//     },
//     {
//         name: "Выпрямители полупроводниковые",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 237,
//         parents: []
//     },
//     {
//         name: "Автотрансформаторы индивидуальные",
//         ki: "0,2",
//         cos: "по сх.замещения",
//         id: 238,
//         parents: []
//     },
//     {
//         name: "Автотрансформаторы индивидуальные сетевые",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 239,
//         parents: []
//     },
//     {
//         name: "Трансформаторы индивидуальные",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 240,
//         parents: []
//     },
//     {
//         name: "Трансформаторы индивидуальные сетевые",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 241,
//         parents: []
//     },
//     {
//         name: "Тренировочные стенды",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 242,
//         parents: []
//     },
//     {
//         name: "Установка для испытания на пробой",
//         ki: "0,1",
//         cos: "по сх.замещения",
//         id: 243,
//         parents: []
//     },
//     {
//         name: "Лабораторное оборудование",
//         ki: "0,25",
//         cos: "по сх.замещения",
//         id: 244,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки мелкосерийного производства с нормальным режимом работы: мелкие токарные, строгальные, долбежные, фрезерные, сверлильные карусельные, точильные и т.п.",
//         ki: "0,14",
//         cos: "по сх.замещения",
//         id: 245,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки при крупносерийном производстве с нормальным режимом работы",
//         ki: "0,17",
//         cos: "по сх.замещения",
//         id: 246,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки мелкосерийного производства с особо тяжелым режимом работы, а также привода молотков, ковочных машин, волочильных станков и др.",
//         ki: "0,22",
//         cos: "по сх.замещения",
//         id: 247,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для ручной дуговой электросварки",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 248,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для автоматической дуговой сварки",
//         ki: "0,5",
//         cos: "по сх.замещения",
//         id: 249,
//         parents: []
//     },
//     {
//         name: "Однопостовые сварочные двигатель-генераторы",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 250,
//         parents: []
//     },
//     {
//         name: "Сварочные машины (нагреватели заклепок)",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 251,
//         parents: []
//     },
//     {
//         name: "Сушильные камеры периодического действия",
//         ki: "0,55",
//         cos: "по сх.замещения",
//         id: 252,
//         parents: []
//     },
//     {
//         name: "Стекольные печи для плавки кварцевого стекла",
//         ki: "0,75",
//         cos: "по сх.замещения",
//         id: 253,
//         parents: []
//     },
//     {
//         name: "Высокочастотные печи для вытяжки кварцевых трубок",
//         ki: "0,8",
//         cos: "по сх.замещения",
//         id: 254,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с периодической загрузкой",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 255,
//         parents: []
//     },
//     {
//         name: "Нагревательные аппараты",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 256,
//         parents: []
//     },
//     {
//         name: "Сушильные шкафы",
//         ki: "0,75",
//         cos: "по сх.замещения",
//         id: 257,
//         parents: []
//     },
//     {
//         name: "Элеваторы ковшовые, вертикальные и наклонные, шнеки, конвейеры и т.п. несблокированные",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 258,
//         parents: []
//     },
//     {
//         name: "Элеваторы ковшовые, вертикальные и наклонные, шнеки, конвейеры и т.п. сблокированные",
//         ki: "0,55",
//         cos: "по сх.замещения",
//         id: 259,
//         parents: []
//     },
//     {
//         name: "Питатели пластинчатые, барабанные, дисковые и т.п.",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 260,
//         parents: []
//     },
//     {
//         name: "Конвейеры легкие мощностью до 10 кВт",
//         ki: "0,45",
//         cos: "по сх.замещения",
//         id: 261,
//         parents: []
//     },
//     {
//         name: "Конвейеры легкие мощностью свыше 10 кВт",
//         ki: "",
//         cos: "по сх.замещения",
//         id: 262,
//         parents: []
//     },
//     {
//         name: "Транспортеры ленточные",
//         ki: "0,575",
//         cos: "по сх.замещения",
//         id: 263,
//         parents: []
//     },
//     {
//         name: "Транспортеры винтовые",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 264,
//         parents: []
//     },
//     {
//         name: "Дробилки шнековые и конусные для крупного дробления",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 265,
//         parents: []
//     },
//     {
//         name: "Дробилки шнековые и конусные среднего дробления",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 266,
//         parents: []
//     },
//     {
//         name: "Дробилки конусные для мелкого дробления, валковые и молотковые мощностью до 100 кВт",
//         ki: "0,775",
//         cos: "по сх.замещения",
//         id: 267,
//         parents: []
//     },
//     {
//         name: "Дробилки конусные для мелкого дробления, валковые и молотковые мощностью свыше 100 кВт",
//         ki: "0,8",
//         cos: "по сх.замещения",
//         id: 268,
//         parents: []
//     },
//     {
//         name: "Грохоты",
//         ki: "0,55",
//         cos: "по сх.замещения",
//         id: 269,
//         parents: []
//     },
//     {
//         name: "Мельницы шаровые",
//         ki: "0,825",
//         cos: "по сх.замещения",
//         id: 270,
//         parents: []
//     },
//     {
//         name: "Грейферные краны",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 271,
//         parents: []
//     },
//     {
//         name: "Магнитные краны",
//         ki: "0,5",
//         cos: "по сх.замещения",
//         id: 272,
//         parents: []
//     },
//     {
//         name: "Штабеллеры",
//         ki: "0,16",
//         cos: "по сх.замещения",
//         id: 273,
//         parents: []
//     },
//     {
//         name: "Скиповый подъемник",
//         ki: "0,05",
//         cos: "по сх.замещения",
//         id: 274,
//         parents: []
//     },
//     {
//         name: "Электротележки",
//         ki: "0,1",
//         cos: "по сх.замещения",
//         id: 275,
//         parents: []
//     },
//     {
//         name: "Вентиляторы санитарно-гигиенической вентиляции",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 276,
//         parents: []
//     },
//     {
//         name: "Вентиляторы производственные",
//         ki: "0,775",
//         cos: "по сх.замещения",
//         id: 277,
//         parents: []
//     },
//     {
//         name: "Вентиляторы к дробилкам",
//         ki: "0,425",
//         cos: "по сх.замещения",
//         id: 278,
//         parents: []
//     },
//     {
//         name: "Насосы водяные",
//         ki: "0,75",
//         cos: "по сх.замещения",
//         id: 279,
//         parents: []
//     },
//     {
//         name: "Насосы песковые",
//         ki: "0,7",
//         cos: "по сх.замещения",
//         id: 280,
//         parents: []
//     },
//     {
//         name: "Вакуум насосы",
//         ki: "0,9",
//         cos: "по сх.замещения",
//         id: 281,
//         parents: []
//     },
//     {
//         name: "Насосы автоматизированных артскважин",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 282,
//         parents: []
//     },
//     {
//         name: "Дымососы",
//         ki: "0,9",
//         cos: "по сх.замещения",
//         id: 283,
//         parents: []
//     },
//     {
//         name: "Газодувки",
//         ki: "0,8",
//         cos: "по сх.замещения",
//         id: 284,
//         parents: []
//     },
//     {
//         name: "Воздуходувки",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 285,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 286,
//         parents: []
//     },
//     {
//         name: "Транспортеры",
//         ki: "0,5",
//         cos: "0,525",
//         id: 287,
//         parents: []
//     },
//     {
//         name: "Транспортеры катучие",
//         ki: "0,3",
//         cos: "0,75",
//         id: 288,
//         parents: []
//     },
//     {
//         name: "Питатели пластинчатые и ленточные",
//         ki: "0,45",
//         cos: "0,75",
//         id: 289,
//         parents: []
//     },
//     {
//         name: "Дробилки молотковые",
//         ki: "0,8",
//         cos: "0,8",
//         id: 290,
//         parents: []
//     },
//     {
//         name: "Дозировочные столы",
//         ki: "0,25",
//         cos: "0,5",
//         id: 291,
//         parents: []
//     },
//     {
//         name: "Штабелеры",
//         ki: "0,16",
//         cos: "0,675",
//         id: 292,
//         parents: []
//     },
//     {
//         name: "Углеперегружатели",
//         ki: "0,14",
//         cos: "0,5",
//         id: 293,
//         parents: []
//     },
//     {
//         name: "Коксовыталкиватели",
//         ki: "0,1",
//         cos: "0,75",
//         id: 294,
//         parents: []
//     },
//     {
//         name: "Загрузочные вагоны",
//         ki: "0,3",
//         cos: "0,6",
//         id: 295,
//         parents: []
//     },
//     {
//         name: "Двересъемные машины",
//         ki: "0,25",
//         cos: "0,7",
//         id: 296,
//         parents: []
//     },
//     {
//         name: "Электровозы сушильных машин вагонов",
//         ki: "0,16",
//         cos: "0,75",
//         id: 297,
//         parents: []
//     },
//     {
//         name: "Скиповые подъемники",
//         ki: "0,05",
//         cos: "0,5",
//         id: 298,
//         parents: []
//     },
//     {
//         name: "Кабестаны",
//         ki: "0,5",
//         cos: "0,7",
//         id: 299,
//         parents: []
//     },
//     {
//         name: "Вагоноопрокидыватели",
//         ki: "0,4",
//         cos: "0,55",
//         id: 300,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,825",
//         cos: "0,785",
//         id: 301,
//         parents: []
//     },
//     {
//         name: "Вентиляторы газовых горелок",
//         ki: "0,65",
//         cos: "0,85",
//         id: 302,
//         parents: []
//     },
//     {
//         name: "Вращающиеся распределители",
//         ki: "0,03",
//         cos: "0,7",
//         id: 303,
//         parents: []
//     },
//     {
//         name: "Грохоты кокса и затворы",
//         ki: "0,12",
//         cos: "0,5",
//         id: 304,
//         parents: []
//     },
//     {
//         name: "Разливочные машины",
//         ki: "0,3",
//         cos: "0,6",
//         id: 305,
//         parents: []
//     },
//     {
//         name: "Бегуны",
//         ki: "0,7",
//         cos: "0,65",
//         id: 306,
//         parents: []
//     },
//     {
//         name: "Воздухонагреватели",
//         ki: "0,5",
//         cos: "0,82",
//         id: 307,
//         parents: []
//     },
//     {
//         name: "Газоочистка",
//         ki: "0,7",
//         cos: "0,7",
//         id: 308,
//         parents: []
//     },
//     {
//         name: "Краны рудного двора",
//         ki: "0,35",
//         cos: "0,7",
//         id: 309,
//         parents: []
//     },
//     {
//         name: "Грейферные краны",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 310,
//         parents: []
//     },
//     {
//         name: "Насосы питательные мартеновского цеха",
//         ki: "0,9",
//         cos: "0,9",
//         id: 311,
//         parents: []
//     },
//     {
//         name: "Дымососы мартеновского цеха",
//         ki: "0,9",
//         cos: "0,9",
//         id: 312,
//         parents: []
//     },
//     {
//         name: "Печные заслонки мартеновских печей",
//         ki: "0,25",
//         cos: "0,6",
//         id: 313,
//         parents: []
//     },
//     {
//         name: "Вентиляторы принудительного дутья",
//         ki: "0,6",
//         cos: "0,75",
//         id: 314,
//         parents: []
//     },
//     {
//         name: "Магнитные краны",
//         ki: "0,5",
//         cos: "по сх.замещения",
//         id: 315,
//         parents: []
//     },
//     {
//         name: "Краны разливочные мартеновского цеха",
//         ki: "0,22",
//         cos: "по сх.замещения",
//         id: 316,
//         parents: []
//     },
//     {
//         name: "Краны заливочного мартеновского цеха",
//         ki: "0,2",
//         cos: "0,6",
//         id: 317,
//         parents: []
//     },
//     {
//         name: "Завалочные машины",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 318,
//         parents: []
//     },
//     {
//         name: "Краны двора изложниц",
//         ki: "0,4",
//         cos: "0,6",
//         id: 319,
//         parents: []
//     },
//     {
//         name: "Насосы высокого давления охлаждения форм",
//         ki: "0,9",
//         cos: "0,9",
//         id: 320,
//         parents: []
//     },
//     {
//         name: "Преобразователи привода наклона конвертора",
//         ki: "0,25",
//         cos: "по сх.замещения",
//         id: 321,
//         parents: []
//     },
//     {
//         name: "Сталевозные и шлаковозные тележки",
//         ki: "0,1",
//         cos: "по сх.замещения",
//         id: 322,
//         parents: []
//     },
//     {
//         name: "Вентиляторы",
//         ki: "0,65",
//         cos: "0,75",
//         id: 323,
//         parents: []
//     },
//     {
//         name: "Разливочные краны",
//         ki: "0,22",
//         cos: "0,6",
//         id: 324,
//         parents: []
//     },
//     {
//         name: "Заливочные краны",
//         ki: "0,2",
//         cos: "0,6",
//         id: 325,
//         parents: []
//     },
//     {
//         name: "Механизмы систем подачи сыпучих",
//         ki: "0,375",
//         cos: "0,675",
//         id: 326,
//         parents: []
//     },
//     {
//         name: "а) односкоростные с синхронными двигателями",
//         ki: "0,9",
//         cos: "0,9",
//         id: 327,
//         parents: []
//     },
//     {
//         name: "б) двухскоростные с асинхронными двигателями",
//         ki: "0,6",
//         cos: "0,7",
//         id: 328,
//         parents: []
//     },
//     {
//         name: "Рольганги раскатные",
//         ki: "0,2",
//         cos: "0,6",
//         id: 329,
//         parents: []
//     },
//     {
//         name: "Рольганги индивидуальные",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 330,
//         parents: []
//     },
//     {
//         name: "Рольганги у ножниц",
//         ki: "0,22",
//         cos: "по сх.замещения",
//         id: 331,
//         parents: []
//     },
//     {
//         name: "Рольганги приемные",
//         ki: "0,34",
//         cos: "по сх.замещения",
//         id: 332,
//         parents: []
//     },
//     {
//         name: "Рольганги у весов",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 333,
//         parents: []
//     },
//     {
//         name: "Рольганги центральные",
//         ki: "0,1",
//         cos: "0,88",
//         id: 334,
//         parents: []
//     },
//     {
//         name: "Рольганги станинные",
//         ki: "0,2",
//         cos: "по сх.замещения",
//         id: 335,
//         parents: []
//     },
//     {
//         name: "Рольганги отводящие",
//         ki: "0,25",
//         cos: "по сх.замещения",
//         id: 336,
//         parents: []
//     },
//     {
//         name: "Рольганги стана 250-1",
//         ki: "0,1",
//         cos: "по сх.замещения",
//         id: 337,
//         parents: []
//     },
//     {
//         name: "Рольганги стана 140",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 338,
//         parents: []
//     },
//     {
//         name: "Рольганги в среднем",
//         ki: "0,17",
//         cos: "по сх.замещения",
//         id: 339,
//         parents: []
//     },
//     {
//         name: "Роликоправильные машины",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 340,
//         parents: []
//     },
//     {
//         name: "Преобразователи частоты рольгангов",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 341,
//         parents: []
//     },
//     {
//         name: "Кантователи",
//         ki: "0,2",
//         cos: "0,7",
//         id: 342,
//         parents: []
//     },
//     {
//         name: "Кантователи основной и дополнительной клети стана 300",
//         ki: "0,35",
//         cos: "по сх.замещения",
//         id: 343,
//         parents: []
//     },
//     {
//         name: "Манипуляторы, нажимные устройства",
//         ki: "0,2",
//         cos: "по сх.замещения",
//         id: 344,
//         parents: []
//     },
//     {
//         name: "Сталкиватели",
//         ki: "0,12",
//         cos: "по сх.замещения",
//         id: 345,
//         parents: []
//     },
//     {
//         name: "Толкатели слябов",
//         ki: "0,32",
//         cos: "по сх.замещения",
//         id: 346,
//         parents: []
//     },
//     {
//         name: "Штабелирующие столы",
//         ki: "0,1",
//         cos: "0,8",
//         id: 347,
//         parents: []
//     },
//     {
//         name: "Вентиляторы машинных залов",
//         ki: "0,65",
//         cos: "0,8",
//         id: 348,
//         parents: []
//     },
//     {
//         name: "Вентиляторы прокатных станов",
//         ki: "0,725",
//         cos: "0,825",
//         id: 349,
//         parents: []
//     },
//     {
//         name: "Краны отделения разделения слитков",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 350,
//         parents: []
//     },
//     {
//         name: "Клещевые (колодцевые) краны прокатного цеха",
//         ki: "0,55",
//         cos: "по сх.замещения",
//         id: 351,
//         parents: []
//     },
//     {
//         name: "Транспортеры обрезков слябов",
//         ki: "0,16",
//         cos: "по сх.замещения",
//         id: 352,
//         parents: []
//     },
//     {
//         name: "Транспортные краны готовой продукции",
//         ki: "0,45",
//         cos: "0,6",
//         id: 353,
//         parents: []
//     },
//     {
//         name: "Конвейеры",
//         ki: "0,35",
//         cos: "0,7",
//         id: 354,
//         parents: []
//     },
//     {
//         name: "Краны отделения заготовок",
//         ki: "0,45",
//         cos: "0,6",
//         id: 355,
//         parents: []
//     },
//     {
//         name: "Шарнир-краны",
//         ki: "0,3",
//         cos: "0,6",
//         id: 356,
//         parents: []
//     },
//     {
//         name: "Слитковозы",
//         ki: "0,2",
//         cos: "по сх.замещения",
//         id: 357,
//         parents: []
//     },
//     {
//         name: "Трансферкары, привод",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 358,
//         parents: []
//     },
//     {
//         name: "Подъемные столы, толкатели",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 359,
//         parents: []
//     },
//     {
//         name: "Двигатели крышек",
//         ki: "0,1",
//         cos: "0,65",
//         id: 360,
//         parents: []
//     },
//     {
//         name: "Ножницы холодной резки",
//         ki: "0,45",
//         cos: "0,65",
//         id: 361,
//         parents: []
//     },
//     {
//         name: "Пилы и ножницы горячей резки",
//         ki: "0,15",
//         cos: "0,9",
//         id: 362,
//         parents: []
//     },
//     {
//         name: "Ножницы блюминга",
//         ki: "0,25",
//         cos: "0,5",
//         id: 363,
//         parents: []
//     },
//     {
//         name: "Транспортеры ножниц",
//         ki: "0,25",
//         cos: "0,9",
//         id: 364,
//         parents: []
//     },
//     {
//         name: "Ножницы района холодильника",
//         ki: "0,3",
//         cos: "0,5",
//         id: 365,
//         parents: []
//     },
//     {
//         name: "Вращение и перемещение пил горячей резки",
//         ki: "0,5",
//         cos: "по сх.замещения",
//         id: 366,
//         parents: []
//     },
//     {
//         name: "Роликоправильные машины",
//         ki: "0,15",
//         cos: "по сх.замещения",
//         id: 367,
//         parents: []
//     },
//     {
//         name: "Направляющие линейки и нажимные механизмы чистовых клетей",
//         ki: "0,01",
//         cos: "0,75",
//         id: 368,
//         parents: []
//     },
//     {
//         name: "Тянущие ролики моталок",
//         ki: "0,3",
//         cos: "по сх.замещения",
//         id: 369,
//         parents: []
//     },
//     {
//         name: "Гильотинные ножницы",
//         ki: "0,13",
//         cos: "0,5",
//         id: 370,
//         parents: []
//     },
//     {
//         name: "Шлеппера",
//         ki: "0,25",
//         cos: "0,7",
//         id: 371,
//         parents: []
//     },
//     {
//         name: "Крышки нагревательных колодцев, шиберы, перекидка клапанов, заслонки нагревательных печей, тарельчатые клапаны",
//         ki: "0,1",
//         cos: "0,6",
//         id: 372,
//         parents: []
//     },
//     {
//         name: "Механизмы качания кристаллизатора",
//         ki: "0,6",
//         cos: "0,8",
//         id: 373,
//         parents: []
//     },
//     {
//         name: "Тянущая клеть",
//         ki: "0,7",
//         cos: "0,8",
//         id: 374,
//         parents: []
//     },
//     {
//         name: "Механизмы газовой резки",
//         ki: "0,5",
//         cos: "0,7",
//         id: 375,
//         parents: []
//     },
//     {
//         name: "Механизмы уборки отрезанных слитков",
//         ki: "0,5",
//         cos: "0,6",
//         id: 376,
//         parents: []
//     },
//     {
//         name: "Технологические вентиляторы",
//         ki: "0,8",
//         cos: "0,8",
//         id: 377,
//         parents: []
//     },
//     {
//         name: "Насосы насосно-аккумуляторной станции",
//         ki: "0,6",
//         cos: "0,8",
//         id: 378,
//         parents: []
//     },
//     {
//         name: "Транспортеры, элеваторы, питатели",
//         ki: "0,475",
//         cos: "0,7",
//         id: 379,
//         parents: []
//     },
//     {
//         name: "Задувки",
//         ki: "0,8",
//         cos: "0,9",
//         id: 380,
//         parents: []
//     },
//     {
//         name: "Насосы, дымососы",
//         ki: "0,675",
//         cos: "0,85",
//         id: 381,
//         parents: []
//     },
//     {
//         name: "Краны",
//         ki: "0,175",
//         cos: "0,6",
//         id: 382,
//         parents: []
//     },
//     {
//         name: "Механизмы открывания фрамуг",
//         ki: "0,02",
//         cos: "0,5",
//         id: 383,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы печей",
//         ki: "0,12",
//         cos: "0,6",
//         id: 384,
//         parents: []
//     },
//     {
//         name: "Очистные барабаны",
//         ki: "0,25",
//         cos: "0,65",
//         id: 385,
//         parents: []
//     },
//     {
//         name: "Дробилки,грохоты",
//         ki: "0,7",
//         cos: "0,8",
//         id: 386,
//         parents: []
//     },
//     {
//         name: "Дробилки молотковые",
//         ki: "0,8",
//         cos: "0,85",
//         id: 387,
//         parents: []
//     },
//     {
//         name: "Дробилки молотковые",
//         ki: "0,625",
//         cos: "0,775",
//         id: 388,
//         parents: []
//     },
//     {
//         name: "Мельницы шаровые",
//         ki: "0,8",
//         cos: "0,8",
//         id: 389,
//         parents: []
//     },
//     {
//         name: "Мельницы стержневые",
//         ki: "0,7",
//         cos: "0,75",
//         id: 390,
//         parents: []
//     },
//     {
//         name: "Грохоты",
//         ki: "0,55",
//         cos: "0,65",
//         id: 391,
//         parents: []
//     },
//     {
//         name: "Конвейеры до 10 кВт",
//         ki: "0,45",
//         cos: "0,75",
//         id: 392,
//         parents: []
//     },
//     {
//         name: "Конвейеры выше 10 кВт",
//         ki: "0,65",
//         cos: "0,725",
//         id: 393,
//         parents: []
//     },
//     {
//         name: "Питатели пластинчатые, тарельчатые, барабанные, дисковые",
//         ki: "0,35",
//         cos: "0,55",
//         id: 394,
//         parents: []
//     },
//     {
//         name: "Элеваторы, шнеки",
//         ki: "0,6",
//         cos: "0,7",
//         id: 395,
//         parents: []
//     },
//     {
//         name: "Механизмы вращающихся печей",
//         ki: "0,6",
//         cos: "0,7",
//         id: 396,
//         parents: []
//     },
//     {
//         name: "Дымососы печей",
//         ki: "0,7",
//         cos: "0,8",
//         id: 397,
//         parents: []
//     },
//     {
//         name: "Краны, электролафеты",
//         ki: "0,15",
//         cos: "0,65",
//         id: 398,
//         parents: []
//     },
//     {
//         name: "Толкатели,туннельных печей",
//         ki: "0,2",
//         cos: "0,5",
//         id: 399,
//         parents: []
//     },
//     {
//         name: "Прессы гидравлические и функциональные",
//         ki: "0,625",
//         cos: "0,775",
//         id: 400,
//         parents: []
//     },
//     {
//         name: "Насосно-аккумуляторные станции",
//         ki: "0,7",
//         cos: "0,8",
//         id: 401,
//         parents: []
//     },
//     {
//         name: "Электрофильтры",
//         ki: "0,5",
//         cos: "0,7",
//         id: 402,
//         parents: []
//     },
//     {
//         name: "Механизмы пылеуборки",
//         ki: "0,46",
//         cos: "0,85",
//         id: 403,
//         parents: []
//     },
//     {
//         name: "Сушильные барабаны",
//         ki: "0,6",
//         cos: "0,7",
//         id: 404,
//         parents: []
//     },
//     {
//         name: "Магнитные сепараторы",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 405,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с непрерывной загрузкой",
//         ki: "0,8",
//         cos: "0,965",
//         id: 406,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с периодической загрузкой",
//         ki: "0,6",
//         cos: "0,965",
//         id: 407,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления с автоматической загрузкой изделий, сушильные шкафы, нагревательные приборы",
//         ki: "0,775",
//         cos: "0,965",
//         id: 408,
//         parents: []
//     },
//     {
//         name: "а) для качественных сталей с механизированной загрузкой",
//         ki: "0,75",
//         cos: "0,9",
//         id: 409,
//         parents: []
//     },
//     {
//         name: "б) для качественных сталей без механизированной загрузки",
//         ki: "0,6",
//         cos: "0,87",
//         id: 410,
//         parents: []
//     },
//     {
//         name: "в) для фасонного литья с механизированной загрузкой",
//         ki: "0,75",
//         cos: "0,9",
//         id: 411,
//         parents: []
//     },
//     {
//         name: "г) для фасонного литья без механизированной загрузки",
//         ki: "0,65",
//         cos: "0,87",
//         id: 412,
//         parents: []
//     },
//     {
//         name: "Дуговые сталеплавильные печи емкостью 0,5-1,5 т для фасонного литья (во вспомогательных цехах с автоматическим регулированием электродов)",
//         ki: "0,5",
//         cos: "0,8",
//         id: 413,
//         parents: []
//     },
//     {
//         name: "Дуговые печи цветного литья (медные сплавы) емкостью 0,25 - 0,5 т с ручным регулированием электродов",
//         ki: "0,7",
//         cos: "0,75",
//         id: 414,
//         parents: []
//     },
//     {
//         name: "Руднотермические печи с трехфазными трансформаторами 6, 7,5 и 9 мВА",
//         ki: "0,9",
//         cos: "0,9",
//         id: 415,
//         parents: []
//     },
//     {
//         name: "Сушильные шкафы",
//         ki: "0,775",
//         cos: "1",
//         id: 416,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 160 мм, рабочий ток 3-3,6 кА",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 417,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм, рабочий ток 3-3,6 кА",
//         ki: "0,08",
//         cos: "по сх.замещения",
//         id: 418,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм, рабочий ток 5-7кА",
//         ki: "0,115",
//         cos: "",
//         id: 419,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм, рабочий ток 8-9кА",
//         ki: "0,145",
//         cos: "",
//         id: 420,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм, рабочий ток 5-7кА",
//         ki: "0,115",
//         cos: "",
//         id: 421,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм, рабочий ток 8-9 кА",
//         ki: "0,15",
//         cos: "",
//         id: 422,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм, рабочий ток 5-7 кА",
//         ki: "0,06",
//         cos: "по сх.замещения",
//         id: 423,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм, 8-9 кА",
//         ki: "0,105",
//         cos: "по сх.замещения",
//         id: 424,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 630 мм, рабочий ток 8-9 кА",
//         ki: "0,12",
//         cos: "по сх.замещения",
//         id: 425,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 160 мм рабочий ток 3-3,6 кА",
//         ki: "0,05",
//         cos: "по сх.замещения",
//         id: 426,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм рабочий ток 3-3,6 кА",
//         ki: "0,125",
//         cos: "по сх.замещения",
//         id: 427,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм рабочий ток 5-7 кА",
//         ki: "0,18",
//         cos: "по сх.замещения",
//         id: 428,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 320 мм рабочий ток 8-9 кА",
//         ki: "0,225",
//         cos: "по сх.замещения",
//         id: 429,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм рабочий ток 5-7 кА",
//         ki: "0,18",
//         cos: "по сх.замещения",
//         id: 430,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм рабочий 8-9 кА",
//         ki: "0,235",
//         cos: "по сх.замещения",
//         id: 431,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм рабочий ток 5-7 кА",
//         ki: "0,095",
//         cos: "по сх.замещения",
//         id: 432,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 400 мм рабочий ток 8-9 кА",
//         ki: "0,135",
//         cos: "по сх.замещения",
//         id: 433,
//         parents: []
//     },
//     {
//         name: "Диаметр кристаллизатора 630 мм рабочий ток 8-9 кА",
//         ki: "0,19",
//         cos: "по сх.замещения",
//         id: 434,
//         parents: []
//     },
//     {
//         name: "Мелкие нагревательные приборы",
//         ki: "0,6",
//         cos: "1",
//         id: 435,
//         parents: []
//     },
//     {
//         name: "а) однопостовой",
//         ki: "0,2",
//         cos: "0,35",
//         id: 436,
//         parents: []
//     },
//     {
//         name: "б) многопостовой",
//         ki: "0,25",
//         cos: "0,4",
//         id: 437,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы автоматической сварки",
//         ki: "0,35",
//         cos: "0,45",
//         id: 438,
//         parents: []
//     },
//     {
//         name: "Однопостовые сварочные двигатель-генераторы",
//         ki: "0,3",
//         cos: "0,625",
//         id: 439,
//         parents: []
//     },
//     {
//         name: "Многопостовые сварочные двигатель-генераторы",
//         ki: "0,5",
//         cos: "0,725",
//         id: 440,
//         parents: []
//     },
//     {
//         name: "Сварочные машины шовные",
//         ki: "0,35",
//         cos: "0,7",
//         id: 441,
//         parents: []
//     },
//     {
//         name: "Сварочные машины стыковые и точечные",
//         ki: "0,225",
//         cos: "0,6",
//         id: 442,
//         parents: []
//     },
//     {
//         name: "Сварочные дуговые автоматы типа АДС",
//         ki: "0,35",
//         cos: "0,5",
//         id: 443,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы дуговой электросварки",
//         ki: "0,2",
//         cos: "0,4",
//         id: 444,
//         parents: []
//     },
//     {
//         name: "Ламповые генераторы индукционных печей высокой частоты",
//         ki: "запросить",
//         cos: "0,65",
//         id: 445,
//         parents: []
//     },
//     {
//         name: "Двигатель-генераторы индукционных печей высокой частоты",
//         ki: "запросить",
//         cos: "0,8",
//         id: 446,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,65",
//         cos: "0,8",
//         id: 447,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,65",
//         cos: "0,8",
//         id: 448,
//         parents: []
//     },
//     {
//         name: "Механическое станочное оборудование",
//         ki: "0,16",
//         cos: "0,55",
//         id: 449,
//         parents: []
//     },
//     {
//         name: "Деревообрабатывающие станки",
//         ki: "0,55",
//         cos: "0,75",
//         id: 450,
//         parents: []
//     },
//     {
//         name: "Котельная",
//         ki: "0,6",
//         cos: "0,75",
//         id: 451,
//         parents: []
//     },
//     {
//         name: "Сантехвентиляция",
//         ki: "0,65",
//         cos: "0,75",
//         id: 452,
//         parents: []
//     },
//     {
//         name: "Внутреннее освещение производственных цехов",
//         ki: "0,9",
//         cos: "0,975",
//         id: 453,
//         parents: []
//     },
//     {
//         name: "Внутреннее освещение вспомогательных цехов",
//         ki: "0,9",
//         cos: "1",
//         id: 454,
//         parents: []
//     },
//     {
//         name: "Волочильные станы",
//         ki: "0,375",
//         cos: "0,835",
//         id: 455,
//         parents: []
//     },
//     {
//         name: "Электропечи сопротивления для термической обработки",
//         ki: "0,625",
//         cos: "1",
//         id: 456,
//         parents: []
//     },
//     {
//         name: "Производственные вентиляторы и воздуходувки",
//         ki: "0,65",
//         cos: "0,75",
//         id: 457,
//         parents: []
//     },
//     {
//         name: "Центробежные насосы",
//         ki: "0,6",
//         cos: "0,7",
//         id: 458,
//         parents: []
//     },
//     {
//         name: "Краны и тельферы",
//         ki: "0,1",
//         cos: "0,65",
//         id: 459,
//         parents: []
//     },
//     {
//         name: "Сварочные аппараты",
//         ki: "0,05",
//         cos: "0,6",
//         id: 460,
//         parents: []
//     },
//     {
//         name: "Калибровочное производство",
//         ki: "0,425",
//         cos: "0,78",
//         id: 461,
//         parents: []
//     },
//     {
//         name: "Электропечи сопротивления для термической обработки",
//         ki: "0,625",
//         cos: "1",
//         id: 462,
//         parents: []
//     },
//     {
//         name: "Автоматические линии изготовления гаек, болтов, шурупов",
//         ki: "0,375",
//         cos: "0,675",
//         id: 463,
//         parents: []
//     },
//     {
//         name: "Электропечи сопротивления для термической обработки",
//         ki: "0,6",
//         cos: "1",
//         id: 464,
//         parents: []
//     },
//     {
//         name: "Прокатное и отделочное отделения (станы)",
//         ki: "0,35",
//         cos: "0,825",
//         id: 465,
//         parents: []
//     },
//     {
//         name: "Электропечи сопротивления для термической обработки",
//         ki: "0,6",
//         cos: "1",
//         id: 466,
//         parents: []
//     },
//     {
//         name: "Агрегаты непрерывного травления",
//         ki: "0,425",
//         cos: "0,775",
//         id: 467,
//         parents: []
//     },
//     {
//         name: "Канатные машины",
//         ki: "0,375",
//         cos: "0,71",
//         id: 468,
//         parents: []
//     },
//     {
//         name: "Прессовка и сушка электродов",
//         ki: "0,405",
//         cos: "0,75",
//         id: 469,
//         parents: []
//     },
//     {
//         name: "Дробильно-сушильное отделение и смесители",
//         ki: "0,425",
//         cos: "0,675",
//         id: 470,
//         parents: []
//     },
//     {
//         name: "Волочильные станы порошковой проволоки",
//         ki: "0,35",
//         cos: "0,75",
//         id: 471,
//         parents: []
//     },
//     {
//         name: "Вентиляторы пневмотранспорта",
//         ki: "0,625",
//         cos: "0,845",
//         id: 472,
//         parents: []
//     },
//     {
//         name: "Плетельные станки",
//         ki: "0,375",
//         cos: "0,675",
//         id: 473,
//         parents: []
//     },
//     {
//         name: "Сварочные автоматы",
//         ki: "0,225",
//         cos: "0,625",
//         id: 474,
//         parents: []
//     },
//     {
//         name: "Автоматы",
//         ki: "0,675",
//         cos: "0,65",
//         id: 475,
//         parents: []
//     },
//     {
//         name: "Насосы эмульсионного хозяйства",
//         ki: "0,45",
//         cos: "0,65",
//         id: 476,
//         parents: []
//     },
//     {
//         name: "Агрегаты гальванических покрытий",
//         ki: "0,6",
//         cos: "0,78",
//         id: 477,
//         parents: []
//     },
//     {
//         name: "Горячее покрытие (агрегат с электрованной оцинкования или лужения)",
//         ki: "0,6",
//         cos: "1",
//         id: 478,
//         parents: []
//     },
//     {
//         name: "Воздуходувки для растворов",
//         ki: "0,6",
//         cos: "0,7",
//         id: 479,
//         parents: []
//     },
//     {
//         name: "Компенсаторы стационарные мощностью до 2000* кВт",
//         ki: "0,75",
//         cos: "0,75",
//         id: 480,
//         parents: []
//     },
//     {
//         name: "Компенсаторы стационарные мощностью от 200 до 400 кВт",
//         ki: "0,825",
//         cos: "0,8",
//         id: 481,
//         parents: []
//     },
//     {
//         name: "Компенсаторы стационарные мощностью свыше 400 кВт",
//         ki: "0,9",
//         cos: "0,8",
//         id: 482,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью до 50 кВт",
//         ki: "0,7",
//         cos: "0,75",
//         id: 483,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью от 50 до 200 кВт",
//         ki: "0,75",
//         cos: "0,8",
//         id: 484,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью от 200 до 400 кВт",
//         ki: "0,8",
//         cos: "0,8",
//         id: 485,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью свыше 400 кВт",
//         ki: "0,9",
//         cos: "0,85",
//         id: 486,
//         parents: []
//     },
//     {
//         name: "Вентиляторы частичного проветривания",
//         ki: "0,65",
//         cos: "0,75",
//         id: 487,
//         parents: []
//     },
//     {
//         name: "Вентиляторы главного проветривания, мощностью до 200 кВт",
//         ki: "0,7",
//         cos: "0,8",
//         id: 488,
//         parents: []
//     },
//     {
//         name: "Вентиляторы главного проветривания, мощностью от 200 до 800 кВт",
//         ki: "0,75",
//         cos: "0,8",
//         id: 489,
//         parents: []
//     },
//     {
//         name: "Вентиляторы главного проветривания, мощностью свыше 800 кВт",
//         ki: "0,8",
//         cos: "0,9",
//         id: 490,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,65",
//         cos: "0,75",
//         id: 491,
//         parents: []
//     },
//     {
//         name: "Ртутные выпрямители металлические",
//         ki: "0,9",
//         cos: "0,9",
//         id: 492,
//         parents: []
//     },
//     {
//         name: "Ртутные выпрямители стеклянные",
//         ki: "0,75",
//         cos: "0,75",
//         id: 493,
//         parents: []
//     },
//     {
//         name: "Селеновые выпрямители",
//         ki: "0,8",
//         cos: "0,8",
//         id: 494,
//         parents: []
//     },
//     {
//         name: "Толкатели, опрокидыватели, качающиеся площадки",
//         ki: "0,6",
//         cos: "0,65",
//         id: 495,
//         parents: []
//     },
//     {
//         name: "Шахтные двери, бункерные затворы",
//         ki: "0,5",
//         cos: "0,65",
//         id: 496,
//         parents: []
//     },
//     {
//         name: "Дозаторы",
//         ki: "0,5",
//         cos: "0,65",
//         id: 497,
//         parents: []
//     },
//     {
//         name: "Погрузочно-доставочные машины",
//         ki: "0,65",
//         cos: "0,65",
//         id: 498,
//         parents: []
//     },
//     {
//         name: "Скребковые конвейеры",
//         ki: "0,6",
//         cos: "0,65",
//         id: 499,
//         parents: []
//     },
//     {
//         name: "Скреперные лебедки мощностью до 15 кВт",
//         ki: "0,5",
//         cos: "0,65",
//         id: 500,
//         parents: []
//     },
//     {
//         name: "Скреперные лебедки мощностью свыше 15 кВт",
//         ki: "0,65",
//         cos: "0,65",
//         id: 501,
//         parents: []
//     },
//     {
//         name: "Лебедки на материальных уклонах",
//         ki: "0,6",
//         cos: "0,65",
//         id: 502,
//         parents: []
//     },
//     {
//         name: "Подъемники лифтовые",
//         ki: "0,2",
//         cos: "0,65",
//         id: 503,
//         parents: []
//     },
//     {
//         name: "Клетевые подъемники",
//         ki: "0,6",
//         cos: "0,65",
//         id: 504,
//         parents: []
//     },
//     {
//         name: "Скиповые подъемники",
//         ki: "0,7",
//         cos: "0,8",
//         id: 505,
//         parents: []
//     },
//     {
//         name: "Электросверла ручные и колонковые",
//         ki: "0,4",
//         cos: "0,675",
//         id: 506,
//         parents: []
//     },
//     {
//         name: "Станки вращательного бурения",
//         ki: "0,45",
//         cos: "0,675",
//         id: 507,
//         parents: []
//     },
//     {
//         name: "Станки ударно-вращательного бурения",
//         ki: "0,45",
//         cos: "0,675",
//         id: 508,
//         parents: []
//     },
//     {
//         name: "Станки холодной обработки металлов",
//         ki: "0,3",
//         cos: "0,5",
//         id: 509,
//         parents: []
//     },
//     {
//         name: "Механизмы обработки земли в литейных цехах",
//         ki: "0,6",
//         cos: "0,65",
//         id: 510,
//         parents: []
//     },
//     {
//         name: "Горны",
//         ki: "0,5",
//         cos: "0,75",
//         id: 511,
//         parents: []
//     },
//     {
//         name: "Сварочные двигатель-генераторы",
//         ki: "0,6",
//         cos: "0,75",
//         id: 512,
//         parents: []
//     },
//     {
//         name: "Сварочные агрегаты",
//         ki: "0,5",
//         cos: "0,75",
//         id: 513,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы",
//         ki: "0,2",
//         cos: "0,4",
//         id: 514,
//         parents: []
//     },
//     {
//         name: "Электровибрационные механизмы",
//         ki: "0,6",
//         cos: "0,65",
//         id: 515,
//         parents: []
//     },
//     {
//         name: "Вагоноопрокидыватели",
//         ki: "0,4",
//         cos: "0,55",
//         id: 516,
//         parents: []
//     },
//     {
//         name: "Вакуум-насосы",
//         ki: "0,8",
//         cos: "0,85",
//         id: 517,
//         parents: []
//     },
//     {
//         name: "электродвигатели",
//         ki: "0,5",
//         cos: "0,65",
//         id: 518,
//         parents: []
//     },
//     {
//         name: "нагревательные приборы",
//         ki: "0,7",
//         cos: "1",
//         id: 519,
//         parents: []
//     },
//     {
//         name: "Краны мостовые, грейферные, кран-балки, тельферы, лифты",
//         ki: "0,2",
//         cos: "0,5",
//         id: 520,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления, нагревательные приборы, сушильные, шкафы",
//         ki: "0,8",
//         cos: "1",
//         id: 521,
//         parents: []
//     },
//     {
//         name: "Освещение стационарных подземных выработок",
//         ki: "1",
//         cos: "1",
//         id: 522,
//         parents: []
//     },
//     {
//         name: "Освещение участков горных работ",
//         ki: "0,9",
//         cos: "1",
//         id: 523,
//         parents: []
//     },
//     {
//         name: "Экскаваторы одноковшовые с приводом на постоянном токе по системе ДГД на добыче при грунтах:",
//         ki: "0,825",
//         cos: "0,65",
//         id: 524,
//         parents: []
//     },
//     {
//         name: "Скреперные лебедки мощностью до 10 кВт",
//         ki: "0,3",
//         cos: "0,7",
//         id: 525,
//         parents: []
//     },
//     {
//         name: "Скреперные лебедки мощностью от 10 до 15 кВт",
//         ki: "0,6",
//         cos: "0,7",
//         id: 526,
//         parents: []
//     },
//     {
//         name: "Скреперные лебедки мощностью свыше 15 кВт",
//         ki: "0,6",
//         cos: "0,7",
//         id: 527,
//         parents: []
//     },
//     {
//         name: "Лебедки маневровые и откаточные",
//         ki: "0,55",
//         cos: "0,75",
//         id: 528,
//         parents: []
//     },
//     {
//         name: "Подъемы мощностью свыше 2000 кВт",
//         ki: "0,25",
//         cos: "0,35",
//         id: 529,
//         parents: []
//     },
//     {
//         name: "Краны мостовые, грейферные, кран-балки, тельферы, лифты",
//         ki: "0,25",
//         cos: "0,35",
//         id: 530,
//         parents: []
//     },
//     {
//         name: "Печи сопротивления, нагревательные приборы, сушильные шкафы",
//         ki: "0,775",
//         cos: "0,965",
//         id: 531,
//         parents: []
//     },
//     {
//         name: "Выпрямители полупроводниковые",
//         ki: "0,75",
//         cos: "0,96",
//         id: 532,
//         parents: []
//     },
//     {
//         name: "Выпрямители селеновые",
//         ki: "0,8",
//         cos: "0,8",
//         id: 533,
//         parents: []
//     },
//     {
//         name: "Сварочные машины шовные",
//         ki: "0,35",
//         cos: "0,7",
//         id: 534,
//         parents: []
//     },
//     {
//         name: "Сварочные машины стыковые и точечные",
//         ki: "0,35",
//         cos: "0,6",
//         id: 535,
//         parents: []
//     },
//     {
//         name: "Сварочные двигатель-генераторы",
//         ki: "0,3",
//         cos: "0,625",
//         id: 536,
//         parents: []
//     },
//     {
//         name: "Сварочные двигатель-генераторы для однопостовой сварки",
//         ki: "0,3",
//         cos: "0,625",
//         id: 537,
//         parents: []
//     },
//     {
//         name: "Сварочные двигатель-генераторы для многопостовой сварки",
//         ki: "0,5",
//         cos: "0,725",
//         id: 538,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для однопостовой ручной сварки",
//         ki: "0,2",
//         cos: "0,35",
//         id: 539,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для многопостовой ручной сварки",
//         ki: "0,25",
//         cos: "0,5",
//         id: 540,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы для автоматической сварки",
//         ki: "0,35",
//         cos: "0,45",
//         id: 541,
//         parents: []
//     },
//     {
//         name: "Сварочные дуговые автоматы",
//         ki: "0,35",
//         cos: "0,5",
//         id: 542,
//         parents: []
//     },
//     {
//         name: "Станки холодной обработки металла",
//         ki: "0,35",
//         cos: "0,65",
//         id: 543,
//         parents: []
//     },
//     {
//         name: "электродвигатели",
//         ki: "0,5",
//         cos: "0,65",
//         id: 544,
//         parents: []
//     },
//     {
//         name: "нагревательные приборы",
//         ki: "0,7",
//         cos: "1",
//         id: 545,
//         parents: []
//     },
//     {
//         name: "Освещение зданий на прикарьерной промплощадке",
//         ki: "0,9",
//         cos: "1",
//         id: 546,
//         parents: []
//     },
//     {
//         name: "Освещение в карьере",
//         ki: "1",
//         cos: "1",
//         id: 547,
//         parents: []
//     },
//     {
//         name: "Освещение территории прикарьерной промплощадки",
//         ki: "1",
//         cos: "1",
//         id: 548,
//         parents: []
//     },
//     {
//         name: "на вскрыше при грунтах",
//         ki: "0,825",
//         cos: "0,55",
//         id: 549,
//         parents: []
//     },
//     {
//         name: "Экскаваторы одноковшовые с приводом на переменном токе при грунтах ",
//         ki: "0,825",
//         cos: "0,65",
//         id: 550,
//         parents: []
//     },
//     {
//         name: "Экскаваторы многоковшовые",
//         ki: "0,75",
//         cos: "0,7",
//         id: 551,
//         parents: []
//     },
//     {
//         name: "Экскаваторы роторные",
//         ki: "запросить",
//         cos: "0,7",
//         id: 552,
//         parents: []
//     },
//     {
//         name: "Отвалообразователи ленточные",
//         ki: "запросить",
//         cos: "0,65",
//         id: 553,
//         parents: []
//     },
//     {
//         name: "Станки ударно-канатного бурения",
//         ki: "0,5",
//         cos: "0,65",
//         id: 554,
//         parents: []
//     },
//     {
//         name: "Станки вращательного бурения",
//         ki: "0,5",
//         cos: "0,7",
//         id: 555,
//         parents: []
//     },
//     {
//         name: "Компрессоры передвижные",
//         ki: "0,7",
//         cos: "0,8",
//         id: 556,
//         parents: []
//     },
//     {
//         name: "Компрессоры стационарные мощностью до 200 кВт",
//         ki: "0,7",
//         cos: "0,8",
//         id: 557,
//         parents: []
//     },
//     {
//         name: "Компрессоры стационарные мощностью от 200 до 400 кВт",
//         ki: "0,8",
//         cos: "0,8",
//         id: 558,
//         parents: []
//     },
//     {
//         name: "Компрессоры стационарные мощностью свыше 400 кВт",
//         ki: "0,9",
//         cos: "0,8",
//         id: 559,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью до 50 кВт",
//         ki: "0,7",
//         cos: "0,75",
//         id: 560,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью от 50 до 200 кВт",
//         ki: "0,75",
//         cos: "0,8",
//         id: 561,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью от 200 до 500 кВт",
//         ki: "0,8",
//         cos: "0,8",
//         id: 562,
//         parents: []
//     },
//     {
//         name: "Насосы мощностью свыше 500 кВт ",
//         ki: "0,85",
//         cos: "0,85",
//         id: 563,
//         parents: []
//     },
//     {
//         name: "Землесосы и песковые насосы мощностью до 50 кВт",
//         ki: "0,8",
//         cos: "0,8",
//         id: 564,
//         parents: []
//     },
//     {
//         name: "Землесосы и песковые насосы мощностью свыше 50 кВт",
//         ki: "0,85",
//         cos: "0,8",
//         id: 565,
//         parents: []
//     },
//     {
//         name: "Электровибрационные механизмы",
//         ki: "0,7",
//         cos: "0,65",
//         id: 566,
//         parents: []
//     },
//     {
//         name: "Вакуум-насосы",
//         ki: "0,8",
//         cos: "0,75",
//         id: 567,
//         parents: []
//     },
//     {
//         name: "Вентиляторы производственные",
//         ki: "0,7",
//         cos: "0,78",
//         id: 568,
//         parents: []
//     },
//     {
//         name: "Вентиляторы главного проветривания мощностью от 200 до 800 кВт",
//         ki: "0,775",
//         cos: "0,8",
//         id: 569,
//         parents: []
//     },
//     {
//         name: "Вентиляторы главного проветривания мощностью свыше 800 кВт",
//         ki: "0,875",
//         cos: "0,85",
//         id: 570,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,65",
//         cos: "0,75",
//         id: 571,
//         parents: []
//     },
//     {
//         name: "Насосы производственного водоснабжения",
//         ki: "0,8",
//         cos: "0,85",
//         id: 572,
//         parents: []
//     },
//     {
//         name: "Насосы песковые производственные",
//         ki: "0,75",
//         cos: "0,8",
//         id: 573,
//         parents: []
//     },
//     {
//         name: "Насосы песковые дренажные",
//         ki: "0,8",
//         cos: "0,8",
//         id: 574,
//         parents: []
//     },
//     {
//         name: "Насосы песковые для перекачки хвостов и оборотного водоснабжения",
//         ki: "0,8",
//         cos: "0,8",
//         id: 575,
//         parents: []
//     },
//     {
//         name: "Вакуум-насосы",
//         ki: "0,95",
//         cos: "0,85",
//         id: 576,
//         parents: []
//     },
//     {
//         name: "Насосы масляные",
//         ki: "0,65",
//         cos: "0,75",
//         id: 577,
//         parents: []
//     },
//     {
//         name: "2 Аглоэксгаустеры для лент 75 м",
//         ki: "0,85",
//         cos: "0,9",
//         id: 578,
//         parents: []
//     },
//     {
//         name: "2 Аглоэксгаустеры для лент 50 мм",
//         ki: "0,75",
//         cos: "0,9",
//         id: 579,
//         parents: []
//     },
//     {
//         name: "Вентиляторы производственные, воздуходувки, дымососы",
//         ki: "0,75",
//         cos: "0,8",
//         id: 580,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,8",
//         cos: "0,8",
//         id: 581,
//         parents: []
//     },
//     {
//         name: "Вентиляторы к дробилкам",
//         ki: "0,5",
//         cos: "0,675",
//         id: 582,
//         parents: []
//     },
//     {
//         name: "Компрессоры стационарные мощностью до 200 кВт",
//         ki: "0,75",
//         cos: "0,75",
//         id: 583,
//         parents: []
//     },
//     {
//         name: "Компрессоры стационарные мощностью от 200 до 400 кВт",
//         ki: "0,9",
//         cos: "0,8",
//         id: 584,
//         parents: []
//     },
//     {
//         name: "Дробилки крупного дробленая, щековые и конусные с двухдвигательным приводом",
//         ki: "0,7",
//         cos: "0,6",
//         id: 585,
//         parents: []
//     },
//     {
//         name: "Дробилки конусные крупного дробления с однодвигательным приводом, дробилки конусные среднего дробления",
//         ki: "0,7",
//         cos: "0,725",
//         id: 586,
//         parents: []
//     },
//     {
//         name: "Дробилки короткоконусные мелкого дробления",
//         ki: "0,7",
//         cos: "0,75",
//         id: 587,
//         parents: []
//     },
//     {
//         name: "Дробилки одновалковые и чатырехвалковые мелкого дробления",
//         ki: "0,7",
//         cos: "0,75",
//         id: 588,
//         parents: []
//     },
//     {
//         name: "Дробилки молотковые",
//         ki: "0,78",
//         cos: "0,875",
//         id: 589,
//         parents: []
//     },
//     {
//         name: "Дробилки четырехвалковые",
//         ki: "0,85",
//         cos: "0,85",
//         id: 590,
//         parents: []
//     },
//     {
//         name: "Мельницы шаровые",
//         ki: "0,9",
//         cos: "0,9",
//         id: 591,
//         parents: []
//     },
//     {
//         name: "Мельницы шаровые и стержневые II и III стадий дробления",
//         ki: "0,8",
//         cos: "0,9",
//         id: 592,
//         parents: []
//     },
//     {
//         name: "Мельницы мощностью до 100 кВт",
//         ki: "0,8",
//         cos: "0,75",
//         id: 593,
//         parents: []
//     },
//     {
//         name: "Мельницы самоизмельчения 7,5х2,5 м",
//         ki: "0,75",
//         cos: "0,9",
//         id: 594,
//         parents: []
//     },
//     {
//         name: "Грохоты разные",
//         ki: "0,5",
//         cos: "0,65",
//         id: 595,
//         parents: []
//     },
//     {
//         name: "Грохоты барабанные",
//         ki: "0,65",
//         cos: "0,75",
//         id: 596,
//         parents: []
//     },
//     {
//         name: "Механизмы вращающихся печей",
//         ki: "0,6",
//         cos: "0,7",
//         id: 597,
//         parents: []
//     },
//     {
//         name: "Дымососы печей",
//         ki: "0,7",
//         cos: "0,8",
//         id: 598,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы конвейеров",
//         ki: "0,15",
//         cos: "0,5",
//         id: 599,
//         parents: []
//     },
//     {
//         name: "Вагоноопрокидыватели",
//         ki: "0,4",
//         cos: "0,55",
//         id: 600,
//         parents: []
//     },
//     {
//         name: "Конвейеры ленточные легкие с двигателями до 10 кВт",
//         ki: "0,6",
//         cos: "0,65",
//         id: 601,
//         parents: []
//     },
//     {
//         name: "Конвейеры ленточные легкие с двигателями свыше 10 кВт",
//         ki: "0,75",
//         cos: "0,75",
//         id: 602,
//         parents: []
//     },
//     {
//         name: "Питатели ленточные, улитковые, тарельчатые, лотковые с двигателями до 10 кВт, шаропитатели, электромагнитные и магнитные сепараторы",
//         ki: "0,4",
//         cos: "0,65",
//         id: 603,
//         parents: []
//     },
//     {
//         name: "Питатели, конвейеры электровибрационные, грохоты",
//         ki: "0,79",
//         cos: "0,625",
//         id: 604,
//         parents: []
//     },
//     {
//         name: "Конвейеры корпуса крупного дробления",
//         ki: "0,5",
//         cos: "0,7",
//         id: 605,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы конвейеров",
//         ki: "0,15",
//         cos: "0,5",
//         id: 606,
//         parents: []
//     },
//     {
//         name: "Конвейеры тяжелые с шириной ленты до 1400 мм",
//         ki: "0,7",
//         cos: "0,75",
//         id: 607,
//         parents: []
//     },
//     {
//         name: "Конвейеры сверхтяжелые с шириной ленты 1600-2000 мм",
//         ki: "0,8",
//         cos: "0,825",
//         id: 608,
//         parents: []
//     },
//     {
//         name: "Питатели пластинчатые тарельчатые, лотковые мощностью свыше 10 кВт шнеки, элеваторы, механические топки",
//         ki: "0,7",
//         cos: "0,75",
//         id: 609,
//         parents: []
//     },
//     {
//         name: "Сгустители",
//         ki: "0,7",
//         cos: "0,8",
//         id: 610,
//         parents: []
//     },
//     {
//         name: "Барабаны смесительные",
//         ki: "0,7",
//         cos: "0,8",
//         id: 611,
//         parents: []
//     },
//     {
//         name: "Чашевые охладители",
//         ki: "0,7",
//         cos: "0,85",
//         id: 612,
//         parents: []
//     },
//     {
//         name: "Столы концентрационные, чаны контактные и реагентные",
//         ki: "0,6",
//         cos: "0,7",
//         id: 613,
//         parents: []
//     },
//     {
//         name: "Сушильные барабаны и сепараторы",
//         ki: "0,6",
//         cos: "0,8",
//         id: 614,
//         parents: []
//     },
//     {
//         name: "Классификаторы спиральные",
//         ki: "0,7",
//         cos: "0,8",
//         id: 615,
//         parents: []
//     },
//     {
//         name: "Отсадочные машины, шлюзы",
//         ki: "0,6",
//         cos: "0,7",
//         id: 616,
//         parents: []
//     },
//     {
//         name: "Классификаторы реечные",
//         ki: "0,6",
//         cos: "0,7",
//         id: 617,
//         parents: []
//     },
//     {
//         name: "Мешкозашивочные машины, фильтры весовые дозаторы, погрузчики концентрата",
//         ki: "0,3",
//         cos: "0,6",
//         id: 618,
//         parents: []
//     },
//     {
//         name: "Флотационные машины механические и пневмомеханические",
//         ki: "0,9",
//         cos: "0,8",
//         id: 619,
//         parents: []
//     },
//     {
//         name: "Вакуум-фильтры",
//         ki: "0,4",
//         cos: "0,6",
//         id: 620,
//         parents: []
//     },
//     {
//         name: "Электрофильтры",
//         ki: "0,4",
//         cos: "0,87",
//         id: 621,
//         parents: []
//     },
//     {
//         name: "Сепараторы электромагнитные и магнитные барабанные (привод барабана)",
//         ki: "0,75",
//         cos: "0,75",
//         id: 622,
//         parents: []
//     },
//     {
//         name: "Магнитные сепараторы индивидуальные",
//         ki: "0,4",
//         cos: "по сх.замещения",
//         id: 623,
//         parents: []
//     },
//     {
//         name: "Сепараторы электромагнитные разные. Нагрузки на обработку возбуждения со стороны переменного тока по отношению к номинальной мощности электромагнитов сепаратора",
//         ki: "1,1",
//         cos: "0,875",
//         id: 624,
//         parents: []
//     },
//     {
//         name: "Реагентные питатели, опробователи",
//         ki: "0,3",
//         cos: "0,5",
//         id: 625,
//         parents: []
//     },
//     {
//         name: "Печи сушильные барабанные, смесители, промывочные барабаны, окомкователи, корытные мойки (логоушеры)",
//         ki: "0,75",
//         cos: "0,8",
//         id: 626,
//         parents: []
//     },
//     {
//         name: "Преобразовательные агрегаты электрофильтров",
//         ki: "0,6",
//         cos: "0,85",
//         id: 627,
//         parents: []
//     },
//     {
//         name: "Печи обжиговые для дв. пост. тока",
//         ki: "0,8",
//         cos: "1",
//         id: 628,
//         parents: []
//     },
//     {
//         name: "Печи обжиговые для дв. перем. тока",
//         ki: "0,8",
//         cos: "0,85",
//         id: 629,
//         parents: []
//     },
//     {
//         name: "Тиристорные преобразователи для возбудителей синхронных двигателей",
//         ki: "0,8",
//         cos: "0,5",
//         id: 630,
//         parents: []
//     },
//     {
//         name: "Тиристорные преобразователи частоты",
//         ki: "0,7",
//         cos: "0,7",
//         id: 631,
//         parents: []
//     },
//     {
//         name: "Двигатель-генераторы",
//         ki: "0,7",
//         cos: "0,8",
//         id: 632,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы",
//         ki: "0,2",
//         cos: "0,4",
//         id: 633,
//         parents: []
//     },
//     {
//         name: "Лебедки разные",
//         ki: "0,3",
//         cos: "0,5",
//         id: 634,
//         parents: []
//     },
//     {
//         name: "Вулканизационные аппараты",
//         ki: "0,885",
//         cos: "1",
//         id: 635,
//         parents: []
//     },
//     {
//         name: "Электроковши, электрогрелки",
//         ki: "0,675",
//         cos: "0,75",
//         id: 636,
//         parents: []
//     },
//     {
//         name: "Металлообрабатывающие станки",
//         ki: "0,2",
//         cos: "0,5",
//         id: 637,
//         parents: []
//     },
//     {
//         name: "Сушильные шкафы",
//         ki: "0,75",
//         cos: "1",
//         id: 638,
//         parents: []
//     },
//     {
//         name: "Лабораторное оборудование",
//         ki: "0,4",
//         cos: "0,725",
//         id: 639,
//         parents: []
//     },
//     {
//         name: "Печи сушильные (омические)",
//         ki: "1",
//         cos: "1",
//         id: 640,
//         parents: []
//     },
//     {
//         name: "Нагревательные приборы",
//         ki: "0,7",
//         cos: "1",
//         id: 641,
//         parents: []
//     },
//     {
//         name: "Краны грейферные",
//         ki: "0,25",
//         cos: "0,55",
//         id: 642,
//         parents: []
//     },
//     {
//         name: "Краны разные",
//         ki: "0,2",
//         cos: "0,55",
//         id: 643,
//         parents: []
//     },
//     {
//         name: "Электроковши, грелки для масла",
//         ki: "0,97",
//         cos: "1",
//         id: 644,
//         parents: []
//     },
//     {
//         name: "Насосы масляные",
//         ki: "0,65",
//         cos: "0,75",
//         id: 645,
//         parents: []
//     },
//     {
//         name: "Крановые установки лесозаготовительных предприятий: К-305Н",
//         ki: "0,28",
//         cos: "0,42",
//         id: 646,
//         parents: []
//     },
//     {
//         name: "Крановые установки лесозаготовительных предприятий: ККС-10",
//         ki: "0,25",
//         cos: "0,42",
//         id: 647,
//         parents: []
//     },
//     {
//         name: "Крановые установки лесозаготовительных предприятий: ККЦ-7,5",
//         ki: "0,3",
//         cos: "0,45",
//         id: 648,
//         parents: []
//     },
//     {
//         name: "Козловые к консольно-козловые краны с грейфером (при непрерывной работе)",
//         ki: "0,4",
//         cos: "0,6",
//         id: 649,
//         parents: []
//     },
//     {
//         name: "БКСМ-14, ПM-2",
//         ki: "0,2",
//         cos: "0,4",
//         id: 650,
//         parents: []
//     },
//     {
//         name: "Башенный кран КБ-572",
//         ki: "0,15",
//         cos: "0,35",
//         id: 651,
//         parents: []
//     },
//     {
//         name: "Мостовой кран",
//         ki: "0,3",
//         cos: "0,5",
//         id: 652,
//         parents: []
//     },
//     {
//         name: "Кабель-кран КК-20",
//         ki: "0,4",
//         cos: "0,65",
//         id: 653,
//         parents: []
//     },
//     {
//         name: "разгрузочно-растаскивающее устройство",
//         ki: "0,1",
//         cos: "0,554",
//         id: 654,
//         parents: []
//     },
//     {
//         name: "сортировочные транспортеры",
//         ki: "0,415",
//         cos: "0,7",
//         id: 655,
//         parents: []
//     },
//     {
//         name: "раскряжевочно-сортировочные линии ПЛХ-2, ПДХ-3 с неавтоматическим сортировочным транспортером",
//         ki: "0,22",
//         cos: "0,6",
//         id: 656,
//         parents: []
//     },
//     {
//         name: "Поперечный растаскиватель хлыстов ПРХ-2С",
//         ki: "0,1",
//         cos: "0,554",
//         id: 657,
//         parents: []
//     },
//     {
//         name: "Многожильный раскряжевочный агрегат с поперечной подачей хлыста (МР-8)",
//         ki: "0,3",
//         cos: "0,8",
//         id: 658,
//         parents: []
//     },
//     {
//         name: "Сучкорезная линия ПСЛ-1",
//         ki: "0,2",
//         cos: "0,645",
//         id: 659,
//         parents: []
//     },
//     {
//         name: "Слешерная линия разделки долготья на коротье",
//         ki: "0,22",
//         cos: "0,554",
//         id: 660,
//         parents: []
//     },
//     {
//         name: "Установка по производству технологической щепы УПЩ-3",
//         ki: "0,3",
//         cos: "0,554",
//         id: 661,
//         parents: []
//     },
//     {
//         name: "Рубильная машина на балансах МРГ-35",
//         ki: "0,43",
//         cos: "0,57",
//         id: 662,
//         parents: []
//     },
//     {
//         name: "Трелевочные лебедки с циклическим движением троса",
//         ki: "0,225",
//         cos: "0,55",
//         id: 663,
//         parents: []
//     },
//     {
//         name: "Трелевочные лебедки с бесконечным движением троса",
//         ki: "0,265",
//         cos: "0,575",
//         id: 664,
//         parents: []
//     },
//     {
//         name: "Лебедка на штабелевке и погрузке на складах",
//         ki: "0,175",
//         cos: "0,54",
//         id: 665,
//         parents: []
//     },
//     {
//         name: "Бревнотаски на бирже",
//         ki: "0,45",
//         cos: "0,7",
//         id: 666,
//         parents: []
//     },
//     {
//         name: "Бревнотаски амбарные, лебедки на складах",
//         ki: "0,25",
//         cos: "0,5",
//         id: 667,
//         parents: []
//     },
//     {
//         name: "Бревнотаски береговые и цепные транспортеры, шнеки и конвейеры: cблокированные",
//         ki: "0,475",
//         cos: "0,75",
//         id: 668,
//         parents: []
//     },
//     {
//         name: "Бревнотаски береговые и цепные транспортеры, шнеки и конвейеры: несблокированные",
//         ki: "0,35",
//         cos: "0,75",
//         id: 669,
//         parents: []
//     },
//     {
//         name: "Бревносвалы",
//         ki: "0,1",
//         cos: "0,525",
//         id: 670,
//         parents: []
//     },
//     {
//         name: "Дровокольные станки",
//         ki: "0,2",
//         cos: "0,4",
//         id: 671,
//         parents: []
//     },
//     {
//         name: "Электропилы (валка деревьев, раскряжевка)",
//         ki: "0,35",
//         cos: "0,645",
//         id: 672,
//         parents: []
//     },
//     {
//         name: "Окорочные станки",
//         ki: "0,3",
//         cos: "0,554",
//         id: 673,
//         parents: []
//     },
//     {
//         name: "Лесопильные рамы",
//         ki: "0,6",
//         cos: "0,75",
//         id: 674,
//         parents: []
//     },
//     {
//         name: "Многопильный станок",
//         ki: "0,43",
//         cos: "0,75",
//         id: 675,
//         parents: []
//     },
//     {
//         name: "Обрезные станки",
//         ki: "0,2",
//         cos: "0,554",
//         id: 676,
//         parents: []
//     },
//     {
//         name: "Торцовочные станки",
//         ki: "0,4",
//         cos: "0,554",
//         id: 677,
//         parents: []
//     },
//     {
//         name: "Торцовки",
//         ki: "0,4",
//         cos: "0,554",
//         id: 678,
//         parents: []
//     },
//     {
//         name: "Рубильные машины",
//         ki: "0,3",
//         cos: "0,554",
//         id: 679,
//         parents: []
//     },
//     {
//         name: "Шпалорезный станок",
//         ki: "0,4",
//         cos: "0,645",
//         id: 680,
//         parents: []
//     },
//     {
//         name: "Универсальный станок",
//         ki: "0,4",
//         cos: "0,645",
//         id: 681,
//         parents: []
//     },
//     {
//         name: "Ленточно-делительный станок",
//         ki: "0,4",
//         cos: "0,645",
//         id: 682,
//         parents: []
//     },
//     {
//         name: "Рейсмусовые и фуговальные станки",
//         ki: "0,45",
//         cos: "0,55",
//         id: 683,
//         parents: []
//     },
//     {
//         name: "Тарный станок",
//         ki: "0,5",
//         cos: "0,645",
//         id: 684,
//         parents: []
//     },
//     {
//         name: "Тарно-делительный станок",
//         ki: "0,5",
//         cos: "0,645",
//         id: 685,
//         parents: []
//     },
//     {
//         name: "Балансирная пила",
//         ki: "0,2",
//         cos: "0,6",
//         id: 686,
//         parents: []
//     },
//     {
//         name: "Древесно-стружечный станок",
//         ki: "0,4",
//         cos: "0,6",
//         id: 687,
//         parents: []
//     },
//     {
//         name: "Деревошерстный станок",
//         ki: "0,4",
//         cos: "0,6",
//         id: 688,
//         parents: []
//     },
//     {
//         name: "Ребровый станок",
//         ki: "0,3",
//         cos: "0,554",
//         id: 689,
//         parents: []
//     },
//     {
//         name: "Круглопильный станок для поперечного распиливания",
//         ki: "0,25",
//         cos: "0,55",
//         id: 690,
//         parents: []
//     },
//     {
//         name: "Круглопильный станок для продольного распиливания",
//         ki: "0,25",
//         cos: "0,55",
//         id: 691,
//         parents: []
//     },
//     {
//         name: "Концеравнильный двухпильный станок",
//         ki: "0,2",
//         cos: "0,645",
//         id: 692,
//         parents: []
//     },
//     {
//         name: "Агрегатный станок",
//         ki: "0,2",
//         cos: "0,645",
//         id: 693,
//         parents: []
//     },
//     {
//         name: "Строгальный станок",
//         ki: "0,6",
//         cos: "0,645",
//         id: 694,
//         parents: []
//     },
//     {
//         name: "Сортировка щепы",
//         ki: "0,5",
//         cos: "0,69",
//         id: 695,
//         parents: []
//     },
//     {
//         name: "Рольганги",
//         ki: "0,3",
//         cos: "0,554",
//         id: 696,
//         parents: []
//     },
//     {
//         name: "Скребковые транспортеры",
//         ki: "0,4",
//         cos: "0,69",
//         id: 697,
//         parents: []
//     },
//     {
//         name: "Ленточные транспортеры",
//         ki: "0,2",
//         cos: "0,554",
//         id: 698,
//         parents: []
//     },
//     {
//         name: "Брусоперекладчики",
//         ki: "0,3",
//         cos: "0,554",
//         id: 699,
//         parents: []
//     },
//     {
//         name: "Сбрасыватель",
//         ki: "0,2",
//         cos: "0,49",
//         id: 700,
//         parents: []
//     },
//     {
//         name: "Дробилки",
//         ki: "0,325",
//         cos: "0,58",
//         id: 701,
//         parents: []
//     },
//     {
//         name: "Грохоты",
//         ki: "0,6",
//         cos: "0,7",
//         id: 702,
//         parents: []
//     },
//     {
//         name: "Продольно-торцевые станки и станки для изготовления дранки",
//         ki: "0,125",
//         cos: "0,52",
//         id: 703,
//         parents: []
//     },
//     {
//         name: "Ленточные ребровые станки",
//         ki: "0,475",
//         cos: "0,675",
//         id: 704,
//         parents: []
//     },
//     {
//         name: "Круглопильные станки с механической подачей (обрезные, длинореечные, делительные) ребровые,",
//         ki: "0,45",
//         cos: "0,675",
//         id: 705,
//         parents: []
//     },
//     {
//         name: "Круглопильные станки с ручной подачей (балансирные, маятниковые) торцевые,",
//         ki: "0,35",
//         cos: "0,55",
//         id: 706,
//         parents: []
//     },
//     {
//         name: "Фрезерные, шипорезные, сверлильные, долбежные, токарные по дереву",
//         ki: "0,2",
//         cos: "0,7",
//         id: 707,
//         parents: []
//     },
//     {
//         name: "Фрезерные, шипорезные, сверлильные, долбежные, токарные поточные линии",
//         ki: "0,25",
//         cos: "0,7",
//         id: 708,
//         parents: []
//     },
//     {
//         name: "Шлифовальные станки",
//         ki: "0,45",
//         cos: "0,6",
//         id: 709,
//         parents: []
//     },
//     {
//         name: "Автоматические линии деревообработки",
//         ki: "0,5",
//         cos: "0,65",
//         id: 710,
//         parents: []
//     },
//     {
//         name: "Станки ремонтно-механических мастерских",
//         ki: "0,175",
//         cos: "0,625",
//         id: 711,
//         parents: []
//     },
//     {
//         name: "Прессы",
//         ki: "0,55",
//         cos: "0,8",
//         id: 712,
//         parents: []
//     },
//     {
//         name: "Транспортеры скребниковые и ленточные",
//         ki: "0,5",
//         cos: "0,7",
//         id: 713,
//         parents: []
//     },
//     {
//         name: "Питатели пластинчатые, тарельчатые, барабанные",
//         ki: "0,4",
//         cos: "0,6",
//         id: 714,
//         parents: []
//     },
//     {
//         name: "Рольганги, элеваторы",
//         ki: "0,3",
//         cos: "0,675",
//         id: 715,
//         parents: []
//     },
//     {
//         name: "Краны, тельферы, тележки цеховые",
//         ki: "0,1",
//         cos: "0,5",
//         id: 716,
//         parents: []
//     },
//     {
//         name: "Сепараторы",
//         ki: "0,4",
//         cos: "0,65",
//         id: 717,
//         parents: []
//     },
//     {
//         name: "Сушильные барабаны",
//         ki: "0,6",
//         cos: "0,7",
//         id: 718,
//         parents: []
//     },
//     {
//         name: "Электрические печи сопротивления",
//         ki: "0,8",
//         cos: "0,95",
//         id: 719,
//         parents: []
//     },
//     {
//         name: "Переносный электроинструмент",
//         ki: "0,08",
//         cos: "0,5",
//         id: 720,
//         parents: []
//     },
//     {
//         name: "Машина УПФМ",
//         ki: "0,45",
//         cos: "0,7",
//         id: 721,
//         parents: []
//     },
//     {
//         name: "Насосы, компрессоры",
//         ki: "0,7",
//         cos: "0,85",
//         id: 722,
//         parents: []
//     },
//     {
//         name: "Вентиляторы эксгаустеров и сушильных камер",
//         ki: "0,7",
//         cos: "0,8",
//         id: 723,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,65",
//         cos: "0,8",
//         id: 724,
//         parents: []
//     },
//     {
//         name: "Четырехсторонние строительные станки",
//         ki: "0,16",
//         cos: "0,53",
//         id: 725,
//         parents: []
//     },
//     {
//         name: "Двухсторонние шипорезы",
//         ki: "0,12",
//         cos: "0,36",
//         id: 726,
//         parents: []
//     },
//     {
//         name: "Шлифовальные машины",
//         ki: "0,22",
//         cos: "0,37",
//         id: 727,
//         parents: []
//     },
//     {
//         name: "Линии продольной и поперечной обработки створок",
//         ki: "0,31",
//         cos: "0,44",
//         id: 728,
//         parents: []
//     },
//     {
//         name: "Станочное оборудование цеха погонажа",
//         ki: "0,61",
//         cos: "0,59",
//         id: 729,
//         parents: []
//     },
//     {
//         name: "Сушильное отделение",
//         ki: "0,37",
//         cos: "0,7",
//         id: 730,
//         parents: []
//     },
//     {
//         name: "Малярное отделение",
//         ki: "0,5",
//         cos: "0,55",
//         id: 731,
//         parents: []
//     },
//     {
//         name: "Лакокрасочное отделение цеха",
//         ki: "0,73",
//         cos: "0,8",
//         id: 732,
//         parents: []
//     },
//     {
//         name: "Технологическая вентиляция",
//         ki: "0,545",
//         cos: "0,735",
//         id: 733,
//         parents: []
//     },
//     {
//         name: "Сантехническая вентиляция",
//         ki: "0,75",
//         cos: "0,8",
//         id: 734,
//         parents: []
//     },
//     {
//         name: "Рубительная машина",
//         ki: "0,245",
//         cos: "0,535",
//         id: 735,
//         parents: []
//     },
//     {
//         name: "Дезинтегратор",
//         ki: "0,12",
//         cos: "0,3",
//         id: 736,
//         parents: []
//     },
//     {
//         name: "Транспортеры щепы",
//         ki: "0,405",
//         cos: "0,55",
//         id: 737,
//         parents: []
//     },
//     {
//         name: "Пневмотранспорт щепы",
//         ki: "0,84",
//         cos: "0,85",
//         id: 738,
//         parents: []
//     },
//     {
//         name: "Механизмы сортировки щепы",
//         ki: "0,245",
//         cos: "0,5",
//         id: 739,
//         parents: []
//     },
//     {
//         name: "Рафинатор",
//         ki: "0,645",
//         cos: "0,87",
//         id: 740,
//         parents: []
//     },
//     {
//         name: "Дефибратор со вспомогательным оборудованием",
//         ki: "0,63",
//         cos: "0,75",
//         id: 741,
//         parents: []
//     },
//     {
//         name: "Мешалка бассейна и насос массы",
//         ki: "0,43",
//         cos: "0,78",
//         id: 742,
//         parents: []
//     },
//     {
//         name: "Механизмы проклейки",
//         ki: "0,47",
//         cos: "0,65",
//         id: 743,
//         parents: []
//     },
//     {
//         name: "Отливочная машина",
//         ki: "0,795",
//         cos: "0,85",
//         id: 744,
//         parents: []
//     },
//     {
//         name: "Насосы брака и обратной воды",
//         ki: "0,36",
//         cos: "0,82",
//         id: 745,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы",
//         ki: "0,38",
//         cos: "0,65",
//         id: 746,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,38",
//         cos: "0,7",
//         id: 747,
//         parents: []
//     },
//     {
//         name: "Форматные станки твердого потока",
//         ki: "0,41",
//         cos: "0,5",
//         id: 748,
//         parents: []
//     },
//     {
//         name: "Отливочная машина пористого потока",
//         ki: "0,22",
//         cos: "0,48",
//         id: 749,
//         parents: []
//     },
//     {
//         name: "Сушилка пористого потока",
//         ki: "0,56",
//         cos: "0,76",
//         id: 750,
//         parents: []
//     },
//     {
//         name: "Вакуум-насосы",
//         ki: "0,77",
//         cos: "0,85",
//         id: 751,
//         parents: []
//     },
//     {
//         name: "Насосы высокого и низкого давления",
//         ki: "0,23",
//         cos: "0,76",
//         id: 752,
//         parents: []
//     },
//     {
//         name: "Насосы теплового аккумулятора",
//         ki: "0,89",
//         cos: "0,86",
//         id: 753,
//         parents: []
//     },
//     {
//         name: "Транспортеры",
//         ki: "0,26",
//         cos: "0,58",
//         id: 754,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,14",
//         cos: "0,58",
//         id: 755,
//         parents: []
//     },
//     {
//         name: "Вспомогательное оборудование пресса",
//         ki: "0,47",
//         cos: "0,8",
//         id: 756,
//         parents: []
//     },
//     {
//         name: "Вентиляторы закалочных камер",
//         ki: "0,3",
//         cos: "0,83",
//         id: 757,
//         parents: []
//     },
//     {
//         name: "Транспортные механизмы (тележки, рольганги и др.)",
//         ki: "0,1",
//         cos: "0,3",
//         id: 758,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,5",
//         cos: "0,5",
//         id: 759,
//         parents: []
//     },
//     {
//         name: "Технические механизмы",
//         ki: "0,54",
//         cos: "0,65",
//         id: 760,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,36",
//         cos: "0,6",
//         id: 761,
//         parents: []
//     },
//     {
//         name: "Моечная установка",
//         ki: "0,7",
//         cos: "0,8",
//         id: 762,
//         parents: []
//     },
//     {
//         name: "Установка заправки маслом",
//         ki: "0,5",
//         cos: "0,645",
//         id: 763,
//         parents: []
//     },
//     {
//         name: "Аппарат промывочный",
//         ki: "0,7",
//         cos: "0,8",
//         id: 764,
//         parents: []
//     },
//     {
//         name: "Гидроподъемник",
//         ki: "0,7",
//         cos: "0,8",
//         id: 765,
//         parents: []
//     },
//     {
//         name: "Гайковерт",
//         ki: "0,14",
//         cos: "0,5",
//         id: 766,
//         parents: []
//     },
//     {
//         name: "Шероховальный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 767,
//         parents: []
//     },
//     {
//         name: "Электровулканизаторы",
//         ki: "0,4",
//         cos: "0,6",
//         id: 768,
//         parents: []
//     },
//     {
//         name: "Компрессор",
//         ki: "0,7",
//         cos: "0,8",
//         id: 769,
//         parents: []
//     },
//     {
//         name: "Испытательный стенд",
//         ki: "0,4",
//         cos: "0,64",
//         id: 770,
//         parents: []
//     },
//     {
//         name: "Токарный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 771,
//         parents: []
//     },
//     {
//         name: "Обрезной станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 772,
//         parents: []
//     },
//     {
//         name: "Фрезерный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 773,
//         parents: []
//     },
//     {
//         name: "Строгальный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 774,
//         parents: []
//     },
//     {
//         name: "Расточный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 775,
//         parents: []
//     },
//     {
//         name: "Сверлильный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 776,
//         parents: []
//     },
//     {
//         name: "Заточный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 777,
//         parents: []
//     },
//     {
//         name: "Пилоножеточный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 778,
//         parents: []
//     },
//     {
//         name: "Шлифовальный станок",
//         ki: "0,14",
//         cos: "0,5",
//         id: 779,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы",
//         ki: "0,2",
//         cos: "0,399",
//         id: 780,
//         parents: []
//     },
//     {
//         name: "Однопостовые сварочные двигатель-генераторы",
//         ki: "0,2",
//         cos: "0,399",
//         id: 781,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,65",
//         cos: "0,8",
//         id: 782,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,5",
//         cos: "0,69",
//         id: 783,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,65",
//         cos: "0,8",
//         id: 784,
//         parents: []
//     },
//     {
//         name: "Дымососы",
//         ki: "0,9",
//         cos: "0,901",
//         id: 785,
//         parents: []
//     },
//     {
//         name: "Насосы водяные",
//         ki: "0,7",
//         cos: "0,8",
//         id: 786,
//         parents: []
//     },
//     {
//         name: "Механизмы на сплаве: сплоточные машины",
//         ki: "0,6",
//         cos: "0,69",
//         id: 787,
//         parents: []
//     },
//     {
//         name: "Сортировочные машины",
//         ki: "0,6",
//         cos: "0,69",
//         id: 788,
//         parents: []
//     },
//     {
//         name: "Сортировочно-формовочные машины",
//         ki: "0,65",
//         cos: "0,69",
//         id: 789,
//         parents: []
//     },
//     {
//         name: "Гидроускорители",
//         ki: "0,65",
//         cos: "0,69",
//         id: 790,
//         parents: []
//     },
//     {
//         name: "Потокообразователи",
//         ki: "0,7",
//         cos: "0,8",
//         id: 791,
//         parents: []
//     },
//     {
//         name: "Барабанные ускорители",
//         ki: "0,3",
//         cos: "0,554",
//         id: 792,
//         parents: []
//     },
//     {
//         name: "Троповые ускорители",
//         ki: "0,5",
//         cos: "0,6",
//         id: 793,
//         parents: []
//     },
//     {
//         name: "Мельница",
//         ki: "0,97",
//         cos: "0,88",
//         id: 794,
//         parents: []
//     },
//     {
//         name: "Дробилка",
//         ki: "0,33",
//         cos: "0,77",
//         id: 795,
//         parents: []
//     },
//     {
//         name: "Оборудование размольного отделения без мельниц",
//         ki: "0,43",
//         cos: "0,81",
//         id: 796,
//         parents: []
//     },
//     {
//         name: "Насосы непрерывного режима работы (перекачка воды, технологического полупродукта)",
//         ki: "0,7",
//         cos: "по сх.замещения",
//         id: 797,
//         parents: []
//     },
//     {
//         name: "Насосы периодического режима работы (вывод шлама, перекачка химикатов)",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 798,
//         parents: []
//     },
//     {
//         name: "Сцежи",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 799,
//         parents: []
//     },
//     {
//         name: "Мешалки",
//         ki: "0,325",
//         cos: "по сх.замещения",
//         id: 800,
//         parents: []
//     },
//     {
//         name: "Сепараторы",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 801,
//         parents: []
//     },
//     {
//         name: "Компрессоры сжатого воздуха",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 802,
//         parents: []
//     },
//     {
//         name: "Насосы непрерывного режима",
//         ki: "0,7",
//         cos: "по сх.замещения",
//         id: 803,
//         parents: []
//     },
//     {
//         name: "Насосы периодического режима работы",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 804,
//         parents: []
//     },
//     {
//         name: "Мешалки",
//         ki: "0,325",
//         cos: "по сх.замещения",
//         id: 805,
//         parents: []
//     },
//     {
//         name: "Сепараторы",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 806,
//         parents: []
//     },
//     {
//         name: "Турбовоздуходувки, нагнетатели воздуха",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 807,
//         parents: []
//     },
//     {
//         name: "Дымососы",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 808,
//         parents: []
//     },
//     {
//         name: "Вентиляторы производственные",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 809,
//         parents: []
//     },
//     {
//         name: "Пневмотранспорт",
//         ki: "0,82",
//         cos: "по сх.замещения",
//         id: 810,
//         parents: []
//     },
//     {
//         name: "Транспортеры, конвейеры, питатели",
//         ki: "0,525",
//         cos: "по сх.замещения",
//         id: 811,
//         parents: []
//     },
//     {
//         name: "Флотаторы",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 812,
//         parents: []
//     },
//     {
//         name: "ЦРМ",
//         ki: "0,65",
//         cos: "по сх.замещения",
//         id: 813,
//         parents: []
//     },
//     {
//         name: "Упаковочная установка",
//         ki: "0,6",
//         cos: "по сх.замещения",
//         id: 814,
//         parents: []
//     },
//     {
//         name: "Насосы водозаборных и очистных сооружений",
//         ki: "0,7",
//         cos: "по сх.замещения",
//         id: 815,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,625",
//         cos: "по сх.замещения",
//         id: 816,
//         parents: []
//     },
//     {
//         name: "Станочное оборудование ремонтных мастерских",
//         ki: "0,135",
//         cos: "по сх.замещения",
//         id: 817,
//         parents: []
//     },
//     {
//         name: "Электротали",
//         ki: "0,075",
//         cos: "по сх.замещения",
//         id: 818,
//         parents: []
//     },
//     {
//         name: "Электросварочные аппараты для ручной сварки",
//         ki: "0,325",
//         cos: "по сх.замещения",
//         id: 819,
//         parents: []
//     },
//     {
//         name: "Оборудование КИПиА",
//         ki: "0,225",
//         cos: "по сх.замещения",
//         id: 820,
//         parents: []
//     },
//     {
//         name: "Электропривод задвижек",
//         ki: "0,65",
//         cos: "",
//         id: 821,
//         parents: []
//     },
//     {
//         name: "Сырьевые мельницы (главный привод)",
//         ki: "0,72",
//         cos: "0,85",
//         id: 822,
//         parents: []
//     },
//     {
//         name: "Низковольтное оборудование сырьевого цеха",
//         ki: "0,56",
//         cos: "0,75",
//         id: 823,
//         parents: []
//     },
//     {
//         name: "Шлам-насосы",
//         ki: "0,56",
//         cos: "0,75",
//         id: 824,
//         parents: []
//     },
//     {
//         name: "Болтушки",
//         ki: "0,62",
//         cos: "0,8",
//         id: 825,
//         parents: []
//     },
//     {
//         name: "Дробилки",
//         ki: "0,54",
//         cos: "0,8",
//         id: 826,
//         parents: []
//     },
//     {
//         name: "Крановые мешалки шлама",
//         ki: "0,38",
//         cos: "0,5",
//         id: 827,
//         parents: []
//     },
//     {
//         name: "Экскаваторы",
//         ki: "0,4",
//         cos: "0,7",
//         id: 828,
//         parents: []
//     },
//     {
//         name: "Транспортеры сырья",
//         ki: "0,5",
//         cos: "0,75",
//         id: 829,
//         parents: []
//     },
//     {
//         name: "Карьер в целом",
//         ki: "0,65",
//         cos: "0,75",
//         id: 830,
//         parents: []
//     },
//     {
//         name: "Вращающиеся печи без холодильников",
//         ki: "0,7",
//         cos: "0,8",
//         id: 831,
//         parents: []
//     },
//     {
//         name: "Вращающиеся печи с холодильниками",
//         ki: "0,6",
//         cos: "0,7",
//         id: 832,
//         parents: []
//     },
//     {
//         name: "Главные приводы печей",
//         ki: "0,7",
//         cos: "0,8",
//         id: 833,
//         parents: []
//     },
//     {
//         name: "Дымососы печей",
//         ki: "0,7",
//         cos: "0,8",
//         id: 834,
//         parents: []
//     },
//     {
//         name: "Механизмы переуборки",
//         ki: "0,46",
//         cos: "0,65",
//         id: 835,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,57",
//         cos: "0,75",
//         id: 836,
//         parents: []
//     },
//     {
//         name: "Транспортеры,клинкера",
//         ki: "0,45",
//         cos: "0,7",
//         id: 837,
//         parents: []
//     },
//     {
//         name: "Холодильники",
//         ki: "0,6",
//         cos: "0,85",
//         id: 838,
//         parents: []
//     },
//     {
//         name: "Электрофильтры",
//         ki: "0,8",
//         cos: "0,85",
//         id: 839,
//         parents: []
//     },
//     {
//         name: "Цементные мельницы в целом",
//         ki: "0,8",
//         cos: "0,85",
//         id: 840,
//         parents: []
//     },
//     {
//         name: "Главный привод цементных мельниц",
//         ki: "0,85",
//         cos: "0,875",
//         id: 841,
//         parents: []
//     },
//     {
//         name: "Низковольтное оборудование цементных мельниц",
//         ki: "0,48",
//         cos: "0,75",
//         id: 842,
//         parents: []
//     },
//     {
//         name: "Упаковочная",
//         ki: "0,4",
//         cos: "0,7",
//         id: 843,
//         parents: []
//     },
//     {
//         name: "Грейферные краны",
//         ki: "0,5",
//         cos: "0,6",
//         id: 844,
//         parents: []
//     },
//     {
//         name: "Пневмовинтовые насосы (фулер-насосы)",
//         ki: "0,48",
//         cos: "0,75",
//         id: 845,
//         parents: []
//     },
//     {
//         name: "Сушильное отделение",
//         ki: "0,6",
//         cos: "0,75",
//         id: 846,
//         parents: []
//     },
//     {
//         name: "Питатели и дозаторы",
//         ki: "0,6",
//         cos: "0,78",
//         id: 847,
//         parents: []
//     },
//     {
//         name: "Угольные мельницы",
//         ki: "0,7",
//         cos: "0,83",
//         id: 848,
//         parents: []
//     },
//     {
//         name: "Электрокалориферы",
//         ki: "0,6",
//         cos: "0,88",
//         id: 849,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,75",
//         cos: "0,85",
//         id: 850,
//         parents: []
//     },
//     {
//         name: "Водонасосы",
//         ki: "0,8",
//         cos: "0,8",
//         id: 851,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,64",
//         cos: "0,75",
//         id: 852,
//         parents: []
//     },
//     {
//         name: "Бетоноукладчики",
//         ki: "0,15",
//         cos: "0,6",
//         id: 853,
//         parents: []
//     },
//     {
//         name: "Автоматические станки для правки и резки проволоки",
//         ki: "0,15",
//         cos: "0,6",
//         id: 854,
//         parents: []
//     },
//     {
//         name: "Формовочные машины",
//         ki: "0,15",
//         cos: "0,6",
//         id: 855,
//         parents: []
//     },
//     {
//         name: "Конвейеры",
//         ki: "0,15",
//         cos: "0,5",
//         id: 856,
//         parents: []
//     },
//     {
//         name: "Рольганги",
//         ki: "0,1",
//         cos: "0,5",
//         id: 857,
//         parents: []
//     },
//     {
//         name: "Земснаряды",
//         ki: "0,59",
//         cos: "0,735",
//         id: 858,
//         parents: []
//     },
//     {
//         name: "Экскаваторы с электроприводом",
//         ki: "запросить",
//         cos: "0,55",
//         id: 859,
//         parents: []
//     },
//     {
//         name: "Растворные узлы",
//         ki: "запросить",
//         cos: "0,55",
//         id: 860,
//         parents: []
//     },
//     {
//         name: "Краны башенные и портальные",
//         ki: "запросить",
//         cos: "0,5",
//         id: 861,
//         parents: []
//     },
//     {
//         name: "Трансформаторный электроподогрев бетона, отогрев грунта и трубопроводов",
//         ki: "запросить",
//         cos: "0,75",
//         id: 862,
//         parents: []
//     },
//     {
//         name: "Однопостовые двигатель-генераторы сварки",
//         ki: "запросить",
//         cos: "0,6",
//         id: 863,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы",
//         ki: "0,2",
//         cos: "0,4",
//         id: 864,
//         parents: []
//     },
//     {
//         name: "Переносные механизмы",
//         ki: "запросить",
//         cos: "0,45",
//         id: 865,
//         parents: []
//     },
//     {
//         name: "Прядильные машины штапеля",
//         ki: "0,38",
//         cos: "0,7",
//         id: 866,
//         parents: []
//     },
//     {
//         name: "Прядильные машины капрона",
//         ki: "0,65",
//         cos: "0,7",
//         id: 867,
//         parents: []
//     },
//     {
//         name: "Прядильные машины вискозного корда",
//         ki: "0,5",
//         cos: "0,7",
//         id: 868,
//         parents: []
//     },
//     {
//         name: "Прядильные машины центрифугального шелка (главный привод)",
//         ki: "0,52",
//         cos: "0,7",
//         id: 869,
//         parents: []
//     },
//     {
//         name: "Прядильные машины центрифугального шелка (электроверетена)",
//         ki: "0,62",
//         cos: "0,7",
//         id: 870,
//         parents: []
//     },
//     {
//         name: "Прядильные машины ацетатного шелка",
//         ki: "0,7",
//         cos: "0,7",
//         id: 871,
//         parents: []
//     },
//     {
//         name: "Мешалки растворителей",
//         ki: "0,55",
//         cos: "0,8",
//         id: 872,
//         parents: []
//     },
//     {
//         name: "Фильтр-прессы",
//         ki: "0,35",
//         cos: "0,55",
//         id: 873,
//         parents: []
//     },
//     {
//         name: "Крутильные машины ацетатного шелка",
//         ki: "0,6",
//         cos: "0,7",
//         id: 874,
//         parents: []
//     },
//     {
//         name: "Перемоточные машины",
//         ki: "0,78",
//         cos: "0,7",
//         id: 875,
//         parents: []
//     },
//     {
//         name: "Тростильно-крутильные машины",
//         ki: "0,89",
//         cos: "0,8",
//         id: 876,
//         parents: []
//     },
//     {
//         name: "Крутильные машины",
//         ki: "0,64",
//         cos: "0,8",
//         id: 877,
//         parents: []
//     },
//     {
//         name: "Вытяжные машины",
//         ki: "0,7",
//         cos: "0,85",
//         id: 878,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки",
//         ki: "0,74",
//         cos: "0,7",
//         id: 879,
//         parents: []
//     },
//     {
//         name: "Динильные котлы",
//         ki: "0,58",
//         cos: "1",
//         id: 880,
//         parents: []
//     },
//     {
//         name: "Периодопреобразователи",
//         ki: "0,75",
//         cos: "0,9",
//         id: 881,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,78",
//         cos: "0,8",
//         id: 882,
//         parents: []
//     },
//     {
//         name: "Водонасосные",
//         ki: "0,83",
//         cos: "0,8",
//         id: 883,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,64",
//         cos: "0,76",
//         id: 884,
//         parents: []
//     },
//     {
//         name: "Компрессоры этилена",
//         ki: "0,85",
//         cos: "0,9",
//         id: 885,
//         parents: []
//     },
//     {
//         name: "Технологические линии",
//         ki: "0,6",
//         cos: "0,75",
//         id: 886,
//         parents: []
//     },
//     {
//         name: "Эльма-насосы",
//         ki: "0,5",
//         cos: "0,8",
//         id: 887,
//         parents: []
//     },
//     {
//         name: "Центрифуги",
//         ki: "0,4",
//         cos: "0,6",
//         id: 888,
//         parents: []
//     },
//     {
//         name: "Сушилка",
//         ki: "0,5",
//         cos: "0,5",
//         id: 889,
//         parents: []
//     },
//     {
//         name: "Грануляторы",
//         ki: "0,8",
//         cos: "0,8",
//         id: 890,
//         parents: []
//     },
//     {
//         name: "Газодувки",
//         ki: "0,45",
//         cos: "0,7",
//         id: 891,
//         parents: []
//     },
//     {
//         name: "Компрессоры цеха дистилляции и очистки азота",
//         ki: "0,43",
//         cos: "0,7",
//         id: 892,
//         parents: []
//     },
//     {
//         name: "Компрессоры воздушные",
//         ki: "0,67",
//         cos: "0,98",
//         id: 893,
//         parents: []
//     },
//     {
//         name: "Газодувка газогенераторного цеха",
//         ki: "0,57",
//         cos: "0,85",
//         id: 894,
//         parents: []
//     },
//     {
//         name: "Компрессоры газовые",
//         ki: "0,85",
//         cos: "0,99",
//         id: 895,
//         parents: []
//     },
//     {
//         name: "Триплекс-насосы",
//         ki: "0,4",
//         cos: "0,75",
//         id: 896,
//         parents: []
//     },
//     {
//         name: "Двигатель турбины (мототурбонасосы)",
//         ki: "0,81",
//         cos: "0,85",
//         id: 897,
//         parents: []
//     },
//     {
//         name: "Экспансионные машины",
//         ki: "0,8",
//         cos: "0,85",
//         id: 898,
//         parents: []
//     },
//     {
//         name: "Циркуляционные компрессоры синтеза",
//         ki: "0,74",
//         cos: "0,78",
//         id: 899,
//         parents: []
//     },
//     {
//         name: "Нагнетатели кислородно-воздушные смеси (КВС)",
//         ki: "0,82",
//         cos: "0,86",
//         id: 900,
//         parents: []
//     },
//     {
//         name: "Компрессоры аммиачные",
//         ki: "0,7",
//         cos: "0,98",
//         id: 901,
//         parents: []
//     },
//     {
//         name: "Кислорододувки",
//         ki: "0,6",
//         cos: "0,78",
//         id: 902,
//         parents: []
//     },
//     {
//         name: "Трубогазодувки",
//         ki: "0,73",
//         cos: "0,9",
//         id: 903,
//         parents: []
//     },
//     {
//         name: "Турбокомпрессоры газовые",
//         ki: "0,8",
//         cos: "0,95",
//         id: 904,
//         parents: []
//     },
//     {
//         name: "Насосы грунтового водоснабжения",
//         ki: "запросить",
//         cos: "0,7",
//         id: 905,
//         parents: []
//     },
//     {
//         name: "Компрессоры, насосы, вентиляторы, мешалки центрифуги (приводы с непрерывным технологическим потоком)",
//         ki: "0,5",
//         cos: "0,86",
//         id: 906,
//         parents: []
//     },
//     {
//         name: "Резиносмесители, работающие на приготовлении резиновой смеси",
//         ki: "0,5",
//         cos: "0,875",
//         id: 907,
//         parents: []
//     },
//     {
//         name: "Резиносмесители, работающие на вулканизацию каучука",
//         ki: "0,54",
//         cos: "0,875",
//         id: 908,
//         parents: []
//     },
//     {
//         name: "Вальцы под резиносмесители",
//         ki: "0,65",
//         cos: "0,875",
//         id: 909,
//         parents: []
//     },
//     {
//         name: "Подогреватели на вальцы",
//         ki: "0,56",
//         cos: "0,875",
//         id: 910,
//         parents: []
//     },
//     {
//         name: "Пелетайзеры",
//         ki: "0,36",
//         cos: "0,875",
//         id: 911,
//         parents: []
//     },
//     {
//         name: "Шприц-машины проекторных агрегатов",
//         ki: "0,7",
//         cos: "0,8",
//         id: 912,
//         parents: []
//     },
//     {
//         name: "Шприц-машины автокамерных агрегатов",
//         ki: "0,53",
//         cos: "0,7",
//         id: 913,
//         parents: []
//     },
//     {
//         name: "Каландры обкладочные (обрезинивание корда)",
//         ki: "0,68",
//         cos: "0,8",
//         id: 914,
//         parents: []
//     },
//     {
//         name: "Сборочные станки",
//         ki: "0,44",
//         cos: "0,6",
//         id: 915,
//         parents: []
//     },
//     {
//         name: "Вулканизаторы шин",
//         ki: "0,05",
//         cos: "0,4",
//         id: 916,
//         parents: []
//     },
//     {
//         name: "Вулканизаторы автокамер и ободных лент",
//         ki: "0,16",
//         cos: "0,5",
//         id: 917,
//         parents: []
//     },
//     {
//         name: "Компрессоры (синхронные двигатели)",
//         ki: "0,93",
//         cos: "0,875",
//         id: 918,
//         parents: []
//     },
//     {
//         name: "Водонасосные (водоснабжение)",
//         ki: "0,89",
//         cos: "0,8",
//         id: 919,
//         parents: []
//     },
//     {
//         name: "Насосы циркуляционной и перегретой воды",
//         ki: "0,43",
//         cos: "0,7",
//         id: 920,
//         parents: []
//     },
//     {
//         name: "Насосы воды низкого давления",
//         ki: "0,87",
//         cos: "0,8",
//         id: 921,
//         parents: []
//     },
//     {
//         name: "Насосы воды высокого давления",
//         ki: "0,55",
//         cos: "0,8",
//         id: 922,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,69",
//         cos: "0,75",
//         id: 923,
//         parents: []
//     },
//     {
//         name: "Транспортные системы",
//         ki: "0,25",
//         cos: "0,45",
//         id: 924,
//         parents: []
//     },
//     {
//         name: "Агрегаты для изготовления особо прочных транспортерных лент",
//         ki: "0,11",
//         cos: "по сх.замещения",
//         id: 925,
//         parents: []
//     },
//     {
//         name: "Каландры для изготовления сердечников транспортерных лент",
//         ki: "0,48",
//         cos: "0,69",
//         id: 926,
//         parents: []
//     },
//     {
//         name: "Каландры для обкладки транспортерных лент",
//         ki: "0,28",
//         cos: "0,51",
//         id: 927,
//         parents: []
//     },
//     {
//         name: "Подогревательные вальцы производства транспортерных лент",
//         ki: "0,47",
//         cos: "0,8",
//         id: 928,
//         parents: []
//     },
//     {
//         name: "Шприц-машины производства формовой техники",
//         ki: "0,37",
//         cos: "0,47",
//         id: 929,
//         parents: []
//     },
//     {
//         name: "Прессы с электрообогревом производства формовой техники при работе",
//         ki: "0,78",
//         cos: "по сх.замещения",
//         id: 930,
//         parents: []
//     },
//     {
//         name: "Прессы с электрообогревом производства формовой техники при разогреве",
//         ki: "запросить",
//         cos: "1",
//         id: 931,
//         parents: []
//     },
//     {
//         name: "Подогревательные вальцы производства формовой техники",
//         ki: "0,43",
//         cos: "0,58",
//         id: 932,
//         parents: []
//     },
//     {
//         name: "Резиносмесители подготовительного цеха",
//         ki: "0,5",
//         cos: "0,8",
//         id: 933,
//         parents: []
//     },
//     {
//         name: "Смесительные вальцы '84' подготовительного цеха",
//         ki: "0,54",
//         cos: "0,8",
//         id: 934,
//         parents: []
//     },
//     {
//         name: "Шприц-машины камер 12-38 мм",
//         ki: "0,4",
//         cos: "0,67",
//         id: 935,
//         parents: []
//     },
//     {
//         name: "Агрегаты для наложения наружного слоя на рукава диаметром 19-38 мм",
//         ki: "0,28",
//         cos: "0,74",
//         id: 936,
//         parents: []
//     },
//     {
//         name: "Каландры подготовительного цеха обкладки",
//         ki: "0,54",
//         cos: "0,69",
//         id: 937,
//         parents: []
//     },
//     {
//         name: "Каландры подготовительного цеха листования",
//         ki: "0,36",
//         cos: "0,52",
//         id: 938,
//         parents: []
//     },
//     {
//         name: "Джины с питателем, волокноочистители",
//         ki: "0,8",
//         cos: "0,82",
//         id: 939,
//         parents: []
//     },
//     {
//         name: "Линтера, семеоголители",
//         ki: "0,7",
//         cos: "0,8",
//         id: 940,
//         parents: []
//     },
//     {
//         name: "Очистители хлопка-сырца, семеочистители",
//         ki: "0,5",
//         cos: "0,7",
//         id: 941,
//         parents: []
//     },
//     {
//         name: "Шнеки хлопка-сырца, отходов, сепараторы, конденсаторы,приемно-подающие устройства хлопка- сырца",
//         ki: "0,4",
//         cos: "0,65",
//         id: 942,
//         parents: []
//     },
//     {
//         name: "Шнеки семян, элеваторы, транспортеры хлопка",
//         ki: "0,3",
//         cos: "0,6",
//         id: 943,
//         parents: []
//     },
//     {
//         name: "Вентиляторы цехового транспорта хлопка-сырца,отсоса от конденсаторов волокна и линта, обеспенивания технологических машин",
//         ki: "0,7",
//         cos: "0,7",
//         id: 944,
//         parents: []
//     },
//     {
//         name: "Вентиляторы дворового пневмотранспорта",
//         ki: "0,5",
//         cos: "0,7",
//         id: 945,
//         parents: []
//     },
//     {
//         name: "Вентиляторы отсоса влаги от бунтов",
//         ki: "0,6",
//         cos: "0,7",
//         id: 946,
//         parents: []
//     },
//     {
//         name: "Гидронасос (с электродвигателем на каждой ступени) и остальные электродвигатели гидропрессовой установки",
//         ki: "0,4",
//         cos: "0,7",
//         id: 947,
//         parents: []
//     },
//     {
//         name: "Гидронасос с одним двигателем на всех ступенях",
//         ki: "0,8",
//         cos: "0,75",
//         id: 948,
//         parents: []
//     },
//     {
//         name: "Основные технологические машины (линии по выработке длинного и короткого волокна)",
//         ki: "0,7",
//         cos: "0,8",
//         id: 949,
//         parents: []
//     },
//     {
//         name: "Транспортные устройства (шнеки, транспортеры, элеваторы)",
//         ki: "0,4",
//         cos: "0,65",
//         id: 950,
//         parents: []
//     },
//     {
//         name: "Вентиляторы и насосы технологического оборудования",
//         ki: "0,7",
//         cos: "0,7",
//         id: 951,
//         parents: []
//     },
//     {
//         name: "Механизмы погрузочно-разгрузочных работ в сырьевой зоне",
//         ki: "0,2",
//         cos: "0,7",
//         id: 952,
//         parents: []
//     },
//     {
//         name: "Прессы-группировки в зоне сортировки сырья",
//         ki: "0,6",
//         cos: "0,75",
//         id: 953,
//         parents: []
//     },
//     {
//         name: "Линии выработки длинного волокна и луба",
//         ki: "0,5",
//         cos: "0,78",
//         id: 954,
//         parents: []
//     },
//     {
//         name: "Линии выработки короткого волокна и луба",
//         ki: "0,5",
//         cos: "0,78",
//         id: 955,
//         parents: []
//     },
//     {
//         name: "Подъемно-транспортное оборудование производственного корпуса",
//         ki: "0,2",
//         cos: "0,7",
//         id: 956,
//         parents: []
//     },
//     {
//         name: "Наружный и внутренний пневмотранспорт и вакуум-насосы пылеуборки",
//         ki: "0,6",
//         cos: "0,85",
//         id: 957,
//         parents: []
//     },
//     {
//         name: "Волокноотделительные машины",
//         ki: "0,84",
//         cos: "0,85",
//         id: 958,
//         parents: []
//     },
//     {
//         name: "Обеспыливающая вентиляция",
//         ki: "0,84",
//         cos: "0,85",
//         id: 959,
//         parents: []
//     },
//     {
//         name: "Насосы технологической насосной станции",
//         ki: "0,7",
//         cos: "0,8",
//         id: 960,
//         parents: []
//     },
//     {
//         name: "Прессовое оборудование участков брикетирования",
//         ki: "0,6",
//         cos: "0,78",
//         id: 961,
//         parents: []
//     },
//     {
//         name: "Разрыхлительно-очистительное оборудование",
//         ki: "0,65",
//         cos: "0,78",
//         id: 962,
//         parents: []
//     },
//     {
//         name: "Трепальное оборудование",
//         ki: "0,68",
//         cos: "0,8",
//         id: 963,
//         parents: []
//     },
//     {
//         name: "Шабазы",
//         ki: "0,3",
//         cos: "0,8",
//         id: 964,
//         parents: []
//     },
//     {
//         name: "Оборудование по переработке отходов производства",
//         ki: "0,65",
//         cos: "0,78",
//         id: 965,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,88",
//         cos: "0,8",
//         id: 966,
//         parents: []
//     },
//     {
//         name: "Ленточные, ровничные и гребне-чесальные машины",
//         ki: "0,68",
//         cos: "0,8",
//         id: 967,
//         parents: []
//     },
//     {
//         name: "Мотально-тростильные машины",
//         ki: "0,82",
//         cos: "0,8",
//         id: 968,
//         parents: []
//     },
//     {
//         name: "Крутильные машины кольцевого кручения",
//         ki: "0,88",
//         cos: "0,85",
//         id: 969,
//         parents: []
//     },
//     {
//         name: "Крутильные машины двухстадийного кручения",
//         ki: "0,82",
//         cos: "0,75",
//         id: 970,
//         parents: []
//     },
//     {
//         name: "Прядильно-крутильные машины",
//         ki: "0,88",
//         cos: "0,85",
//         id: 971,
//         parents: []
//     },
//     {
//         name: "Кольцепрядильные машины ",
//         ki: "0,88",
//         cos: "0,85",
//         id: 972,
//         parents: []
//     },
//     {
//         name: "Прядильные машины пневмомеханического прядения без сероудаления",
//         ki: "0,69",
//         cos: "0,8",
//         id: 973,
//         parents: []
//     },
//     {
//         name: "Прядильные машины пневмомеханического прядения с сероудалением",
//         ki: "0,72",
//         cos: "0,8",
//         id: 974,
//         parents: []
//     },
//     {
//         name: "Прядильные машины роторного прядения",
//         ki: "0,74",
//         cos: "0,8",
//         id: 975,
//         parents: []
//     },
//     {
//         name: "Мотальные и уточно-перемоточные машины",
//         ki: "0,82",
//         cos: "0,8",
//         id: 976,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,67",
//         cos: "0,8",
//         id: 977,
//         parents: []
//     },
//     {
//         name: "Шлихтовальные машины",
//         ki: "0,73",
//         cos: "0,82",
//         id: 978,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки",
//         ki: "0,82",
//         cos: "0,75",
//         id: 979,
//         parents: []
//     },
//     {
//         name: "Браковочные, приемно-контрольные бердоремезные, узловязательные машины",
//         ki: "0,69",
//         cos: "0,75",
//         id: 980,
//         parents: []
//     },
//     {
//         name: "Оборудование отдела перемотки",
//         ki: "0,66",
//         cos: "0,8",
//         id: 981,
//         parents: []
//     },
//     {
//         name: "Оборудование опально-мерсеризационного отдела",
//         ki: "0,68",
//         cos: "0,75",
//         id: 982,
//         parents: []
//     },
//     {
//         name: "Оборудование намоточного отдела",
//         ki: "0,7",
//         cos: "0,75",
//         id: 983,
//         parents: []
//     },
//     {
//         name: "Оборудование отдела выработки фильтров",
//         ki: "0,75",
//         cos: "0,75",
//         id: 984,
//         parents: []
//     },
//     {
//         name: "Машина для упаковки ваты",
//         ki: "0,77",
//         cos: "0,7",
//         id: 985,
//         parents: []
//     },
//     {
//         name: "Ватоформочная машина",
//         ki: "0,77",
//         cos: "0,7",
//         id: 986,
//         parents: []
//     },
//     {
//         name: "Оборудование отделастерилизации и упаковки",
//         ki: "0,79",
//         cos: "0,85",
//         id: 987,
//         parents: []
//     },
//     {
//         name: "Лентоткацкие станки",
//         ki: "0,64",
//         cos: "0,7",
//         id: 988,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,4",
//         cos: "0,7",
//         id: 989,
//         parents: []
//     },
//     {
//         name: "Уточно-мотальные автоматы",
//         ki: "0,63",
//         cos: "0,7",
//         id: 990,
//         parents: []
//     },
//     {
//         name: "Шнуроплетельные машины",
//         ki: "0,65",
//         cos: "0,7",
//         id: 991,
//         parents: []
//     },
//     {
//         name: "Лентоплетельные машины и уточно-перемоточные автоматы",
//         ki: "0,7",
//         cos: "0,72",
//         id: 992,
//         parents: []
//     },
//     {
//         name: "Гардинные челночные машины уточно-вязательные основязательные машины",
//         ki: "0,7",
//         cos: "0,7",
//         id: 993,
//         parents: []
//     },
//     {
//         name: "Гардинные машины",
//         ki: "0,72",
//         cos: "0,7",
//         id: 994,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,3",
//         cos: "0,7",
//         id: 995,
//         parents: []
//     },
//     {
//         name: "Круглотрикотажные и кругловязательные жаккардовые машины, однофонтурные машины",
//         ki: "0,63",
//         cos: "0,7",
//         id: 996,
//         parents: []
//     },
//     {
//         name: "Автомат плосковязательный контурный круглоластичная машина",
//         ki: "0,55",
//         cos: "0,7",
//         id: 997,
//         parents: []
//     },
//     {
//         name: "Бобинажно-перемоточная машина",
//         ki: "0,72",
//         cos: "0,7",
//         id: 998,
//         parents: []
//     },
//     {
//         name: "Рисунчатая многосистемная однофонтурная машина и автомат чулочно-насосный Решель-машина для изготовления гардинного полотна и кругловязальная *котонная однофонтурная машина, двухгребенчатая основовязательная машина",
//         ki: "0,65",
//         cos: "0,75",
//         id: 999,
//         parents: []
//     },
//     {
//         name: "Плосковязательная машина",
//         ki: "0,72",
//         cos: "0,7",
//         id: 1000,
//         parents: []
//     },
//     {
//         name: "Выпускной конвейер",
//         ki: "0,8",
//         cos: "0,8",
//         id: 1001,
//         parents: []
//     },
//     {
//         name: "Льночесальное производство",
//         ki: "0,8",
//         cos: "0,8",
//         id: 1002,
//         parents: []
//     },
//     {
//         name: "Поточные линии",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1003,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,85",
//         cos: "0,8",
//         id: 1004,
//         parents: []
//     },
//     {
//         name: "Гребенечесальные, ленточные и ровничные машины",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1005,
//         parents: []
//     },
//     {
//         name: "Прядильные машины сухого прядения",
//         ki: "0,82",
//         cos: "0,82",
//         id: 1006,
//         parents: []
//     },
//     {
//         name: "Прядильные машины мокрого прядения",
//         ki: "0,8",
//         cos: "0,8",
//         id: 1007,
//         parents: []
//     },
//     {
//         name: "Сушильные машины",
//         ki: "0,79",
//         cos: "0,85",
//         id: 1008,
//         parents: []
//     },
//     {
//         name: "Ткацкое производство",
//         ki: "0,73",
//         cos: "0,84",
//         id: 1009,
//         parents: []
//     },
//     {
//         name: "Мотальные машины",
//         ki: "0,73",
//         cos: "0,84",
//         id: 1010,
//         parents: []
//     },
//     {
//         name: "Крутильные машины",
//         ki: "0,82",
//         cos: "0,81",
//         id: 1011,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1012,
//         parents: []
//     },
//     {
//         name: "Шлихтовальные машины",
//         ki: "0,75",
//         cos: "0,82",
//         id: 1013,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки",
//         ki: "0,82",
//         cos: "0,75",
//         id: 1014,
//         parents: []
//     },
//     {
//         name: "Браковочно-мерильные машины",
//         ki: "0,69",
//         cos: "0,75",
//         id: 1015,
//         parents: []
//     },
//     {
//         name: "Картоносекальная мастерская",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1016,
//         parents: []
//     },
//     {
//         name: "Цеха химической обработки ровницы и крашения пряжи",
//         ki: "0,8",
//         cos: "0,85",
//         id: 1017,
//         parents: []
//     },
//     {
//         name: "Поточные линии подготовки волокна",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1018,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,81",
//         cos: "0,79",
//         id: 1019,
//         parents: []
//     },
//     {
//         name: "Прядильные машины",
//         ki: "0,81",
//         cos: "0,8",
//         id: 1020,
//         parents: []
//     },
//     {
//         name: "Крутильные машины",
//         ki: "0,77",
//         cos: "0,8",
//         id: 1021,
//         parents: []
//     },
//     {
//         name: "Веревочные прядевьющие и свивальные машины",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1022,
//         parents: []
//     },
//     {
//         name: "Канатные прядевьющие и свивальные машины",
//         ki: "0,76",
//         cos: "0,75",
//         id: 1023,
//         parents: []
//     },
//     {
//         name: "Канатоплетенные машины",
//         ki: "0,64",
//         cos: "0,72",
//         id: 1024,
//         parents: []
//     },
//     {
//         name: "Мотальные машины",
//         ki: "0,71",
//         cos: "0,8",
//         id: 1025,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1026,
//         parents: []
//     },
//     {
//         name: "Шлихтовальные машины",
//         ki: "0,72",
//         cos: "0,8",
//         id: 1027,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки",
//         ki: "0,75",
//         cos: "0,72",
//         id: 1028,
//         parents: []
//     },
//     {
//         name: "Браковочно-учетные машины",
//         ki: "0,66",
//         cos: "0,7",
//         id: 1029,
//         parents: []
//     },
//     {
//         name: "Оборудование цеха переработки кизячей шерсти",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1030,
//         parents: []
//     },
//     {
//         name: "Оборудование сортировочного цеха",
//         ki: "0,77",
//         cos: "0,8",
//         id: 1031,
//         parents: []
//     },
//     {
//         name: "Оборудование моечно-сушильного цеха",
//         ki: "0,73",
//         cos: "0,7",
//         id: 1032,
//         parents: []
//     },
//     {
//         name: "Лабазы",
//         ki: "0,3",
//         cos: "0,74",
//         id: 1033,
//         parents: []
//     },
//     {
//         name: "Оборудование помещения рыхления свалков",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1034,
//         parents: []
//     },
//     {
//         name: "Оборудование цеха постоянной классировки",
//         ki: "0,44",
//         cos: "0,85",
//         id: 1035,
//         parents: []
//     },
//     {
//         name: "Оборудование сырьевой лаборатории",
//         ki: "0,7",
//         cos: "0,6",
//         id: 1036,
//         parents: []
//     },
//     {
//         name: "Оборудование дезкамеры",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1037,
//         parents: []
//     },
//     {
//         name: "Карбонизационно-красильные линии непрерывного действия для обработки волокна",
//         ki: "0,79",
//         cos: "0,8",
//         id: 1038,
//         parents: []
//     },
//     {
//         name: "Трепальные машины",
//         ki: "0,79",
//         cos: "0,8",
//         id: 1039,
//         parents: []
//     },
//     {
//         name: "Оборудование для обработки отходов и угарно-ватирное оборудование",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1040,
//         parents: []
//     },
//     {
//         name: "Смесовые машины",
//         ki: "0,59",
//         cos: "0,8",
//         id: 1041,
//         parents: []
//     },
//     {
//         name: "Кольцепрядильные суконные машины",
//         ki: "0,8",
//         cos: "0,83",
//         id: 1042,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,61",
//         cos: "0,82",
//         id: 1043,
//         parents: []
//     },
//     {
//         name: "Пневмомеханические прядильные машины",
//         ki: "0,74",
//         cos: "0,83",
//         id: 1044,
//         parents: []
//     },
//     {
//         name: "Оборудование для обработки угаров",
//         ki: "0,59",
//         cos: "0,8",
//         id: 1045,
//         parents: []
//     },
//     {
//         name: "Смесовые машины",
//         ki: "0,54",
//         cos: "0,82",
//         id: 1046,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,72",
//         cos: "0,8",
//         id: 1047,
//         parents: []
//     },
//     {
//         name: "Ленточные машины",
//         ki: "0,74",
//         cos: "0,8",
//         id: 1048,
//         parents: []
//     },
//     {
//         name: "Гребнечесальные машины",
//         ki: "0,86",
//         cos: "0,77",
//         id: 1049,
//         parents: []
//     },
//     {
//         name: "Штапельные машины",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1050,
//         parents: []
//     },
//     {
//         name: "Красильно-гладильное оборудование",
//         ki: "0,57",
//         cos: "0,8",
//         id: 1051,
//         parents: []
//     },
//     {
//         name: "Розничные машины",
//         ki: "0,74",
//         cos: "0,8",
//         id: 1052,
//         parents: []
//     },
//     {
//         name: "Кольцепрядильные машины камвольные",
//         ki: "0,82",
//         cos: "0,83",
//         id: 1053,
//         parents: []
//     },
//     {
//         name: "Тростильнокрутильное оборудование",
//         ki: "0,82",
//         cos: "0,83",
//         id: 1054,
//         parents: []
//     },
//     {
//         name: "Мотально-сновальные машины",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1055,
//         parents: []
//     },
//     {
//         name: "Шлихтовальные машины",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1056,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки (без двигателей подъема навоев)",
//         ki: "0,8",
//         cos: "0,75",
//         id: 1057,
//         parents: []
//     },
//     {
//         name: "Контрольно-чистильное, приборное оборудование",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1058,
//         parents: []
//     },
//     {
//         name: "Ковровые ткацкие машины двухполотенные",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1059,
//         parents: []
//     },
//     {
//         name: "Однополотенные ковровые ткацкие станки",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1060,
//         parents: []
//     },
//     {
//         name: "Ткацко-приготовительный отдел",
//         ki: "0,75",
//         cos: "0,7",
//         id: 1061,
//         parents: []
//     },
//     {
//         name: "Термофиксация",
//         ki: "0,88",
//         cos: "0,75",
//         id: 1062,
//         parents: []
//     },
//     {
//         name: "Тафтинг-машины",
//         ki: "0,7",
//         cos: "0,77",
//         id: 1063,
//         parents: []
//     },
//     {
//         name: "Латекс-машины",
//         ki: "0,85",
//         cos: "0,75",
//         id: 1064,
//         parents: []
//     },
//     {
//         name: "Оборудование сухой отделки",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1065,
//         parents: []
//     },
//     {
//         name: "Печатный агрегат",
//         ki: "0,78",
//         cos: "0,8",
//         id: 1066,
//         parents: []
//     },
//     {
//         name: "Краскосварочное отделение",
//         ki: "0,78",
//         cos: "0,8",
//         id: 1067,
//         parents: []
//     },
//     {
//         name: "Установка конфекции, резки, упаковки, перемотки",
//         ki: "0,85",
//         cos: "0,8",
//         id: 1068,
//         parents: []
//     },
//     {
//         name: "Основное технологическое оборудование шелкомотального производства",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1069,
//         parents: []
//     },
//     {
//         name: "Оборудование цеха переработки отходов кокономотания",
//         ki: "0,4",
//         cos: "0,65",
//         id: 1070,
//         parents: []
//     },
//     {
//         name: "Крутильное оборудование",
//         ki: "0,79",
//         cos: "0,8",
//         id: 1071,
//         parents: []
//     },
//     {
//         name: "Запарное оборудование",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1072,
//         parents: []
//     },
//     {
//         name: "Уточно-перемоточное оборудование",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1073,
//         parents: []
//     },
//     {
//         name: "Сновальные машины",
//         ki: "0,59",
//         cos: "0,78",
//         id: 1074,
//         parents: []
//     },
//     {
//         name: "Шлихтовальные машины",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1075,
//         parents: []
//     },
//     {
//         name: "Ткацкие станки",
//         ki: "0,84",
//         cos: "0,75",
//         id: 1076,
//         parents: []
//     },
//     {
//         name: "Товарно-чистильное и браковочное оборудование",
//         ki: "0,69",
//         cos: "0,8",
//         id: 1077,
//         parents: []
//     },
//     {
//         name: "Оборудование участков сухой отделки (каландры, стригальные машины, ворсовальные, опальные)",
//         ki: "0,75",
//         cos: "0,82",
//         id: 1078,
//         parents: []
//     },
//     {
//         name: "Термические токоприемники",
//         ki: "0,75",
//         cos: "1",
//         id: 1079,
//         parents: []
//     },
//     {
//         name: "Инфракрасные нагреватели",
//         ki: "1",
//         cos: "1",
//         id: 1080,
//         parents: []
//     },
//     {
//         name: "отделочных линий",
//         ki: "0,62",
//         cos: "0,8",
//         id: 1081,
//         parents: []
//     },
//     {
//         name: "Складальное, мерильное и браковочное оборудование",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1082,
//         parents: []
//     },
//     {
//         name: "Оборудование крашения волокна периодического действия",
//         ki: "0,56",
//         cos: "0,82",
//         id: 1083,
//         parents: []
//     },
//     {
//         name: "Оборудование крашения пряжи периодического действия",
//         ki: "0,56",
//         cos: "0,84",
//         id: 1084,
//         parents: []
//     },
//     {
//         name: "Оборудование подготовки волокна",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1085,
//         parents: []
//     },
//     {
//         name: "Участки лабазов",
//         ki: "0,3",
//         cos: "0,74",
//         id: 1086,
//         parents: []
//     },
//     {
//         name: "Иглопробивные цехи, угарные участки (переработка отходов) и браковочные участки",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1087,
//         parents: []
//     },
//     {
//         name: "Участки лабазов",
//         ki: "0,3",
//         cos: "0,74",
//         id: 1088,
//         parents: []
//     },
//     {
//         name: "Сновально-мотальное оборудование",
//         ki: "0,5",
//         cos: "0,7",
//         id: 1089,
//         parents: []
//     },
//     {
//         name: "Оборудование переработки отходов",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1090,
//         parents: []
//     },
//     {
//         name: "Оборудование обработки отходов",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1091,
//         parents: []
//     },
//     {
//         name: "Швейные машины",
//         ki: "0,6",
//         cos: "0,62",
//         id: 1092,
//         parents: []
//     },
//     {
//         name: "Транспортеры швейных конвейеров",
//         ki: "0,68",
//         cos: "0,8",
//         id: 1093,
//         parents: []
//     },
//     {
//         name: "Браковочно-мерильные машины",
//         ki: "0,4",
//         cos: "0,62",
//         id: 1094,
//         parents: []
//     },
//     {
//         name: "Раскройное оборудование",
//         ki: "0,4",
//         cos: "0,65",
//         id: 1095,
//         parents: []
//     },
//     {
//         name: "Утюги, прессы",
//         ki: "0,75",
//         cos: "0,98",
//         id: 1096,
//         parents: []
//     },
//     {
//         name: "Машины герметизации швов",
//         ki: "0,8",
//         cos: "0,7",
//         id: 1097,
//         parents: []
//     },
//     {
//         name: "Смесители",
//         ki: "0,5",
//         cos: "0,8",
//         id: 1098,
//         parents: []
//     },
//     {
//         name: "Дисторезальная ротационная машина и картонорезальная машина",
//         ki: "0,35",
//         cos: "0,76",
//         id: 1099,
//         parents: []
//     },
//     {
//         name: "Электрогидравлический пресс для вырубки деталей",
//         ki: "0,303",
//         cos: "0,8",
//         id: 1100,
//         parents: []
//     },
//     {
//         name: "Универсальный просекальный станок, пресс гидравлический для деталей и пресс карусельный механический выработки",
//         ki: "0,303",
//         cos: "0,73",
//         id: 1101,
//         parents: []
//     },
//     {
//         name: "Машина для спускания краев деталей низа, браковочно-мерильная полуавтомат для постановки хольнитенов машина и",
//         ki: "0,4",
//         cos: "0,59",
//         id: 1102,
//         parents: []
//     },
//     {
//         name: "Машина проходная для перетяжки перчаточных кож",
//         ki: "0,36",
//         cos: "0,76",
//         id: 1103,
//         parents: []
//     },
//     {
//         name: "Машина раскройная четырехшкивная ленточная",
//         ki: "0,26",
//         cos: "0,76",
//         id: 1104,
//         parents: []
//     },
//     {
//         name: "Машина для спускания краев деталей верха",
//         ki: "0,35",
//         cos: "0,59",
//         id: 1105,
//         parents: []
//     },
//     {
//         name: "Машина для клеймления деталей",
//         ki: "0,28",
//         cos: "0,58",
//         id: 1106,
//         parents: []
//     },
//     {
//         name: "Пресс для тюковки отходов",
//         ki: "0,23",
//         cos: "0,76",
//         id: 1107,
//         parents: []
//     },
//     {
//         name: "Высокочастотная установка",
//         ki: "0,73",
//         cos: "0,86",
//         id: 1108,
//         parents: []
//     },
//     {
//         name: "Пресс для постановки мелкой фурнитуры и обжига рамочных замков",
//         ki: "0,37",
//         cos: "0,58",
//         id: 1109,
//         parents: []
//     },
//     {
//         name: "Машина для багировки и нарезки клинчиков портфелей, сумок",
//         ki: "0,27",
//         cos: "0,59",
//         id: 1110,
//         parents: []
//     },
//     {
//         name: "Машина для гибки и формирования ботанов чемоданов и машина раскройно-ленточная",
//         ki: "0,37",
//         cos: "0,73",
//         id: 1111,
//         parents: []
//     },
//     {
//         name: "Машина для выворотки сумок хозяйственных и дорожных",
//         ki: "0,27",
//         cos: "0,73",
//         id: 1112,
//         parents: []
//     },
//     {
//         name: "Машина для окраски в пачках краев деталей кожгалантерейных изделий",
//         ki: "0,265",
//         cos: "0,58",
//         id: 1113,
//         parents: []
//     },
//     {
//         name: "Машина швейная",
//         ki: "0,35",
//         cos: "0,58",
//         id: 1114,
//         parents: []
//     },
//     {
//         name: "Барабан подвесной для отмочно-зольных процессов, красильно-жировальных процессов и преддубильно-дубильных процессов",
//         ki: "0,23",
//         cos: "0,7",
//         id: 1115,
//         parents: []
//     },
//     {
//         name: "Машина мездрильная",
//         ki: "0,19",
//         cos: "0,8",
//         id: 1116,
//         parents: []
//     },
//     {
//         name: "Машина двоильно-ленточная и машина валичная отжимная",
//         ki: "0,23",
//         cos: "0,8",
//         id: 1117,
//         parents: []
//     },
//     {
//         name: "Машина волососгонно-чистильная",
//         ki: "0,27",
//         cos: "0,88",
//         id: 1118,
//         parents: []
//     },
//     {
//         name: "Пресс для тюковки хромовой стружки",
//         ki: "0,23",
//         cos: "0,78",
//         id: 1119,
//         parents: []
//     },
//     {
//         name: "Шерстомойная машина",
//         ki: "0,54",
//         cos: "0,76",
//         id: 1120,
//         parents: []
//     },
//     {
//         name: "Агрегат для обезволошивания шкур",
//         ki: "0,63",
//         cos: "0,79",
//         id: 1121,
//         parents: []
//     },
//     {
//         name: "Машина строгальная",
//         ki: "0,19",
//         cos: "0,81",
//         id: 1122,
//         parents: []
//     },
//     {
//         name: "Машина разводная гидравлическая",
//         ki: "0,27",
//         cos: "0,83",
//         id: 1123,
//         parents: []
//     },
//     {
//         name: "Машина проходная щетино-дергательная",
//         ki: "0,27",
//         cos: "0,79",
//         id: 1124,
//         parents: []
//     },
//     {
//         name: "Сушилка внаклейку на стекло 200-рамная, проходная, сушилка и вакуумная сушилка",
//         ki: "0,45",
//         cos: "0,87",
//         id: 1125,
//         parents: []
//     },
//     {
//         name: "Сушилка рамная подвесная и машина намазная",
//         ki: "0,45",
//         cos: "0,78",
//         id: 1126,
//         parents: []
//     },
//     {
//         name: "Машина обрезная",
//         ki: "0,23",
//         cos: "0,59",
//         id: 1127,
//         parents: []
//     },
//     {
//         name: "Вибрационно-мягчильная машина, проходной шлифовально-обеспыливающий агрегат и машина шлифовальная",
//         ki: "0,27",
//         cos: "0,85",
//         id: 1128,
//         parents: []
//     },
//     {
//         name: "Машина для очистки от пыли с 2-х сторон шлифовальных кож",
//         ki: "0,27",
//         cos: "0,76",
//         id: 1129,
//         parents: []
//     },
//     {
//         name: "Машина поливочная, агрегат покрывного крашения щетками, а также агрегат покрывного крашения двухкратный",
//         ki: "0,45",
//         cos: "0,76",
//         id: 1130,
//         parents: []
//     },
//     {
//         name: "Аппретурная машина 'Ратона'",
//         ki: "0,45",
//         cos: "0,79",
//         id: 1131,
//         parents: []
//     },
//     {
//         name: "Гидравлический пресс",
//         ki: "0,3",
//         cos: "0,87",
//         id: 1132,
//         parents: []
//     },
//     {
//         name: "Машина проходная гладильная 'Рамора'",
//         ki: "0,36",
//         cos: "0,83",
//         id: 1133,
//         parents: []
//     },
//     {
//         name: "Машина тянульно-мягчильная",
//         ki: "0,27",
//         cos: "0,79",
//         id: 1134,
//         parents: []
//     },
//     {
//         name: "Машина для покрывного крашения и сушки кож",
//         ki: "0,45",
//         cos: "0,87",
//         id: 1135,
//         parents: []
//     },
//     {
//         name: "Измерительная машина и машина для клеймения кож",
//         ki: "0,3",
//         cos: "0,58",
//         id: 1136,
//         parents: []
//     },
//     {
//         name: "Автоматическое распылительное устройство во взрывоопасном исполнении, распылительная кабина",
//         ki: "0,54",
//         cos: "0,79",
//         id: 1137,
//         parents: []
//     },
//     {
//         name: "Машина проходная отжимная",
//         ki: "0,29",
//         cos: "0,81",
//         id: 1138,
//         parents: []
//     },
//     {
//         name: "Выносной транспортер для стружки",
//         ki: "0,25",
//         cos: "0,54",
//         id: 1139,
//         parents: []
//     },
//     {
//         name: "Машина для обрубки краев кож",
//         ki: "0,23",
//         cos: "0,7",
//         id: 1140,
//         parents: []
//     },
//     {
//         name: "Непрерывная увлажнительная машина",
//         ki: "0,36",
//         cos: "0,76",
//         id: 1141,
//         parents: []
//     },
//     {
//         name: "Пылеотстойник",
//         ki: "0,54",
//         cos: "0,8",
//         id: 1142,
//         parents: []
//     },
//     {
//         name: "Проходная обеспыливающая машина с нагнетателем",
//         ki: "0,36",
//         cos: "0,87",
//         id: 1143,
//         parents: []
//     },
//     {
//         name: "Сушилка к агрегатам крашения щетками",
//         ki: "0,45",
//         cos: "0,88",
//         id: 1144,
//         parents: []
//     },
//     {
//         name: "Машина для отжига и разварки кож",
//         ki: "0,27",
//         cos: "0,85",
//         id: 1145,
//         parents: []
//     },
//     {
//         name: "Двоильно-ленточная машина волососгонно-чистильная машина, и машина валичная отжимная",
//         ki: "0,27",
//         cos: "0,81",
//         id: 1146,
//         parents: []
//     },
//     {
//         name: "Мездрильная машина и машина строгальная",
//         ki: "0,19",
//         cos: "0,82",
//         id: 1147,
//         parents: []
//     },
//     {
//         name: "Сушильно-увлажнительный агрегат",
//         ki: "0,45",
//         cos: "0,79",
//         id: 1148,
//         parents: []
//     },
//     {
//         name: "Пресс",
//         ki: "0,23",
//         cos: "0,77",
//         id: 1149,
//         parents: []
//     },
//     {
//         name: "Машина для подвилки кож низа обуви, машина наманная и валичная щеточная машина для жирования кож",
//         ki: "0,27",
//         cos: "0,73",
//         id: 1150,
//         parents: []
//     },
//     {
//         name: "Сушильно-увлажнительный агрегат для сушки и увлажнения юфтевых кож внаклейку на дюралюминиевых листах",
//         ki: "0,45",
//         cos: "0,87",
//         id: 1151,
//         parents: []
//     },
//     {
//         name: "Барабанная разводная машина",
//         ki: "0,36",
//         cos: "0,8",
//         id: 1152,
//         parents: []
//     },
//     {
//         name: "Каток проходной подошвенный",
//         ki: "0,54",
//         cos: "0,8",
//         id: 1153,
//         parents: []
//     },
//     {
//         name: "Машина для измерения площади жестких кож",
//         ki: "0,36",
//         cos: "0,73",
//         id: 1154,
//         parents: []
//     },
//     {
//         name: "Барабан подвесной преддубильно-дубильных процессов",
//         ki: "0,23",
//         cos: "0,7",
//         id: 1155,
//         parents: []
//     },
//     {
//         name: "Пресс для разруба материалов низа",
//         ki: "0,33",
//         cos: "0,65",
//         id: 1156,
//         parents: []
//     },
//     {
//         name: "Машина для выравнивания низа обуви",
//         ki: "0,36",
//         cos: "0,7",
//         id: 1157,
//         parents: []
//     },
//     {
//         name: "Машина для тиснения и перфорации обуви",
//         ki: "0,38",
//         cos: "0,8",
//         id: 1158,
//         parents: []
//     },
//     {
//         name: "Машина для шкурения и шлифовки деталей низа обуви",
//         ki: "9,43*",
//         cos: "0,78",
//         id: 1159,
//         parents: []
//     },
//     {
//         name: "Машина для фрезерования площадки на ход по верху",
//         ki: "0,43",
//         cos: "0,78",
//         id: 1160,
//         parents: []
//     },
//     {
//         name: "Машина для взъерошивания (фрезерование) подошв по периметру",
//         ki: "0,37",
//         cos: "0,75",
//         id: 1161,
//         parents: []
//     },
//     {
//         name: "Машины для фрезерования подошв в печках",
//         ki: "0,24",
//         cos: "0,85",
//         id: 1162,
//         parents: []
//     },
//     {
//         name: "Машина для холодной полировки подошв",
//         ki: "0,42",
//         cos: "0,7",
//         id: 1163,
//         parents: []
//     },
//     {
//         name: "Машина для обеспыливания контура подошв",
//         ki: "0,44",
//         cos: "0,7",
//         id: 1164,
//         parents: []
//     },
//     {
//         name: "Машина для спускания краев деталей низа",
//         ki: "0,47",
//         cos: "0,6",
//         id: 1165,
//         parents: []
//     },
//     {
//         name: "Машина для профилирования подошв площади",
//         ki: "0,36",
//         cos: "0,68",
//         id: 1166,
//         parents: []
//     },
//     {
//         name: "Машина для клеймения размеров",
//         ki: "0,31",
//         cos: "0,95",
//         id: 1167,
//         parents: []
//     },
//     {
//         name: "Машина для вырезания желоба в полустельках",
//         ki: "0,39",
//         cos: "0,72",
//         id: 1168,
//         parents: []
//     },
//     {
//         name: "Машина для приклеивания супинаторов",
//         ki: "0,59",
//         cos: "0,51",
//         id: 1169,
//         parents: []
//     },
//     {
//         name: "Машина для намазки клеем пяточной части",
//         ki: "0,5",
//         cos: "0,68",
//         id: 1170,
//         parents: []
//     },
//     {
//         name: "Машина для склеивания деталей",
//         ki: "0,39",
//         cos: "0,52",
//         id: 1171,
//         parents: []
//     },
//     {
//         name: "Машина для активизации клеевых пленок на подошвах",
//         ki: "0,6",
//         cos: "0,95",
//         id: 1172,
//         parents: []
//     },
//     {
//         name: "Машина для склеивания подошв с каблуками",
//         ki: "0,34",
//         cos: "0,71",
//         id: 1173,
//         parents: []
//     },
//     {
//         name: "Машина для вырезания подошв по контуру",
//         ki: "0,43",
//         cos: "0,6",
//         id: 1174,
//         parents: []
//     },
//     {
//         name: "Машина для снятия фаски с пяточной части стелек",
//         ki: "0,5",
//         cos: "0,8",
//         id: 1175,
//         parents: []
//     },
//     {
//         name: "Машина для формирования подошв стелек",
//         ki: "0,34",
//         cos: "0,68",
//         id: 1176,
//         parents: []
//     },
//     {
//         name: "Сушилка для клеевой пленки на стельках",
//         ki: "0,44",
//         cos: "0,46",
//         id: 1177,
//         parents: []
//     },
//     {
//         name: "Конвейер вырубочной ленточный",
//         ki: "0,55",
//         cos: "0,65",
//         id: 1178,
//         parents: []
//     },
//     {
//         name: "Конвейер для обработки деталей низа",
//         ki: "0,51",
//         cos: "0,52",
//         id: 1179,
//         parents: []
//     },
//     {
//         name: "Пресс для верхнего кроя",
//         ki: "0,35",
//         cos: "0,65",
//         id: 1180,
//         parents: []
//     },
//     {
//         name: "Пресс для раскроя текстильных материалов",
//         ki: "0,31",
//         cos: "0,77",
//         id: 1181,
//         parents: []
//     },
//     {
//         name: "Машина для выравнивания кроя",
//         ki: "0,46",
//         cos: "0,75",
//         id: 1182,
//         parents: []
//     },
//     {
//         name: "Конвейер закройный ленточный для текстиля",
//         ki: "0,4",
//         cos: "0,55",
//         id: 1183,
//         parents: []
//     },
//     {
//         name: "Машина для клеймения деталей верха обуви",
//         ki: "0,77",
//         cos: "0,95",
//         id: 1184,
//         parents: []
//     },
//     {
//         name: "Конвейер заготовочный ленточный",
//         ki: "0,54",
//         cos: "0,81",
//         id: 1185,
//         parents: []
//     },
//     {
//         name: "Конвейер закройный ленточный для верхних кож",
//         ki: "0,45",
//         cos: "0,53",
//         id: 1186,
//         parents: []
//     },
//     {
//         name: "Машина для опускания краев деталей верха заготовок",
//         ki: "0,44",
//         cos: "0,7",
//         id: 1187,
//         parents: []
//     },
//     {
//         name: "Швейная машина для сострачивания деталей верха и машина для разглаживания швов задников",
//         ki: "0,47",
//         cos: "0,7",
//         id: 1188,
//         parents: []
//     },
//     {
//         name: "Машина для загибки краев деталей верха, машина для вставки блочек и швейная машина",
//         ki: "0,44",
//         cos: "0,54",
//         id: 1189,
//         parents: []
//     },
//     {
//         name: "Горячее формирование деталей верха",
//         ki: "0,61",
//         cos: "0,75",
//         id: 1190,
//         parents: []
//     },
//     {
//         name: "Пресс для тиснения и перфорации",
//         ki: "0,38",
//         cos: "0,8",
//         id: 1191,
//         parents: []
//     },
//     {
//         name: "Пресс для дублирования (вклеивания деталей верха обуви)",
//         ki: "0,68",
//         cos: "0,95",
//         id: 1192,
//         parents: []
//     },
//     {
//         name: "Увлажнительная камера заготовок",
//         ki: "0,54",
//         cos: "0,93",
//         id: 1193,
//         parents: []
//     },
//     {
//         name: "Машина для чистки колодок, полировка подошв",
//         ki: "0,3",
//         cos: "0,72",
//         id: 1194,
//         parents: []
//     },
//     {
//         name: "Пресс для прикрепления подошв",
//         ki: "0,36",
//         cos: "0,54",
//         id: 1195,
//         parents: []
//     },
//     {
//         name: "Машина для вытяжки и формирования задников",
//         ki: "0,69",
//         cos: "0,95",
//         id: 1196,
//         parents: []
//     },
//     {
//         name: "Машина для крепления гвоздями деталей низа",
//         ki: "0,54",
//         cos: "0,54",
//         id: 1197,
//         parents: []
//     },
//     {
//         name: "Машина для термоувлажнения",
//         ki: "0,73",
//         cos: "0,95",
//         id: 1198,
//         parents: []
//     },
//     {
//         name: "Машина для обтяжки и клеевой затяжки насосной пусковой части заготовок",
//         ki: "0,47",
//         cos: "0,82",
//         id: 1199,
//         parents: []
//     },
//     {
//         name: "Машина для затяжки гененочной части заготовок",
//         ki: "0,43",
//         cos: "0,95",
//         id: 1200,
//         parents: []
//     },
//     {
//         name: "Машина для затяжки насосной пучковой части обуви",
//         ki: "0,5",
//         cos: "0,82",
//         id: 1201,
//         parents: []
//     },
//     {
//         name: "Машина для затяжки пяточной части заготовок",
//         ki: "0,З",
//         cos: "0,65",
//         id: 1202,
//         parents: []
//     },
//     {
//         name: "Машина для клеевой затяжки пяточной части заготовок",
//         ki: "0,52",
//         cos: "0,82",
//         id: 1203,
//         parents: []
//     },
//     {
//         name: "Машина для затяжки пяточной части заготовок",
//         ki: "0,46",
//         cos: "0,81",
//         id: 1204,
//         parents: []
//     },
//     {
//         name: "Машина для затяжки бочков и пяточной части гвоздями",
//         ki: "0,55",
//         cos: "0,59",
//         id: 1205,
//         parents: []
//     },
//     {
//         name: "Машина для формирования следа обуви",
//         ki: "0,49",
//         cos: "0,81",
//         id: 1206,
//         parents: []
//     },
//     {
//         name: "Сушилка для обуви",
//         ki: "0,45",
//         cos: "0,65",
//         id: 1207,
//         parents: []
//     },
//     {
//         name: "Элеватор (хранение обуви)",
//         ki: "0,1",
//         cos: "0,46",
//         id: 1208,
//         parents: []
//     },
//     {
//         name: "Машина для прикрепления каблуков, камера для аппаратирования верха обуви и машина для шкурения пластмассовых каблуков",
//         ki: "0,32",
//         cos: "0,68",
//         id: 1209,
//         parents: []
//     },
//     {
//         name: "Машина для обрезки излишней кромки",
//         ki: "0,5",
//         cos: "0,6",
//         id: 1210,
//         parents: []
//     },
//     {
//         name: "Сушилка, первая и вторая намазка клеем",
//         ki: "0,44",
//         cos: "0,68",
//         id: 1211,
//         parents: []
//     },
//     {
//         name: "Термоактиватор (активизация клеевых пленок)",
//         ki: "0,6",
//         cos: "0,95",
//         id: 1212,
//         parents: []
//     },
//     {
//         name: "Пресс для склеивания подошв с каблуками",
//         ki: "0,34",
//         cos: "0,71",
//         id: 1213,
//         parents: []
//     },
//     {
//         name: "Машина для фрезерования подошв",
//         ki: "0,37",
//         cos: "0,75",
//         id: 1214,
//         parents: []
//     },
//     {
//         name: "Машина для шлифования каблуков",
//         ki: "0,53",
//         cos: "0,82",
//         id: 1215,
//         parents: []
//     },
//     {
//         name: "Машина для снятия обуви с колодок",
//         ki: "0,5",
//         cos: "0,73",
//         id: 1216,
//         parents: []
//     },
//     {
//         name: "Машина для утюжки верха обуви",
//         ki: "0,31",
//         cos: "1",
//         id: 1217,
//         parents: []
//     },
//     {
//         name: "Машина для разглаживания верха",
//         ki: "0,4",
//         cos: "1",
//         id: 1218,
//         parents: []
//     },
//     {
//         name: "Машина для разглаживания голени",
//         ki: "0,5",
//         cos: "1",
//         id: 1219,
//         parents: []
//     },
//     {
//         name: "Баркас с подогревателем и мешалка переносная Н/О",
//         ki: "0,21",
//         cos: "0,76",
//         id: 1220,
//         parents: []
//     },
//     {
//         name: "Разводная валичная машина",
//         ki: "0,39",
//         cos: "0,79",
//         id: 1221,
//         parents: []
//     },
//     {
//         name: "Рубильная машина",
//         ki: "0,39",
//         cos: "0,79",
//         id: 1222,
//         parents: []
//     },
//     {
//         name: "Проходная шерстерезная машина",
//         ki: "0,15",
//         cos: "0,79",
//         id: 1223,
//         parents: []
//     },
//     {
//         name: "Центрифуга",
//         ki: "0,39",
//         cos: "0,87",
//         id: 1224,
//         parents: []
//     },
//     {
//         name: "Линия непрерывной обработки",
//         ki: "0,445",
//         cos: "0,8",
//         id: 1225,
//         parents: []
//     },
//     {
//         name: "Загрузочный транспортер",
//         ki: "0,45",
//         cos: "0,58",
//         id: 1226,
//         parents: []
//     },
//     {
//         name: "Машина для мойки и обезвреживания",
//         ki: "0,23",
//         cos: "0,88",
//         id: 1227,
//         parents: []
//     },
//     {
//         name: "Мездрильная машина",
//         ki: "0,27",
//         cos: "0,8",
//         id: 1228,
//         parents: []
//     },
//     {
//         name: "Машина для уменьшения толщины кожевой ткани",
//         ki: "0,39",
//         cos: "0,76",
//         id: 1229,
//         parents: []
//     },
//     {
//         name: "Машина отжимная",
//         ki: "0,45",
//         cos: "0,83",
//         id: 1230,
//         parents: []
//     },
//     {
//         name: "Отжимной пресс",
//         ki: "0,2",
//         cos: "0,83",
//         id: 1231,
//         parents: []
//     },
//     {
//         name: "Жировальная машина",
//         ki: "0,2",
//         cos: "0,76",
//         id: 1232,
//         parents: []
//     },
//     {
//         name: "Мялка молотковая",
//         ki: "0,45",
//         cos: "0,79",
//         id: 1233,
//         parents: []
//     },
//     {
//         name: "Агрегат анилинового крашения",
//         ki: "0,5",
//         cos: "0,86",
//         id: 1234,
//         parents: []
//     },
//     {
//         name: "Сушилка концевая рамная",
//         ki: "0,59",
//         cos: "0,76",
//         id: 1235,
//         parents: []
//     },
//     {
//         name: "Проходная разбивочная машина",
//         ki: "0,45",
//         cos: "0,77",
//         id: 1236,
//         parents: []
//     },
//     {
//         name: "Стригальная машина",
//         ki: "0,3",
//         cos: "0,8",
//         id: 1237,
//         parents: []
//     },
//     {
//         name: "Барабан комбинированный для откатки",
//         ki: "0,28",
//         cos: "0,8",
//         id: 1238,
//         parents: []
//     },
//     {
//         name: "Сетчатый барабан",
//         ki: "0,28",
//         cos: "0,77",
//         id: 1239,
//         parents: []
//     },
//     {
//         name: "Чесальная машина",
//         ki: "0,54",
//         cos: "0,77",
//         id: 1240,
//         parents: []
//     },
//     {
//         name: "Намазная машина",
//         ki: "0,28",
//         cos: "0,76",
//         id: 1241,
//         parents: []
//     },
//     {
//         name: "Машина гладильная",
//         ki: "0,38",
//         cos: "0,8",
//         id: 1242,
//         parents: []
//     },
//     {
//         name: "Машина для тяжки, разбивки шкурок норки",
//         ki: "0,37",
//         cos: "0,64",
//         id: 1243,
//         parents: []
//     },
//     {
//         name: "Подвесная петельная сушилка",
//         ki: "0,45",
//         cos: "0,82",
//         id: 1244,
//         parents: []
//     },
//     {
//         name: "Машина проходная для склеивания меха на тканевой основе",
//         ki: "0,27",
//         cos: "0,59",
//         id: 1245,
//         parents: []
//     },
//     {
//         name: "Станок для заточки дисковых ножей",
//         ki: "0,2",
//         cos: "0,76",
//         id: 1246,
//         parents: []
//     },
//     {
//         name: "Колотильная машина и 2-х барабанная чесальная машина",
//         ki: "0,45",
//         cos: "0,76",
//         id: 1247,
//         parents: []
//     },
//     {
//         name: "Вибрационно-мягчильная машина 'Поллиса' и шлифовальная мягчительная машина",
//         ki: "0,23",
//         cos: "0,83",
//         id: 1248,
//         parents: []
//     },
//     {
//         name: "Машина растяжная для шкурок норки",
//         ki: "0,23",
//         cos: "0,73",
//         id: 1249,
//         parents: []
//     },
//     {
//         name: "Измерительная машина",
//         ki: "0,3",
//         cos: "0,64",
//         id: 1250,
//         parents: []
//     },
//     {
//         name: "Гидропресс",
//         ki: "0,18",
//         cos: "0,84",
//         id: 1251,
//         parents: []
//     },
//     {
//         name: "Сушилка для подсушки",
//         ki: "0,36",
//         cos: "0,73",
//         id: 1252,
//         parents: []
//     },
//     {
//         name: "Обрезная машина",
//         ki: "0,23",
//         cos: "0,59",
//         id: 1253,
//         parents: []
//     },
//     {
//         name: "Аппарат для обезжиривания",
//         ki: "0,27",
//         cos: "0,83",
//         id: 1254,
//         parents: []
//     },
//     {
//         name: "Машина для химической чистки",
//         ki: "0,3",
//         cos: "0,87",
//         id: 1255,
//         parents: []
//     },
//     {
//         name: "Адсорбер",
//         ki: "0,45",
//         cos: "0,72",
//         id: 1256,
//         parents: []
//     },
//     {
//         name: "Нитроагрегат",
//         ki: "0,45",
//         cos: "0,84",
//         id: 1257,
//         parents: []
//     },
//     {
//         name: "Щеточная машина",
//         ki: "0,36",
//         cos: "0,76",
//         id: 1258,
//         parents: []
//     },
//     {
//         name: "Скорняжная машина",
//         ki: "0,45",
//         cos: "0,54",
//         id: 1259,
//         parents: []
//     },
//     {
//         name: "Агрегат для опарки шапок и агрегат для правки шапок",
//         ki: "0,225",
//         cos: "0,78",
//         id: 1260,
//         parents: []
//     },
//     {
//         name: "Сушилка",
//         ki: "0,58",
//         cos: "0,73",
//         id: 1261,
//         parents: []
//     },
//     {
//         name: "Машина маркировочная, машина для измерения размеров головных уборов и машина для заполнения реквизитов на ленточке",
//         ki: "0,23",
//         cos: "0,56",
//         id: 1262,
//         parents: []
//     },
//     {
//         name: "Машина для чистки и обжига",
//         ki: "0,27",
//         cos: "0,54",
//         id: 1263,
//         parents: []
//     },
//     {
//         name: "Maшина электрозакройная",
//         ki: "0,33",
//         cos: "0,64",
//         id: 1264,
//         parents: []
//     },
//     {
//         name: "Пресс",
//         ki: "0,23",
//         cos: "0,72",
//         id: 1265,
//         parents: []
//     },
//     {
//         name: "Автомат для печатания ярлыков",
//         ki: "0,28",
//         cos: "0,53",
//         id: 1266,
//         parents: []
//     },
//     {
//         name: "Механическая линейка для обрезания полотен при настилании",
//         ki: "0,23",
//         cos: "0,53",
//         id: 1267,
//         parents: []
//     },
//     {
//         name: "Машина для вырезания наружных контуров",
//         ki: "0,3",
//         cos: "0,54",
//         id: 1268,
//         parents: []
//     },
//     {
//         name: "Машина для высекания внутренних контуров, лекал, машина для высекания фигурных отверстий в лекалах, утюг, станок для клеймения срезов лекал, машина для резки карточных заготовок лекал из рулона",
//         ki: "0,23",
//         cos: "0,58",
//         id: 1269,
//         parents: []
//     },
//     {
//         name: "Пресс гидравлический и пачковязательная машина",
//         ki: "0,23",
//         cos: "0,59",
//         id: 1270,
//         parents: []
//     },
//     {
//         name: "Машина раскройная четырехшкивная ленточная",
//         ki: "0,27",
//         cos: "0,64",
//         id: 1271,
//         parents: []
//     },
//     {
//         name: "Машина фотоэлектронная для измерения площади лекал",
//         ki: "0,27",
//         cos: "0,54",
//         id: 1272,
//         parents: []
//     },
//     {
//         name: "Установка для скоростного копирования лекал",
//         ki: "0,27",
//         cos: "0,72",
//         id: 1273,
//         parents: []
//     },
//     {
//         name: "Машина швейная 12-ти игольная для стяжки ватина",
//         ki: "0,36",
//         cos: "0,73",
//         id: 1274,
//         parents: []
//     },
//     {
//         name: "Щетка механическая для чистки изделий",
//         ki: "0,23",
//         cos: "0,76",
//         id: 1275,
//         parents: []
//     },
//     {
//         name: "Швейные машины различных классов",
//         ki: "0,36",
//         cos: "0,58",
//         id: 1276,
//         parents: []
//     },
//     {
//         name: "Механическая линейная для обрезки и клеймения полотен при настилании",
//         ki: "0,3",
//         cos: "0,54",
//         id: 1277,
//         parents: []
//     },
//     {
//         name: "Автомат для печатания контрольно-маркировочных талонов",
//         ki: "0,27",
//         cos: "0,59",
//         id: 1278,
//         parents: []
//     },
//     {
//         name: "Установка для перекачки мездры",
//         ki: "0,54",
//         cos: "0,86",
//         id: 1279,
//         parents: []
//     },
//     {
//         name: "Установка с насосами для повторного использования стоков",
//         ki: "0,36",
//         cos: "0,79",
//         id: 1280,
//         parents: []
//     },
//     {
//         name: "Транспортер для опилок",
//         ki: "0,27",
//         cos: "0,76",
//         id: 1281,
//         parents: []
//     },
//     {
//         name: "Конвейеры отделочные",
//         ki: "0,36",
//         cos: "0,76",
//         id: 1282,
//         parents: []
//     },
//     {
//         name: "Перекидчик",
//         ki: "0,23",
//         cos: "0,76",
//         id: 1283,
//         parents: []
//     },
//     {
//         name: "Бункер для опилок и лоскута",
//         ki: "0,38",
//         cos: "0,64",
//         id: 1284,
//         parents: []
//     },
//     {
//         name: "Конвейер и транспортер механический для хранения и транспортирования лекал",
//         ki: "0,45",
//         cos: "0,76",
//         id: 1285,
//         parents: []
//     },
//     {
//         name: "Механизированный настилочный стол с решающейся этажеркой",
//         ki: "0,36",
//         cos: "0,58",
//         id: 1286,
//         parents: []
//     },
//     {
//         name: "Элеватор для хранения кроя",
//         ki: "0,27",
//         cos: "0,59",
//         id: 1287,
//         parents: []
//     },
//     {
//         name: "Механизированная вешалка",
//         ki: "0,27",
//         cos: "0,64",
//         id: 1288,
//         parents: []
//     },
//     {
//         name: "Линия для подготовки и смешивания синтетических волокон и линия для формирования лицевого покрытия синтетической кожи с экспериментальной камерой предкоагуляции",
//         ki: "0,675",
//         cos: "0,76",
//         id: 1289,
//         parents: []
//     },
//     {
//         name: "Агрегат для формирования волокнистого слоя и предварительного иглопрокалывания",
//         ki: "0,63",
//         cos: "0,87",
//         id: 1290,
//         parents: []
//     },
//     {
//         name: "Агрегат для термоусадки и агрегат для дублирования",
//         ki: "0,63",
//         cos: "0,77",
//         id: 1291,
//         parents: []
//     },
//     {
//         name: "Линия для пропитки волокнистой основы и агрегат для промывки",
//         ki: "0,68",
//         cos: "0,79",
//         id: 1292,
//         parents: []
//     },
//     {
//         name: "Машина сушильно-ширильная",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1293,
//         parents: []
//     },
//     {
//         name: "Агрегат красильно-сушильный для отделки",
//         ki: "0,5",
//         cos: "0,76",
//         id: 1294,
//         parents: []
//     },
//     {
//         name: "Щипальная машина с конденсатором, машина для набивки шерсти в мешки",
//         ki: "0,45",
//         cos: "0,77",
//         id: 1295,
//         parents: []
//     },
//     {
//         name: "Шлифовальная машина и двоильно-ленточная машина",
//         ki: "0,54",
//         cos: "0,87",
//         id: 1296,
//         parents: []
//     },
//     {
//         name: "Двухстадийный смеситель и двухроторный смеситель",
//         ki: "0,54",
//         cos: "0,89",
//         id: 1297,
//         parents: []
//     },
//     {
//         name: "Червячный асциллирующий смеситель",
//         ki: "0,63",
//         cos: "0,68",
//         id: 1298,
//         parents: []
//     },
//     {
//         name: "Червячный пресс-кранолятор",
//         ki: "0,63",
//         cos: "0,87",
//         id: 1299,
//         parents: []
//     },
//     {
//         name: "Установка пневмотранспорта и диспергатор для перетирования пигментов",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1300,
//         parents: []
//     },
//     {
//         name: "Вакуум-насос",
//         ki: "0,63",
//         cos: "0,76",
//         id: 1301,
//         parents: []
//     },
//     {
//         name: "Тепловая станция и диспергатор для перетирования наполнителей",
//         ki: "0,63",
//         cos: "0,86",
//         id: 1302,
//         parents: []
//     },
//     {
//         name: "Весы автоматические",
//         ki: "0,5",
//         cos: "0,59",
//         id: 1303,
//         parents: []
//     },
//     {
//         name: "Копировальная машина",
//         ki: "0,67",
//         cos: "0,77",
//         id: 1304,
//         parents: []
//     },
//     {
//         name: "Печатная 4-х красочная машина",
//         ki: "0,5",
//         cos: "0,77",
//         id: 1305,
//         parents: []
//     },
//     {
//         name: "Реактор емкостью до 1250 л",
//         ki: "0,63",
//         cos: "0,77",
//         id: 1306,
//         parents: []
//     },
//     {
//         name: "Планетарный смеситель",
//         ki: "0,55",
//         cos: "9,76*",
//         id: 1307,
//         parents: []
//     },
//     {
//         name: "Установка для тиснения",
//         ki: "0,68",
//         cos: "0,86",
//         id: 1308,
//         parents: []
//     },
//     {
//         name: "Каландр отделочный",
//         ki: "0,49",
//         cos: "0,87",
//         id: 1309,
//         parents: []
//     },
//     {
//         name: "Машина для paзброкировки клеенки",
//         ki: "0,57",
//         cos: "0,76",
//         id: 1310,
//         parents: []
//     },
//     {
//         name: "Машина ширильно-цепная",
//         ki: "0,58",
//         cos: "0,77",
//         id: 1311,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологических линий",
//         ki: "0,7",
//         cos: "0,89",
//         id: 1312,
//         parents: []
//     },
//     {
//         name: "Насосы технологических линий",
//         ki: "0,8",
//         cos: "0,76",
//         id: 1313,
//         parents: []
//     },
//     {
//         name: "Контакт-дисельвер",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1314,
//         parents: []
//     },
//     {
//         name: "Краскотерна и мельница тонкого помола",
//         ki: "0,54",
//         cos: "0,86",
//         id: 1315,
//         parents: []
//     },
//     {
//         name: "Вакуум-синусдисольвер",
//         ki: "0,63",
//         cos: "0,83",
//         id: 1316,
//         parents: []
//     },
//     {
//         name: "Вакуум-мешалка",
//         ki: "0,55",
//         cos: "0,76",
//         id: 1317,
//         parents: []
//     },
//     {
//         name: "Вакуум-насос",
//         ki: "0,63",
//         cos: "0,76",
//         id: 1318,
//         parents: []
//     },
//     {
//         name: "Ленточно-шлифовальный станок",
//         ki: "0,45",
//         cos: "0,87",
//         id: 1319,
//         parents: []
//     },
//     {
//         name: "Мяльный агрегат с распределительным шкафом",
//         ki: "0,54",
//         cos: "0,77",
//         id: 1320,
//         parents: []
//     },
//     {
//         name: "Комбинированная браковочно-учетная машина",
//         ki: "0,45",
//         cos: "0,83",
//         id: 1321,
//         parents: []
//     },
//     {
//         name: "Тепловая установка",
//         ki: "0,63",
//         cos: "0,89",
//         id: 1322,
//         parents: []
//     },
//     {
//         name: "Браковочно-перемотальная машина для подложки",
//         ki: "0,47",
//         cos: "0,83",
//         id: 1323,
//         parents: []
//     },
//     {
//         name: "Автоматическая тандем-установка для нанесения покрытия",
//         ki: "0,68",
//         cos: "0,87",
//         id: 1324,
//         parents: []
//     },
//     {
//         name: "Агрегат 4-х дисковый для резки синтетического каучука и нож гидравлический",
//         ki: "0,23",
//         cos: "0,85",
//         id: 1325,
//         parents: []
//     },
//     {
//         name: "Вальцы 800 мм рафинирующие и резиносмеситель",
//         ki: "0,55",
//         cos: "0,8",
//         id: 1326,
//         parents: []
//     },
//     {
//         name: "Камера механизированная для разогрева каучука",
//         ki: "0,54",
//         cos: "0,77",
//         id: 1327,
//         parents: []
//     },
//     {
//         name: "Вальцы смесительные и вальцы подогревательные",
//         ki: "0,55",
//         cos: "0,85",
//         id: 1328,
//         parents: []
//     },
//     {
//         name: "Смеситель и краскотерка 3-х валковая",
//         ki: "0,54",
//         cos: "0,79",
//         id: 1329,
//         parents: []
//     },
//     {
//         name: "Одночервячная машина",
//         ki: "0,4",
//         cos: "0,8",
//         id: 1330,
//         parents: []
//     },
//     {
//         name: "Штамп-автомат",
//         ki: "0,35",
//         cos: "0,72",
//         id: 1331,
//         parents: []
//     },
//     {
//         name: "Пресс карусельный вулканизационный",
//         ki: "0,23",
//         cos: "0,75",
//         id: 1332,
//         parents: []
//     },
//     {
//         name: "Вулканизационный пресс гидравлический",
//         ki: "0,23",
//         cos: "0,72",
//         id: 1333,
//         parents: []
//     },
//     {
//         name: "Двоильно-ленточная машина",
//         ki: "0,45",
//         cos: "0,82",
//         id: 1334,
//         parents: []
//     },
//     {
//         name: "Девулканизатор непрерывного действия",
//         ki: "0,54",
//         cos: "0,85",
//         id: 1335,
//         parents: []
//     },
//     {
//         name: "Бункер с питателем",
//         ki: "0,54",
//         cos: "0,75",
//         id: 1336,
//         parents: []
//     },
//     {
//         name: "Реактор-смеситель",
//         ki: "0,54",
//         cos: "0,88",
//         id: 1337,
//         parents: []
//     },
//     {
//         name: "Бункер с ворошителем",
//         ki: "0,54",
//         cos: "0,59",
//         id: 1338,
//         parents: []
//     },
//     {
//         name: "Дозатор с весовым контролем",
//         ki: "0,23",
//         cos: "0,73",
//         id: 1339,
//         parents: []
//     },
//     {
//         name: "Буферная емкость для регенератной крошки с питателем",
//         ki: "0,63",
//         cos: "0,73",
//         id: 1340,
//         parents: []
//     },
//     {
//         name: "Вальцы 800 мм дробильные",
//         ki: "0,55",
//         cos: "0,85",
//         id: 1341,
//         parents: []
//     },
//     {
//         name: "Вальцы размалывающие и рафинирующие",
//         ki: "0,55",
//         cos: "0,8",
//         id: 1342,
//         parents: []
//     },
//     {
//         name: "Мельница тонкого измельчения",
//         ki: "0,53",
//         cos: "0,88",
//         id: 1343,
//         parents: []
//     },
//     {
//         name: "Сито вибрационное одноярусное",
//         ki: "0,63",
//         cos: "0,59",
//         id: 1344,
//         parents: []
//     },
//     {
//         name: "Дробилка молотковая",
//         ki: "0,63",
//         cos: "0,74",
//         id: 1345,
//         parents: []
//     },
//     {
//         name: "Гидроразбиватель и коническая мельница",
//         ki: "0,72",
//         cos: "0,89",
//         id: 1346,
//         parents: []
//     },
//     {
//         name: "Машина для предварительного измельчения отходов и длинносеточная машина",
//         ki: "0,68",
//         cos: "0,88",
//         id: 1347,
//         parents: []
//     },
//     {
//         name: "Аппарат для работы под налив и реактор",
//         ki: "0,63",
//         cos: "0,83",
//         id: 1348,
//         parents: []
//     },
//     {
//         name: "Кондукс тонкого размола",
//         ki: "0,52",
//         cos: "0,88",
//         id: 1349,
//         parents: []
//     },
//     {
//         name: "Емкость для массы с мешалкой и реактор с рамой мешалкой",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1350,
//         parents: []
//     },
//     {
//         name: "Пресс для вырубки стелек и машина для раскроя картона на коробки",
//         ki: "0,23",
//         cos: "0,75",
//         id: 1351,
//         parents: []
//     },
//     {
//         name: "Машина для сшивки коробок",
//         ki: "0,23",
//         cos: "0,54",
//         id: 1352,
//         parents: []
//     },
//     {
//         name: "Горизонтальный транспортер",
//         ki: "0,54",
//         cos: "0,73",
//         id: 1353,
//         parents: []
//     },
//     {
//         name: "Измельчительная мельница",
//         ki: "0,52",
//         cos: "0,9",
//         id: 1354,
//         parents: []
//     },
//     {
//         name: "Мельница с металлическими дисками",
//         ki: "0,52",
//         cos: "0,6",
//         id: 1355,
//         parents: []
//     },
//     {
//         name: "Циклические чаны с мешалкой",
//         ki: "0,54",
//         cos: "0,79",
//         id: 1356,
//         parents: []
//     },
//     {
//         name: "Мельница с базальтовыми дисками",
//         ki: "0,52",
//         cos: "0,8",
//         id: 1357,
//         parents: []
//     },
//     {
//         name: "Конический рафинер и чаны с мешалкой",
//         ki: "0,54",
//         cos: "0,9",
//         id: 1358,
//         parents: []
//     },
//     {
//         name: "Двухсеточная машина",
//         ki: "0,68",
//         cos: "0,86",
//         id: 1359,
//         parents: []
//     },
//     {
//         name: "Машина для резания рулонного картона на листы",
//         ki: "0,58",
//         cos: "0,87",
//         id: 1360,
//         parents: []
//     },
//     {
//         name: "Машина для тиснения и машина для шкурения",
//         ki: "0,45",
//         cos: "0,88",
//         id: 1361,
//         parents: []
//     },
//     {
//         name: "Машина для резания рулонного картона на ленты",
//         ki: "0,375",
//         cos: "0,8",
//         id: 1362,
//         parents: []
//     },
//     {
//         name: "Линия для упаковки листов картона",
//         ki: "0,575",
//         cos: "0,75",
//         id: 1363,
//         parents: []
//     },
//     {
//         name: "Малая краскотерочная машина",
//         ki: "0,54",
//         cos: "0,73",
//         id: 1364,
//         parents: []
//     },
//     {
//         name: "Гид роразб и вател ь",
//         ki: "0,63",
//         cos: "0,88",
//         id: 1365,
//         parents: []
//     },
//     {
//         name: "Машина для предварительного измельчения кожотходов и кондуксы",
//         ki: "0,65",
//         cos: "0,88",
//         id: 1366,
//         parents: []
//     },
//     {
//         name: "Коническая мельница, бассейн, дезенинтегратор и виброгрохот",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1367,
//         parents: []
//     },
//     {
//         name: "Гидропульпер",
//         ki: "0,63",
//         cos: "0,87",
//         id: 1368,
//         parents: []
//     },
//     {
//         name: "Намоточная машина",
//         ki: "0,75",
//         cos: "0,83",
//         id: 1369,
//         parents: []
//     },
//     {
//         name: "Кондуксы",
//         ki: "0,53",
//         cos: "0,88",
//         id: 1370,
//         parents: []
//     },
//     {
//         name: "Центробежный очиститель",
//         ki: "0,63",
//         cos: "0,76",
//         id: 1371,
//         parents: []
//     },
//     {
//         name: "Машина для мойки фетров",
//         ki: "0,54",
//         cos: "0,75",
//         id: 1372,
//         parents: []
//     },
//     {
//         name: "Длинносеточная машина",
//         ki: "0,68",
//         cos: "0,88",
//         id: 1373,
//         parents: []
//     },
//     {
//         name: "Установка для центрования и обрезка кромок",
//         ki: "0,68",
//         cos: "0,85",
//         id: 1374,
//         parents: []
//     },
//     {
//         name: "Каландр отделочный и намоточная машина",
//         ki: "0,68",
//         cos: "0,83",
//         id: 1375,
//         parents: []
//     },
//     {
//         name: "Шлифовальная машина с системой отсоса пыли",
//         ki: "0,45",
//         cos: "0,85",
//         id: 1376,
//         parents: []
//     },
//     {
//         name: "Оборудование для предварительной сортировки",
//         ki: "0,45",
//         cos: "0,75",
//         id: 1377,
//         parents: []
//     },
//     {
//         name: "Машина для резки картона",
//         ki: "0,3",
//         cos: "0,85",
//         id: 1378,
//         parents: []
//     },
//     {
//         name: "Тиснильный каландр",
//         ki: "0,68",
//         cos: "0,88",
//         id: 1379,
//         parents: []
//     },
//     {
//         name: "Бассейны",
//         ki: "0,8",
//         cos: "0,89",
//         id: 1380,
//         parents: []
//     },
//     {
//         name: "Реакторы, аппараты цельносварные, емкости для массы",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1381,
//         parents: []
//     },
//     {
//         name: "Автомат для вырубки задников и автомат для спускания края задников",
//         ki: "0,3",
//         cos: "0,76",
//         id: 1382,
//         parents: []
//     },
//     {
//         name: "Прессы для формирования и вырубки стелек и простилок и машина для шкурения грани стелек",
//         ki: "0,23",
//         cos: "0,76",
//         id: 1383,
//         parents: []
//     },
//     {
//         name: "Машина для спускания края деталей",
//         ki: "0,32",
//         cos: "0,59",
//         id: 1384,
//         parents: []
//     },
//     {
//         name: "Машина для формирования стелек",
//         ki: "0,32",
//         cos: "0,76",
//         id: 1385,
//         parents: []
//     },
//     {
//         name: "Машина для связки стелек",
//         ki: "0,32",
//         cos: "0,54",
//         id: 1386,
//         parents: []
//     },
//     {
//         name: "Машина для раскроя картона для коробок",
//         ki: "0,225",
//         cos: "0,73",
//         id: 1387,
//         parents: []
//     },
//     {
//         name: "Машина для сшивки коробок",
//         ki: "0,225",
//         cos: "0,54",
//         id: 1388,
//         parents: []
//     },
//     {
//         name: "Дробилки и вальцы грубого помола",
//         ki: "0,35",
//         cos: "0,75",
//         id: 1389,
//         parents: []
//     },
//     {
//         name: "Дезинтеграторы",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1390,
//         parents: []
//     },
//     {
//         name: "Бегуны, штатомойки, смесители сухих компонентов, мешалки",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1391,
//         parents: []
//     },
//     {
//         name: "Грохоты вибрационные",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1392,
//         parents: []
//     },
//     {
//         name: "Сита",
//         ki: "0,5",
//         cos: "0,7",
//         id: 1393,
//         parents: []
//     },
//     {
//         name: "Сепараторы",
//         ki: "0,4",
//         cos: "0,65",
//         id: 1394,
//         parents: []
//     },
//     {
//         name: "Питатели",
//         ki: "0,3",
//         cos: "0,65",
//         id: 1395,
//         parents: []
//     },
//     {
//         name: "Шкивы электромагнитные",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1396,
//         parents: []
//     },
//     {
//         name: "Машины глинорезальные",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1397,
//         parents: []
//     },
//     {
//         name: "Прессы, вакуумпрессы",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1398,
//         parents: []
//     },
//     {
//         name: "Фильтр-прессы",
//         ki: "0,58",
//         cos: "0,7",
//         id: 1399,
//         parents: []
//     },
//     {
//         name: "Барабаны сушильные автоматизированные, центрифуги, бетоносмесители",
//         ki: "0,4",
//         cos: "0,7",
//         id: 1400,
//         parents: []
//     },
//     {
//         name: "Дозаторы весовые автоматизированные*",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1401,
//         parents: []
//     },
//     {
//         name: "Пoлуaвтoмaты для формовки капселей, насосы мембранные, установки для литья изделий*",
//         ki: "0,66",
//         cos: "0,7",
//         id: 1402,
//         parents: []
//     },
//     {
//         name: "Дозаторы весовые автоматизированные*",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1403,
//         parents: []
//     },
//     {
//         name: "Полуавтоматы для формовки капселей, насосы мембранные, установки для литья изделий*",
//         ki: "0,66",
//         cos: "0,7",
//         id: 1404,
//         parents: []
//     },
//     {
//         name: "Полуавтоматы для формовки плоских изделий и станки для калибровки гипсовых форм",
//         ki: "0,4",
//         cos: "0,7",
//         id: 1405,
//         parents: []
//     },
//     {
//         name: "Полуавтоматы для формовки полых изделий, блюд, тарелок",
//         ki: "0,58",
//         cos: "0,7",
//         id: 1406,
//         parents: []
//     },
//     {
//         name: "Мельницы",
//         ki: "0,66",
//         cos: "0,8",
//         id: 1407,
//         parents: []
//     },
//     {
//         name: "Сушилка",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1408,
//         parents: []
//     },
//     {
//         name: "Автоматы для литья полых изделий, полуавтоматы оправочные, обдувки, глазурования, шлифовки, декоррования, поточные и автоматизированные изготовления чашек, автомат для печати",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1409,
//         parents: []
//     },
//     {
//         name: "Полуавтоматы для отводки плоских изделий, упаковки стенки для печати через сетку",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1410,
//         parents: []
//     },
//     {
//         name: "Дробилки щековые лабораторные растворомешалки",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1411,
//         parents: []
//     },
//     {
//         name: "Электрические печи лабораторные",
//         ki: "0,7",
//         cos: "1",
//         id: 1412,
//         parents: []
//     },
//     {
//         name: "Трепальные машины, щипально-замасливающие, обезрепеивающие и машины для разработки свалков нефти",
//         ki: "0,68",
//         cos: "0,8",
//         id: 1413,
//         parents: []
//     },
//     {
//         name: "Питатель универсальный",
//         ki: "0,65",
//         cos: "0,78",
//         id: 1414,
//         parents: []
//     },
//     {
//         name: "Смесовые машины",
//         ki: "0,59",
//         cos: "0,8",
//         id: 1415,
//         parents: []
//     },
//     {
//         name: "Чесальные машины",
//         ki: "0,73",
//         cos: "0,82",
//         id: 1416,
//         parents: []
//     },
//     {
//         name: "Свойлачивающие полуавтоматы",
//         ki: "0,69",
//         cos: "0,75",
//         id: 1417,
//         parents: []
//     },
//     {
//         name: "Свойлачивающие машины",
//         ki: "0,56",
//         cos: "0,7",
//         id: 1418,
//         parents: []
//     },
//     {
//         name: "Машины катальные",
//         ki: "0,66",
//         cos: "0,75",
//         id: 1419,
//         parents: []
//     },
//     {
//         name: "Станки для расправки основы, точильно-пропиловочные",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1420,
//         parents: []
//     },
//     {
//         name: "Оборудование для обработки отходов",
//         ki: "0,83",
//         cos: "0,75",
//         id: 1421,
//         parents: []
//     },
//     {
//         name: "Молотовые машины",
//         ki: "0,69",
//         cos: "0,8",
//         id: 1422,
//         parents: []
//     },
//     {
//         name: "Красильные аппараты",
//         ki: "0,81",
//         cos: "0,8",
//         id: 1423,
//         parents: []
//     },
//     {
//         name: "Станки ролико-растяжные, рычажно-растяжные, насадочно-расколодочные, расправочно-отжимные",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1424,
//         parents: []
//     },
//     {
//         name: "Сушильные камеры",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1425,
//         parents: []
//     },
//     {
//         name: "Универсально-свойлачивающие машины",
//         ki: "0,47",
//         cos: "0,8",
//         id: 1426,
//         parents: []
//     },
//     {
//         name: "Кисловочные машины",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1427,
//         parents: []
//     },
//     {
//         name: "Молотовые машины",
//         ki: "0,82",
//         cos: "0,87",
//         id: 1428,
//         parents: []
//     },
//     {
//         name: "Красильные аппараты",
//         ki: "0,8",
//         cos: "0,8",
//         id: 1429,
//         parents: []
//     },
//     {
//         name: "Сушильно-ширильные машины",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1430,
//         parents: []
//     },
//     {
//         name: "Сушильные камеры и сушилки для кругов",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1431,
//         parents: []
//     },
//     {
//         name: "Станки для полировки, обрезки, шлифовки валяной обуви",
//         ki: "0,5",
//         cos: "0,75",
//         id: 1432,
//         parents: []
//     },
//     {
//         name: "Машины для комплексной отделки поверхности валенок с электроопаливанием",
//         ki: "0,9",
//         cos: "0,97",
//         id: 1433,
//         parents: []
//     },
//     {
//         name: "Машины для сухой отделки",
//         ki: "0,62",
//         cos: "0,8",
//         id: 1434,
//         parents: []
//     },
//     {
//         name: "Машины для обкатки войлочных полировальных кругов",
//         ki: "0,8",
//         cos: "0,86",
//         id: 1435,
//         parents: []
//     },
//     {
//         name: "Пресса гидравлические",
//         ki: "0,5",
//         cos: "0,86",
//         id: 1436,
//         parents: []
//     },
//     {
//         name: "Вальцы подогревательные",
//         ki: "0,8",
//         cos: "0,8",
//         id: 1437,
//         parents: []
//     },
//     {
//         name: "Пресса электрогидравлические",
//         ki: "0,5",
//         cos: "0,86",
//         id: 1438,
//         parents: []
//     },
//     {
//         name: "Пресса-автоматы для горячей вулканизации",
//         ki: "0,8",
//         cos: "1",
//         id: 1439,
//         parents: []
//     },
//     {
//         name: "Гидропривод",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1440,
//         parents: []
//     },
//     {
//         name: "Участок строительного войлока, машины для разработки войлочного лоскута",
//         ki: "0,8",
//         cos: "0,85",
//         id: 1441,
//         parents: []
//     },
//     {
//         name: "Сверлильные, точильно-шлифовальные резьбонарезные, зубофрезерные и зубодолбежные станки",
//         ki: "0,16",
//         cos: "0,6",
//         id: 1442,
//         parents: []
//     },
//     {
//         name: "Кузнечно-прессовоe оборудование, гидравлические прессы, термопластавтоматы",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1443,
//         parents: []
//     },
//     {
//         name: "Ножницы, гибочное оборудование, мостовые краны литейных цехов",
//         ki: "0,45",
//         cos: "0,65",
//         id: 1444,
//         parents: []
//     },
//     {
//         name: "Галтовочное и очистные барабаны, сварочные трансформаторы ручной сварки, волочильные и протяжные станки, решетка выбивная",
//         ki: "0,25",
//         cos: "0,65",
//         id: 1445,
//         parents: []
//     },
//     {
//         name: "Трансформаторы для полуавтоматичаской и автоматической сварки, питатели дисковые",
//         ki: "0,4",
//         cos: "0,55",
//         id: 1446,
//         parents: []
//     },
//     {
//         name: "Токарные станки, автоматы и полуавтоматы, металлообрабатывающие станки литейного производства",
//         ki: "0,2",
//         cos: "0,6",
//         id: 1447,
//         parents: []
//     },
//     {
//         name: "Фрезерные, строительные расточные станки",
//         ki: "0,17",
//         cos: "0,65",
//         id: 1448,
//         parents: []
//     },
//     {
//         name: "Кран-балки, тельферы, лифты",
//         ki: "0,3",
//         cos: "0,5",
//         id: 1449,
//         parents: []
//     },
//     {
//         name: "Сушильные шкафы, камеры, печи сопротивления",
//         ki: "0,8",
//         cos: "1",
//         id: 1450,
//         parents: []
//     },
//     {
//         name: "Кран-балки, тельферы лифты, станки деревообрабатывающие",
//         ki: "0,3",
//         cos: "0,6",
//         id: 1451,
//         parents: []
//     },
//     {
//         name: "Дымососы печей, окрасочные камеры, индукционные печи высокой частоты",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1452,
//         parents: []
//     },
//     {
//         name: "Машины консольные для центробежного литья",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1453,
//         parents: []
//     },
//     {
//         name: "Агрегаты гальванического покрытия",
//         ki: "0,6",
//         cos: "0,9",
//         id: 1454,
//         parents: []
//     },
//     {
//         name: "Пневотранспорт* деревообделочного оборудования, шаровые мельницы, дробилки комбинированные",
//         ki: "0,85",
//         cos: "0,85",
//         id: 1455,
//         parents: []
//     },
//     {
//         name: "Питатели вибрационные и бункеры с вибрационными питателями",
//         ki: "0,98",
//         cos: "0,6",
//         id: 1456,
//         parents: []
//     },
//     {
//         name: "Барабаны сушильные, элеваторы ковшевые",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1457,
//         parents: []
//     },
//     {
//         name: "Конвейеры ленточные",
//         ki: "0,5",
//         cos: "0,7",
//         id: 1458,
//         parents: []
//     },
//     {
//         name: "Бегуны смешивающие",
//         ki: "0,7",
//         cos: "0,65",
//         id: 1459,
//         parents: []
//     },
//     {
//         name: "Питатели качающиеся",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1460,
//         parents: []
//     },
//     {
//         name: "Установки магнитной сепарации",
//         ki: "0,4",
//         cos: "0,8",
//         id: 1461,
//         parents: []
//     },
//     {
//         name: "Индукционные печи низкой частоты",
//         ki: "0,7",
//         cos: "0,35",
//         id: 1462,
//         parents: []
//     },
//     {
//         name: "Подсобное оборудование прядильно-ткацких производств (валичные, точильные, клееварки)",
//         ki: "0,4",
//         cos: "0,7",
//         id: 1463,
//         parents: []
//     },
//     {
//         name: "Внутрицеховой транспорт непрерывный",
//         ki: "0,4",
//         cos: "0,75",
//         id: 1464,
//         parents: []
//     },
//     {
//         name: "Электрические краны, штабеллеры",
//         ki: "0,3",
//         cos: "0,75",
//         id: 1465,
//         parents: []
//     },
//     {
//         name: "Оборудование химических станций и красковарок, емкостей с мешалками",
//         ki: "0,5",
//         cos: "0,76",
//         id: 1466,
//         parents: []
//     },
//     {
//         name: "Холодильные станции (низковольтные токоприемники кроме насосов и холодильных машин)",
//         ki: "0,75",
//         cos: "0,84",
//         id: 1467,
//         parents: []
//     },
//     {
//         name: "Градирни",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1468,
//         parents: []
//     },
//     {
//         name: "Оборудование складов сырья и химикатов",
//         ki: "0,25",
//         cos: "0,75",
//         id: 1469,
//         parents: []
//     },
//     {
//         name: "Оборудование пожарных депо",
//         ki: "0,3",
//         cos: "0,78",
//         id: 1470,
//         parents: []
//     },
//     {
//         name: "Лаборатории",
//         ki: "0,3",
//         cos: "0,75",
//         id: 1471,
//         parents: []
//     },
//     {
//         name: "Центральные ремонтные и прочие мастерские",
//         ki: "0,3",
//         cos: "0,8",
//         id: 1472,
//         parents: []
//     },
//     {
//         name: "а) электроплиты и электрофритюрницы",
//         ki: "0,65",
//         cos: "1",
//         id: 1473,
//         parents: []
//     },
//     {
//         name: "б) электрические мармиты, тепловые шкафы, электросковороды и тепловые стойки",
//         ki: "0,5",
//         cos: "1",
//         id: 1474,
//         parents: []
//     },
//     {
//         name: "в) прочее электрооборудование",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1475,
//         parents: []
//     },
//     {
//         name: "Зарядные станции тяговых аккумуляторов",
//         ki: "0,4",
//         cos: "0,75",
//         id: 1476,
//         parents: []
//     },
//     {
//         name: "По установке непрерывной разливки стали (УНРС) в целом",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1477,
//         parents: []
//     },
//     {
//         name: "Аглофабрики",
//         ki: "0,6",
//         cos: "запросить",
//         id: 1478,
//         parents: []
//     },
//     {
//         name: "Коксохимический завод с углемойкой без сероочистки",
//         ki: "0,3",
//         cos: "запросить",
//         id: 1479,
//         parents: []
//     },
//     {
//         name: "Доменный цех",
//         ki: "0,6",
//         cos: "0,855",
//         id: 1480,
//         parents: []
//     },
//     {
//         name: "Мартеновский цех с котлами утилизаторами",
//         ki: "0,3",
//         cos: "0,74",
//         id: 1481,
//         parents: []
//     },
//     {
//         name: "Мартеновский цех без котлов утилизаторов",
//         ki: "0,2",
//         cos: "запросить",
//         id: 1482,
//         parents: []
//     },
//     {
//         name: "Главный привод",
//         ki: "0,75",
//         cos: "запросить",
//         id: 1483,
//         parents: []
//     },
//     {
//         name: "Механизмы и краны",
//         ki: "0,375",
//         cos: "запросить",
//         id: 1484,
//         parents: []
//     },
//     {
//         name: "Всего по стану",
//         ki: "0,5",
//         cos: "запросить",
//         id: 1485,
//         parents: []
//     },
//     {
//         name: "Заготовочный стан 900",
//         ki: "0,625",
//         cos: "запросить",
//         id: 1486,
//         parents: []
//     },
//     {
//         name: "Трубопрокатный стан (агрегат Штифеля 130-З60 мм)",
//         ki: "0,6",
//         cos: "0,525",
//         id: 1487,
//         parents: []
//     },
//     {
//         name: "Прошивной стан 250-1",
//         ki: "0,36",
//         cos: "запросить",
//         id: 1488,
//         parents: []
//     },
//     {
//         name: "Автомат-стан 250-1",
//         ki: "0,35",
//         cos: "запросить",
//         id: 1489,
//         parents: []
//     },
//     {
//         name: "Редукционный и калибровочный станы 140",
//         ki: "0,19",
//         cos: "запросить",
//         id: 1490,
//         parents: []
//     },
//     {
//         name: "Калибровочный стан и механизмы трубоотделки",
//         ki: "0,59",
//         cos: "запросить",
//         id: 1491,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы дуговой электросварки",
//         ki: "0,4",
//         cos: "0,2",
//         id: 1492,
//         parents: []
//     },
//     {
//         name: "Ламповые генераторы индукционных печей высокой частоты",
//         ki: "0,65",
//         cos: "запросить",
//         id: 1493,
//         parents: []
//     },
//     {
//         name: "Двигатель-генераторы индукционных высокой частоты",
//         ki: "0,8",
//         cos: "запросить",
//         id: 1494,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,8",
//         cos: "0,65",
//         id: 1495,
//         parents: []
//     },
//     {
//         name: "Компенсаторы",
//         ki: "0,8",
//         cos: "0,65",
//         id: 1496,
//         parents: []
//     },
//     {
//         name: "Механическое станочное оборудование",
//         ki: "0,55",
//         cos: "0,16",
//         id: 1497,
//         parents: []
//     },
//     {
//         name: "Деревообрабатывающие станки",
//         ki: "0,75",
//         cos: "0,55",
//         id: 1498,
//         parents: []
//     },
//     {
//         name: "Котельная",
//         ki: "0,75",
//         cos: "0,6",
//         id: 1499,
//         parents: []
//     },
//     {
//         name: "Сантехвентиляция",
//         ki: "0,75",
//         cos: "0,65",
//         id: 1500,
//         parents: []
//     },
//     {
//         name: "Внутреннее освещение производственных цехов",
//         ki: "0,975",
//         cos: "0,9",
//         id: 1501,
//         parents: []
//     },
//     {
//         name: "Внутреннее освещение вспомогательных цехов",
//         ki: "1",
//         cos: "0,9",
//         id: 1502,
//         parents: []
//     },
//     {
//         name: "Волочильные станы",
//         ki: "0,835",
//         cos: "0,375",
//         id: 1503,
//         parents: []
//     },
//     {
//         name: "Электропечи сопротивления для термической обработки",
//         ki: "1",
//         cos: "0,625",
//         id: 1504,
//         parents: []
//     },
//     {
//         name: "Производственные вентиляторы и воздуходувки",
//         ki: "0,75",
//         cos: "0,65",
//         id: 1505,
//         parents: []
//     },
//     {
//         name: "Центробежные насосы",
//         ki: "0,7",
//         cos: "0,6",
//         id: 1506,
//         parents: []
//     },
//     {
//         name: "Краны и тельферы",
//         ki: "0,65",
//         cos: "0,1",
//         id: 1507,
//         parents: []
//     },
//     {
//         name: "Сварочные аппараты",
//         ki: "0,6",
//         cos: "0,05",
//         id: 1508,
//         parents: []
//     },
//     {
//         name: "Расточный станок",
//         ki: "0,5",
//         cos: "0,14",
//         id: 1509,
//         parents: []
//     },
//     {
//         name: "Сверлильный станок",
//         ki: "0,5",
//         cos: "0,14",
//         id: 1510,
//         parents: []
//     },
//     {
//         name: "Заточный станок",
//         ki: "0,5",
//         cos: "0,14",
//         id: 1511,
//         parents: []
//     },
//     {
//         name: "Пилоножеточный станок",
//         ki: "0,5",
//         cos: "0,14",
//         id: 1512,
//         parents: []
//     },
//     {
//         name: "Шлифовальный станок",
//         ki: "0,5",
//         cos: "0,14",
//         id: 1513,
//         parents: []
//     },
//     {
//         name: "Сварочные трансформаторы",
//         ki: "0,399",
//         cos: "0,2",
//         id: 1514,
//         parents: []
//     },
//     {
//         name: "Однопостовые сварочные двигатель-генераторы",
//         ki: "0,399",
//         cos: "0,2",
//         id: 1515,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,8",
//         cos: "0,65",
//         id: 1516,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,69",
//         cos: "0,5",
//         id: 1517,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,8",
//         cos: "0,65",
//         id: 1518,
//         parents: []
//     },
//     {
//         name: "Дымососы",
//         ki: "0,901",
//         cos: "0,9",
//         id: 1519,
//         parents: []
//     },
//     {
//         name: "Насосы водяные",
//         ki: "0,8",
//         cos: "0,7",
//         id: 1520,
//         parents: []
//     },
//     {
//         name: "Механизмы на сплаве: сплоточные машины",
//         ki: "0,69",
//         cos: "0,6",
//         id: 1521,
//         parents: []
//     },
//     {
//         name: "Сортировочные машины",
//         ki: "0,69",
//         cos: "0,6",
//         id: 1522,
//         parents: []
//     },
//     {
//         name: "Сортировочно-формовочные машины",
//         ki: "0,69",
//         cos: "0,65",
//         id: 1523,
//         parents: []
//     },
//     {
//         name: "Гидроускорители",
//         ki: "0,69",
//         cos: "0,65",
//         id: 1524,
//         parents: []
//     },
//     {
//         name: "Потокообразователи",
//         ki: "0,8",
//         cos: "0,7",
//         id: 1525,
//         parents: []
//     },
//     {
//         name: "Барабанные ускорители",
//         ki: "0,554",
//         cos: "0,3",
//         id: 1526,
//         parents: []
//     },
//     {
//         name: "Троповые ускорители",
//         ki: "0,6",
//         cos: "0,5",
//         id: 1527,
//         parents: []
//     },
//     {
//         name: "Вспомогательные механизмы автомат-стана 250-1",
//         ki: "0,45",
//         cos: "запросить",
//         id: 1528,
//         parents: []
//     },
//     {
//         name: "Рельсобалочный стан",
//         ki: "0,55",
//         cos: "запросить",
//         id: 1529,
//         parents: []
//     },
//     {
//         name: "Сортовые станы 300",
//         ki: "0,5",
//         cos: "0,92",
//         id: 1530,
//         parents: []
//     },
//     {
//         name: "Мелкосортные и проволочные станы",
//         ki: "0,5",
//         cos: "запросить",
//         id: 1531,
//         parents: []
//     },
//     {
//         name: "Тонколистовой стан, включая отжиг",
//         ki: "0,5",
//         cos: "запросить",
//         id: 1532,
//         parents: []
//     },
//     {
//         name: "Преобразовательный агрегат главного подъема доменного цеха",
//         ki: "0,5",
//         cos: "0,85",
//         id: 1533,
//         parents: []
//     },
//     {
//         name: "Ремонтные и вспомогательные цехи",
//         ki: "0,45",
//         cos: "0,815",
//         id: 1534,
//         parents: []
//     },
//     {
//         name: "лесопильные цеха и заводы (2-х, 4-х, 6-ти и 8-ми рамные)",
//         ki: "0,4",
//         cos: "0,6675",
//         id: 1535,
//         parents: []
//     },
//     {
//         name: "Тарные цеха",
//         ki: "0,38",
//         cos: "0,6",
//         id: 1536,
//         parents: []
//     },
//     {
//         name: "Деревообрабатывающие цеха (оконных и щитовых блоков)",
//         ki: "0,4",
//         cos: "0,645",
//         id: 1537,
//         parents: []
//     },
//     {
//         name: "Цех древесно-волокнистых плит",
//         ki: "0,47",
//         cos: "0,69",
//         id: 1538,
//         parents: []
//     },
//     {
//         name: "Цеха подготовки волокна",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1539,
//         parents: []
//     },
//     {
//         name: "Браковочные цеха",
//         ki: "0,7",
//         cos: "0,73",
//         id: 1540,
//         parents: []
//     },
//     {
//         name: "Нитепрошивные и холстопрошивные цеха",
//         ki: "0,75",
//         cos: "0,77",
//         id: 1541,
//         parents: []
//     },
//     {
//         name: "Цеха подготовки волокна",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1542,
//         parents: []
//     },
//     {
//         name: "Цеха клееных материалов",
//         ki: "0,65",
//         cos: "0,79",
//         id: 1543,
//         parents: []
//     },
//     {
//         name: "Клетьевой подъем дренажной шахты",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1544,
//         parents: []
//     },
//     {
//         name: "Корпус дробления",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1545,
//         parents: []
//     },
//     {
//         name: "Сушильный корпус",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1546,
//         parents: []
//     },
//     {
//         name: "Радиальные сгустители",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1547,
//         parents: []
//     },
//     {
//         name: "Погрузка угля",
//         ki: "0,4",
//         cos: "0,75",
//         id: 1548,
//         parents: []
//     },
//     {
//         name: "Шламовое хозяйство",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1549,
//         parents: []
//     },
//     {
//         name: "Околоствольный двор",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1550,
//         parents: []
//     },
//     {
//         name: "Собственные нужды скиповых угольных подъемников",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1551,
//         parents: []
//     },
//     {
//         name: "Клетьевые подъемники",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1552,
//         parents: []
//     },
//     {
//         name: "Технологический комплекс",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1553,
//         parents: []
//     },
//     {
//         name: "Котельная",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1554,
//         parents: []
//     },
//     {
//         name: "Калориферная",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1555,
//         parents: []
//     },
//     {
//         name: "Насосная",
//         ki: "0,75",
//         cos: "0,75",
//         id: 1556,
//         parents: []
//     },
//     {
//         name: "Административно-бытовой комбинат",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1557,
//         parents: []
//     },
//     {
//         name: "Прочие мелкие установки",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1558,
//         parents: []
//     },
//     {
//         name: "Очистные работы",
//         ki: "0,4",
//         cos: "0,6",
//         id: 1559,
//         parents: []
//     },
//     {
//         name: "Подготовительные работы",
//         ki: "0,55",
//         cos: "0,9",
//         id: 1560,
//         parents: []
//     },
//     {
//         name: "Прочие механизмы",
//         ki: "0,7",
//         cos: "0,7",
//         id: 1561,
//         parents: []
//     },
//     {
//         name: "Компрессорные станции",
//         ki: "0,875",
//         cos: "0,825",
//         id: 1562,
//         parents: []
//     },
//     {
//         name: "Глубинонасосные установки",
//         ki: "0,525",
//         cos: "0,65",
//         id: 1563,
//         parents: []
//     },
//     {
//         name: "воды",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1564,
//         parents: []
//     },
//     {
//         name: "нефти",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1565,
//         parents: []
//     },
//     {
//         name: "Бурящиеся буровые",
//         ki: "0,475",
//         cos: "0,675",
//         id: 1566,
//         parents: []
//     },
//     {
//         name: "Нефтедобывающий район в целом",
//         ki: "0,45",
//         cos: "0,75",
//         id: 1567,
//         parents: []
//     },
//     {
//         name: "Электрообессоливающая установка при установленной мощности 130 кВт",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1568,
//         parents: []
//     },
//     {
//         name: "Электрообессоливающая установка при установленной мощности 350 кВт",
//         ki: "0,8",
//         cos: "0,85",
//         id: 1569,
//         parents: []
//     },
//     {
//         name: "Депарафинизация масел",
//         ki: "0,95",
//         cos: "0,85",
//         id: 1570,
//         parents: []
//     },
//     {
//         name: "Атмосферно-вакуумная трубчатка с электроприводом",
//         ki: "0,8",
//         cos: "0,88",
//         id: 1571,
//         parents: []
//     },
//     {
//         name: "Вторичная перегонка",
//         ki: "0,95",
//         cos: "0,85",
//         id: 1572,
//         parents: []
//     },
//     {
//         name: "Фенольные установки",
//         ki: "0,95",
//         cos: "0,85",
//         id: 1573,
//         parents: []
//     },
//     {
//         name: "Термический крекинг",
//         ki: "0,85",
//         cos: "запросить",
//         id: 1574,
//         parents: []
//     },
//     {
//         name: "Азеотропная установка",
//         ki: "0,4",
//         cos: "запросить",
//         id: 1575,
//         parents: []
//     },
//     {
//         name: "Алкилирование",
//         ki: "0,65",
//         cos: "0,86",
//         id: 1576,
//         parents: []
//     },
//     {
//         name: "Газофракционирующая установка",
//         ki: "0,7",
//         cos: "0,84",
//         id: 1577,
//         parents: []
//     },
//     {
//         name: "Каталитический крекинг",
//         ki: "0,85",
//         cos: "0,9",
//         id: 1578,
//         parents: []
//     },
//     {
//         name: "Сернокислотная очистка",
//         ki: "0,3",
//         cos: "запросить",
//         id: 1579,
//         parents: []
//     },
//     {
//         name: "Гидроформинг",
//         ki: "0,5",
//         cos: "запросить",
//         id: 1580,
//         parents: []
//     },
//     {
//         name: "Резиносмесители",
//         ki: "0,68",
//         cos: "0,8",
//         id: 1581,
//         parents: []
//     },
//     {
//         name: "Пелетайзеры",
//         ki: "0,75",
//         cos: "0,85",
//         id: 1582,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,7",
//         cos: "0,85",
//         id: 1583,
//         parents: []
//     },
//     {
//         name: "Транспортное оборудование",
//         ki: "0,35",
//         cos: "0,65",
//         id: 1584,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1585,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,4",
//         cos: "0,75",
//         id: 1586,
//         parents: []
//     },
//     {
//         name: "Двигатель-генератор",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1587,
//         parents: []
//     },
//     {
//         name: "Подъемники",
//         ki: "0,4",
//         cos: "0,45",
//         id: 1588,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,25",
//         cos: "0,5",
//         id: 1589,
//         parents: []
//     },
//     {
//         name: "Зарядные агрегаты",
//         ki: "0,625",
//         cos: "0,8",
//         id: 1590,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,45",
//         cos: "0,6",
//         id: 1591,
//         parents: []
//     },
//     {
//         name: "Внутризаводской транспорт",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1592,
//         parents: []
//     },
//     {
//         name: "Пневмотранспорт",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1593,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1594,
//         parents: []
//     },
//     {
//         name: "Технологическое отделение",
//         ki: "0,55",
//         cos: "0,77",
//         id: 1595,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1596,
//         parents: []
//     },
//     {
//         name: "Пневмотранспорт",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1597,
//         parents: []
//     },
//     {
//         name: "Станки",
//         ki: "0,6",
//         cos: "0,65",
//         id: 1598,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1599,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1600,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1601,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,52",
//         cos: "0,73",
//         id: 1602,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1603,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,4",
//         cos: "0,6",
//         id: 1604,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1605,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,4",
//         cos: "0,67",
//         id: 1606,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1607,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,46",
//         cos: "0,62",
//         id: 1608,
//         parents: []
//     },
//     {
//         name: "Электрообогрев прессов",
//         ki: "0,8",
//         cos: "0,95",
//         id: 1609,
//         parents: []
//     },
//     {
//         name: "Установка ТВЧ",
//         ki: "0,8",
//         cos: "0,6",
//         id: 1610,
//         parents: []
//     },
//     {
//         name: "Внутризаводской транспорт",
//         ki: "0,61",
//         cos: "0,8",
//         id: 1611,
//         parents: []
//     },
//     {
//         name: "Вентиляция",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1612,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1613,
//         parents: []
//     },
//     {
//         name: "Массомешалки",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1614,
//         parents: []
//     },
//     {
//         name: "Вертикальные паронитовые вальцы и отбор. устройства",
//         ki: "0,62",
//         cos: "0,75",
//         id: 1615,
//         parents: []
//     },
//     {
//         name: "Вакуум-насосы РМК-3",
//         ki: "0,45",
//         cos: "0,8",
//         id: 1616,
//         parents: []
//     },
//     {
//         name: "Прочее технологическое оборудование",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1617,
//         parents: []
//     },
//     {
//         name: "Электрообогрев прессов",
//         ki: "0,8",
//         cos: "0,95",
//         id: 1618,
//         parents: []
//     },
//     {
//         name: "Металлорежущие станки",
//         ki: "0,18",
//         cos: "0,8",
//         id: 1619,
//         parents: []
//     },
//     {
//         name: "Сантехническое оборудование",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1620,
//         parents: []
//     },
//     {
//         name: "Пневмотранспорт",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1621,
//         parents: []
//     },
//     {
//         name: "Транспортеры, шнеки, весовая, дозировки, лебедки",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1622,
//         parents: []
//     },
//     {
//         name: "Лабораторное оборудование",
//         ki: "0,6",
//         cos: "0,65",
//         id: 1623,
//         parents: []
//     },
//     {
//         name: "Термические приемники",
//         ki: "0,6",
//         cos: "0,95",
//         id: 1624,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1625,
//         parents: []
//     },
//     {
//         name: "Цех производства транспортных лент и приводных ремней (высоковольтные двигатели)",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1626,
//         parents: []
//     },
//     {
//         name: "Цех производства транспортных лент и приводных ремней (низковольтные двигатели)",
//         ki: "0,4",
//         cos: "0,7",
//         id: 1627,
//         parents: []
//     },
//     {
//         name: "Цех производства формовой техники без учета электропроцессов",
//         ki: "0,39",
//         cos: "0,63",
//         id: 1628,
//         parents: []
//     },
//     {
//         name: "Цех производства формовой техники с учетом электропроцессов",
//         ki: "0,67",
//         cos: "0,8",
//         id: 1629,
//         parents: []
//     },
//     {
//         name: "Подготовительный цех",
//         ki: "0,58",
//         cos: "запросить",
//         id: 1630,
//         parents: []
//     },
//     {
//         name: "Цех спецшлангов (в том числе без дорновых рукавов)",
//         ki: "0,41",
//         cos: "0,57",
//         id: 1631,
//         parents: []
//     },
//     {
//         name: "Цех спиральных и буровых рукавов",
//         ki: "0,3",
//         cos: "0,6",
//         id: 1632,
//         parents: []
//     },
//     {
//         name: "Цех напорных рукавов",
//         ki: "0,32",
//         cos: "0,62",
//         id: 1633,
//         parents: []
//     },
//     {
//         name: "Цех клиновидных ремней",
//         ki: "0,39",
//         cos: "0,63",
//         id: 1634,
//         parents: []
//     },
//     {
//         name: "Подготовительный цех (высоковольтные двигатели)",
//         ki: "0,7",
//         cos: "0,875",
//         id: 1635,
//         parents: []
//     },
//     {
//         name: "Подготовительный цех (низковольтные двигатели)",
//         ki: "0,65",
//         cos: "0,65",
//         id: 1636,
//         parents: []
//     },
//     {
//         name: "Сборочный цех",
//         ki: "0,5",
//         cos: "0,65",
//         id: 1637,
//         parents: []
//     },
//     {
//         name: "Цех каландров (высоковольтные двигатели)",
//         ki: "0,65",
//         cos: "0,875",
//         id: 1638,
//         parents: []
//     },
//     {
//         name: "Цех каландров (низковольтные двигатели)",
//         ki: "0,45",
//         cos: "0,65",
//         id: 1639,
//         parents: []
//     },
//     {
//         name: "Автокамерный цех (высоковольтные двигатели)",
//         ki: "0,8",
//         cos: "0,875",
//         id: 1640,
//         parents: []
//     },
//     {
//         name: "Автокамерный цех (низковольтные двигатели)",
//         ki: "0,4",
//         cos: "0,75",
//         id: 1641,
//         parents: []
//     },
//     {
//         name: "Цех вулканизации",
//         ki: "0,35",
//         cos: "0,55",
//         id: 1642,
//         parents: []
//     },
//     {
//         name: "Цех очистки этилена",
//         ki: "0,9",
//         cos: "0,9",
//         id: 1643,
//         parents: []
//     },
//     {
//         name: "Цех полимеризации",
//         ki: "0,5",
//         cos: "0,75",
//         id: 1644,
//         parents: []
//     },
//     {
//         name: "Цех дистилляции и очистки азота",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1645,
//         parents: []
//     },
//     {
//         name: "Цех грануляции",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1646,
//         parents: []
//     },
//     {
//         name: "Цех катализации",
//         ki: "0,85",
//         cos: "0,65",
//         id: 1647,
//         parents: []
//     },
//     {
//         name: "Цех легковоспламеняющихся жидкостей",
//         ki: "0,9",
//         cos: "0,75",
//         id: 1648,
//         parents: []
//     },
//     {
//         name: "Цех разделения воздуха",
//         ki: "0,87",
//         cos: "0,95",
//         id: 1649,
//         parents: []
//     },
//     {
//         name: "Газовый цех (на природном газе)",
//         ki: "0,87",
//         cos: "0,8",
//         id: 1650,
//         parents: []
//     },
//     {
//         name: "Газовый цех (на газогенераторном газе)",
//         ki: "0,65",
//         cos: "0,8",
//         id: 1651,
//         parents: []
//     },
//     {
//         name: "Цех холодильных установок",
//         ki: "0,79",
//         cos: "0,9",
//         id: 1652,
//         parents: []
//     },
//     {
//         name: "Цех компрессии",
//         ki: "0,87",
//         cos: "0,9",
//         id: 1653,
//         parents: []
//     },
//     {
//         name: "Цех моноэтаноламиновой очистки",
//         ki: "0,74",
//         cos: "0,8",
//         id: 1654,
//         parents: []
//     },
//     {
//         name: "Цех синтеза аммиака",
//         ki: "0,87",
//         cos: "0,85",
//         id: 1655,
//         parents: []
//     },
//     {
//         name: "Цех водной очистки",
//         ki: "0,89",
//         cos: "0,8",
//         id: 1656,
//         parents: []
//     },
//     {
//         name: "Цех медно-аммиачной очистки",
//         ki: "0,8",
//         cos: "0,85",
//         id: 1657,
//         parents: []
//     },
//     {
//         name: "Цех синтеза метанола",
//         ki: "0,81",
//         cos: "0,7",
//         id: 1658,
//         parents: []
//     },
//     {
//         name: "Цех сероочистки",
//         ki: "0,8",
//         cos: "0,64",
//         id: 1659,
//         parents: []
//     },
//     {
//         name: "Цех конверсии окиси углерода",
//         ki: "0,77",
//         cos: "0,8",
//         id: 1660,
//         parents: []
//     },
//     {
//         name: "Цех ректификации метанола",
//         ki: "0,5",
//         cos: "0,72",
//         id: 1661,
//         parents: []
//     },
//     {
//         name: "Химический цех",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1662,
//         parents: []
//     },
//     {
//         name: "Прядильный цех (с учетом динильных котлов)",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1663,
//         parents: []
//     },
//     {
//         name: "Крутильный цех",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1664,
//         parents: []
//     },
//     {
//         name: "Отделочный цех",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1665,
//         parents: []
//     },
//     {
//         name: "Бобинажно-перемоточный цех",
//         ki: "0,8",
//         cos: "0,75",
//         id: 1666,
//         parents: []
//     },
//     {
//         name: "Цех регенераций отходов",
//         ki: "0,65",
//         cos: "0,7",
//         id: 1667,
//         parents: []
//     },
//     {
//         name: "Химический цех",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1668,
//         parents: []
//     },
//     {
//         name: "Прядильный цех",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1669,
//         parents: []
//     },
//     {
//         name: "Крутильный цех",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1670,
//         parents: []
//     },
//     {
//         name: "Ткацкий цех",
//         ki: "0,85",
//         cos: "0,75",
//         id: 1671,
//         parents: []
//     },
//     {
//         name: "Химический цех",
//         ki: "0,55",
//         cos: "0,7",
//         id: 1672,
//         parents: []
//     },
//     {
//         name: "Прядильный цех (без электроверетен)",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1673,
//         parents: []
//     },
//     {
//         name: "Отделочный цех",
//         ki: "0,65",
//         cos: "0,75",
//         id: 1674,
//         parents: []
//     },
//     {
//         name: "Бобинажно-перемоточный цех",
//         ki: "0,8",
//         cos: "0,7",
//         id: 1675,
//         parents: []
//     },
//     {
//         name: "Химический цех",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1676,
//         parents: []
//     },
//     {
//         name: "Прядильно-отделочный цех",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1677,
//         parents: []
//     },
//     {
//         name: "Химический цех",
//         ki: "0,9",
//         cos: "0,65",
//         id: 1678,
//         parents: []
//     },
//     {
//         name: "Прядильный цех",
//         ki: "0,85",
//         cos: "0,7",
//         id: 1679,
//         parents: []
//     },
//     {
//         name: "Крутильный цех",
//         ki: "0,7",
//         cos: "0,65",
//         id: 1680,
//         parents: []
//     },
//     {
//         name: "Цех регенерации ацетона",
//         ki: "0,9",
//         cos: "0,8",
//         id: 1681,
//         parents: []
//     },
//     {
//         name: "Водонасосная",
//         ki: "0,75",
//         cos: "0,8",
//         id: 1682,
//         parents: []
//     },
//     {
//         name: "Цех слабой азотной кислоты при повышенном давлении",
//         ki: "0,86",
//         cos: "0,95",
//         id: 1683,
//         parents: []
//     },
//     {
//         name: "Цех слабой азотной кислоты при нормальном давлении",
//         ki: "0,78",
//         cos: "0,91",
//         id: 1684,
//         parents: []
//     },
//     {
//         name: "В целом по заводу, исключая электролиз хлора и каустической соды",
//         ki: "0,525",
//         cos: "0,95",
//         id: 1685,
//         parents: []
//     },
//     {
//         name: "Электролиз (серии ванн) производства хлора и каустической соды",
//         ki: "1",
//         cos: "0,9",
//         id: 1686,
//         parents: []
//     },
//     {
//         name: "Ротационный цех в целом",
//         ki: "0,63",
//         cos: "0,8",
//         id: 1687,
//         parents: []
//     },
//     {
//         name: "Цех изготовления офсетных форм в целом",
//         ki: "0,54",
//         cos: "0,7",
//         id: 1688,
//         parents: []
//     },
//     {
//         name: "Цех глубокой печати в целом",
//         ki: "0,54",
//         cos: "0,85",
//         id: 1689,
//         parents: []
//     },
//     {
//         name: "Брошюровочный цех в целом",
//         ki: "0,58",
//         cos: "0,75",
//         id: 1690,
//         parents: []
//     },
//     {
//         name: "Печатный цех в целом",
//         ki: "0,66",
//         cos: "0,65",
//         id: 1691,
//         parents: []
//     },
//     {
//         name: "Переплетный цех в целом",
//         ki: "0,47",
//         cos: "0,72",
//         id: 1692,
//         parents: []
//     },
//     {
//         name: "Печатные машины 'Пламаг' и 'Вимаг' (газеты)",
//         ki: "0,5",
//         cos: "0,54",
//         id: 1693,
//         parents: []
//     },
//     {
//         name: "Печатные машины ГА, ГА-2 и ГАУ (газеты)",
//         ki: "0,425",
//         cos: "0,24",
//         id: 1694,
//         parents: []
//     },
//     {
//         name: "Многокрасочные печатные машины 'Пламаг' (журналы)",
//         ki: "0,7",
//         cos: "0,4",
//         id: 1695,
//         parents: []
//     },
//     {
//         name: "Печатные машины 'Планета' (журналы, иллюстрации)",
//         ki: "0,7",
//         cos: "0,45",
//         id: 1696,
//         parents: []
//     },
//     {
//         name: "Печатные машины ПД-2 и ПРП-3 (книги, журналы)",
//         ki: "0,65",
//         cos: "0,3",
//         id: 1697,
//         parents: []
//     },
//     {
//         name: "Печатные машины ПРК-2, ПРК-3 и 'Пламаг' (книги, журналы)",
//         ki: "0,66",
//         cos: "0,45",
//         id: 1698,
//         parents: []
//     },
//     {
//         name: "Строкоотливные машины Н-7 и Н-11",
//         ki: "0,62",
//         cos: "0,5",
//         id: 1699,
//         parents: []
//     },
//     {
//         name: "Фальцевальные машины",
//         ki: "0,95",
//         cos: "0,75",
//         id: 1700,
//         parents: []
//     },
//     {
//         name: "Вкладочно-швейные резальные агрегаты ВШРА (журналы)",
//         ki: "0,55",
//         cos: "0,3",
//         id: 1701,
//         parents: []
//     },
//     {
//         name: "2.8. ПИЩЕВАЯ ПРОМЫШЛЕННОСТЬ",
//         ki: "0,6",
//         cos: "0,5",
//         id: 1702,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,4",
//         cos: "",
//         id: 1703,
//         parents: []
//     },
//     {
//         name: "Транспортное оборудование",
//         ki: "0,5",
//         cos: "",
//         id: 1704,
//         parents: []
//     },
//     {
//         name: "Жидкостные насосы",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1705,
//         parents: []
//     },
//     {
//         name: "Воздушные и газовые компрессоры, насосы",
//         ki: "0,525",
//         cos: "0,8",
//         id: 1706,
//         parents: []
//     },
//     {
//         name: "Вентиляторы и дымососы",
//         ki: "0,525",
//         cos: "",
//         id: 1707,
//         parents: []
//     },
//     {
//         name: "Мельница сортового помола",
//         ki: "0,725",
//         cos: "0,8",
//         id: 1708,
//         parents: []
//     },
//     {
//         name: "Мельница обойного помола",
//         ki: "0,775",
//         cos: "0,8",
//         id: 1709,
//         parents: []
//     },
//     {
//         name: "Сушильно-очистительные башни с шахтами и газовыми сушилками",
//         ki: "0,475",
//         cos: "0,75",
//         id: 1710,
//         parents: []
//     },
//     {
//         name: "Сушильно-очистительные башни и механизированные склады зерна",
//         ki: "0,6",
//         cos: "0,75",
//         id: 1711,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,525",
//         cos: "0,7",
//         id: 1712,
//         parents: []
//     },
//     {
//         name: "Нагревательное оборудование",
//         ki: "0,85",
//         cos: "0,95",
//         id: 1713,
//         parents: []
//     },
//     {
//         name: "Компрессоры, насосы",
//         ki: "0,675",
//         cos: "0,775",
//         id: 1714,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,45",
//         cos: "0,675",
//         id: 1715,
//         parents: []
//     },
//     {
//         name: "Нагревательное оборудование",
//         ki: "0,85",
//         cos: "0,95",
//         id: 1716,
//         parents: []
//     },
//     {
//         name: "Компрессоры, насосы",
//         ki: "0,675",
//         cos: "0,775",
//         id: 1717,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование для производства масла, сыра, брынзы, цельномолочной продукции",
//         ki: "0,525",
//         cos: "0,75",
//         id: 1718,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование для производства молочных консервов",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1719,
//         parents: []
//     },
//     {
//         name: "Транспортное оборудование",
//         ki: "0,6",
//         cos: "0,75",
//         id: 1720,
//         parents: []
//     },
//     {
//         name: "Вентиляционное оборудование",
//         ki: "0,725",
//         cos: "0,75",
//         id: 1721,
//         parents: []
//     },
//     {
//         name: "Холодильное оборудование",
//         ki: "0,675",
//         cos: "0,8",
//         id: 1722,
//         parents: []
//     },
//     {
//         name: "Теплотехническое оборудование",
//         ki: "0,55",
//         cos: "0,8",
//         id: 1723,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,4",
//         cos: "0,7",
//         id: 1724,
//         parents: []
//     },
//     {
//         name: "Нагревательное оборудование",
//         ki: "0,7",
//         cos: "0,95",
//         id: 1725,
//         parents: []
//     },
//     {
//         name: "Дымогенераторы",
//         ki: "0,6",
//         cos: "0,95",
//         id: 1726,
//         parents: []
//     },
//     {
//         name: "Печи производства рыбных консервов",
//         ki: "0,3",
//         cos: "1",
//         id: 1727,
//         parents: []
//     },
//     {
//         name: "привод",
//         ki: "0,7",
//         cos: "0,75",
//         id: 1728,
//         parents: []
//     },
//     {
//         name: "нагрев",
//         ki: "0,8",
//         cos: "0,95",
//         id: 1729,
//         parents: []
//     },
//     {
//         name: "Транспортное и грузоподъемное оборудование",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1730,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,55",
//         cos: "0,8",
//         id: 1731,
//         parents: []
//     },
//     {
//         name: "Компрессоры и морозильные агрегаты",
//         ki: "0,7",
//         cos: "",
//         id: 1732,
//         parents: []
//     },
//     {
//         name: "Выпрямители",
//         ki: "0,8",
//         cos: "0,6",
//         id: 1733,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1734,
//         parents: []
//     },
//     {
//         name: "Технологическое оборудование",
//         ki: "0,3",
//         cos: "0,7",
//         id: 1735,
//         parents: []
//     },
//     {
//         name: "Нагревательное оборудование",
//         ki: "0,4",
//         cos: "0,95",
//         id: 1736,
//         parents: []
//     },
//     {
//         name: "Грузоподъемное оборудование",
//         ki: "0,3",
//         cos: "0,65",
//         id: 1737,
//         parents: []
//     },
//     {
//         name: "Компрессоры, насосы",
//         ki: "0,575",
//         cos: "0,8",
//         id: 1738,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1739,
//         parents: []
//     },
//     {
//         name: "Компрессоры",
//         ki: "0,8",
//         cos: "0,85",
//         id: 1740,
//         parents: []
//     },
//     {
//         name: "Насосы",
//         ki: "0,6",
//         cos: "0,8",
//         id: 1741,
//         parents: []
//     },
//     {
//         name: "Лифты",
//         ki: "0,2",
//         cos: "0,65",
//         id: 1742,
//         parents: []
//     },
//     {
//         name: "Вентиляторы технологические",
//         ki: "0,6",
//         cos: "0,7",
//         id: 1743,
//         parents: []
//     },
//     {
//         name: "Зарядные станции",
//         ki: "0,825",
//         cos: "0,82",
//         id: 1744,
//         parents: []
//     },
//     {
//         name: "Вентиляторы сантехнические",
//         ki: "0,7",
//         cos: "0,8",
//         id: 1745,
//         parents: []
//     },
//     {
//         name: "Станки РММ",
//         ki: "0,4",
//         cos: "0,6",
//         id: 1746,
//         parents: []
//     },
//     {
//         name: "Бактерицидная установка",
//         ki: "0,9",
//         cos: "0,98",
//         id: 1747,
//         parents: []
//     },
//     {
//         name: "Бетономешалка",
//         ki: "0,45",
//         cos: "0,87",
//         id: 1748,
//         parents: []
//     },
//     {
//         name: "Блок питания БПРУ",
//         ki: "0,45",
//         cos: "0,9",
//         id: 1749,
//         parents: []
//     },
//     {
//         name: "Вентилятор вытяжной",
//         ki: "0,32",
//         cos: "0,76",
//         id: 1750,
//         parents: []
//     },
//     {
//         name: "Вентилятор дутьевой",
//         ki: "0,72",
//         cos: "0,9",
//         id: 1751,
//         parents: []
//     },
//     {
//         name: "Вентилятор приточный",
//         ki: "0,64",
//         cos: "0,81",
//         id: 1752,
//         parents: []
//     },
//     {
//         name: "Воздушное охлаждение газа",
//         ki: "0,72",
//         cos: "0,93",
//         id: 1753,
//         parents: []
//     },
//     {
//         name: "Ворота",
//         ki: "0,11",
//         cos: "0,87",
//         id: 1754,
//         parents: []
//     },
//     {
//         name: "Вычислительная техника",
//         ki: "0,5",
//         cos: "0,9",
//         id: 1755,
//         parents: []
//     },
//     {
//         name: "Дистиллятор",
//         ki: "0,72",
//         cos: "0,87",
//         id: 1756,
//         parents: []
//     },
//     {
//         name: "Дымосос",
//         ki: "0,72",
//         cos: "0,85",
//         id: 1757,
//         parents: []
//     },
//     {
//         name: "Емкость аварийная",
//         ki: "0,27",
//         cos: "0,86",
//         id: 1758,
//         parents: []
//     },
//     {
//         name: "Емкость дренажная",
//         ki: "0,56",
//         cos: "0,86",
//         id: 1759,
//         parents: []
//     },
//     {
//         name: "Задвижка",
//         ki: "0,11",
//         cos: "0,73",
//         id: 1760,
//         parents: []
//     },
//     {
//         name: "Зарядное устройство для аккумуляторов",
//         ki: "0,41",
//         cos: "0,7",
//         id: 1761,
//         parents: []
//     },
//     {
//         name: "Калорифер",
//         ki: "0,63",
//         cos: "1",
//         id: 1762,
//         parents: []
//     },
//     {
//         name: "Кладовая",
//         ki: "0,41",
//         cos: "0,98",
//         id: 1763,
//         parents: []
//     },
//     {
//         name: "Компрессор воздушный",
//         ki: "0,64",
//         cos: "0,81",
//         id: 1764,
//         parents: []
//     },
//     {
//         name: "Конвейер",
//         ki: "0,68",
//         cos: "0,8",
//         id: 1765,
//         parents: []
//     },
//     {
//         name: "Конденсатосборник",
//         ki: "0,54",
//         cos: "0,8",
//         id: 1766,
//         parents: []
//     },
//     {
//         name: "Кран козловой",
//         ki: "0,11",
//         cos: "0,7",
//         id: 1767,
//         parents: []
//     },
//     {
//         name: "Кран мостовой",
//         ki: "0,25",
//         cos: "0,7",
//         id: 1768,
//         parents: []
//     },
//     {
//         name: "Кран подвесной",
//         ki: "0,21",
//         cos: "0,7",
//         id: 1769,
//         parents: []
//     },
//     {
//         name: "Механическое оборудование",
//         ki: "0,3",
//         cos: "0,75",
//         id: 1770,
//         parents: []
//     },
//     {
//         name: "Нагревательное оборудование",
//         ki: "0,6",
//         cos: "1",
//         id: 1771,
//         parents: []
//     },
//     {
//         name: "Нагрузка пожаротушения",
//         ki: "0,08",
//         cos: "0,8",
//         id: 1772,
//         parents: []
//     },
//     {
//         name: "Насос водозаборной скважины",
//         ki: "0,66",
//         cos: "0,72",
//         id: 1773,
//         parents: []
//     },
//     {
//         name: "Насос газовый",
//         ki: "0,72",
//         cos: "0,8",
//         id: 1774,
//         parents: []
//     },
//     {
//         name: "Насос горячей воды",
//         ki: "0,56",
//         cos: "0,8",
//         id: 1775,
//         parents: []
//     },
//     {
//         name: "Насос дозировочный",
//         ki: "0,8",
//         cos: "0,9",
//         id: 1776,
//         parents: []
//     },
//     {
//         name: "Насос масляный",
//         ki: "0,31",
//         cos: "0,81",
//         id: 1777,
//         parents: []
//     },
//     {
//         name: "Насос на ДНС",
//         ki: "0,75",
//         cos: "0,86",
//         id: 1778,
//         parents: []
//     },
//     {
//         name: "Насос на КСП",
//         ki: "0,75",
//         cos: "0,9",
//         id: 1779,
//         parents: []
//     },
//     {
//         name: "Насос на ЦПС",
//         ki: "0,75",
//         cos: "0,86",
//         id: 1780,
//         parents: []
//     },
//     {
//         name: "Насос над артскважиной",
//         ki: "0,64",
//         cos: "0,81",
//         id: 1781,
//         parents: []
//     },
//     {
//         name: "Насос оборотный",
//         ki: "0,67",
//         cos: "0,95",
//         id: 1782,
//         parents: []
//     },
//     {
//         name: "Насос очистных сооружений",
//         ki: "0,57",
//         cos: "0,76",
//         id: 1783,
//         parents: []
//     },
//     {
//         name: "Насос перекачки воды",
//         ki: "0,56",
//         cos: "0,88",
//         id: 1784,
//         parents: []
//     },
//     {
//         name: "Насос питательный",
//         ki: "0,65",
//         cos: "0,9",
//         id: 1785,
//         parents: []
//     },
//     {
//         name: "Насос подачи ингибитора",
//         ki: "0,66",
//         cos: "0,88",
//         id: 1786,
//         parents: []
//     },
//     {
//         name: "Насос подачи реагента",
//         ki: "0,68",
//         cos: "0,89",
//         id: 1787,
//         parents: []
//     },
//     {
//         name: "Насос подготовки воды",
//         ki: "0,73",
//         cos: "0,76",
//         id: 1788,
//         parents: []
//     },
//     {
//         name: "Насос подпитки",
//         ki: "0,65",
//         cos: "0,89",
//         id: 1789,
//         parents: []
//     },
//     {
//         name: "Насос подпиточный",
//         ki: "0,63",
//         cos: "0,89",
//         id: 1790,
//         parents: []
//     },
//     {
//         name: "Насос противопожарной насосной станции",
//         ki: "0,08",
//         cos: "0,9",
//         id: 1791,
//         parents: []
//     },
//     {
//         name: "Насос разгрузки",
//         ki: "0,57",
//         cos: "0,91",
//         id: 1792,
//         parents: []
//     },
//     {
//         name: "Насос сетевой",
//         ki: "0,5",
//         cos: "0,9",
//         id: 1793,
//         parents: []
//     },
//     {
//         name: "Насос ШГН",
//         ki: "0,66",
//         cos: "0,78",
//         id: 1794,
//         parents: []
//     },
//     {
//         name: "Насос ЭЦН",
//         ki: "0,68",
//         cos: "0,79",
//         id: 1795,
//         parents: []
//     },
//     {
//         name: "Насос ЭЦН (70 Гц)",
//         ki: "0,68",
//         cos: "0,67",
//         id: 1796,
//         parents: []
//     },
//     {
//         name: "Освещение аварийное",
//         ki: "1",
//         cos: "0,98",
//         id: 1797,
//         parents: []
//     },
//     {
//         name: "Освещение внутреннее б/п",
//         ki: "0,5",
//         cos: "0,98",
//         id: 1798,
//         parents: []
//     },
//     {
//         name: "Освещение внутреннее с/п",
//         ki: "0,7",
//         cos: "0,98",
//         id: 1799,
//         parents: []
//     },
//     {
//         name: "Освещение наружное",
//         ki: "0,7",
//         cos: "0,98",
//         id: 1800,
//         parents: []
//     },
//     {
//         name: "Освещение охранное",
//         ki: "1",
//         cos: "0,98",
//         id: 1801,
//         parents: []
//     },
//     {
//         name: "Освещение рабочее",
//         ki: "0,7",
//         cos: "0,98",
//         id: 1802,
//         parents: []
//     },
//     {
//         name: "Освещение ремонтное",
//         ki: "0,05",
//         cos: "0,98",
//         id: 1803,
//         parents: []
//     },
//     {
//         name: "Отопление вентилируемого помещения б/р",
//         ki: "0,9",
//         cos: "1",
//         id: 1804,
//         parents: []
//     },
//     {
//         name: "Отопление вентилируемого помещения с/р",
//         ki: "0,45",
//         cos: "1",
//         id: 1805,
//         parents: []
//     },
//     {
//         name: "Отопление воздушное",
//         ki: "0,8",
//         cos: "0,75",
//         id: 1806,
//         parents: []
//     },
//     {
//         name: "Отопление компрессорной б/р",
//         ki: "0,9",
//         cos: "1",
//         id: 1807,
//         parents: []
//     },
//     {
//         name: "Отопление компрессорной с/р",
//         ki: "0,45",
//         cos: "1",
//         id: 1808,
//         parents: []
//     },
//     {
//         name: "Отопление насосной б/р",
//         ki: "0,9",
//         cos: "1",
//         id: 1809,
//         parents: []
//     },
//     {
//         name: "Отопление насосной с/р",
//         ki: "0,45",
//         cos: "1",
//         id: 1810,
//         parents: []
//     },
//     {
//         name: "Отопление электропомещения б/р",
//         ki: "0,9",
//         cos: "1",
//         id: 1811,
//         parents: []
//     },
//     {
//         name: "Отопление электропомещения с/р",
//         ki: "0,45",
//         cos: "1",
//         id: 1812,
//         parents: []
//     },
//     {
//         name: "Печь ПТБ (Венгрия)",
//         ki: "0,8",
//         cos: "0,79",
//         id: 1813,
//         parents: []
//     },
//     {
//         name: "Пресс",
//         ki: "0,42",
//         cos: "0,87",
//         id: 1814,
//         parents: []
//     },
//     {
//         name: "Розетка",
//         ki: "0,1",
//         cos: "0,98",
//         id: 1815,
//         parents: []
//     },
//     {
//         name: "Сборник утечек",
//         ki: "0,48",
//         cos: "0,8",
//         id: 1816,
//         parents: []
//     },
//     {
//         name: "Станок сверлильный",
//         ki: "0,31",
//         cos: "0,8",
//         id: 1817,
//         parents: []
//     },
//     {
//         name: "Станок токарно-винтовой",
//         ki: "0,31",
//         cos: "0,85",
//         id: 1818,
//         parents: []
//     },
//     {
//         name: "Стол лабораторный",
//         ki: "0,41",
//         cos: "0,9",
//         id: 1819,
//         parents: []
//     },
//     {
//         name: "Тележка",
//         ki: "0,51",
//         cos: "0,7",
//         id: 1820,
//         parents: []
//     },
//     {
//         name: "Тиристорный возбудитель",
//         ki: "0,9",
//         cos: "0,39",
//         id: 1821,
//         parents: []
//     },
//     {
//         name: "Трансформатор понижающий",
//         ki: "0,85",
//         cos: "0,7",
//         id: 1822,
//         parents: []
//     },
//     {
//         name: "Холодильник",
//         ki: "0,36",
//         cos: "0,98",
//         id: 1823,
//         parents: []
//     },
//     {
//         name: "Шкаф сушильный",
//         ki: "0,5",
//         cos: "1",
//         id: 1824,
//         parents: []
//     },
//     {
//         name: "Щит КИП и А",
//         ki: "0,9",
//         cos: "0,98",
//         id: 1825,
//         parents: []
//     },
//     {
//         name: "Щит управления котлоагрегатом",
//         ki: "0,65",
//         cos: "0,98",
//         id: 1826,
//         parents: []
//     },
//     {
//         name: "Электродегидратор",
//         ki: "0,54",
//         cos: "0,85",
//         id: 1827,
//         parents: []
//     },
//     {
//         name: "Электрообогрев труб",
//         ki: "0,6",
//         cos: "1",
//         id: 1828,
//         parents: []
//     },
//     {
//         name: "Электропечь",
//         ki: "0,58",
//         cos: "1",
//         id: 1829,
//         parents: []
//     },
//     {
//         name: "Электрополотенце",
//         ki: "0,07",
//         cos: "1",
//         id: 1830,
//         parents: []
//     },
//     {
//         name: "Электроталь",
//         ki: "0,11",
//         cos: "0,75",
//         id: 1831,
//         parents: []
//     }
// ]

// let arr = []
// guide_items.forEach(element => {
//   let name = element.name
//   let ki = element.ki
//   let cos = element.cos
//   let id = element.id
//   let parents = []
//   if (id >= 2 & id <= 58) {
//     parents.push(1)
//     parents.push(1.1)
//   }
//   if (id >= 59 & id <= 109) {
//     parents.push(1)
//     parents.push(1.2)
//   }
//   if (id >= 110 & id <= 193) {
//     parents.push(1)
//     parents.push(1.3)
//   }

//   if (id >= 194 & id <= 216) {
//     parents.push(1)
//     parents.push(1.4)
//   }

//   if (id >= 217 & id <= 286) {
//     parents.push(1)
//     parents.push(1.5)
//   }
//   if (id >= 287 & id <= 479) {
//     parents.push(1)
//     parents.push(1.6)
//   }

//   if (id >= 480 & id <= 645) {
//     parents.push(1)
//     parents.push(1.7)
//   }

//   if (id >= 646 & id <= 793) {
//     parents.push(1)
//     parents.push(1.8)
//   }

//   if (id >= 794 & id <= 796) {
//     parents.push(1)
//     parents.push(1.9)
//   }

//   if (id >= 797 & id <= 821) {
//     parents.push(1)
//     parents.push(1.10)
//   }

//   if (id >= 822 & id <= 865) {
//     parents.push(1)
//     parents.push(1.11)
//   }

//   if (id >= 866 & id <= 938) {
//     parents.push(1)
//     parents.push(1.12)
//   }


//   if (id >= 939 & id <= 961) {
//     parents.push(1)
//     parents.push(1.13)
//   }

//   if (id >= 962 & id <= 987) {
//     parents.push(1)
//     parents.push(1.14)
//   }

//   if (id >= 988 & id <= 1001) {
//     parents.push(1)
//     parents.push(1.15)
//   }

//   if (id >= 1002 & id <= 1017) {
//     parents.push(1)
//     parents.push(1.16)
//   }

//   if (id >= 1018 & id <= 1029) {
//     parents.push(1)
//     parents.push(1.17)
//   }

//   if (id >= 1030 & id <= 1068) {
//     parents.push(1)
//     parents.push(1.18)
//   }

//   if (id >= 1069 & id <= 1091) {
//     parents.push(1)
//     parents.push(1.19)
//   }

//   if (id >= 1092 & id <= 1098) {
//     parents.push(1)
//     parents.push(1.20)
//   }
//   if (id >= 1099 & id <= 1114) {
//     parents.push(1)
//     parents.push(1.21)
//   }
//   if (id >= 1115 & id <= 1155) {
//     parents.push(1)
//     parents.push(1.22)
//   }
//   if (id >= 1156 & id <= 1219) {
//     parents.push(1)
//     parents.push(1.23)
//   }
//   if (id >= 1220 & id <= 1288) {
//     parents.push(1)
//     parents.push(1.24)
//   }
//   if (id >= 1289 & id <= 1388) {
//     parents.push(1)
//     parents.push(1.25)
//   }
//   if (id >= 1389 & id <= 1412) {
//     parents.push(1)
//     parents.push(1.26)
//   }
//   if (id >= 1413 & id <= 1441) {
//     parents.push(1)
//     parents.push(1.27)
//   }
//   if (id >= 1442 & id <= 1476) {
//     parents.push(1)
//     parents.push(1.25)
//   }
//   if (id >= 1477 & id <= 1534) {
//     parents.push(2)
//     parents.push(2.1)
//   }
//   if (id >= 1535 & id <= 1538) {
//     parents.push(2)
//     parents.push(2.2)
//   }
//   if (id >= 1539 & id <= 1543) {
//     parents.push(2)
//     parents.push(2.3)
//   }
//   if (id >= 1544 & id <= 1561) {
//     parents.push(2)
//     parents.push(2.4)
//   }
//   if (id >= 1562 & id <= 1580) {
//     parents.push(2)
//     parents.push(2.5)
//   }
//   if (id >= 1581 & id <= 1686) {
//     parents.push(2)
//     parents.push(2.6)
//   }
//   if (id >= 1687 & id <= 1701) {
//     parents.push(2)
//     parents.push(2.7)
//   }
//   if (id >= 1703 & id <= 1746) {
//     parents.push(2)
//     parents.push(2.8)
//   }


//   if (id >= 1747 & id <= 1831) {
//     parents.push(3)
//   }

//   // let name = element.name
//   // let ki = element.ki
//   // let cos = element.cos
//   // let id = element.id
//   // let parents = []
//   arr.push({name,ki,cos,id,parents})
// });

// console.log(guide_items)
// console.log(arr)

// const result = `[${arr.map(item => JSON.stringify(item)).join(',')}]`;
// console.log(result);