<template lang="">
    <div>
        
        <div v-if="input||select"> {{label}}</div>
        <input v-if="input" class="input" type="text" 
            @input="$emit('updateProjectParams', label, $event.target.value);
            validateInput(label, $event.target.value)" v-bind:placeholder=val />

        <select class="input" v-model="selected" v-if="select" @change="handleChange" v-bind:placeholder=val>
            <option value="" disabled >{{val}}</option>
            <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
        </select>

        <div v-if="radio" class="parameters_Radio">
            <div class="h3 h3_top_0">{{label}}</div>

            <div v-for="(value, key) in radioOptions" :key="key">
                <input type="radio" :id="key" :name="label" :value="key" v-model="selected" @change="handleChange" :checked="key == val" />
                <label :for=option>{{ value }}</label>
            </div>
        </div>

    </div>
</template>
<script>
import { ref, watchEffect } from 'vue'

export default {
    props: {
        labelText: String,
        valueInput: Object
    },
    methods: {
        validateInput(label, value) {
            // Добавьте здесь проверки для валидации данных
            // Например, можно проверить наличие значений, тип данных и т.д.
            const regex = /[^.,0-9]/;
            const numericInputs = ["Ikz3max", "Ikz3min", "Ikz_loading", "ImaxKTP", "IminKTP", "Kp1", "Kp2", "Kp_vvod_cabel", "R1", "Ro", "X1", "Xo", "len_vvod_cabel"];
            if (!numericInputs.includes(label)) {
                console.log(value)
            } else {
                if (regex.test(value)) {
                    console.log("Строка содержит буквы или другие символы, кроме точки и запятой.");
                    alert("Введено не число")
                }
            }
        },
        handleChange() {
            this.$emit('updateProjectParams', this.label, this.selected);
        }
    },
    setup(props) {
        let input = false
        let select = false
        let radio = false
        let options = []
        let selected = ref('')
        let radioOptions = ref({})
        let val = ref(props.valueInput)

        if (props.labelText == 'Категория эл. снаб.') {
            select = true,
                options = ["3"]
            // options = ["3", "1", "2"]
        }
        else if (props.labelText == "Тип заземления") {
            select = true,
                options = ["TN-S", "TN-C-S"]
        }
        else if (props.labelText == "Формат листа") {
            select = true,
                options = ["Формат А3х3 г", "Формат А2х3 г"]
        }
        else if (props.labelText == "Iкз нагрузки") {
            radio = true,
                radioOptions = {
                    "1": "без учета переходных сопротивлений аппаратов и дуги",
                    "2": "с учетом переходных сопротивлений аппаратов и дуги",
                    "3": "с учетом температуры и переходных сопротивлений аппаратов и дуги"
                }
        }
        else {
            input = true
        }

        watchEffect(() => {
            val.value = props.valueInput
        })

        return {
            label: props.labelText,
            val,
            input,
            select,
            selected,
            options,
            radio,
            radioOptions
        }
    }
}
</script>
<style>
.input {
    max-width: 100%;
}

.parameters_Radio {
    padding: 5px 15px 15px 15px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 20px;
    /* margin: 20px; */
    text-align: left;
}

.h3.h3_top_0 {
    margin-top: 0 !important
}
</style>