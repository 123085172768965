<template lang="">
    <div>
        
        <div>
            <div class="h3">380В</div>
            <div v-for="(item,index) in items380" :key="index">
            <FiderComponent
                :root-items = items
                :class="{'active' : activeFider == item.id}"
                :title-text="item.title" 
                :key="item.id" 
                :fider-data="item.data" 
                @click="($event) => $emit('clickChangeFider1', item.id)" 
                @clickChangeFider="(id)=>$emit('clickChangeFider1' ,id)" 
                :id-value="item.id" 
                @clickDeleteFider="(id)=>$emit('clickDeleteFider1' ,id)" 
                @updateItems2 = "updateItems3"
            />
        </div>
        </div>

        <div>
            <div class="h3">220В</div>
            <div v-for="(item,index) in items220" :key="index">
                <FiderComponent
                    :root-items = items
                    :class="{'active' : activeFider == item.id}"
                    :title-text="item.title" 
                    :key="item.id" 
                    :fider-data="item.data" 
                    @click="($event) => $emit('clickChangeFider1', item.id)" 
                    @clickChangeFider="(id)=>$emit('clickChangeFider1' ,id)" 
                    :id-value="item.id" 
                    @clickDeleteFider="(id)=>$emit('clickDeleteFider1' ,id)" 
                    @updateItems2 = "updateItems3"
                />
            </div>
        </div>
        
        <!-- <div v-for="(item,index) in items" :key="index">
            <FiderComponent
                :title-text="item.title" 
                :key="item.id" 
                :fider-data="item.data" 
                @click="($event) => $emit('clickChangeFider1', item.id)" 
                @clickChangeFider="(id)=>$emit('clickChangeFider1' ,id)" 
                :id-value="item.id" 
                @clickDeleteFider="(id)=>$emit('clickDeleteFider1' ,id)" 
            />
        </div> -->
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import FiderComponent from './FiderComponent.vue'

export default {
    props: {
        listItems: {
            type: Array,
            required: true
        },
        activeFider2: {
            type: String,
            default: ""
        }
    },
    components: {
        FiderComponent,
    },
    methods:{
        updateItems3(data){
            this.$emit('updateItems2',data)
        }
    },
    setup(props) {
        const items = ref([...props.listItems]);
        let items220 = ref([])
        let items380 = ref([])

        const activeFider = ref(props.activeFider2);

        // Следим за изменениями пропсов и обновляем локальные данные
        watchEffect(() => {
            // items.value = props.listItems; //тут нужно изменить, чтобы итемсы приходили объединенные
            //console.log("изменился список фидеров..... " + [...props.listItems].length)
            items.value = [...props.listItems].sort((a, b) => (a.index) - (b.index)); // Сортируем копию массива по убыванию даты createdAt

            const arr = items.value
            //отбираем элементы с напряжением 220

            items220.value.length = 0
            arr.forEach(function (item) {
                if (item.data["Напряжение, В"] == "220") {
                    items220.value.push(item)
                }
            })

            //отбираем элементы с напряжением 380
            items380.value.length = 0
            arr.forEach(function (item) {
                if (item.data["Напряжение, В"] == "380") {
                    items380.value.push(item)
                }
            })
            activeFider.value = props.activeFider2;
        });
        return {
            items,
            items220,
            items380,
            activeFider
        };
    }
}
</script>

<style>
.h3 {
    margin: 15px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}
</style>