// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import AuthPage from '../pages/AuthPage.vue'
import MainPage from '../pages/MainPage.vue'
import FuncPage from '../pages/FuncPage.vue'
import ShieldsPage from '../pages/ShieldsPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      // name: "Home",
      name: "Home",
      component: MainPage
      // component: FuncPage
    },
    {
      path: '/auth',
      // name: "Home",
      name: "Auth",
      component: AuthPage
      // component: FuncPage
    },
    {
      path: '/shields',
      name: "Shields",
      component: ShieldsPage
    }
    ,
    {
      path: '/func',
      name: "Calc",
      component: FuncPage,
      props: true
    },
    {
      path: '/func/:project_id',
      name: "Fiders",
      component: FuncPage,
      props: true
    }
  ]
})

router.beforeEach((to, from, next) => {
  ////// const isAuthenticated = sessionStorage.getItem('userName')
  ////// console.log(isAuthenticated)
  // if (window.location.href === 'http://localhost:8080/auth') { 
  //   console.log("Мы на странице авторизации")
  // }
  // else {
  //   if (!isAuthenticated) {
  //     window.location.href = '/auth';
  //     console.log("нужно авторизоваться")
  //   } else {
  //     console.log("не нужно авторизоваться")
  //   }
  // }

  if (to.path !== '/auth' && !sessionStorage.getItem('userName')) {
    window.location.href = '/auth';
  } else {
    next();
  }

})

export default router