<template lang="">
    <div class="transPopupContainer">
        <div class="transPopupContainer2">
            <div class="transPopupContainerBlock" >
                <div class="closeButton" @click="$emit('togglechooseTransPopupBool')">X</div>

                <div class="transPopupInputContainer">
                    <label>Завод изготовитель</label>
                    <v-select :options=factoryArr label="name" taggable placeholder=""  v-model="factory"></v-select>
                </div>

                <div class="transPopupInputContainer">
                    <label>Модель</label>
                    <v-select :options=modelArr label="name" taggable placeholder=""  v-model="model"></v-select>
                </div>

                <div class="transPopupInputContainer">
                    <label>Мощность, кВа</label>
                    <v-select :options=powerArr label="name" taggable placeholder=""  v-model="power"></v-select>
                </div>

                <div class="transPopupInputContainer">
                    <label>Uном ВН, кВ</label>
                    <v-select :options=ubhArr label="name" taggable placeholder=""  v-model="ubh"></v-select>
                </div>

                <div class="transPopupInputContainer">
                    <label>Uном HН, кВ</label>
                    <v-select :options=uhhArr label="name" taggable placeholder=""  v-model="uhh"></v-select>
                </div>

                <div class="transPopupInputContainer">
                    <label>Схема и группа соед.</label>
                    <v-select :options=schemeArr label="name" taggable placeholder=""  v-model="scheme"></v-select>
                </div>

                <div class="selectedTransContainer">
                <div>R1: {{r1}}</div>
                <div>R0: {{r0}}</div>
                <div>X1: {{x1}}</div>
                <div>X0: {{x0}}</div>

                <div class="chooseTransButton" v-if="r1 && r0 && x1 && x0" @click="$emit('newValueTrans', [r1,r0,x1,x0])">Готово</div>
            </div>

            </div>
        </div>
    </div>
    </template>
<script>
import { ref, watchEffect } from 'vue'
import VSelect from "vue-select";
import 'vue-select/dist/vue-select.css'
import trans from '../TransPopup/trans'

console.log(trans)

export default {
    components: { 'v-select': VSelect },
    // methods: {

    // },
    setup() {
        let trans_var = ref(trans.trans)
        let factory = ref(null)
        let model = ref(null)
        let power = ref(null)
        let ubh = ref(null)
        let uhh = ref(null)
        let scheme = ref(null)

        let factoryArr = ref([])
        let modelArr = ref([])
        let powerArr = ref([])
        let ubhArr = ref([])
        let uhhArr = ref([])
        let schemeArr = ref([])

        let r1 = ref(1.9)
        let r0 = ref(1.9)
        let x1 = ref(8.6)
        let x0 = ref(8.6)

        /*-------------------------------------------------------------------------- */
        /*-----------------МЕТОДЫ ДЛЯ РАСЧЕТА ОПЦИЙ ДЛЯ ИНПУТОВ--------------------- */
        /*-------------------------------------------------------------------------- */

        const calculationFactoryArr = (arr) => {
            const uniqueFactoryArr = arr.reduce((acc, item) => {
                if (acc.includes(item["factory"])) {
                    return acc
                }
                return [...acc, item["factory"]]
            }, [])
            return uniqueFactoryArr
        }
        const calculationModelArr = (val) => {
            let result
            if (val == "Завод им. В.И.Козлова") {
                result = ["ТМГ"]
            } else if (val == "Отечественный трансформатор") {
                result = ["ТМ"]
            } else {
                result = []
            }
            return result
        }

        const calculationPowerArr = (arr, factory_val, model_var) => {
            const newPowerArr = arr.reduce((acc, item) => {
                if (item["factory"] == factory_val && item["model"] == model_var) {
                    if (acc.includes(item["power"])) {
                        return acc
                    }
                    return [...acc, item["power"]]
                } else {
                    return acc
                }

            }, [])

            return newPowerArr
        }

        const calculationUbhArr = (arr, factory_val, model_var, power_var) => {
            const newPowerArr = arr.reduce((acc, item) => {
                if (item["factory"] == factory_val && item["model"] == model_var && item["power"] == power_var && item["ubh"] != null && item["uhh"] != null) {
                    if (acc.includes(item["ubh"])) {
                        return acc
                    }
                    return [...acc, item["ubh"]]
                } else {
                    return acc
                }

            }, [])

            return newPowerArr
        }

        const calculationUhhArr = (arr, factory_val, model_var, power_var) => {
            const newPowerArr = arr.reduce((acc, item) => {
                if (item["factory"] == factory_val && item["model"] == model_var && item["power"] == power_var && item["ubh"] != null && item["uhh"] != null) {
                    if (acc.includes(item["uhh"])) {
                        return acc
                    }
                    return [...acc, item["uhh"]]
                } else {
                    return acc
                }

            }, [])

            return newPowerArr
        }

        const calculationSchemeArr = (arr, factory_val, model_var, power_var, ubh_var, uhh_var) => {
            const newPowerArr = arr.reduce((acc, item) => {
                if (item["factory"] == factory_val && item["model"] == model_var && item["power"] == power_var && item["ubh"] == ubh_var && item["uhh"] == uhh_var) {
                    if (acc.includes(item["scheme"])) {
                        return acc
                    }
                    return [...acc, item["scheme"]]
                } else {
                    return acc
                }

            }, [])

            return newPowerArr
        }

        const calculationResults = (arr, factory_val, model_var, power_var, ubh_var, uhh_var, scheme_var) => {
            const resultItem = arr.find(item => (item["factory"] == factory_val && item["model"] == model_var && item["power"] == power_var && item["ubh"] == ubh_var && item["uhh"] == uhh_var && item["scheme"] == scheme_var))
            // console.log("resultItem" + resultItem)
            let result
            if (resultItem) {
                result = [resultItem["r1"], resultItem["r0"], resultItem["x1"], resultItem["x0"]]

            } else {
                result = [null, null, null, null]

            }

            // console.log("result" + result)
            return result
        }
        /*-------------- END МЕТОДЫ ДЛЯ РАСЧЕТА ОПЦИЙ ДЛЯ ИНПУТОВ------------------- */

        watchEffect(() => {
            trans_var.value = trans.trans
            factoryArr.value = calculationFactoryArr(trans_var.value)
            modelArr.value = calculationModelArr(factory.value)
            powerArr.value = calculationPowerArr(trans_var.value, factory.value, model.value)
            ubhArr.value = calculationUbhArr(trans_var.value, factory.value, model.value, power.value)
            uhhArr.value = calculationUhhArr(trans_var.value, factory.value, model.value, power.value)
            schemeArr.value = calculationSchemeArr(trans_var.value, factory.value, model.value, power.value, ubh.value, uhh.value)
            r1.value = calculationResults(trans_var.value, factory.value, model.value, power.value, ubh.value, uhh.value, scheme.value)[0]
            r0.value = calculationResults(trans_var.value, factory.value, model.value, power.value, ubh.value, uhh.value, scheme.value)[1]
            x1.value = calculationResults(trans_var.value, factory.value, model.value, power.value, ubh.value, uhh.value, scheme.value)[2]
            x0.value = calculationResults(trans_var.value, factory.value, model.value, power.value, ubh.value, uhh.value, scheme.value)[3]
        })

        return {
            trans_var,
            factory,
            model,
            power,
            ubh,
            uhh,
            scheme,
            factoryArr,
            modelArr,
            powerArr,
            ubhArr,
            uhhArr,
            schemeArr,
            r1,
            r0,
            x1,
            x0
        }
    }
}

</script>
<style>
.v-select .vs__selected {
    white-space: normal;
    word-break: break-all;
}

.transPopupContainer {
    z-index: 9999999999;
    background-color: #0000004f;
    left: 0;
    right: 0;
    /* width: 100; */
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: 0;
    top: 0;
    height: auto;
}

.transPopupContainerBlock {
    background-color: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    padding-top: 50px;
    min-width: 400px;
}

@media(max-width: 500px) {
    .transPopupContainerBlock {
        width: 77%;
    }
}

.chooseTransButton {
        color: #fff;
        background-color: #004c97;
        padding: 0.6em 0.5em;
        font-weight: 600;
        border-radius: 50px;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        padding: 0.5em 0.8em;
        margin-top: 20px;
    }
    
    .chooseTransButton:hover {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    }

.selectedTransContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.transPopupInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.transPopupInputContainer>div {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
}

/* .chooseEPContainer2 {
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 100vh;
    } */

.closeButton {
    top: 20px;
    right: 20px;
    position: absolute;
    font-weight: 800;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 2px solid #004c97;
    cursor: pointer;
    color: #004c97;
    align-items: center;
    justify-content: center;
    display: flex;
}

.ps {
    height: 300px;
    /* or max-height: 400px; */
    border: 1px solid var(--vs-border-color);
    border-radius: 20px;
    margin: 20px 0;
}

.inputReceiver {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    padding: 0 0 4px;
    background: none;
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-radius: var(--vs-border-radius);
    white-space: normal;
    width: calc(100% - 16px);
    height: 15px;
    padding: 8px;
}

.elReceiver p {
    font-size: 14px;
}

.ps__thumb-y {
    background-color: #004c97 !important;
}

.item.elReceiver {
    cursor: pointer;
    border-bottom: 1px solid var(--vs-border-color);
    padding-left: 8px;
    padding-right: 8px;
    text-align: left;
}

.ReceiverListContainer {
    height: 300px;
    overflow: auto;
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-radius: var(--vs-border-radius);
    margin: 20px 0;
    border: 2px var(--vs-border-style) #333;
}
</style>