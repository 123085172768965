<template lang="">
    <div class="appButton marginTop selectNone button_Add_Fider">Создать новый фидер</div>
</template>
<script>
export default {

}
</script>
<style>
.button_Add_Fider {
    color: #fff;
    background-color: #004c97;
    padding: 0.6em 0.5em;
    font-weight: 600;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 0.5em 0.8em;
    margin-top: 20px;
}
</style>