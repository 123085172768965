<template lang="">
    <div class="Container">
        <!-- <h1 class="h1">Шкафы</h1> -->
        <div class="shiledsContainer">
            <div class="buttonAddShield" @click="addShield">Создать проект шкафа</div>
            <ListShields :shields-prop="shields"/> <!-- сюда передаем список всех шкафов -->
        </div>
    </div>
</template>
<script>
import ListShields from '../components/ShieldsData/ListShields.vue'
import { v4 as uuidv4 } from 'uuid';

import { ref, onMounted } from 'vue'
export default {
    components: {
        ListShields
    },

    methods: {
        async addShield() {
            let userName = sessionStorage.getItem('userName')
            var id = uuidv4()
            console.log(id)
            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/add-shield/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        author:userName,
                        id: id,
                        name: "Новый проект",
                        changeExcel:false,
                        changeNCad:false,
                        data: {
                            'Категория эл. снаб.': 3,
                            'Ikz3max': 0,
                            'Ikz3min': 0,
                            'Определение Ikз(3) max в месте подключения КТП': 8,
                            'Определение Ikз(3) min в месте подключения КТП': 7,
                            'Тип заземления': "TN-S",
                            'Iкз нагрузки': 3,
                            'Формат листа': "Формат А3х3 г",
                            'Длина, км': 0.1,
                            'Кратноть Iп, КП': "1",
                            'Имя щита': "ЩУЭ",
                            'R1': 1.9,
                            'Ro': 1.9,
                            'X1': 8.6,
                            'Xo': 8.6,
                            'Kp1': 1,
                            'Kp2': 1
                        }
                    })
                });

                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    location.reload()
                    alert("Шкаф добавлен")
                }
            }
            catch (error) {
                console.error('Ошибка добавления шкафа:', error);
            }
        }
    },
    setup() {

        // let shields = ref([//это хзначение будет вытягивать из апишки, список шкафов, с именем и айдишниками
        //     { id: "1", name: "Шкаф 1" },
        //     { id: "2", name: "Шкаф 2" },
        //     { id: "3", name: "Шкаф 3" },
        //     { id: "4", name: "Шкаф 4" }
        // ])

        let shields = ref([])
        onMounted(async () => {
            try {
                let userName = sessionStorage.getItem('userName')
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/shields/?author=' + userName);

                
                console.log(process.env.VUE_APP_BASE_URL + '/shields/')
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                }
                const data = await response.json();
                shields.value = data; // сохраняем полученные данные в переменной shield
            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        });
        //     fetch('http://localhost:3001/shields')
        //   .then(response => response.json())
        //   .then(data => {
        //     shields.value = data
        //   })
        //   .catch(error => {
        //     console.error('Error fetching data:', error)
        //   })

        return {
            shields
        }
    }
}
</script>
<style>
.Container {
    width: 99%;
    margin: 0 auto;
}

.buttonAddShield {
    background-color: #004c97;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    color: #fff;
    max-width: 300px;
    margin-bottom: 20px;

}
.shiledsContainer{
    margin-top: 1.4rem;
}
</style>