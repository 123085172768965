export default {
    trans: [
        {
            id: 1,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 16,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.324,
            r0: 0.07276,
            x1: 0.403,
            x0: 0.29104
        },
        {
            id: 2,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 16,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.33,
            r0: 0.07276,
            x1: 0.399,
            x0: 0.29104
        },
        {
            id: 3,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 25,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.18688,
            r0: 0.01984,
            x1: 0.24896,
            x0: 0.07936
        },
        {
            id: 4,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 25,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.18624,
            r0: 0.01984,
            x1: 0.25408,
            x0: 0.07936
        },
        {
            id: 5,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 40,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.1044,
            r0: 0.0097,
            x1: 0.1556,
            x0: 0.03881
        },

        {
            id: 6,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 40,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.1024,
            r0: 0.0097,
            x1: 0.1636,
            x0: 0.03881
        },
        {
            id: 7,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 63,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.05994,
            r0: 0.00703,
            x1: 0.09625,
            x0: 0.02813
        },
        {
            id: 8,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 63,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.06121,
            r0: 0.00703,
            x1: 0.1021,
            x0: 0.02813
        },
        {
            id: 9,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 100,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.03696,
            r0: 0.00451,
            x1: 0.06176,
            x0: 0.01804
        },
        {
            id: 10,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 100,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.03616,
            r0: 0.00451,
            x1: 0.06096,
            x0: 0.01804
        },
        {
            id: 11,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.0165,
            r0: 0.25612,
            x1: 0.041,
            x0: 1.02447
        },
        {
            id: 12,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.0161,
            r0: 0.25612,
            x1: 0.0421,
            x0: 1.02447
        },
        {
            id: 13,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.0169,
            r0: 0.01104,
            x1: 0.0415,
            x0: 0.04414
        },
        {
            id: 14,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.0173,
            r0: 0.01104,
            x1: 0.0406,
            x0: 0.04414
        },
        {
            id: 15,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 6,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.0183,
            r0: 0.00225,
            x1: 0.042,
            x0: 0.009
        },
        {
            id: 16,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 160,
            ubh: 10,
            uhh: 0.4,
            scheme: "Y/Zh-11",
            r1: 0.018,
            r0: 0.00225,
            x1: 0.0412,
            x0: 0.009
        },
        {
            id: 17,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 250,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00941,
            r0: 0.13582,
            x1: 0.02707,
            x0: 0.54328
        },
        {
            id: 18,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 250,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00973,
            r0: 0.13582,
            x1: 0.02647,
            x0: 0.54328
        },
        {
            id: 19,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 250,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.01069,
            r0: 0.00699,
            x1: 0.02605,
            x0: 0.02794
        },
        {
            id: 20,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 250,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.01056,
            r0: 0.00699,
            x1: 0.02643,
            x0: 0.02794
        },
        {
            id: 21,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 400,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00548,
            r0: 0.08367,
            x1: 0.01704,
            x0: 0.3347
        },
        {
            id: 22,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 400,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00548,
            r0: 0.08367,
            x1: 0.0166,
            x0: 0.3347
        },
        {
            id: 23,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 400,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00552,
            r0: 0.00456,
            x1: 0.01636,
            x0: 0.01824
        },
        {
            id: 24,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 400,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00572,
            r0: 0.00456,
            x1: 0.017,
            x0: 0.01824
        },
        {
            id: 25,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 630,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00305,
            r0: 0.06063,
            x1: 0.01354,
            x0: 0.24254
        },
        {
            id: 26,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 630,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.0031,
            r0: 0.06063,
            x1: 0.01338,
            x0: 0.24254
        },

        {
            id: 27,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 630,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00302,
            r0: 0.0034,
            x1: 0.01402,
            x0: 0.01358
        },
        {
            id: 28,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 630,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00312,
            r0: 0.0034,
            x1: 0.01328,
            x0: 0.01358
        },
        {
            id: 29,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1000,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00166,
            r0: 0.05093,
            x1: 0.00928,
            x0: 0.20373
        },
        {
            id: 30,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1000,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.0017,
            r0: 0.05093,
            x1: 0.00872,
            x0: 0.20373
        },
        {
            id: 31,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1000,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.0023,
            r0: 0.00224,
            x1: 0.00891,
            x0: 0.00897
        },
        {
            id: 32,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1000,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00173,
            r0: 0.00224,
            x1: 0.00902,
            x0: 0.00897
        },
        {
            id: 33,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1250,
            ubh: 6,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00131,
            r0: 0.03735,
            x1: 0.00758,
            x0: 0.1494
        },
        {
            id: 34,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1250,
            ubh: 10,
            uhh: 0.4,
            scheme: "У/Ун-О",
            r1: 0.00129,
            r0: 0.03735,
            x1: 0.00742,
            x0: 0.1494
        },

        {
            id: 35,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1250,
            ubh: 6,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00128,
            r0: 0.00188,
            x1: 0.00739,
            x0: 0.00752
        },
        {
            id: 36,
            factory: "Завод им. В.И.Козлова",
            model: "ТМГ",
            power: 1250,
            ubh: 10,
            uhh: 0.4,
            scheme: "Д/Ун-11",
            r1: 0.00127,
            r0: 0.00188,
            x1: 0.00767,
            x0: 0.00752
        },
        {
            id: 37,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 25,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.154,
            r0: 1.65,
            x1: 0.244,
            x0: 1.93

        },
        {
            id: 38,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 25,
            ubh: null,
            uhh: null,
            scheme: "У/Zн",
            r1: 0.177,
            r0: 0.073,
            x1: 0.243,
            x0: 0.0354

        },
        {
            id: 39,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 40,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.088,
            r0: 0.952,
            x1: 0.157,
            x0: 1.269

        },
        {
            id: 40,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 63,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.052,
            r0: 0.504,
            x1: 0.102,
            x0: 0.873
        },
        {
            id: 41,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 63,
            ubh: null,
            uhh: null,
            scheme: "У/Zн",
            r1: 0.059,
            r0: 0.028,
            x1: 0.105,
            x0: 0.012
        },
        {
            id: 42,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 100,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0315,
            r0: 0.254,
            x1: 0.065,
            x0: 0.582

        },
        {
            id: 43,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 100,
            ubh: null,
            uhh: null,
            scheme: "У/Zн",
            r1: 0.0363,
            r0: 0.0156,
            x1: 0.0657,
            x0: 0.0106
        },
        {
            id: 44,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 160,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0166,
            r0: 0.151,
            x1: 0.0417,
            x0: 0.367

        },
        {
            id: 45,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 160,
            ubh: null,
            uhh: null,
            scheme: "У/Дн",
            r1: 0.0166,
            r0: 0.0166,
            x1: 0.0417,
            x0: 0.0417
        },
        {
            id: 46,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 250,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0094,
            r0: 0.0965,
            x1: 0.0272,
            x0: 0.235
        },
        {
            id: 47,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 250,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.0094,
            r0: 0.0094,
            x1: 0.0272,
            x0: 0.0272
        },
        {
            id: 48,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 400,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0055,
            r0: 0.0556,
            x1: 0.0171,
            x0: 0.149
        },
        {
            id: 49,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 400,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.0059,
            r0: 0.0559,
            x1: 0.0017,
            x0: 0.0017
        },
        {
            id: 50,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 630,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0031,
            r0: 0.0302,
            x1: 0.0136,
            x0: 0.0958
        },
        {
            id: 51,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 630,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.0034,
            r0: 0.0034,
            x1: 0.0135,
            x0: 0.0135
        },
        {
            id: 52,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 1000,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.0017,
            r0: 0.0196,
            x1: 0.0086,
            x0: 0.0606
        },
        {
            id: 53,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 1000,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.0019,
            r0: 0.0019,
            x1: 0.0086,
            x0: 0.0086
        },
        {
            id: 54,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 1600,
            ubh: null,
            uhh: null,
            scheme: "У/Ун",
            r1: 0.001,
            r0: 0.0163,
            x1: 0.0054,
            x0: 0.05
        },
        {
            id: 55,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 1600,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.0011,
            r0: 0.0011,
            x1: 0.0054,
            x0: 0.0054
        },
        {
            id: 56,
            factory: "Отечественный трансформатор",
            model: "ТМ",
            power: 2500,
            ubh: null,
            uhh: null,
            scheme: "Д/Ун",
            r1: 0.00064,
            r0: 0.00064,
            x1: 0.00346,
            x0: 0.00346
        }
    ]
}