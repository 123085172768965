<template lang="">
    <div class="containerShadow InputFiderDataContainer">
        <div class="h2">Создать новый фидер</div>
        <div class="InputFiderDataContainer_InputContainer">
            <div class="block_fiderData1">
                <div class="InputFiderDataContainer_InputContainer">
                    <InputSingleFiderData class="InputSingleFiderData" 
                    @updateFiderData ="(label, inputText)=>FiderData[label] = inputText" 
                    v-for="(item,index) in FiderData1" 
                    :value-input = "item"
                    :label-text="index" 
                    :key="index"
                    :voltage="voltage"
                    :valueEP="ValueEP"
                    :fider-id=fider_Id
                    />
                    <div class="InputSingleFiderData">
                    <div class="input_Label" style="color: #fff; user-select: none">sdrydr </div>
                    <div class="chooseEP_Btn" @click="() => chooseEPContainerBool = true">Выбрать наименование ЭП</div>
                </div>
                </div>
            </div>

            <div class="InputFiderDataContainer_InputContainer">
                <InputSingleFiderData class="InputSingleFiderData" 
                @updateFiderData ="(label, inputText)=>FiderData[label] = inputText" 
                v-for="(item,index) in FiderData2" 
                :value-input = "item"
                :label-text="index" 
                :key="index"
                :voltage="voltage"
                :valueEP="ValueEP"
                :fider-id=fider_Id
                />
            </div>
        </div>
        <!-- <div class="InputFiderDataContainer_InputContainer">
            <InputSingleFiderData class="InputSingleFiderData" 
                @updateFiderData ="(label, inputText)=>FiderData[label] = inputText" 
                v-for="(item,index) in FiderData" 
                :value-input = "item"
                :label-text="index" 
                :key="index"
                :voltage="voltage"
            />
        </div> -->
        <chooseEPContainer 
        @togglechooseEPContainerBool="{ chooseEPContainerBool = false;  }"
        @newValueEP="(data) => setNewValueEP(data)"
        :fider-id = fider_Id
        v-if="chooseEPContainerBool" 
        
        />

        <div class="appButton marginTop selectNone" @click="handleClick(id,FiderData,items)">Сохранить фидер</div>
    </div>
</template>
<script>
import { ref, watchEffect } from 'vue';
import InputSingleFiderData from './InputSingleFiderData.vue';
import { v4 as uuidv4 } from 'uuid';
import chooseEPContainer from '../chooseEP/chooseEPContainer.vue'
export default {
    components: {
        chooseEPContainer,
        InputSingleFiderData
    },
    props: {
        fiderId: {
            type: String,
            required: true
        },
        projectId: {
            type: Number,
            required: true
        },
        rootItems: {
            type: Array,
            required: true
        },
    },

    methods: {
        async handleClick(project_id, data, items) {

            const regex = /[^.,0-9]/;
            const numericInputs = ["Кол-во ЭП, n", "Номинальная (установленная) мощность, кВт", "Коэфф. исп.Кu", "Коэфф. cosφ", "Длина кабеля, км", "Кол-во жил", "Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)"];

            for (let key in data) {
                let label = key
                let value = data[key]
                if (numericInputs.includes(label)) {
                    if (regex.test(value) || value == "") {
                        alert("Поля заполнены неверно!")
                        return false
                    }
                }
            }


            console.log(project_id)
            console.log(data)
            // var id = Date.now();
            var id = uuidv4()
            console.log(id)
            var isEmpty = false
            // проверка инпутов на пустоту
            var dataCopy = data

            for (let key in dataCopy) {
                if (dataCopy[key] === null || dataCopy[key] === undefined || dataCopy[key] === '') {
                    isEmpty = true;
                } else {
                    if (typeof dataCopy[key] === 'string') {
                        var dataData = dataCopy[key];
                        var data_Without_Wspaces = dataData.replace(' ', '');

                        console.log("dataData: " + dataData);
                        console.log("data_Without_Wspaces: " + data_Without_Wspaces);
                        if (data_Without_Wspaces === '') {
                            isEmpty = true;
                        }
                    }
                }
            }
            if (isEmpty === false) {
                try {
                    let items_Count
                    let index
                    if (items.length > 0) {
                        items_Count = items.length - 1
                        index = +items[items_Count].index + 1
                        console.log("Индекс последнего элемента = " + items[items_Count].index)
                    } else {
                        index = 0
                    }

                    console.log(index)
                    const [response1, response2] = await Promise.all([
                        fetch(process.env.VUE_APP_BASE_URL + '/add-fider/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                id: id,
                                project_id: project_id,
                                data: data,
                                index: index
                            })
                        }),
                        fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
                            method: 'POST',
                            body: JSON.stringify({
                                id: project_id,
                                changeExcel: true,
                                changeNCad: true
                            })
                        })
                    ]);
                    console.log(response1)
                    console.log(response2)
                    if (!response1.ok || !response2.ok) {
                        throw new Error('Неправильный ответ сети');
                    } else {
                        alert("Фидер добавлен")
                        location.reload()
                    }
                } catch (error) {
                    console.error('Ошибка добавления фидера:', error);
                }
            } else {
                alert('Заполните все поля!');
            }

        }
    },

    // components: {

    // },
    setup(props) {

        const fider_Id = ref(props.fiderId)

        const id = ref(props.projectId);
        const voltage = ref(0)

        const items = ref(props.rootItems);
        watchEffect(() => {
            id.value = props.projectId;
            items.value = props.rootItems;
        });

        const FiderData1 = ref({
            'Наименование ЭП (справочный)': '',
            'Коэфф. исп.Кu': '',
            'Коэфф. cosφ': '',
        });

        const FiderData2 = ref({
            'Наименование ЭП': 'Стан порошковой проволки',
            'Напряжение, В': '380',
            'Кол-во ЭП, n': '1',
            'Номинальная (установленная) мощность, кВт': '28',
            'Длина кабеля, км': '0.161',
            'Тип автомата': 'АВ',
            'Кол-во полюсов автомата': '',
            'Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)': 'C',
            'Позиция кабеля': 'ГРЩ-1',
            'Тип кабеля': 'ВВГнг',
            'Кол-во жил': '5',
            'Способ прокладки кабеля': 'На воздухе',
            'Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)': '1',
            'Тип загрузки': 'Электрообогрев',
        });

        const chooseEPContainerBool = ref(false)

        const ValueEP = ref({
            name: "",
            ki: "",
            cos: "",
            id: 0,
            parents: [],
        })

        watchEffect(() => {
            fider_Id.value = props.fiderId
        });

        

        const FiderData = ref({
            'Наименование ЭП': 'Стан порошковой проволки',
            'Наименование ЭП (справочный)': '',
            'Напряжение, В': "380",
            'Кол-во ЭП, n': "1",
            'Номинальная (установленная) мощность, кВт': "28",
            'Коэфф. исп.Кu': "",
            'Коэфф. cosφ': "",
            'Длина кабеля, км': "0.161",
            'Тип автомата': "АВ",
            'Кол-во полюсов автомата': "",
            'Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)': "C",
            'Позиция кабеля': "ГРЩ-1",
            'Тип кабеля': "ВВГнг",
            'Кол-во жил': "5",
            'Способ прокладки кабеля': "На воздухе",
            'Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)': "1",
            'Тип загрузки': "Электрообогрев"
        })

        watchEffect(() => {
            voltage.value = FiderData.value["Напряжение, В"]
        });

        // onMounted(() => {
        //     for (let key in FiderData.value) {
        //         // console.log(key)
        //         if (key === "Наименование ЭП (справочный)" || key === "Коэфф. исп.Кu" || key === "Коэфф. cosφ") {
        //             fiderData1.value[key] = FiderData.value[key]
        //         } else {
        //             fiderData2.value[key] = FiderData.value[key]
        //         }
        //     }
        // })

        // watchEffect(() => {

        //     for (let key in FiderData.value) {
        //         console.log(key)
        //         if (key === "Наименование ЭП (справочный)" || key === "Коэфф. исп.Кu" || key === "Коэфф. cosφ") {
        //             fiderData1.value[key] = FiderData.value[key]
        //         } else {
        //             fiderData2.value[key] = FiderData.value[key]
        //         }
        //     }


        //     voltage.value = FiderData.value["Напряжение, В"]
        // });



        function setNewValueEP(data) {
            console.log(typeof (data))
            console.log(typeof (ValueEP.value))
            ValueEP.value.name = data.input3_value.name
            ValueEP.value.ki = data.input3_value.ki
            ValueEP.value.cos = data.input3_value.cos
            ValueEP.value.id = data.input3_value.id
            ValueEP.value.parents = data.input3_value.parents
            ValueEP.value.fiderId = data.fiderID
            chooseEPContainerBool.value = false;
        }

        return {
            fider_Id,
            FiderData,
            id,
            voltage,
            items,
            chooseEPContainerBool,
            ValueEP,
            setNewValueEP,
            FiderData1,
            FiderData2,
        }
    }
}
</script>
<style>
.InputFiderDataContainer {
    flex-basis: 63%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box;
}

@media(max-width: 700px) {
    .InputFiderDataContainer {
        flex-basis: 100%;
        margin-top: 2rem;
    }
}

.InputSingleFiderData {
    flex-basis: 47%;
    flex-direction: column;
    text-align: left;
    margin-bottom: 0.9rem;
    display: flex;
    padding: 5px;
}


@media(max-width: 1500px) {
    .InputSingleFiderData {
        flex-basis: 100%;
    }
}

.input {
    padding: 0.5em 0.8em;
    border-radius: 20px;
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(255, 255, 255);
    margin: 0.1rem 0;
    text-align: left;
}

.appButton {
    color: #fff;
    background-color: #004c97;
    padding: 0.6em 0.5em;
    font-weight: 600;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 0.5em 0.8em
}

.appButton:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}

.InputFiderDataContainer_InputContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
</style>