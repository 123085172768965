<template lang="">
    <div>
        <!-- представляет собой роутер линк с параметрами -->
        <router-link :to="{name: 'Fiders', params : {project_id : id}}" class = "shield_div containerControlFiders containerShadow marginBot075 linkBlock">
            <div class="flex_Contain">
                <div class="shield_Name">{{name}}</div>
                <!-- <div>Последнее изменение:{{formatDate(data_time)}}</div> -->
                <div>Последнее изменение: {{last_Change.toLocaleString('ru-RU', options)}}</div>
            </div>
        </router-link>
    </div>
</template>
<script>
import { ref, watchEffect } from 'vue';

export default {
    props: {
        shieldData: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatDate(dateString) {
            // Разбиваем строку на части
            const [time, timezone] = dateString.split('+');
            console.log(timezone)

            // Разбиваем время на часы, минуты, секунды и миллисекунды
            const [hms, ms] = time.split('.');
            const [h, m, s] = hms.split(':');

            // Создаем объект даты с текущей датой и указанным временем
            const date = new Date();
            date.setHours(parseInt(h) + date.getTimezoneOffset() / 60);
            date.setMinutes(parseInt(m));
            date.setSeconds(parseInt(s));
            date.setMilliseconds(parseInt(ms || '0'));

            // Форматируем дату и время
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            // const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

            return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
        }
    },
    setup(props) {
        const name = ref(props.shieldData.name);
        const data_time = ref(props.shieldData.updatedAt)
        const id = ref(props.shieldData.id);
        let last_Change = ref(new Date(props.shieldData.updatedAt))
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };

        watchEffect(() => {
            name.value = props.shieldData.name;
            id.value = props.shieldData.id;
            last_Change.value = new Date(props.shieldData.updatedAt)
            data_time.value = props.shieldData.updatedAt
        });
        return {
            id,
            name,
            last_Change,
            options,
            data_time
        }
    }
}
</script>
<style>
.shield_div {
    display: flex;
    margin-bottom: 1vw;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    transition: 0.3s ease;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.062);
}

.shield_div:hover {
    text-decoration: underline;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.flex_Contain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media(max-width: 500px) {
    .flex_Contain {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
}

.shield_Name {
    font-weight: 600;
}
</style>