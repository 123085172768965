<template lang="">
    <div class="containerShadow InputFiderDataContainer posRelative">
        <div class="h2">Изменить фидер</div>
        
        <div class="InputFiderDataContainer_InputContainer">
            <div class="block_fiderData1">
                <ChangeSingleFiderData class="InputSingleFiderData"
                @updateFiderData ="(label, inputText)=>fiderOData[label] = inputText" 
                v-for="(item,index) in fiderData1" 
                :value-input = "item"
                :label-text="index" 
                :key="index"
                :voltage="voltage"
                :valueEP="ValueEP"
                :fider-id=fider_Id
                />
                <div class="InputSingleFiderData">
                    <div class="input_Label" style="color: #fff; user-select: none">sdrydr </div>
                    <div class="chooseEP_Btn" @click="() => chooseEPContainerBool = true">выбрать ЭП из справочника</div>
                </div>
            </div>
            <ChangeSingleFiderData class="InputSingleFiderData"
                @updateFiderData ="(label, inputText)=>fiderOData[label] = inputText" 
                v-for="(item,index) in fiderData2" 
                :value-input = "item"
                :label-text="index" 
                :key="index"
                :voltage="voltage"
                :valueEP="ValueEP" 
                :fider-id=fider_Id
            />
        </div>

        <!-- @togglechooseEPContainerBool="{ chooseEPContainerBool = false}"  -->

        <chooseEPContainer 
        @togglechooseEPContainerBool="{ chooseEPContainerBool = false;  }"
        @newValueEP="(data) => setNewValueEP(data)"
        :fider-id = fider_Id
        v-if="chooseEPContainerBool" 
        
        />
        <div class="appButton marginTop selectNone" @click="handleClick(fider_Id,fiderOData,fider_Data)">Сохранить изменения фидер</div>
    </div>
</template>
<script>
import { ref, watchEffect, onMounted } from 'vue';
import ChangeSingleFiderData from './ChangeSingleFiderData.vue';
import chooseEPContainer from '../chooseEP/chooseEPContainer.vue'
export default {
    components: {
        ChangeSingleFiderData,
        chooseEPContainer
    },
    props: {
        fiderId: {
            type: String,
            required: true
        },
        fiderData: {
            type: Object,
            required: true
        }

    },
    methods: {
        async handleClick(id, data, fider_Data) {
            // if (!checkValidData(data)) {
            //     alert("Поля заполнены неверно!")
            //     return
            // }
            const regex = /[^.,0-9]/;
            const numericInputs = ["Кол-во ЭП, n", "Номинальная (установленная) мощность, кВт", "Коэфф. исп.Кu", "Коэфф. cosφ", "Длина кабеля, км", "Кол-во жил", "Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)"];

            for (let key in data) {
                let label = key
                let value = data[key]
                if (numericInputs.includes(label)) {
                    if (regex.test(value) || value == "") {
                        alert("Поля заполнены неверно!")
                        return false
                    }
                }
            }

            console.log(id)
            console.log(data)
            // проверка инпутов на пустоту
            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/update-fider/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                        data: data
                    })
                })
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    try {
                        const response_2 = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
                            method: 'POST',
                            body: JSON.stringify({
                                id: fider_Data.project_id,
                                changeExcel: true,
                                changeNCad: true
                            })
                        })
                        if (response_2.ok) {
                            // location.reload()
                            // alert("Фидер добавлен")
                        }
                        else {
                            throw new Error('Неправильный ответ сети');
                        }
                    }
                    catch (error) {
                        console.error('Ошибка добавления фидера:', error);
                    }
                }
            }
            catch (error) {
                alert("Ошибка изменения данных: " + error)
            }
        },

    },
    setup(props) {
        const voltage = ref(0)
        const fider_Id = ref(props.fiderId)

        const fiderData1 = ref({})
        const fiderData2 = ref({})
        const fiderDataRes = ref({})

        const chooseEPContainerBool = ref(false)

        const ValueEP = ref({
            name: "",
            ki: "",
            cos: "",
            id: 0,
            parents: [],
        })

        watchEffect(() => {
            fider_Id.value = props.fiderId
        });

        const fider_Data = ref(props.fiderData)
        watchEffect(() => {
            fider_Data.value = props.fiderData
        });
        let fiderOData = ref(props.fiderData.data)
        watchEffect(() => {
            fiderOData.value = props.fiderData.data
        });

        watchEffect(() => {
            voltage.value = fiderOData.value["Напряжение, В"]
        });


        // const checkValidData = (data) => {
        //     const regex = /[^.,0-9]/;
        //     const numericInputs = ["Кол-во ЭП, n", "Номинальная (установленная) мощность, кВт", "Коэфф. исп.Кu", "Коэфф. cosφ", "Длина кабеля, км", "Кол-во жил", "Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)"];


        //     for (let key in data) {
        //         let label = key
        //         let value = data[key]
        //         if (numericInputs.includes(label)) {
        //             if (regex.test(value)) {
        //                 return false
        //             }
        //         }
        //     }
        //     return true

        // }

        function setNewValueEP(data){
            console.log("dfkjgdfkl")
            console.log(typeof(data))
            console.log(typeof(ValueEP.value))
            ValueEP.value.name = data.input3_value.name
            ValueEP.value.ki = data.input3_value.ki
            ValueEP.value.cos = data.input3_value.cos
            ValueEP.value.id = data.input3_value.id
            ValueEP.value.parents = data.input3_value.parents
            ValueEP.value.fiderId = data.fiderID
            chooseEPContainerBool.value = false;
        }

        onMounted(async () => {
            for (let key in fiderOData.value) {
                // console.log(key)
                if (key === "Наименование ЭП (справочный)" || key === "Коэфф. исп.Кu" || key === "Коэфф. cosφ") {
                    fiderData1.value[key] = fiderOData.value[key]
                } else {
                    fiderData2.value[key] = fiderOData.value[key]
                }
            }
        })

        watchEffect(() => {

            for (let key in fiderOData.value) {
                console.log(key)
                if (key === "Наименование ЭП (справочный)" || key === "Коэфф. исп.Кu" || key === "Коэфф. cosφ") {
                    fiderData1.value[key] = fiderOData.value[key]
                } else {
                    fiderData2.value[key] = fiderOData.value[key]
                }
            }


            fiderDataRes.value = {
                ...fiderData1.value,
                ...fiderData2.value
            };
        });


        return {
            fider_Id,
            fider_Data,
            fiderOData,
            voltage,
            fiderData1,
            fiderData2,
            fiderDataRes,
            chooseEPContainerBool,
            ValueEP,
            setNewValueEP
            // checkValidData
        }
    }
}
</script>
<style>
.closeElem {
    color: #fff;
    background-color: #004c97;
    border-radius: 50px;
    right: 20px;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.posRelative {
    position: relative;
}

.InputFiderDataContainer {
    flex-basis: 63%;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
}

@media(max-width: 700px) {
    .InputFiderDataContainer {
        flex-basis: 100%;
        margin-top: 2rem;
    }
}

.InputSingleFiderData {
    flex-basis: 30%;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    font-size: 14px;
    max-width: 300px;
}

@media(max-width: 545px) {
    .block_fiderData1 .InputSingleFiderData {
        flex-basis: 100%;
    }
}

@media(max-width: 545px) {
    .InputSingleFiderData {
        flex-basis: 100%;
    }
}




.InputFiderDataContainer_InputContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.block_fiderData1 {
    /* max-width: 300px; */
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.5rem;
    border-radius: 20px;
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(255, 255, 255);
    margin: 0.1rem 0;
    width: 100%;
    align-items: center;
    border: 2px solid #333;
}


.chooseEP_Btn {
    color: #000;
    background-color: #b1b8bf;
    padding: 0.6em 0.5em;
    font-weight: 600;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 0.5em 0.8em;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chooseEP_Btn:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
</style>