<template lang="">
<div class="chooseEPContainer">
    <div class="chooseEPContainer2">
        <div class="chooseEPContainerBlock" >
            <div class="closeButton" @click="$emit('togglechooseEPContainerBool')">X</div>
            <!-- пункты.  -->
            <div class="chooseEpInputContainer">
                <label>Выберите вид промышленности 1</label>
                <v-select :options="arrForInput1" label="name" taggable placeholder=""  v-model="input1_value"></v-select>
            </div>
        
            <!-- подпункты. -->
            <div class="chooseEpInputContainer">
                <label>Выберите вид промышленности 2</label>
                <v-select :options="arrForInput2" label="name" taggable placeholder=""  v-model="input2_value"></v-select>
            <!-- </select> -->
            </div>

            <!-- список наименований. -->
            <input class="inputReceiver" type="text" v-model="input" placeholder="Найти электроприемник..." />
            <div class="ReceiverListContainer">
                <div @click="setSelectedReceiver(elReceiver)" class="item elReceiver" v-for="elReceiver in filteredList()" :key="elReceiver.id">
                    <p>{{ elReceiver.name }}</p>
                </div>
                <div class="item error" v-if="input&&!filteredList().length">
                    <p>Ничего не найдено!</p>
                </div>
            </div>
            <!-- Результаты выбора. По умолчанию скрыты. Появляется, когда выбрано наименование из списка -->
            <div class="selectedEPContainer">
                <b>Выбрано:</b>
                <div>Наименование ЭП (справочный): {{input3_value ? input3_value.name : ''}}</div>
                <div>Коэфф. исп.Кu: {{input3_value ? input3_value.ki : ''}}</div>
                <div>Коэфф. cosφ: {{input3_value ? input3_value.cos : ''}}</div>
            </div>
            <div class="chooseEPButton" @click="$emit('newValueEP',{input3_value, fiderID} )">Готово</div>
        </div>
    </div>
</div>
</template>
<script>
import { ref, watchEffect } from 'vue'
import guide_items from '../../guide_items'
import guide_categories from '../../guide_categories'
import VSelect from "vue-select";
import 'vue-select/dist/vue-select.css'


console.log(guide_items)
console.log(guide_categories)

export default {
    props: {
        fiderId: String
    },
    components: { 'v-select': VSelect },
    // emits: ["togglechooseEPContainerBoolParent"],
    // methods:{
    //     togglechooseEPContainerBool(){
    //         console.log("togglechooseEPContainerBool")
    //         this.$emit('togglechooseEPContainerBoolParent')
    //     }
    // },
    setup(props) {
        let fiderID = ref(props.fiderId)
        let input1_value = ref({})
        let input2_value = ref({})
        let input3_value = ref({
            name: "",
            ki: "",
            cos: "",
            id: 0,
            parents: []
        })
        let guide_categories_var = ref(guide_categories.categories)
        let guide_items_var = ref(guide_items.guide_items)

        let arrForInput1 = guide_categories_var.value.filter((el) => el.parent.length == 0)
        let arrForInput2 = ref(guide_categories_var.value)
        let arrForInput3 = ref(guide_items_var.value)

        let input = ref("");

        // let SelectedReceiver = ref({
        //     name: "",
        //     ki: "",
        //     cos: "",
        //     id: 0,
        //     parents: []
        // })

        function filteredList() {
            console.log("filteredList")
            console.log(arrForInput3)
            console.log(arrForInput3.value.filter((el) =>
                el.name.toLowerCase().includes(input.value.toLowerCase())
            ))
            return arrForInput3.value.filter((el) =>
                el.name.toLowerCase().includes(input.value.toLowerCase())
            );
        }

        function setSelectedReceiver(data) {
            console.log(data)
            input3_value.value = data
        }


        watchEffect(() => {
            fiderID = props.fiderId
            guide_categories_var.value = guide_categories.categories
            guide_items_var.value = guide_items.guide_items

            if (input1_value.value != null) {
                arrForInput2.value = guide_categories_var.value.filter((el) => el.parent[0] == input1_value.value.id)
            }


            // if( Object.keys(input1_value.value).length != 0 & Object.keys(input2_value.value).length != 0){
            //     console.log("sldfjghldifjg")
            // }
            console.log("input1_value.value")
            console.log(input1_value.value)
            console.log("input2_value.value")
            console.log(input2_value.value)
            if (input1_value.value != null & input2_value.value != null) {
                if (Object.keys(input1_value.value).length != 0 & Object.keys(input2_value.value).length != 0) {
                    arrForInput3.value = guide_items_var.value.filter((el) => el.parents[0] == input1_value.value.id & el.parents[1] == input2_value.value.id)
                }
            } else if (input1_value.value != null) {
                if (Object.keys(input1_value.value).length != 0) {
                    arrForInput3.value = guide_items_var.value.filter((el) => el.parents[0] == input1_value.value.id)
                }
            } else if (input2_value.value != null) {
                if (Object.keys(input2_value.value).length != 0) {
                    arrForInput3.value = guide_items_var.value.filter((el) => el.parents[1] == input2_value.value.id)
                }
            }
        })

        watchEffect(() => {
            filteredList()
        }, [arrForInput3])

        return {
            fiderID,
            guide_categories_var,
            guide_items_var,
            arrForInput1,
            input1_value,
            input2_value,
            input3_value,
            arrForInput2,
            arrForInput3,
            filteredList,
            input,
            setSelectedReceiver
            // togglechooseEPContainerBool
        }
    }
}

</script>
<style>
.v-select .vs__selected {
    white-space: normal;
    word-break: break-all;
}

.chooseEPContainer {
    z-index: 9999999999;
    background-color: #0000004f;
    left: 0;
    right: 0;
    /* width: 100; */
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;
    bottom: 0;
    top: 0;
    height: auto;
}

.chooseEPContainerBlock {
    background-color: #fff;
    width: 50%;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    padding-top: 50px;
}

@media(max-width: 500px) {
    .chooseEPContainerBlock {
        width: 77%;
    }
}

.chooseEPButton {
    color: #fff;
    background-color: #004c97;
    padding: 0.6em 0.5em;
    font-weight: 600;
    border-radius: 50px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 0.5em 0.8em;
    margin-top: 20px;
}

.chooseEPButton:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}

.selectedEPContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.chooseEpInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chooseEpInputContainer>div {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 10px;
}

.chooseEPContainer2 {
    width: 100%;
    /* height: 100%; */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
}

.closeButton {
    top: 20px;
    right: 20px;
    position: absolute;
    font-weight: 800;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 2px solid #004c97;
    cursor: pointer;
    color: #004c97;
    align-items: center;
    justify-content: center;
    display: flex;
}

.ps {
    height: 300px;
    /* or max-height: 400px; */
    border: 1px solid var(--vs-border-color);
    border-radius: 20px;
    margin: 20px 0;
}

.inputReceiver {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    padding: 0 0 4px;
    background: none;
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-radius: var(--vs-border-radius);
    white-space: normal;
    width: calc(100% - 16px);
    height: 15px;
    padding: 8px;
}

.elReceiver p {
    font-size: 14px;
}

.ps__thumb-y {
    background-color: #004c97 !important;
}

.item.elReceiver {
    cursor: pointer;
    border-bottom: 1px solid var(--vs-border-color);
}

.ReceiverListContainer {
    height: 300px;
    overflow: auto;
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-radius: var(--vs-border-radius);
    margin: 20px 0;
}
</style>