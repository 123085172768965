jsonToXmlExcel<template>
  <div class="Container">
    <!-- v-if="loading" -->
    <div v-if="loading" class="loading">
      <div><img src="../../public/loading.gif" alt=""></div>
      <!-- <div>Подождите, запрос выполняется</div> -->
    </div>
    <router-link to="/shields/" class="link_To_ShieldsPage">Вернуться к проектам</router-link>
    <!-- название проекта -->
    <!-- <h1></h1> -->
    <ProjectParameters :project-id=routeParams.project_id @giveParametersTop="(data) => { projectData = data }"
      @giveNameTop="(data) => { projectName = data }" />
    <!-- <ProjectParameters :project-id=routeParams.project_id @giveParametersTop="(data) => { projectData = data }"
        @giveNameTop="(data) => { projectName = data }"  @giveProjectChanged="(data) => { projectChanged = data }" /> -->
    <!-- <h1 class="h1">Фидеры</h1> -->
    <div class="containerFlex" style="min-height: calc(100vh - 30em);">
      <ControlFiders @updateItems1="updateItems"
        @clickAddFider="{ showInputFiderData = true; showChangeFiderData = false }" :root-items="items"
        :active-fider1="activeFider" @clickDeleteFider2="(id) => { deleteFider(id, routeParams.project_id); }"
        @clickChangeFider2="(id) => { activeFider = id; showChangeFiderData = false; showChangeFiderData = true; fider_Id = id; fider_Data = items.find((item) => item.id == id); showInputFiderData = false; }" />
      <InputFiderDataContainer v-if="showInputFiderData" :fider-id=fider_Id :project-id=routeParams.project_id :root-items="items" />
      <ChangeFiderDataContainer v-if="showChangeFiderData" :fider-id=fider_Id :fider-data=fider_Data />
    </div>
    <!-- <div class="deleteBtn">Удалить проект</div> -->
    <div class="butttons_Func_Page_Contain">

      <!-- <div v-if="fileExistsExcel || fileExistsNanocad"> -->
      <div>
        <!-- <div>Скачать рассчитанные файлы</div> -->
        <!-- <div class="appButton formBtn">Скачать рассчитанный Excel</div> -->
        <div v-if="fileExistsExcel && !changedExcel">
          <div class="appButton formBtn"
            @click="downloadExcel(userName, routeParams.project_id, projectName, changedNCad)">Скачать
            рассчитанный Excel</div>
        </div>
        <div v-else>
          <div class="appButton formBtn notActiveBtn">Скачать
            рассчитанный Excel</div>
        </div>


        <div v-if="fileExistsNanocad && !changedNCad">
          <div class="appButton formBtn"
            @click="downloadNanocad(userName, routeParams.project_id, projectName, changedExcel)">Скачать
            отрисованный Nanocad</div>
        </div>
        <div v-else>
          <div class="appButton formBtn notActiveBtn">Скачать
            отрисованный Nanocad</div>
        </div>

      </div>

      <div>
        <!-- <div>Рассчитать проект</div> -->
        <div class="appButton formBtn"
          @click="jsonToXmlExcel(xml, projectData, remake_items(items), userName, routeParams.project_id, projectName, changedNCad)">
          Рассчитать проект в Excel</div>
        <!-- <div class="appButton formBtn"
          @click="jsonToXmlNanocad(xml, projectData, items, userName, routeParams.project_id, projectName)">
          Отрисовать проект в Nanocad</div> -->
        <div v-if="fileExistsExcel && !changedExcel">
          <div class="appButton formBtn"
            @click="jsonToXmlNanocad(xml, projectData, remake_items(items), userName, routeParams.project_id, projectName, changedExcel)">
            Отрисовать проект в Nanocad</div>
        </div>
        <div v-else>
          <div class="appButton formBtn notActiveBtn">
            Отрисовать проект в Nanocad</div>
        </div>
      </div>
    </div>

    <!-- <DeleteShieldButton :project-id=routeParams.project_id /> -->
  </div>
</template>

<script>
import ControlFiders from '../components/ControlFiders/ControlFiders.vue'
// import DeleteShieldButton from '../components/ChangeShieldData/DeleteShieldButton.vue'
import InputFiderDataContainer from '../components/InputFiderData/InputFiderDataContainer.vue'
import ChangeFiderDataContainer from '../components/ChangeFiderData/ChangeFiderDataContainer.vue'
import ProjectParameters from '../components/ShieldsData/ProjectParameters.vue'
import { ref, onMounted, reactive, watch } from 'vue'

// import { ref, onMounted } from 'vue'
// const { create } = require('xmlbuilder');

export default {
  props: ['project_id'],
  emits: ["giveParametersTop", "giveNameTop", "giveProjectChanged"],
  components: {
    ControlFiders,
    InputFiderDataContainer,
    // DeleteShieldButton,
    ChangeFiderDataContainer,
    ProjectParameters
  },
  methods: {
    remake_items(arr) {
      let items = [...arr].sort((a, b) => (a.index) - (b.index));
      let items220 = []
      let items380 = []
      items220.length = 0
      items.forEach(function (item) {
        if (item.data["Напряжение, В"] == "220") {
          items220.push(item)
        }
      })

      //отбираем элементы с напряжением 380
      items380.length = 0
      items.forEach(function (item) {
        if (item.data["Напряжение, В"] == "380") {
          items380.push(item)
        }
      })

      let combinedArray = [...items380, ...items220]
      return combinedArray
    },
    updateItems(data) {
      console.log("data")
      console.log(data)
      this.items = data;
    },
    async jsonToXmlExcel(xml, data, fiders, userName, project_id, projectName, changedNCad) {
      const fidersLength = Object.keys(fiders).length;
      console.log("fidersLength: " + fidersLength)
      if (fidersLength === 0) {
        alert("Добавьте фидеры в проект!")
        return
      }
      // let fiders_Count = 0
      // fiders.forEach((item) => {

      //   fiders_Count = fiders_Count + 1
      // })
      // console.log("fiders_Count: " + fiders_Count)
      let newXml = xml
      // параметры проекта
      newXml += '<general>'
      newXml += '<Ikz3max>' + data['Ikz3max'] + '</Ikz3max>'
      newXml += '<Ikz3min>' + data['Ikz3min'] + '</Ikz3min>'
      newXml += '<Ikz_loading>' + data['Iкз нагрузки'] + '</Ikz_loading>'
      newXml += '<ImaxKTP>' + data['Определение Ikз(3) max в месте подключения КТП'] + '</ImaxKTP>'
      newXml += '<IminKTP>' + data['Определение Ikз(3) min в месте подключения КТП'] + '</IminKTP>'
      newXml += '<Kp1>' + data['Kp1'] + '</Kp1>'
      newXml += '<Kp2>' + data['Kp2'] + '</Kp2>'
      newXml += '<Kp_vvod_cabel>' + data['Кратноть Iп, КП'] + '</Kp_vvod_cabel>'
      newXml += '<R1>' + data['R1'] + '</R1>'
      newXml += '<Ro>' + data['Ro'] + '</Ro>'
      newXml += '<X1>' + data['X1'] + '</X1>'
      newXml += '<Xo>' + data['Xo'] + '</Xo>'
      newXml += '<Zt3>' + 0 + '</Zt3>'
      newXml += '<category>' + data['Категория эл. снаб.'] + '</category>'
      newXml += '<formatSheet>' + data['Формат листа'] + '</formatSheet>'
      newXml += '<len_vvod_cabel>' + data['Длина, км'] + '</len_vvod_cabel>'
      newXml += '<numberShield>' + data['Имя щита'] + '</numberShield>'
      newXml += '<typeGround>' + data['Тип заземления'] + '</typeGround>'
      newXml += '</general>'

      // фидеры
      newXml += '<fiders>'

      console.log(project_id)

      // запись данных фидера в newXml + проверка на пустоту
      fiders.forEach((item) => {
        console.log(item)
        // проверка на пустоту
        if (item.id == '' || item.data["Наименование ЭП"] == '' || item.data["Имя директории"] == '' || item.data['Напряжение, В'] == '' ||
          item.data['Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)'] == '' || item.data['Номинальная (установленная) мощность, кВт'] == '' ||
          item.data['Коэфф. исп.Кu'] == '' || item.data['Коэфф. cosφ'] == '' || item.data['Длина кабеля, км'] == '' ||
          item.data['Тип автомата'] == '' || item.data['Кол-во полюсов автомата'] == '' ||
          item.data['Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)'] == '' ||
          item.data['Позиция кабеля'] == '' || item.data['Тип кабеля'] == '' || item.data['Кол-во жил'] == '' ||
          item.data['Способ прокладки кабеля'] == '' || item.data['Кол-во ЭП, n'] == '' || item.data['Тип загрузки'] == ''
        ) {
          alert("Заполните все поля!")
          return
        }
        // end проверка на пустоту
        newXml += '<fider>'
        newXml += '<id>' + item.id + '</id>'
        newXml += '<name>' + item.data["Наименование ЭП"] + '</name>'
        newXml += '<name_directory>' + item.data["Имя директории"] + '</name_directory>'
        newXml += '<voltage>' + item.data['Напряжение, В'] + '</voltage>'
        newXml += '<quantity>' + item.data['Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)'] + '</quantity>'
        newXml += '<power>' + item.data['Номинальная (установленная) мощность, кВт'] + '</power>'
        newXml += '<Ki>' + item.data['Коэфф. исп.Кu'] + '</Ki>'
        newXml += '<cosf>' + item.data['Коэфф. cosφ'] + '</cosf>'
        newXml += '<length>' + item.data['Длина кабеля, км'] + '</length>'
        newXml += '<typeAV>' + item.data['Тип автомата'] + '</typeAV>'
        newXml += '<quant_pole>' + item.data['Кол-во полюсов автомата'] + '</quant_pole>'
        newXml += '<curve>' + item.data['Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)'] + '</curve>'
        newXml += '<position>' + item.data['Позиция кабеля'] + '</position>'
        newXml += '<type_cable>' + item.data['Тип кабеля'] + '</type_cable>'
        newXml += '<quant_vein>' + item.data['Кол-во жил'] + '</quant_vein>'
        newXml += '<way_laying>' + item.data['Способ прокладки кабеля'] + '</way_laying>'
        newXml += '<Kp>' + item.data['Кол-во ЭП, n'] + '</Kp>'
        newXml += '<TypeLoad>' + item.data['Тип загрузки'] + '</TypeLoad>'
        newXml += '</fider>'
      });

      newXml += '</fiders>'
      newXml += '</KRUS_Calc_RTM>'
      // console.log(newXml)


      try {
        this.loading = true
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/create-xml-excel/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'charset': 'utf-8'
          },
          body: JSON.stringify({
            data: newXml,
            user: userName,
            projectId: project_id
          })
        });
        this.loading = false
        console.log(response)
        if (!response.ok) {
          // console.log("response " + response.json().error)
          // throw new Error('Расчет завершился с ошибкой');
          // \\\\\\\\\\\\\\const errorResponse = await response.json();
          // \\\\\\\\\\\\\\\throw new Error('Расчет завершился с ошибкой: ' + errorResponse.error );

          const errorResponse = await response.text(); // получаем текст ошибки
          throw new Error('Расчет завершился с ошибкой: ' + errorResponse);
        } else {
          alert("Данные рассчитаны")
          // const data = await response.json();
          // alert(data); // сохраняем полученные данные в переменной shield
          // ТУТ ПЫТАЕМСЯ ПОЛУЧИТЬ ФАЙЛ
          const getFileResponse = await fetch(process.env.VUE_APP_BASE_URL + '/get-file/?path=' + 'files' + '/' + userName + "/" + project_id + ".xlsx")
          if (getFileResponse.ok) {
            const fileData = await getFileResponse.blob();
            const url = window.URL.createObjectURL(fileData);
            const a = document.createElement('a');
            a.href = url;
            a.download = projectName + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            //this.changedExcel = false -  вместо этого - обновить данные в базе данных

            await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
              method: 'POST',
              body: JSON.stringify({
                id: project_id,
                changeExcel: false,
                changeNCad: changedNCad
              })
            })

            location.reload()
          } else {
            alert('Ошибка при расчете проекта')
          }

          // ////////////////////////////////
        }

      } catch (error) {
        alert("Ошибка : " + error)
      }
    },

    async jsonToXmlNanocad(xml, data, fiders, userName, project_id, projectName, changedExcel) {
      const fidersLength = Object.keys(fiders).length;
      console.log("fidersLength: " + fidersLength)
      if (fidersLength === 0) {
        alert("Добавьте фидеры в проект!")
        return
      }

      let newXml = xml
      // параметры проекта
      newXml += '<general>'
      newXml += '<Ikz3max>' + data['Ikz3max'] + '</Ikz3max>'
      newXml += '<Ikz3min>' + data['Ikz3min'] + '</Ikz3min>'
      newXml += '<Ikz_loading>' + data['Iкз нагрузки'] + '</Ikz_loading>'
      newXml += '<ImaxKTP>' + data['Определение Ikз(3) max в месте подключения КТП'] + '</ImaxKTP>'
      newXml += '<IminKTP>' + data['Определение Ikз(3) min в месте подключения КТП'] + '</IminKTP>'
      newXml += '<Kp1>' + data['Kp1'] + '</Kp1>'
      newXml += '<Kp2>' + data['Kp2'] + '</Kp2>'
      newXml += '<Kp_vvod_cabel>' + data['Кратноть Iп, КП'] + '</Kp_vvod_cabel>'
      newXml += '<R1>' + data['R1'] + '</R1>'
      newXml += '<Ro>' + data['Ro'] + '</Ro>'
      newXml += '<X1>' + data['X1'] + '</X1>'
      newXml += '<Xo>' + data['Xo'] + '</Xo>'
      newXml += '<Zt3>' + 0 + '</Zt3>'
      newXml += '<category>' + data['Категория эл. снаб.'] + '</category>'
      newXml += '<formatSheet>' + data['Формат листа'] + '</formatSheet>'
      newXml += '<len_vvod_cabel>' + data['Длина, км'] + '</len_vvod_cabel>'
      newXml += '<numberShield>' + data['Имя щита'] + '</numberShield>'
      newXml += '<typeGround>' + data['Тип заземления'] + '</typeGround>'
      newXml += '</general>'

      // фидеры
      newXml += '<fiders>'

      console.log(fiders)
      fiders.forEach((item) => {
        console.log(item)

        // проверка на пустоту
        if (item.id == '' || item.data["Наименование ЭП"] == '' || item.data["Имя директории"] == '' || item.data['Напряжение, В'] == '' ||
          item.data['Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)'] == '' || item.data['Номинальная (установленная) мощность, кВт'] == '' ||
          item.data['Коэфф. исп.Кu'] == '' || item.data['Коэфф. cosφ'] == '' || item.data['Длина кабеля, км'] == '' ||
          item.data['Тип автомата'] == '' || item.data['Кол-во полюсов автомата'] == '' ||
          item.data['Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)'] == '' ||
          item.data['Позиция кабеля'] == '' || item.data['Тип кабеля'] == '' || item.data['Кол-во жил'] == '' ||
          item.data['Способ прокладки кабеля'] == '' || item.data['Кол-во ЭП, n'] == '' || item.data['Тип загрузки'] == ''
        ) {
          alert("Заполните все поля!")
          return
        }
        // end проверка на пустоту


        newXml += '<fider>'
        newXml += '<id>' + item.id + '</id>'
        newXml += '<name>' + item.data["Наименование ЭП"] + '</name>'
        newXml += '<name_directory>' + item.data["Имя директории"] + '</name_directory>'
        newXml += '<voltage>' + item.data['Напряжение, В'] + '</voltage>'
        newXml += '<quantity>' + item.data['Кратность пускового тока Кп (для двиг.нагрузки и эл. обогрева)'] + '</quantity>'
        newXml += '<power>' + item.data['Номинальная (установленная) мощность, кВт'] + '</power>'
        newXml += '<Ki>' + item.data['Коэфф. исп.Кu'] + '</Ki>'
        newXml += '<cosf>' + item.data['Коэфф. cosφ'] + '</cosf>'
        newXml += '<length>' + item.data['Длина кабеля, км'] + '</length>'
        newXml += '<typeAV>' + item.data['Тип автомата'] + '</typeAV>'
        newXml += '<quant_pole>' + item.data['Кол-во полюсов автомата'] + '</quant_pole>'
        newXml += '<curve>' + item.data['Кривая сраб-я для мод-х автоматов (не для литых АВ с электро/термо расц-м)'] + '</curve>'
        newXml += '<position>' + item.data['Позиция кабеля'] + '</position>'
        newXml += '<type_cable>' + item.data['Тип кабеля'] + '</type_cable>'
        newXml += '<quant_vein>' + item.data['Кол-во жил'] + '</quant_vein>'
        newXml += '<way_laying>' + item.data['Способ прокладки кабеля'] + '</way_laying>'
        newXml += '<Kp>' + item.data['Кол-во ЭП, n'] + '</Kp>'
        newXml += '<TypeLoad>' + item.data['Тип загрузки'] + '</TypeLoad>'
        newXml += '</fider>'
      });

      newXml += '</fiders>'
      newXml += '</KRUS_Calc_RTM>'
      // console.log(newXml)


      try {
        this.loading = true
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/create-xml-nanocad/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: newXml,
            user: userName,
            projectId: project_id
          })
        });
        this.loading = false
        console.log("response: " + response)
        if (!response.ok) {
          // throw new Error('Неправильный ответ сети');

          const errorResponse = await response.text(); // получаем текст ошибки
          throw new Error('Расчет завершился с ошибкой: ' + errorResponse);
        } else {
          alert("Данные отправлены")
          // const data = await response.json();
          // alert(data); // сохраняем полученные данные в переменной shield
          const getFileResponse = await fetch(process.env.VUE_APP_BASE_URL + '/get-file/?path=' + 'files' + '/' + userName + "/" + project_id + ".dwg")
          if (getFileResponse.ok) {
            const fileData = await getFileResponse.blob();
            const url = window.URL.createObjectURL(fileData);
            const a = document.createElement('a');
            a.href = url;
            a.download = projectName + '.dwg';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            //this.changedNCad = false - вместо этого - обновить данные в базе данных
            await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
              method: 'POST',
              body: JSON.stringify({
                id: project_id,
                changeNCad: false,
                changeExcel: changedExcel
              })
            })
            // alert("нажмите")
            location.reload()
          }
        }
      } catch (error) {
        alert("Ошибка : " + error)
      }
    },
    // функции для скачивания готовых рассчитанных файлов
    async downloadExcel(userName, project_id, projectName) {
      const getFileResponse = await fetch(process.env.VUE_APP_BASE_URL + '/get-file/?path=' + 'files' + '/' + userName + "/" + project_id + ".xlsx")
      const fileData = await getFileResponse.blob();
      const url = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');
      a.href = url;
      a.download = projectName + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    async downloadNanocad(userName, project_id, projectName) {
      const getFileResponse = await fetch(process.env.VUE_APP_BASE_URL + '/get-file/?path=' + 'files' + '/' + userName + "/" + project_id + ".dwg")
      const fileData = await getFileResponse.blob();
      const url = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');
      a.href = url;
      a.download = projectName + '.dwg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  setup(props) {
    let userName = sessionStorage.getItem('userName')

    const loading = ref(false)

    const fileExistsExcel = ref(false);
    const fileExistsNanocad = ref(false);
    // const fileUrlExcel = process.env.VUE_APP_BASE_URL + '/check-file/?path=' + 'files' + '/' + userName + "_" + props.project_id + "_krusc" + ".xlsx"
    const routeParams = reactive({
      project_id: props.project_id
    });

    watch(() => props.project_id, () => {
      routeParams.project_id = props.project_id;
    })

    let changeFiderId = ref(0)
    const showInputFiderData = ref(false)
    const showChangeFiderData = ref(false)
    const fiderCount = ref(1)

    let projectData = ref({})
    let projectName = ref("")

    let fider_Id = ref(0)
    let fider_Data = ref({})



    let items = ref([]) //сюда будем засовывать состояние шкафа(его фидеры) из юрлки

    let changedExcel = ref(false)
    let changedNCad = ref(false)
    onMounted(async () => {
      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/fiders/?projectId=' + props.project_id);
        if (!response.ok) {
          throw new Error('Неправильный ответ сети');
        }
        const data = await response.json();
        // console.log("Получение фидеров...")
        items.value = data; // сохраняем полученные данные в переменной shield

      } catch (error) {
        console.error('Ошибка получения данных:', error);
      }

      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/project-changed/?projectId=' + props.project_id);
        if (!response.ok) {
          throw new Error('Неправильный ответ сети');
        }
        const data = await response.json();
        // console.log("Получение фидеров...")
        console.log(data)
        changedExcel.value = data["changedExcel"]
        changedNCad.value = data["changedNCad"]

      } catch (error) {
        console.error('Ошибка получения данных:', error);
      }


      // проверка, существуют ли для этого проекта рассчитанные файлы
      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/check-file/?path=' + 'files' + '/' + userName + "/" + props.project_id + ".xlsx");
        if (response.status === 200) {
          fileExistsExcel.value = true;
        }
      } catch (error) {
        console.error(error);
      }


      try {
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/check-file/?path=' + 'files' + '/' + userName + "/" + props.project_id + ".dwg");
        if (response.status === 200) {
          fileExistsNanocad.value = true;
        }
      } catch (error) {
        console.error(error);
      }
    });


    function findItemWithIndex(index) {
      const foundItem = items.value.find(item => item.id === index);
      // console.log(foundItem)
      return foundItem;
    }

    // функция для обновления данных одного фидера
    function changeItem(id) {
      console.log("changeItem " + id)
      showChangeFiderData.value = false
    }


    async function deleteFider(id, project_id) {

      let confirmDelete = confirm("Точно удалить?")
      if (!confirmDelete) {
        return false
      }

      try {
        // loading.value = true
        const response = await fetch(process.env.VUE_APP_BASE_URL + '/delete-fider/?id=' + id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
          })
        });
        // loading.value = false
        // console.log(response)
        if (!response.ok) {
          throw new Error('Неправильный ответ сети');
        } else {
          try {
            const response_2 = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
              method: 'POST',
              body: JSON.stringify({
                id: project_id,
                changeExcel: true,
                changeNCad: true
              })
            })
            if (response_2.ok) {
              location.reload()
              alert("Фидер удален")
            }
            else {
              throw new Error('Неправильный ответ сети');
            }
          }
          catch (error) {
            console.error('Ошибка добавления фидера:', error);
          }


        }
      }
      catch (error) {
        console.error('Ошибка удаления фидера:', error);
      }
    }

    let xml = '<?xml version="1.0" encoding="utf-8"?>' +
      '<KRUS_Calc_RTM>' +
      '<xs:schema xmlns="" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata" id="KRUS_Calc_20190115_1547">' +
      '<xs:element name="KRUS_Calc_RTM" msdata:IsDataSet="true" msdata:UseCurrentLocale="true">' +
      '<xs:complexType>' +
      '<xs:choice minOccurs="0" maxOccurs="unbounded">' +
      '<xs:element name="general">' +
      '<xs:complexType>' +
      '<xs:sequence>' +
      '<xs:element name="category" type="xs:integer"/>' +
      '<xs:element name="Zt3" type="xs:decimal"/>' +
      '<xs:element name="Ikz3max" type="xs:decimal"/>' +
      '<xs:element name="Ikz3min" type="xs:decimal"/>' +
      '<xs:element name="ImaxKTP" type="xs:decimal"/>' +
      '<xs:element name="IminKTP" type="xs:decimal"/>' +
      '<xs:element name="typeGround" type="xs:string"/>' +
      '<xs:element name="Ikz_loading" type="xs:integer"/>' +
      '<xs:element name="formatSheet" type="xs:string"/>' +
      '</xs:sequence>' +
      '</xs:complexType>' +
      '</xs:element>' +
      '<xs:element name="fiders">' +
      '<xs:complexType>' +
      '<xs:sequence>' +
      '<xs:element maxOccurs="unbounded" name="fider">' +
      '<xs:complexType>' +
      '<xs:sequence>' +
      '<xs:element name="id" type="xs:unsignedByte"/>' +
      '<xs:element name="name" type="xs:string"/>' +
      '<xs:element name="name_directory" type="xs:string"/>' +
      '<xs:element name="voltage" type="xs:integer"/>' +
      '<xs:element name="quantity" type="xs:integer"/>' +
      '<xs:element name="power" type="xs:decimal"/>' +
      '<xs:element name="Ki" type="xs:decimal"/>' +
      '<xs:element name="cosf" type="xs:decimal"/>' +
      '<xs:element name="length" type="xs:integer"/>' +
      '<xs:element name="typeAV" type="xs:string"/>' +
      '<xs:element name="quant_pole" type="xs:integer"/>' +
      '<xs:element name="curve" type="xs:string"/>' +
      '<xs:element name="position" type="xs:string"/>' +
      '<xs:element name="type_cable" type="xs:string"/>' +
      '<xs:element name="quant_vein" type="xs:integer"/>' +
      '<xs:element name="way_laying" type="xs:string"/>' +
      '<xs:element name="Kp1" type="xs:decimal"/>' +
      '<xs:element name="Kp2" type="xs:decimal"/>' +
      '</xs:sequence>' +
      '</xs:complexType>' +
      '</xs:element>' +
      '</xs:sequence>' +
      '</xs:complexType>' +
      '</xs:element>' +
      '</xs:choice>' +
      '</xs:complexType>' +
      '</xs:element>' +
      '</xs:schema>';

    let activeFider = '';
    return {
      activeFider,
      fiderCount,
      showInputFiderData,
      showChangeFiderData,
      changeItem,
      items,
      changeFiderId,
      findItemWithIndex,
      deleteFider,
      routeParams,
      fider_Id,
      fider_Data,
      projectData,
      xml,
      userName,
      projectName,
      fileExistsExcel,
      fileExistsNanocad,
      loading,
      changedExcel,
      changedNCad
    }

  }
}
</script>

<style>
.Container {
  width: 99%;
  margin: 0 auto;
}

.link_To_ShieldsPage {
  display: block;
  max-width: fit-content;
  padding: 10px;
  color: rgb(112, 112, 112);
  transition: 0.3s ease;
}

.link_To_ShieldsPage:hover {
  color: #000;
}

.containerFlex {
  display: flex;
  justify-content: space-between;
}

.containerFlex>div:nth-child(1) {
  flex-basis: 23%;
}

.containerFlex>div:nth-child(2) {
  flex-basis: 73%;
}

@media(max-width: 900px) {
  .containerFlex>div:nth-child(1) {
    flex-basis: 100%;
    margin-bottom: 2vw;
  }

  .containerFlex>div:nth-child(2) {
    flex-basis: 100%;
  }

  .containerFlex {
    flex-direction: column;
  }
}

.formBtn {
  padding: 0.5rem 2rem;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
  color: #fff;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5em 0.8em
}

.butttons_Func_Page_Contain {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media(max-width: 600px) {
  .butttons_Func_Page_Contain {
    flex-direction: column;
  }
}

.butttons_Func_Page_Contain>div:nth-child(1) {
  margin-right: 10px;
}

@media(max-width: 600px) {
  .butttons_Func_Page_Contain>div:nth-child(1) {
    margin-right: 0px;
  }
}

.notActiveBtn {
  background-color: #838c95;
  cursor: auto
}

.notActiveBtn:hover {
  box-shadow: none;
}

.loading {
  z-index: 9999999999;
  background-color: #0000004f;
  left: 0;
  right: 0;
  /* box-sizing: border-box; */
  width: 100;
  /* background-color: aliceblue; */
  position: fixed;
  /* height: 100vh; */
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 0;
  top: 0;
  height: auto;
}
</style>