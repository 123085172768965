import { createApp } from 'vue'
import router from './router/index'
import App from './App.vue'
// import VueMask from 'v-mask';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faPen, faTrash, faCopy,faArrowUp ,faArrowDown} from '@fortawesome/free-solid-svg-icons'

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';



/* add icons to the library */
library.add(faPen, faTrash,faCopy,faArrowUp,faArrowDown)



createApp(App).use(router).use(PerfectScrollbarPlugin).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
