<template lang="">
    <div class="fiderComponent">
        <div class="fiderComponentTitle">{{title}}</div>
        <div class="flex-row">
            
            <!-- <div class="iconFontAwesome" @click.stop="$emit('clickChangeFider', id)"><font-awesome-icon  :icon="['fas', 'pen']" style="color: #004c97;" title="Изменить"/></div> -->
            <div class="iconFontAwesome" @click.stop="cloneFider(data, projectId,items)"><font-awesome-icon :icon="['fas', 'copy']" style="color: #004c97;" title="Копировать"/></div>
            <div class="iconFontAwesome" @click.stop="$emit('clickDeleteFider', id)"><font-awesome-icon :icon="['fas', 'trash']" style="color: #004c97;" title="Удалить"/></div>


            <div class="iconFontAwesome" @click.stop="moveFider('down',id,items, projectId)"><font-awesome-icon  :icon="['fas', 'arrow-down']" style="color: #004c97;" title="Вниз"/></div>
            <div class="iconFontAwesome" @click.stop="moveFider('up',id,items, projectId)"><font-awesome-icon  :icon="['fas', 'arrow-up']" style="color: #004c97;" title="Вверх"/></div>
        </div>
    </div>
</template>

<script>

import { defineComponent, watchEffect, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';


export default defineComponent({
    props: {
        fiderData: {
            type: Object,
            default() {
                return {}
            }
        },
        idValue: {
            type: String,
            default: ""
        },
        rootItems: {
            type: Array,
            required: true
        }
    },
    methods: {
        async cloneFider(data, project_id, items) {
            console.log("cloneFider")
            console.log(data)
            var id = uuidv4()
            console.log(id)
            console.log(JSON.stringify({
                id: id,
                project_id: project_id,
                data: data
            }))

            let items_Count
            let index
            if (items.length > 0) {
                items_Count = items.length - 1
                index = +items[items_Count].index + 1
            } else {
                index = 0
            }

            console.log(index)

            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/add-fider/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                        project_id: project_id,
                        data: data,
                        index: index
                    })
                });
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    try {
                        const response_2 = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
                            method: 'POST',
                            body: JSON.stringify({
                                id: project_id,
                                changeExcel: true,
                                changeNCad: true
                            })
                        })
                        if (response_2.ok) {
                            location.reload()
                            alert("Фидер добавлен")
                        }
                        else {
                            throw new Error('Неправильный ответ сети');
                        }
                    }
                    catch (error) {
                        console.error('Ошибка добавления фидера:', error);
                    }


                }
            }
            catch (error) {
                console.error('Ошибка добавления фидера:', error);
            }

        },
        async moveFider(operation, id1, items, project_id) {

            let index1 = items.findIndex(obj => obj.id == id1)
            let index2 = 0

            let voltage = items[index1].data["Напряжение, В"]
            let newItems = []

            if (voltage == "380") {
                items.forEach(function (item) {
                    if (item.data["Напряжение, В"] == "380") {
                        newItems.push(item)
                    }
                })
            } else if (voltage == "220") {
                items.forEach(function (item) {
                    if (item.data["Напряжение, В"] == "220") {
                        newItems.push(item)
                    }
                })
            }

            index1 = newItems.findIndex(obj => obj.id == id1)
            index2 = 0

            console.log(operation)
            console.log(index1)
            if (operation == "up") {
                if (index1 != 0) {
                    index2 = index1 - 1
                    console.log("index1: " + index1 + " index2 " + index2)
                } else {
                    // alert("Нельзя поднять выше")
                    return
                }
            } else if (operation == "down") {
                console.log(newItems)
                if (index1 != newItems.length - 1) {
                    index2 = index1 + 1
                    console.log("index1: " + index1 + " index2 " + index2)
                } else {
                    // alert("Нельзя опустить ниже")
                    return
                }
            }

            let id2 = newItems[index2].id
            console.log(id1)
            console.log(id2)

            let number1 = newItems[index1].index
            let number2 = newItems[index2].index

            // console.log("number1 " + number1)
            // console.log("number2 " + number2)
            // console.log(newItems)
            // console.log(items)

            // формирование нового массива элементов
            let items_New_Arr = []
            items.forEach(function (item) {
                if (item.id == id1) {
                    console.log("1")
                    let id = item.id
                    let data = item.data
                    let index = number2
                    let createdAt = item.createdAt
                    let updatedAt = item.updatedAt
                    let project_id = item.project_id
                    let new_Item = { "id": id, "data": data, "index": index, "createdAt": createdAt, "updatedAt": updatedAt, "project_id": project_id }
                    items_New_Arr.push(new_Item)
                } else if (item.id == id2) {
                    console.log("2")
                    let id = item.id
                    let data = item.data
                    let index = number1
                    let createdAt = item.createdAt
                    let updatedAt = item.updatedAt
                    let project_id = item.project_id
                    let new_Item = { "id": id, "data": data, "index": index, "createdAt": createdAt, "updatedAt": updatedAt, "project_id": project_id }
                    items_New_Arr.push(new_Item)
                } else {
                    let id = item.id
                    let data = item.data
                    let index = item.index
                    let createdAt = item.createdAt
                    let updatedAt = item.updatedAt
                    let project_id = item.project_id
                    let new_Item = { "id": id, "data": data, "index": index, "createdAt": createdAt, "updatedAt": updatedAt, "project_id": project_id }
                    items_New_Arr.push(new_Item)
                }
            })

            console.log(items)
            console.log(items_New_Arr)

            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/update-fider-number/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id1: id1,
                        number1: number2,
                        id2: id2,
                        number2: number1
                    })
                });
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {
                    try {
                        const response_2 = await fetch(process.env.VUE_APP_BASE_URL + '/update-project-change/', {
                            method: 'POST',
                            body: JSON.stringify({
                                id: project_id,
                                changeExcel: true,
                                changeNCad: true
                            })
                        })
                        if (response_2.ok) {

                            // alert("Готово")
                            // location.reload()

                            // emit обновление у родительского элемента массива
                            this.$emit('updateItems2', items_New_Arr)
                        }
                        else {
                            throw new Error('Неправильный ответ сети');
                        }
                    }
                    catch (error) {
                        console.error('Ошибка перемещения фидера:', error);
                    }
                }
            }
            catch (error) {
                console.error('Ошибка добавления фидера:', error);
            }
        }
    },
    setup(props) {

        const route = useRoute();
        const projectId = route.params.project_id;

        // const routeParams = reactive({
        //     project_id: props.project_id
        // });

        // watch(() => props.project_id, () => {
        //     routeParams.project_id = props.project_id;
        // })


        const title = ref(props.fiderData["Наименование ЭП"]);
        const data = ref(props.fiderData);
        const id = ref(props.idValue);
        // const id = uuidv4()

        const items = ref(props.rootItems)
        watchEffect(() => {

            // console.log("изменился id фидера....." + title.value + " на "+ props.idValue)

            title.value = props.fiderData["Наименование ЭП"];
            data.value = props.fiderData;
            id.value = props.idValue;
            items.value = props.rootItems

        });
        return {
            id,
            title,
            data,
            projectId,
            items
        }
    }
})
</script>
<style>
.fiderComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: normal;
    padding: 0.5rem;
    border-radius: 20px;
    border: 1px solid rgb(204, 204, 204);
    background-color: rgb(255, 255, 255);
    margin: 0.1rem 0;
    cursor: pointer;
}

.fiderComponentTitle {
    font-size: 14px;
    transition: 0.1s ease;
    display: flex;
    align-items: center;
    text-align: left;
}

.fiderComponent:hover {
    background-color: #cce6ff5e;
    border-radius: 20px;
}

.fiderComponent:hover .fiderComponentTitle {
    text-decoration: underline;
}

.iconFontAwesome {
    margin: 5px !important;
    transition: 0.3s ease;
    cursor: pointer;
    transform: scale(0.8);
}

.iconFontAwesome:hover {
    text-decoration: underline;
    transform: scale(1);
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.fiderComponent.active {
    background-color: #cce6ff5e;
    border-radius: 20px;
}
</style>