<template>
    <div>
        <div class="appButton logoutBtn" @click="logout">Выйти</div>
    </div>
</template>

<script>
export default {
    methods: {
        async logout() {
            const response = await fetch(process.env.VUE_APP_BASE_URL+ '/logout');

            if (response.ok) {
                console.log('Успешно');
                window.location.href = '/';
                // localStorage.removeItem('userName');
                sessionStorage.removeItem('userName')
            } else {
                console.error('Не получилось');
            }
        },
    },
};
</script>
<style>
.logoutBtn {
   background-color: #316fac;
}
</style>