<template>
  <div>
    <ShieldsPage />
  </div>
</template>
  
<script>
import ShieldsPage from './ShieldsPage.vue';
export default {
  components: {
    ShieldsPage
  }
}
</script>
  
<style>
.greeting {
  color: red;
  font-weight: bold;
}
</style>