<template lang="">
    <div class="containerControlFiders containerShadow">
        <h2 class="h2">Список фидеров</h2>
        <ButtonAddFider @click="$emit('clickAddFider')"/> <!--при клике  вызываем компонент ввода данных, и потом возвращаем в виде объекта и добавляем в items-->
        <ListFiders :list-items="items" :active-fider2 = "activeFider"
            @clickChangeFider1="(id) => $emit('clickChangeFider2', id)"  
            @clickDeleteFider1="(id) => $emit('clickDeleteFider2', id)"
            @updateItems2="updateItems1"
        /> 
        <!-- <ControlFidersLabel :message-text="'Задача успешно добавлена, задача удалена'"/> -->
        
    </div>
</template>
<script>
import { ref, watchEffect } from 'vue'
import ListFiders from './ListFiders.vue'
// import ControlFidersLabel from './ControlFidersLabel.vue'
import ButtonAddFider from './ButtonAddFider.vue'

export default {
    props: {
        rootItems: {
            type: Array,
            required: true
        },
        activeFider1:{
            type: String,
            default: ""
        }
    },
    components: {
        ListFiders,
        // ControlFidersLabel,
        ButtonAddFider
    },
    methods:{
        updateItems1(data){
            this.$emit('updateItems1',data)
        }
    },

    setup(props) {
        const items = ref(props.rootItems);
        const activeFider = ref(props.activeFider1);

        // Следим за изменениями пропсов и обновляем локальные данные
        watchEffect(() => {
            console.log("Изменились элементы в props.rootItems из FuncPage")
            items.value = props.rootItems;
            activeFider.value = props.activeFider1;
        });
        return {
            items,
            activeFider
        }
    }
}
</script>
<style>
.containerControlFiders {
    flex-basis: 33%;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
}

@media(max-width: 700px) {
    .containerControlFiders {
        flex-basis: 100%;
    }
}
</style>