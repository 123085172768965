<template lang="">
    <div class="deleteBtn" @click="handleClick(projectId)" >Удалить проект</div>
</template>
<script>

import { ref, watchEffect } from 'vue'


export default {
    props: {
        projectId: {
            type: String,
            required: true
        }
    },
    methods: {
        async handleClick(id) {
            let confirmDelete = confirm("Точно удалить?")
            if (!confirmDelete) {
                return false
            }

            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/delete-shield/?id=' + id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: id,
                    })
                });
                console.log(response)
                if (!response.ok) {
                    throw new Error('Неправильный ответ сети');
                } else {

                    alert("Шкаф удален")
                    this.$router.go(-1)
                }
            }
            catch (error) {
                console.error('Ошибка удаления шкафа:', error);
            } finally {
                // this.$router.go(-1);
            }
        }
    },
    setup(props) {
        const id = ref(props.projectId);


        watchEffect(() => {
            id.value = props.projectId;
        });
        return {
            id,
        }
    }
}
</script>
<style>
.deleteBtn {
    background-color: #9c1f09;
    padding: 0.5rem 2rem;
    border-radius: 20px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    color: #fff;
    max-width: fit-content;
    /* margin: 0 auto; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5em 0.8em;
}
</style>